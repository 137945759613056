import { useState } from 'react';
import ProgressSteps from 'components/steps';
import { RenderIf } from 'utilities/helpers';
import { SimpleImageUpload } from 'components/imgupload';
import { MainCategoryList } from '../category';
import { Button } from 'components/button';
import { useNavigate } from 'react-router-dom';

export const UploadMenuTemplate = ({ onGoBack, onChangeStep }) => {
  const navigate = useNavigate();
  const [uploadSuccess, setUploadSuccess] = useState(false);
  return (
    <>
      <RenderIf isTrue={!uploadSuccess}>
        <article
          style={{ minHeight: window.innerHeight }}
          className={`onflexlay`}
        >
          <div className="onbox">
            <h2>
              <strong>Add Menu</strong>
            </h2>
            <h5>Upload menu template</h5>
            <ProgressSteps active={3} />
            <h5 className="mt_20">
              <b>Fill a template</b>
            </h5>
            <div className="mt_20"></div>
            <h6>
              For many people, those days when you had a tuna salad sandwich for
              lunch are still some of the h...
            </h6>
            <h6 className="mt_25">
              <a href="/">Click here </a> to download the template
            </h6>
            <div className="mt_20"></div>
            <SimpleImageUpload
              label={''}
              name={'menuPicture'}
              // onChange={(e) => uploadPicture(e, 'menuPicture')}
              // error={
              //   errors?.menuPicture?.type === 'required'
              //     ? 'Menu item picture is required'
              //     : ''
              // }
              // validation={{
              //   required: true
              // }}
              // register={register}
              // image={categoryImg}
            />
            <h6 className="text_center mt_15">
              <a href="/">Click here </a> to upload the template
            </h6>
            <div className="mt_40"></div>
            <div className="pt_30"></div>
            <div className="onfooter">
              <Button
                type="button"
                label={`I'll do this later`}
                onClick={() => navigate('/dashboard')}
                className="alink"
                color="dark"
              />
              <div className="flex item_vcenter">
                <Button
                  onClick={() => onGoBack}
                  type="button"
                  label={'Back'}
                  color="outline-lite"
                />
                <Button
                  onClick={() => setUploadSuccess(true)}
                  type="button"
                  label={'Next'}
                  color="bg-button"
                />
              </div>
            </div>
          </div>
        </article>
      </RenderIf>
      <RenderIf isTrue={uploadSuccess}>
        <MainCategoryList
          onGoBack={onGoBack}
          onChangeStep={onChangeStep}
          tempUpload={true}
        />
      </RenderIf>
    </>
  );
};
