import Modal from 'react-modal';
import { Ikon } from 'assets/icons';
import styles from './index.module.css';

export const ModalComponent = ({
  children,
  modal,
  handleModal,
  contentLabel,
  modalMiddle = true,
  rightModal = false,
  chooseOption = false
}) => {
  Modal.setAppElement('#root');

  return (
    <div>
      <Modal
        isOpen={modal}
        onRequestClose={handleModal}
        contentLabel={contentLabel ? contentLabel : 'Modal'}
        style={{
          overlay: {
            backgroundColor: rightModal
              ? 'var(--bgOverlay1)'
              : 'var(--bgOverlay)',
            display: 'flex',
            justifyContent: rightModal ? 'flex-end' : 'center',
            alignItems: modalMiddle ? 'center' : 'flex-start',
            overflowY: 'auto',
            padding: rightModal ? 0 : '20px 0',
            zIndex: 'var(--modalZIndex)'
          },
          content: {
            inset: 'unset',
            backgroundColor: '#edf2f7',
            border: chooseOption ? '1px solid #edf2f7' : 0,
            borderRadius: rightModal ? 0 : chooseOption ? 10 : 16,
            padding: rightModal ? 40 : chooseOption ? 32 : '20px 16px',
            width: rightModal || chooseOption ? '100%' : 'auto',
            maxWidth: rightModal ? 620 : chooseOption ? 494 : 'auto',
            minHeight: rightModal ? '100%' : 'auto',
            top: rightModal ? 0 : 'auto',
            color: 'var(--dark)',
            overflow: 'visible'
          }
        }}
      >
        <div className={rightModal ? styles.closeR : styles.close}>
          <button
            type={`button`}
            className={styles.close_button}
            onClick={handleModal}
          >
            <Ikon closex />
          </button>
        </div>
        <div>{children}</div>
      </Modal>
    </div>
  );
};
