// import { Ikon } from 'assets/icons';
// import { useNavigate } from 'react-router-dom';
// import { QueryCache } from 'react-query';

// import { Ikon } from 'assets/icons';
// import { useNavigate } from 'react-router-dom';

export const OnBoardLayout = ({ children }) => {
  // const queryCache = new QueryCache({
  //   onError: (error) => {
  //     console.log(error);
  //   },
  //   onSuccess: (data) => {
  //     console.log(data);
  //   }
  // });

  // const navigate = useNavigate();

  // const handleLogout = () => {
  //   localStorage.clear();
  //   queryCache.clear();
  //   navigate('/login');
  //   window.location.reload();
  // };
  return (
    <>
      <main className="onboard_bg">
        <div style={{ minHeight: window.innerHeight }} className={`onflexlay`}>
          {/* <button className="logout" onClick={() => handleLogout()}>
            <span>
              <Ikon name="logout" />
            </span>
            <span>Logout</span>
          </button> */}
          {children}
        </div>
      </main>
    </>
  );
};
