import { useQuery } from 'react-query';
import { AXIOS_INSTANCE_ODS } from 'utilities/db';

export const useFetchOneDeviceHook = (keyName, deviceId) => {
  const { status, data, error, isFetching, isLoading, refetch } = useQuery(
    [keyName],
    () => getData(deviceId),
    {
      enabled: !!deviceId
    }
  );

  return {
    deviceStatus: status,
    deviceData: data,
    deviceError: error,
    deviceFetching: isFetching,
    deviceLoading: isLoading,
    deviceRefetch: refetch
  };
};

const getData = async (deviceId) => {
  let { data } = await AXIOS_INSTANCE_ODS.get(
    `/device/findOneDevice/${deviceId}`
  );
  return data;
};
