import { Button } from 'components/button';
import { useFetchKdsByBrands } from 'hooks/kds';
import { useNavigate } from 'react-router-dom';
import { useStore } from 'store';
import { RenderIf } from 'utilities/helpers';
import { Table } from 'rsuite';
import { CustomTable } from 'components/datatable';
import { Loading } from 'components/loading';

const { Column, HeaderCell, Cell } = Table;

const ProductCell = ({ rowData, dataKey, ...props }) => (
  <Cell {...props}>
    <b style={{ color: '#b789da', fontSize: '15px' }}>{rowData?.code}</b>
  </Cell>
);

export const KDSListings = () => {
  const navigate = useNavigate();

  const { brand } = useStore();

  const { brandKdsData, brandKdsLoading } = useFetchKdsByBrands(
    `kds/${brand?.id}`,
    brand?.id
  );

  console.log(
    '🚀 ~ file: index.jsx:13 ~ KDSListings ~ brandKdsData',
    brandKdsData
  );

  return (
    <>
      <div className={`mt_40`}></div>
      <div className={`flex item_space_btn pt_10`}>
        <div>
          <h1>
            <strong>KDS</strong>
          </h1>
        </div>
        <div>
          <Button
            label={'Add New KDS'}
            color="bg-titary"
            onClick={() => navigate('/KDS')}
          />
        </div>
      </div>
      <RenderIf isTrue={brandKdsLoading}>
        <div className={`loading_full`}>
          <Loading />
        </div>
      </RenderIf>
      <RenderIf isTrue={brandKdsData}>
        <div className={`mt_30`}></div>
        <CustomTable
          rows={brandKdsData
            ?.map((kds) => kds?.KDSScreenModel)
            ?.map((kds, index) => {
              return {
                id: index + 1,
                name: kds?.name || '-',
                status: kds?.status || '-',
                code: kds?.code || '-',
                device_info: kds?.device_info?.value || '-',
                created_at: kds?.created_at?.split('T')?.[0] || '-'
              };
            })}
          loading={brandKdsLoading}
          pageLimit={10}
          pagination={brandKdsData?.length > 10 ? true : false}
        >
          <Column width={60} align="center" fixed>
            <HeaderCell>Id</HeaderCell>
            <Cell dataKey="id" />
          </Column>
          <Column flexGrow={1} sortable>
            <HeaderCell>KDS Name</HeaderCell>
            <Cell dataKey="name" />
          </Column>
          <Column width={150} sortable>
            <HeaderCell>Status</HeaderCell>
            <Cell dataKey="status" />
          </Column>
          <Column width={180} sortable>
            <HeaderCell>Code</HeaderCell>
            <ProductCell dataKey="code" />
          </Column>
          <Column width={180} sortable>
            <HeaderCell>Device Info</HeaderCell>
            <Cell dataKey="device_info" />
          </Column>
          <Column width={180} sortable>
            <HeaderCell>Created At</HeaderCell>
            <Cell dataKey="created_at" />
          </Column>
        </CustomTable>
      </RenderIf>
    </>
  );
};
