import axios from 'axios';

console.log(process.env.REACT_APP_PORTAL_API_URL);

const request = axios.create({
  baseURL: process.env.REACT_APP_PORTAL_API_URL
});

export const AXIOS_INSTANCE_ZEWARDS = axios.create({
  baseURL: process.env.REACT_APP_ZEWARDS_API_URL
});

export const AXIOS_INSTANCE_ONBOARDING = axios.create({
  baseURL: process.env.REACT_APP_REVAMP_API_URL
});

export const DeploymentClient = axios.create({
  baseURL: process.env.REACT_APP_DEPLOYMENT_API_URL
});

export const AXIOS_INSTANCE_CUSTOMER_ORDERS = axios.create({
  baseURL: process.env.REACT_APP_CUSTOMER_API_URL
});

export const AXIOS_INSTANCE_ODS = axios.create({
  baseURL: process.env.REACT_APP_ODS_API_URL
});

request.interceptors.request.use(async (config) => {
  const token = window.localStorage.getItem('token') || null;
  config.headers['Content-Type'] = 'application/json';
  config.headers['ngrok-skip-browser-warning'] = false;
  if (token !== null) config.headers['access-token'] = `${token}`;
  return config;
});

request.interceptors.response.use((response) => {
  return response;
});

export const client = request;
