import { LineChart, Line, XAxis, Tooltip, ResponsiveContainer } from 'recharts';

const data = [
  {
    name: 'Page A',
    uv: 4000,
    pv: 2400,
    amt: 2400,
    count: 0
  },
  {
    name: 'Page B',
    uv: 3000,
    pv: 1398,
    amt: 2210,
    count: 6
  },
  {
    name: 'Page C',
    uv: 2000,
    pv: 9800,
    amt: 2290,
    count: 1
  },
  {
    name: 'Page D',
    uv: 2780,
    pv: 3908,
    amt: 2000,
    count: 9
  },
  {
    name: 'Page E',
    uv: 1890,
    pv: 4800,
    amt: 2181,
    count: 2
  },
  {
    name: 'Page F',
    uv: 2390,
    pv: 3800,
    amt: 2500,
    count: 5
  },
  {
    name: 'Page G',
    uv: 3490,
    pv: 4300,
    amt: 2100,
    count: 3
  },
  {
    name: 'Page H',
    uv: 3490,
    pv: 4300,
    amt: 2100,
    count: 11
  },
  {
    name: 'Page I',
    uv: 3490,
    pv: 4300,
    amt: 2100,
    count: 4
  },
  {
    name: 'Page J',
    uv: 3490,
    pv: 4300,
    amt: 2100,
    count: 8
  },
  {
    name: 'Page K',
    uv: 3490,
    pv: 4300,
    amt: 2100,
    count: 5
  },
  {
    name: 'Page L',
    uv: 3490,
    pv: 4300,
    amt: 2100,
    count: 7
  },
  {
    name: 'Page M',
    uv: 3490,
    pv: 4300,
    amt: 2100,
    count: 3
  },
  {
    name: 'Page N',
    uv: 3490,
    pv: 4300,
    amt: 2100,
    count: 9
  }
];

const CustomizedAxisTick = (props) => {
  const { x, y, payload } = props;

  return (
    <g transform={`translate(${x},${y})`}>
      <text x={5} y={10} dy={15} textAnchor="end" fill="#77838F" fontSize={12}>
        {payload?.value}
      </text>
    </g>
  );
};

export const LinechartComponent = ({ chartData }) => {
  return (
    <div style={{ width: `100%`, height: 96 }}>
      <ResponsiveContainer>
        <LineChart
          width={500}
          height={150}
          data={data}
          margin={{
            top: 10,
            right: 10,
            left: 10,
            bottom: 5
          }}
        >
          <XAxis
            dataKey="count"
            axisLine={false}
            tickLine={false}
            tick={<CustomizedAxisTick />}
            interval={0}
          />
          <Tooltip
            cursor={{
              stroke: '#A561D8',
              strokeWidth: 19,
              strokeOpacity: 0.2,
              strokeLinecap: 'round'
            }}
          />

          <Line
            type="natural"
            dataKey="count"
            stroke="#A561D8"
            strokeWidth={2}
            activeDot={{ r: 6 }}
            dot={{ r: 0 }}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};
