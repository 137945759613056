import { Controller } from 'react-hook-form';
import { RenderIf } from 'utilities/helpers';
import PhoneInput from 'react-phone-number-input';
import { Ikon } from 'assets/icons';
import styles from './index.module.css';

export const TextField = (props) => {
  const {
    label,
    type,
    id,
    name,
    value,
    onChange = () => {},
    placeholder,
    error,
    register = () => {},
    validation = {},
    info,
    infoChild,
    disabled,
    fieldSm = false,
    step,
    required,
    refEl
  } = props;

  return (
    <article className={styles.article}>
      <div className={styles.bx}>
        <RenderIf isTrue={label}>
          <div className={styles.labelBox}>
            <label htmlFor={id} className={styles.label}>
              {label} {required ? <span>*</span> : null}
            </label>
            <div>
              {info ? (
                <div className={styles.infobox}>
                  <Ikon info />
                  <div className={styles.infoChild}>
                    <Ikon indicatorArrow />
                    <div className={styles.infoContent}>{infoChild}</div>
                  </div>
                </div>
              ) : null}
            </div>
            {/* {required ? <span className={styles.required}>*</span> : null} */}
          </div>
        </RenderIf>
        <input
          {...register(name, validation)}
          type={type}
          name={name}
          className={
            fieldSm ? `${styles.input} ${styles.fieldSm}` : styles.input
          }
          id={id}
          value={value}
          onChange={onChange}
          disabled={disabled}
          placeholder={placeholder ? placeholder : ' '}
          step={step}
          // ref={refEl}
        />
      </div>
      <RenderIf isTrue={error}>
        <div className={styles.error}>{error}</div>
      </RenderIf>
    </article>
  );
};

export const FieldInput = (props) => {
  const {
    label,
    type,
    id,
    name,
    value,
    placeholder,
    error,
    register = () => {},
    validation = {},
    info,
    infoChild,
    disabled,
    fieldSm = false,
    required
  } = props;

  return (
    <article className={styles.article}>
      <div className={styles.bx}>
        <RenderIf isTrue={label}>
          <div className={styles.labelBox}>
            <label htmlFor={id} className={styles.label}>
              {label} {required ? <span>*</span> : null}
            </label>
            <div>
              {info ? (
                <div className={styles.infobox}>
                  <Ikon info />
                  <div className={styles.infoChild}>
                    <Ikon indicatorArrow />
                    <div className={styles.infoContent}>{infoChild}</div>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </RenderIf>
        <input
          {...register(name, validation)}
          type={type}
          name={name}
          className={
            fieldSm ? `${styles.input} ${styles.fieldSm}` : styles.input
          }
          id={id}
          value={value}
          disabled={disabled}
          placeholder={placeholder ? placeholder : ' '}
        />
      </div>
      <RenderIf isTrue={error}>
        <div className={styles.error}>{error}</div>
      </RenderIf>
    </article>
  );
};

export const PhoneField = (props) => {
  const {
    label,
    id,
    name,
    rules,
    control,
    placeholder,
    error,
    info,
    infoChild,
    disabled = false,
    required = false
  } = props;

  return (
    <article className={styles.article}>
      <RenderIf isTrue={label}>
        <div className={styles.labelBox}>
          <label htmlFor={id} className={styles.label}>
            {label} {required ? <span>*</span> : null}
          </label>
          <div>
            {info ? (
              <div className={styles.infobox}>
                <Ikon info />
                <div className={styles.infoChild}>
                  <Ikon indicatorArrow />
                  <div className={styles.infoContent}>{infoChild}</div>
                </div>
              </div>
            ) : null}
          </div>
          {/* {required ? <span className={styles.required}>*</span> : null} */}
        </div>
      </RenderIf>
      <Controller
        name={name}
        id={id}
        control={control}
        rules={rules}
        render={({ field }) => (
          <PhoneInput
            {...field}
            international
            countryCallingCodeEditable={false}
            //defaultCountry="US"
            placeholder={placeholder}
            disabled={disabled}
          />
        )}
      />
      <RenderIf isTrue={error}>
        <div className={styles.error}>{error}</div>
      </RenderIf>
    </article>
  );
};

export const TextFieldReffed = (props) => {
  const {
    label,
    type,
    id,
    name,
    value,
    onChange = () => {},
    placeholder,
    error,
    register = () => {},
    validation = {},
    info,
    infoChild,
    disabled,
    fieldSm = false,
    step,
    required,
    refEl
  } = props;

  return (
    <article className={styles.article}>
      <div className={styles.bx}>
        <RenderIf isTrue={label}>
          <div className={styles.labelBox}>
            <label htmlFor={id} className={styles.label}>
              {label} {required ? <span>*</span> : null}
            </label>
            <div>
              {info ? (
                <div className={styles.infobox}>
                  <Ikon info />
                  <div className={styles.infoChild}>
                    <Ikon indicatorArrow />
                    <div className={styles.infoContent}>{infoChild}</div>
                  </div>
                </div>
              ) : null}
            </div>
            {/* {required ? <span className={styles.required}>*</span> : null} */}
          </div>
        </RenderIf>
        <input
          {...register(name, validation)}
          type={type}
          name={name}
          className={
            fieldSm ? `${styles.input} ${styles.fieldSm}` : styles.input
          }
          id={id}
          value={value}
          onChange={onChange}
          disabled={disabled}
          placeholder={placeholder ? placeholder : ' '}
          step={step}
          ref={refEl}
        />
      </div>
      <RenderIf isTrue={error}>
        <div className={styles.error}>{error}</div>
      </RenderIf>
    </article>
  );
};
