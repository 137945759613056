import Iframe from 'react-iframe';
import { useStore } from 'store';

export default function UpdatePaymentForm() {
  const { brand, branch } = useStore((state) => state);

  return (
    <div style={{ width: '100%', height: '105vh' }}>
      <div style={{ marginBottom: '20px' }} />
      <Iframe
        url={`${process.env.REACT_APP_PAYMENT_ONBOARDING_URL}?buisnessId=${brand?.business_id}&brandId=${brand?.id}&branchId=${branch?.id}&level=branch&action=Onboard_Merchant&update=true`}
        width="100%"
        height="100%"
        id="myId"
        className="myClassname"
        display="block"
        position="relative"
        overflow="hidden"
        frameBorder={0}
      />
    </div>
  );
}
