import { RenderIf } from 'utilities/helpers';
import styles from './index.module.css';

export const BuilderTextField = ({
  label,
  type,
  name,
  id,
  min,
  max,
  minLength,
  maxLength,
  placeholder,
  defaultValue,
  error,
  onChange = () => {},
  marginField = false
}) => {
  return (
    <article className={styles.article}>
      <RenderIf isTrue={label}>
        <label>{label}</label>
      </RenderIf>
      <div>
        <input
          type={type ? type : `text`}
          name={name}
          id={id}
          min={min}
          max={max}
          minLength={minLength}
          maxLength={maxLength}
          placeholder={placeholder}
          defaultValue={defaultValue}
          style={{
            padding: marginField ? `6px 8px` : `6px 12px`,
            borderRadius: marginField ? 8 : 32,
            fontSize: marginField ? `12px` : `14px`,
            fontWeight: marginField ? `400` : `600`
          }}
          onChange={onChange}
        />
      </div>
      <RenderIf isTrue={error}>
        <div className={styles.error}>{error}</div>
      </RenderIf>
    </article>
  );
};

export const BuilderCheckboxfield = ({
  label,
  name,
  id,
  onChange,
  defaultChecked
}) => {
  return (
    <article className={styles.article}>
      <label className={`${styles.customCheckbox}`}>
        <input
          type={`checkbox`}
          name={name}
          id={id}
          defaultChecked={defaultChecked}
          onChange={onChange}
        />
        <span className={styles.checkmark}></span>
        {label}
      </label>
    </article>
  );
};
