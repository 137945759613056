import { BuilderJSON } from 'store';
import { AsideContentItem } from '../..';
import { BuilderColorPicker } from 'pages/storebuilder/components/forms/colorpicker';
import { BuilderUploadImage } from 'pages/storebuilder/components/forms/upload';
import { BuilderTextField } from 'pages/storebuilder/components/forms/input';

export const FooterStyle = () => {
  const {
    pages,
    setFooterBackgroundColor,
    setFooterBackgroundImage,
    setFooterForegroundColor,
    setFooterMarginTop,
    setFooterMarginBottom,
    setFooterPaddingTop,
    setFooterPaddingBottom
  } = BuilderJSON((state) => state);
  const { style } = pages?.footer;

  return (
    <>
      <AsideContentItem label={`Background`}>
        <div className={`builder_items`}>
          <BuilderColorPicker
            label={`Color`}
            activeColor={style?.backgroundColor}
            handleActiveColor={setFooterBackgroundColor}
          />
          <BuilderUploadImage
            label={`Image`}
            mediaRecommendations={`Recommended dimensions are 1600 x 750, types are jpg, jpeg, png, gif or svg`}
            defaultImage={style?.backgroundImage}
            setImage={setFooterBackgroundImage}
          />
        </div>
      </AsideContentItem>
      <AsideContentItem label={`Foreground`}>
        <div className={`builder_items`}>
          <BuilderColorPicker
            label={`Color`}
            activeColor={style?.foregroundColor}
            handleActiveColor={setFooterForegroundColor}
          />
        </div>
      </AsideContentItem>
      <AsideContentItem label={`Section spaces`}>
        <div className={`builder_items`}>
          <BuilderTextField
            type={`number`}
            label={`Margin top`}
            defaultValue={style?.marginTop}
            onChange={(e) => {
              setFooterMarginTop(e?.target?.value);
            }}
          />
          <BuilderTextField
            type={`number`}
            label={`Margin bottom`}
            defaultValue={style?.marginBottom}
            onChange={(e) => {
              setFooterMarginBottom(e?.target?.value);
            }}
          />
          <BuilderTextField
            type={`number`}
            label={`Padding top`}
            defaultValue={style?.paddingTop}
            onChange={(e) => {
              setFooterPaddingTop(e?.target?.value);
            }}
          />
          <BuilderTextField
            type={`number`}
            label={`Padding bottom`}
            defaultValue={style?.paddingBottom}
            onChange={(e) => {
              setFooterPaddingBottom(e?.target?.value);
            }}
          />
        </div>
      </AsideContentItem>
    </>
  );
};
