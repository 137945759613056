import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Toggle } from 'rsuite';
import styles from './index.module.css';
import { useNavigate, useParams } from 'react-router-dom';
import { useFetchOneDeviceHook } from 'hooks/finix-payments/useFetchOneDevice';
import { AXIOS_INSTANCE_ODS } from 'utilities/db';
import { Toasts } from 'components/toasts';
import { useStore } from 'store';
import { RenderIf } from 'utilities/helpers';
import { PrimaryButton } from 'components/button';
import { ModalComponent } from 'components/modal';
import { CustomSelectDropdown } from 'components/select/multi';

export const DeviceDetails = () => {
  const params = useParams();

  const { brand, branch } = useStore((state) => state);

  const hardCodedDevice = params?.id;
  console.log('🚀 ~ DeviceDetails ~ hardCodedDevice:', hardCodedDevice);

  const navigate = useNavigate();

  const [submitLoading, setSubmitLoading] = useState(false);
  const [updateSettingsModal, setUpdateSettingsModal] = useState(false);
  const [activateDeviceLoading, setActivateDeviceLoading] = useState(false);

  const { deviceData, deviceLoading, deviceRefetch } = useFetchOneDeviceHook(
    `device/${hardCodedDevice}`,
    hardCodedDevice
  );

  const device = deviceData?.result?.deviceDetails;

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    watch
  } = useForm({
    defaultValues: {
      enableTips: device?.enableTips,
      tipsPercentages: device?.tipsPercentages,
      tipsFixedAmounts: device?.tipsFixedAmounts
    }
  });

  useEffect(() => {
    if (device) {
      setValue(`enableTips`, device?.enable_tips);
      setValue(
        `tipsPercentages`,
        device?.tips_percentages?.map((tip: any) => ({
          value: tip,
          label: `${tip}%`
        }))
      );
      setValue(
        `tipsFixedAmounts`,
        device?.tips_fixed_amounts?.map((tip: any) => ({
          value: tip,
          label: `$${tip}`
        }))
      );
    }
  }, [device]);

  const handleActivateDevice = async () => {
    setActivateDeviceLoading(true);
    try {
      await AXIOS_INSTANCE_ODS.post(`/device/activateDevice`, {
        third_party_id: `${hardCodedDevice}`
      });
      deviceRefetch();
      Toasts.success('Device activated successfully');
    } catch (error) {
      Toasts.error(error?.response?.data?.message);
    } finally {
      setActivateDeviceLoading(false);
    }
  };

  const onSubmit = async (data) => {
    console.log(data);
    setSubmitLoading(true);
    try {
      await AXIOS_INSTANCE_ODS.post(`/device/updateDevice`, {
        third_party_id: `${hardCodedDevice}`,
        enableTips: data.enableTips, // optional
        tipsPercentages:
          data.tipsPercentages?.length > 0
            ? data.tipsPercentages?.map((tip) => Number(tip.value))
            : [], // optional
        tipsFixedAmounts:
          data.tipsFixedAmounts?.length > 0
            ? data.tipsFixedAmounts?.map((tip) => Number(tip.value) * 100)
            : [] // optional
      });
      deviceRefetch();
      Toasts.success('Device updated successfully');
      navigate(`/device-settings`);
    } catch (error) {
      Toasts.error(error?.response?.data?.message);
    } finally {
      setSubmitLoading(false);
    }
  };

  return (
    <div className={styles.page}>
      <RenderIf isTrue={deviceLoading}>
        <div>Loading...</div>
      </RenderIf>

      <RenderIf isTrue={!deviceLoading}>
        <header className={styles.header}>
          <div className={styles.backRw}>
            <div>
              <button
                className={styles.button}
                onClick={() => {
                  navigate(`/device-settings`);
                }}
              >
                {/* <Icon name={'back'} /> */}
              </button>
            </div>
            <div>
              <RenderIf isTrue={device}>
                <div className={styles.status}>
                  Status:
                  <div
                    className={styles.badge}
                    style={{
                      background: device?.enabled
                        ? 'var(--primary)'
                        : 'rgb(var(--grey-rgb))'
                    }}
                  >
                    {device?.enabled ? `Enabled` : `Disabled`}
                  </div>
                </div>
              </RenderIf>
              <h4>{device?.name}</h4>
            </div>
          </div>
          <div>
            <PrimaryButton onClick={() => setUpdateSettingsModal(true)}>
              Update Settings
            </PrimaryButton>
          </div>
        </header>
        <RenderIf isTrue={device}>
          <div className={styles.deviceRw}>
            <div>
              <p>Device modal:</p>
              <h6>{device?.model}</h6>
            </div>
            <div>
              <p>Serial number:</p>
              <h6>{device?.serialNumber}</h6>
            </div>
          </div>
          <p>{device?.description}</p>
          <footer className={styles.footer}>
            <RenderIf isTrue={!device?.enabled}>
              <PrimaryButton
                disabled={activateDeviceLoading}
                onClick={handleActivateDevice}
              >
                {activateDeviceLoading
                  ? `Activating device...`
                  : `Activate Device`}
              </PrimaryButton>
            </RenderIf>
          </footer>
        </RenderIf>
      </RenderIf>

      <ModalComponent
        modal={updateSettingsModal}
        handleModal={() => setUpdateSettingsModal(false)}
      >
        <div style={{ padding: `20px 0`, width: `500px` }}>
          <form onSubmit={handleSubmit(onSubmit)} className={styles.formBx}>
            <h4>Update Settings</h4>
            <div>
              <Controller
                name={`enableTips`}
                control={control}
                render={({ field }) => (
                  <div className={styles.toggleRw}>
                    <Toggle {...field} checked={field.value} />
                    <label>Enable Tips</label>
                  </div>
                )}
              />
            </div>

            <RenderIf isTrue={watch(`enableTips`)}>
              <div>
                <Controller
                  name={`tipsPercentages`}
                  control={control}
                  render={({ field }) => (
                    <CustomSelectDropdown
                      hookForm={true}
                      label={'Tips Percentages'}
                      typeArray={false}
                      isMulti={true}
                      field={field}
                      placeholder={`Select tips percentages`}
                      options={
                        // create options from 18 to 30
                        Array.from({ length: 13 }, (_, index) => ({
                          value: (index + 18).toString(),
                          label: `${index + 18}%`
                        }))
                      }
                    />
                  )}
                />
              </div>
              <div>
                <Controller
                  name={`tipsFixedAmounts`}
                  control={control}
                  render={({ field }) => (
                    <CustomSelectDropdown
                      label={'Tips Fixed Amounts'}
                      typeArray={false}
                      isMulti={true}
                      field={field}
                      placeholder={`Select tips fixed amounts`}
                      options={
                        // create options from 1 to 50 with step of 3
                        Array.from({ length: 10 }, (_, index) => ({
                          value: (index * 3 + 1).toString(),
                          label: `$${index * 3 + 1}`
                        }))
                      }
                    />
                  )}
                />
              </div>
            </RenderIf>
            <div style={{ display: `flex`, gap: `10px` }}>
              <PrimaryButton type="submit" disabled={submitLoading}>
                {submitLoading ? `Updating device...` : `Update Device`}
              </PrimaryButton>
              <PrimaryButton onClick={() => setUpdateSettingsModal(false)}>
                Cancel
              </PrimaryButton>
            </div>
          </form>
        </div>
      </ModalComponent>
    </div>
  );
};
