import { useState } from 'react';
import { RenderIf } from 'utilities/helpers';
import { ModalComponent } from 'components/modal';
import { ButtonDropdown } from 'components/select/button';
import { Button } from 'components/button';
import { AddBranchPage } from 'pages/branches/add';
import { Ikon } from 'assets/icons';
import styles from './index.module.css';
import { useParams } from 'react-router-dom';
import { useFetchSingleBrand } from 'hooks/brands/useBrandHook';
import { useFetchBranches } from 'hooks/resourceHooks';
import { useEffect } from 'react';
import { useDeleteBranch } from 'hooks/branch/useBranchHook';
import { Loading } from 'components/loading';

export const CardEmptyBrand = ({
  title,
  buttonLabel,
  buttonClick = () => {},
  ikonName
}) => {
  return (
    <>
      <div className={styles.emptyBox}>
        <div>
          <h2>{title}</h2>
          <div className="mt_40"></div>
          <Button
            color={`outline-primary`}
            label={buttonLabel}
            onClick={buttonClick}
          />
        </div>
        <div>
          <Ikon name={ikonName} />
        </div>
      </div>
    </>
  );
};

export const BrandDetailPage = () => {
  const { id } = useParams();
  const [data, setData] = useState([]);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const { data: brandData, isLoading } = useFetchSingleBrand(id);
  const {
    data: branchesData,
    isLoading: branchLoading,
    isSuccess,
    refetch: refetchBranch,
    isFetching: branchesLoadingAgain
  } = useFetchBranches(id);
  const { mutate, isSuccess: deleteBranchSucess } = useDeleteBranch();
  const branchesLoading = branchLoading || branchesLoadingAgain;
  const handleModal = () => {
    setIsOpenModal(!isOpenModal);
  };
  useEffect(() => {
    if (branchesData?.length > 0) {
      setData(branchesData);
    }
  }, [branchesData, isSuccess]);

  useEffect(() => {
    if (deleteBranchSucess) {
      refetchBranch();
    }
  }, [deleteBranchSucess, refetchBranch]);

  if (isLoading) {
    return (
      <div className={`loading_full`}>
        <Loading />
      </div>
    );
  }

  const deleteBranch = (id) => {
    mutate({
      id
    });
  };

  return (
    <>
      <article className={styles.article}>
        <div className="mt_35"></div>
        <div className={styles.header}>
          <div className={styles.headerLeft}>
            <picture className="imgplace">
              {brandData?.image_url ? (
                <img className="max-w-[150px]" src={brandData?.image_url} />
              ) : (
                <Ikon name="imgplaceholder" />
              )}
              <source srcSet="" />
              <img src="" alt="" />
            </picture>
            <div>
              <h2>
                <strong>{brandData?.brand_name}</strong>
              </h2>
              <h5>{brandData?.brand_type}</h5>
              <div className={styles.moreDetails}>
                <div>
                  <span>
                    <Ikon name="email" />
                  </span>
                  <span>{brandData?.brand_email}</span>
                </div>
                <div>
                  <span>
                    <Ikon name="phone" />
                  </span>
                  <span>{brandData?.phone_number}</span>
                </div>
                <div>
                  <span>
                    <Ikon name="tag" />
                  </span>
                  <span>{brandData?.ein}</span>
                </div>
              </div>
            </div>
          </div>
          <ButtonDropdown>
            <div className="bdd1 width_216">
              <Button type="button" className="bdd_item">
                Edit
              </Button>
              <Button type="button" className="bdd_item">
                Employees
              </Button>
              <Button type="button" className="bdd_item">
                Website Setup
              </Button>
              <Button type="button" className="bdd_item">
                Delete
              </Button>
            </div>
          </ButtonDropdown>
        </div>
        <div className="mt_40"></div>
        <div className="flex item_vcenter item_space_btn">
          <h2>
            <strong>Branches</strong>
          </h2>
          <Button
            type={`button`}
            label={`Add New Branch`}
            color={`bg-primary`}
            onClick={handleModal}
          />
        </div>
        <div className="mt_30"></div>
        <RenderIf isTrue={branchesLoading}>
          <div className={`loading_full`}>
            <Loading />
          </div>
        </RenderIf>
        <RenderIf isTrue={branchesData?.length === 0 && !branchesLoading}>
          <CardEmptyBrand
            title={`No Branches Found Yet! Add a Branch First`}
            buttonLabel={`Add New Branch`}
            buttonClick={handleModal}
            ikonName={`nobrand`}
          />
        </RenderIf>
        <RenderIf isTrue={branchesData?.length > 0}>
          <div className="brands">
            <table cellPadding={0} cellSpacing={0} className="table">
              <thead>
                <tr>
                  <th>Branch</th>
                  <th>State</th>
                  <th>City</th>
                  <th>Postal code</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {data?.map((item) => (
                  <tr>
                    <td>
                      <div className="flex item_vcenter">
                        <div className="brand_profile">
                          {data?.image ? (
                            <picture>
                              <source srcSet="" />
                              <img src="" alt="" />
                            </picture>
                          ) : (
                            <div className="imgplace">
                              <Ikon name="imgplaceholder" />
                            </div>
                          )}
                        </div>
                        <h6>
                          <b>{item?.branch_name}</b>
                        </h6>
                      </div>
                    </td>
                    <td>{item?.state}</td>
                    <td>{item?.city}</td>
                    <td>{item?.postal_code}</td>
                    <td>
                      <ButtonDropdown>
                        <div className="bdd1 width_216">
                          <Button
                            type="button"
                            className="bdd_item"
                            //   onClick={(e) => navigate(`/brand/detail/kfc`)}
                          >
                            View Details
                          </Button>
                          <Button
                            onClick={() => deleteBranch(item?.id)}
                            type="button"
                            className="bdd_item"
                          >
                            Delete
                          </Button>
                        </div>
                      </ButtonDropdown>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </RenderIf>
      </article>
      <RenderIf isTrue={isOpenModal}>
        <ModalComponent
          modal={isOpenModal}
          handleModal={handleModal}
          modalMiddle={false}
        >
          <AddBranchPage />
        </ModalComponent>
      </RenderIf>
    </>
  );
};
