import { client } from 'utilities/db';
import { useQuery } from 'react-query';

export const useFetchCategoriesByBranch = (keyName, id) => {
  const { status, data, error, isFetching, isLoading, refetch } = useQuery(
    [keyName, id],
    () => getData(id),
    {
      enabled: !!id,
      refetchInterval: 60000
    }
  );

  return {
    brandCategoriesStatus: status,
    brandCategoriesData: data,
    brandCategoriesError: error,
    brandCategoriesFetching: isFetching,
    brandCategoriesLoading: isLoading,
    brandCategoriesRefetch: refetch
  };
};

const getData = async (id) => {
  let result = await client.get(`/category/find-by-branch/${id}`);
  result = result.data?.data;
  return result;
};
