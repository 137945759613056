import { Link } from 'react-router-dom';
import { Button } from '../../components/button';
import { TextField } from '../../components/input';
import { useForm } from 'react-hook-form';
import { Ikon } from 'assets/icons';
import { useMutation } from 'react-query';
import { client } from 'utilities/db';
import { useEffect } from 'react';

export const LinkSquarePage = () => {
  const { mutate, isLoading, isSuccess, isError, error, data } = useMutation(
    (userData) => {
      return client.post(`/restaurant/addSecret/1`, userData);
    }
  );
  const {
    handleSubmit,
    register,
    formState: { errors }
  } = useForm();

  const onLinkSquare = (val) => {
    mutate({
      secret_keys: {
        sqaure: val?.link
      }
    });
  };

  useEffect(() => {
    if (!isError) return;
    // alert(error);
  }, [isError]);

  useEffect(() => {
    if (!isSuccess) return;
    // alert(data?.data?.message);
  }, [isSuccess]);

  return (
    <>
      <form onSubmit={handleSubmit(onLinkSquare)}>
        <div className="auth_box">
          <div className="mt_40"></div>
          <div className="pt_40"></div>
          <div className="mt_20"></div>
          <div className="auth_ikon">
            <span color="primary">
              <Ikon signin />
            </span>
          </div>
          <h1 className="mt_40">
            <strong>Link Square account</strong>
          </h1>
          <h5 className="mt_25">
            Enter the Square secret key to link square account.
          </h5>
          <div className="mt_40"></div>
          <div className="auth_form">
            <TextField
              name="link"
              label={'Secret Key'}
              error={
                errors?.link?.type === 'required' ? 'Link is required' : ''
              }
              register={register}
              validation={{
                required: true
              }}
              required
            />
            <div className="mt_40"></div>
            <div className="pt_20"></div>
            <div className={`field`}>
              <Button
                type="submit"
                label={'Continue to link with square'}
                color="bg-primary"
                full
                isLoading={isLoading}
              />
            </div>
            <div className="mt_25"></div>
            <div className={`field`}>
              <p>
                <Link to="/dashboard">I'll do this later</Link>
              </p>
            </div>
          </div>
        </div>
      </form>
      {error && (
        <div>
          <p style={{ color: 'red', fontWeight: '800' }}>
            {error?.response?.data?.message}
          </p>
        </div>
      )}
    </>
  );
};
