import { useStore } from 'store';
import { useFetchInventoryHook } from 'hooks/inventory/useFetchInventoryHook';
import { useFetchVendorsHook } from 'hooks/vendors/useFetchVendorsHook';
import { useFetchProductsHook } from 'hooks/products/useFetchProductsHook';
import { useFetchProductsCategoryHook } from 'hooks/products/useFetchProductsCategoryHook';
import { useFetchProductsGroupHook } from 'hooks/products/useFetchProductsGroupHook';
import { useFetchBranches, useFetchBrands } from 'hooks/resourceHooks';
import { BarchartComponent } from 'components/charts/barchart';
import { PiechartComponent } from 'components/charts/piechart';
import { LinechartComponent } from 'components/charts/linechart';
import { Ikon } from 'assets/icons';
import styles from './index.module.css';

export const InventoryOverviewPage = () => {
  const { brand, user, branch } = useStore((state) => state);

  const { inventoryData } = useFetchInventoryHook(
    `inventory/${brand?.id}`,
    branch?.id
  );
  const { vendorData } = useFetchVendorsHook(`vendors/${brand?.id}`, brand?.id);

  const { productsData } = useFetchProductsHook('products');
  const { productCategoryData } = useFetchProductsCategoryHook(
    'products-categories',
    branch?.id
  );
  const { productGroupData } = useFetchProductsGroupHook(
    'products-groups',
    branch?.id
  );

  const { data: branchesData } = useFetchBranches(brand?.id);

  const { data: brandsData } = useFetchBrands(user?.id);

  const filteredData = inventoryData?.filter((item) => {
    return (
      productsData
        ?.find((product) => product?.id === item?.product_id)
        ?.product_name?.toLowerCase() ||
      item?.unit?.toLowerCase() ||
      productCategoryData
        ?.find(
          (productCategory) => productCategory?.id === item?.product_category_id
        )
        ?.category_name?.toLowerCase() ||
      productGroupData
        ?.find((productGroup) => productGroup?.id === item?.product_group_id)
        ?.group_name?.toLowerCase() ||
      brandsData
        ?.find((brand) => brand?.id === item?.brand_id)
        ?.brand_name?.toLowerCase() ||
      branchesData
        ?.find((branch) => branch?.id === item?.branch_id)
        ?.branch_name?.toLowerCase() ||
      vendorData
        ?.find((vendor) => vendor?.id === item?.vendor_id)
        ?.vendor_information?.name?.toLowerCase() ||
      vendorData
        ?.find((vendor) => vendor?.id === item?.vendor_id)
        ?.vendor_information?.email?.toLowerCase()
    );
  });

  return (
    <>
      <header className={styles.header}>
        <div>
          <div className={styles.headCard} style={{ background: `#40C978` }}>
            <div>
              <p>Total items</p>
              <h5>{filteredData?.length}</h5>
            </div>
            <div className={styles.headCardIcon}>
              <Ikon name={`all-products`} fillColor={`var(--white)`} />
            </div>
          </div>
        </div>
        <div>
          <div className={styles.headCard} style={{ background: `#FE9F43` }}>
            <div>
              <p>Low stock</p>
              <h5>
                {
                  filteredData?.filter((item) => item?.low_stock === true)
                    ?.length
                }
              </h5>
            </div>
            <div className={styles.headCardIcon}>
              <Ikon name={`users`} fillColor={`var(--white)`} />
            </div>
          </div>
        </div>
        <div>
          <div className={styles.headCard} style={{ background: `#F06071` }}>
            <div>
              <p>Expired</p>
              <h5>
                {
                  filteredData?.filter(
                    (item) =>
                      new Date(item?.expiry_date).getTime() <
                      new Date().getTime()
                  )?.length
                }
              </h5>
            </div>
            <div className={styles.headCardIcon}>
              <Ikon name={`locationPin`} fillColor={`var(--white)`} />
            </div>
          </div>
        </div>
        {/* <div>
          <div className={styles.headCard} style={{ background: `#7B3ACE` }}>
            <div>
              <p>Most popular</p>
              <h5>28</h5>
            </div>
            <div className={styles.headCardIcon}>
              <Ikon name={`locationPin`} fillColor={`var(--white)`} />
            </div>
          </div>
        </div> */}
        <div>
          <div className={styles.headCard} style={{ background: `#00CFE8` }}>
            <div>
              <p>Suppliers</p>
              <h5>
                {
                  filteredData?.filter(
                    (item, index) =>
                      filteredData.indexOf(item?.vendor_id) !== index
                  )?.length
                }
              </h5>
            </div>
            <div className={styles.headCardIcon}>
              <Ikon name={`locationPin`} fillColor={`var(--white)`} />
            </div>
          </div>
        </div>
      </header>
      <div className={styles.content}>
        <div>
          <div className={styles.cardBx}>
            <header className={styles.cardHeader}>
              <div>
                <h6>Item inventory</h6>
                <div className={styles.badge}>Buying</div>
              </div>
              <div>
                <div className={styles.dateFilter}>Last date added</div>
              </div>
            </header>
            <div>
              <BarchartComponent paddHeightInPercent={`46.6%`} />
            </div>
          </div>
          <div className={styles.fRow}>
            <div>
              <div className={styles.cardBx}>
                <header className={styles.cardHeader}>
                  <div>
                    <h6>Items by revenue</h6>
                  </div>
                  <div>
                    <div className={styles.dateFilter}>This Week</div>
                  </div>
                </header>
                <div>
                  <PiechartComponent />
                </div>
              </div>
            </div>
            <div>
              <div className={styles.primaryCard}>
                <div>
                  <div className={styles.primaryCardIcon}>
                    <Ikon name={`all-products`} fillColor={`var(--white)`} />
                  </div>
                </div>
                <div>
                  <div>
                    <p>All Products</p>
                    <h4>45</h4>
                  </div>
                  <div>
                    <p>In-stock</p>
                    <h4>
                      32 <small>+24%</small>
                    </h4>
                  </div>
                </div>
              </div>
              <div className={styles.cardBx} style={{ padding: `8px 12px` }}>
                <header className={styles.cardHeader}>
                  <div>
                    <h6>Inventory usage</h6>
                  </div>
                  <div>
                    <div className={styles.dateFilter}>Nov</div>
                  </div>
                </header>
                <div>
                  <LinechartComponent />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className={styles.cardBx}>
            <header className={styles.cardHeader}>
              <div>
                <h6>Item inventory</h6>
              </div>
              <div>
                <div className={styles.dateFilter}>Last 10 items</div>
              </div>
            </header>
            <div>Latest items here...</div>
          </div>
        </div>
      </div>
    </>
  );
};
