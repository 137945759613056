import { useEffect, useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation } from 'react-query';
import { useStore } from 'store';
import { useFetchModifiersByBranch } from 'hooks/modifiers/useModifierHook';
import { client } from 'utilities/db';
import { getUser } from 'utilities/helpers/auth';
import { RenderIf } from 'utilities/helpers';
import { AddMenuModifier } from './add';
import { TextField } from 'components/input';
import { Button } from 'components/button';
import { ButtonDropdown } from 'components/select/button';
import { Toasts } from 'components/toasts';
import { Ikon } from 'assets/icons';
import { Loading } from 'components/loading';

export const MenuModifier = () => {
  const { brand, branch } = useStore((state) => state);
  const { mutate, isLoading, isSuccess, isError, error, data } = useMutation(
    (userData) => {
      return client.post(
        isEdit ? `/modifierGroup/update/id` : `/modifierGroup/addModifier`,
        userData
      );
    }
  );
  const {
    isLoading: modifierLoading,
    refetch: refetchModifiers,
    data: modifiersList
  } = useFetchModifiersByBranch(branch?.id);
  const navigate = useNavigate();
  const user = getUser();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [searchtext, setSearchtext] = useState('');
  const [formData, setFormData] = useState([
    {
      option_name: '',
      option_price: '',
      add_separate: false
    }
  ]);

  useEffect(() => {
    if (!user?.id) {
      navigate('/login');
      return;
    }
  }, [user]);

  const addMenu = (val) => {
    console.log(val);
    mutate({
      brand_id: brand?.id,
      branch_id: branch?.id,
      modifier_group_name: val?.name,
      modifier_group_description: '',
      modifier_group_level: 'BRANCH',
      is_required: val?.is_required === 'REQUIRED' ? true : false,
      image: '',
      menu_item_id: val?.link_menu_items?.value,
      modifiers:
        val?.modifiers?.map((item) => ({
          modifier_name: item?.modifier,
          modifier_description: '',
          modifier_level: 'BRANCH',
          additional_price: item?.modifier_price,
          calories: 0,
          image: ''
        })) || [],
      settings: {
        free_choice_quantity: val?.free_choice_quantity,
        free_choice: val?.free_choice,
        modifier_charges: val?.modifier_charges
      }
    });
  };

  const onClose = () => {
    setIsOpenModal(false);
    setIsEdit(false);
  };

  useMemo(() => {
    if (isSuccess) {
      refetchModifiers();
      onClose();
      Toasts.success('Modifier group has been added.');
    }
  }, [isSuccess]);

  useEffect(() => {
    if (!isError) return;
    Toasts.error(error?.response?.data?.message);
  }, [isError]);

  return (
    <>
      <div className="mt_40"></div>
      <div className="flex item_space_btn pt_10">
        <div>
          <h1>
            <strong>Modifier groups</strong>
          </h1>
          <h5 className="mt_4">Modifier groups</h5>
        </div>
        <div style={{ opacity: 0.4 }}>
          <Button disabled label={'Download guidebook'} color="bg-titary" />
        </div>
      </div>
      <div className="mt_32"></div>
      <div className="add_new_grid">
        <AddMenuCards
          name="fileChecked"
          heading={`Add modifier group`}
          description={`Create your modifier group from scratch`}
          onClick={() => {
            setIsOpenModal(true);
          }}
        />
      </div>
      <RenderIf isTrue={modifiersList?.length > 0}>
        <div className="mt_32"></div>
        <h4>
          <span className="ff_semi">Modifiers</span>
        </h4>
        <div className="mt_32"></div>
        <div className="box2 flex item_vcenter item_space_btn">
          <div className="w_356">
            <TextField
              value={searchtext}
              onChange={(e) => setSearchtext(e?.target?.value)}
              placeholder={'Search'}
            />
          </div>
          <div></div>
        </div>
      </RenderIf>

      <div className="pt_8">
        {modifierLoading ? (
          <div className={`loading_full`}>
            <Loading />
          </div>
        ) : (
          <>
            {modifiersList
              ?.filter((item) =>
                item?.modifier_group_name
                  ?.toLowerCase()
                  ?.includes(searchtext?.toLowerCase())
              )
              ?.map((item, ind) => (
                <div key={ind}>
                  <ModifierListCard item={item} />
                </div>
              ))}
          </>
        )}
      </div>
      {isOpenModal && (
        <AddMenuModifier
          isLoading={isLoading}
          addMenu={addMenu}
          onClose={onClose}
          isOpenModal={isOpenModal}
          formData={formData}
          setFormData={setFormData}
        />
      )}
    </>
  );
};

const AddMenuCards = ({ name, heading, description, onClick }) => {
  return (
    <>
      <div>
        <button className="add_new_button" onClick={onClick}>
          <div>
            <div>
              <Ikon name={name} />
            </div>
            <h4>{heading}</h4>
            <p>{description}</p>
          </div>
        </button>
      </div>
    </>
  );
};

const ModifierListCard = ({ item }) => {
  const navigate = useNavigate();

  return (
    <>
      <div className="cat_box cat_box2 mt_24">
        <div className="cat_head1">
          <div className="cat_head1_top">
            <div className="head_flex1">
              <h6>
                <span className="ff_semi">{item?.modifier_group_name}</span>
              </h6>
              <div className="badge">
                {item?.is_required ? 'REQUIRED' : 'OPTIONAL'}
              </div>
              <RenderIf isTrue={item?.settings?.free_choice_quantity}>
                <div className="badge badge2">
                  {item?.settings?.free_choice_quantity} FREE CHOICE
                </div>
              </RenderIf>
            </div>
            <div className="action_btns">
              {/* <button type="button" className="alink mr_10">
                <span className="ff_semi" color="primary">
                  Add new options
                </span>
              </button> */}
              <ButtonDropdown positionX="rzero">
                <button
                  className="button button_radius"
                  color="bg-primary"
                  onClick={() =>
                    navigate(`/menu-engineering/modifier/detail/${item?.id}`)
                  }
                >
                  Details
                </button>
              </ButtonDropdown>
            </div>
          </div>
          <RenderIf
            isTrue={item?.settings?.modifier_charges === 'NOADDITIONAL'}
          >
            <div className="attach_branch">
              <div className="attach_ikon">
                <Ikon name={`attachbox`} />
              </div>
              <div>No Additional Charges</div>
            </div>
          </RenderIf>
          <RenderIf isTrue={item?.settings?.modifier_charges === 'BYOPTION'}>
            <div className="attach_branch">
              <div className="attach_ikon">
                <Ikon name={`attachbox`} />
              </div>
              <div>Per option price</div>
            </div>
          </RenderIf>
          <RenderIf isTrue={item?.settings?.modifier_charges === 'BYGROUP'}>
            <div className="attach_branch">
              <div className="attach_ikon">
                <Ikon name={`attachbox`} />
              </div>
              <div>Per modifier group price</div>
            </div>
          </RenderIf>
        </div>
        <hr />
        {item?.modifier?.map((data) => (
          <div className="modifier_item">
            <div>
              <span className="ff_semi">{data?.modifier_name}</span>
            </div>
            <div>
              <span className="ff_semi">$ {data?.additional_price}</span>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};
