import { client } from 'utilities/db';
import { useQuery } from 'react-query';

export const useFetchProductsCategoryHook = (keyName, id) => {
  const { status, data, error, isFetching, isLoading, refetch } = useQuery(
    [keyName, id],
    () => getProductCategory(id),
    {
      enabled: !!id,
      refetchInterval: 60000
    }
  );

  return {
    productCategoryStatus: status,
    productCategoryData: data,
    productCategoryError: error,
    productCategoryFetching: isFetching,
    productCategoryLoading: isLoading,
    productCategoryRefetch: refetch
  };
};

const getProductCategory = async (id) => {
  let result = await client.get(`/productCategories/findAllByBranch/${id}`);
  result = result.data;
  result = result?.productCategories;

  return result;
};
