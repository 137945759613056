import { useNavigate } from 'react-router-dom';
import { Ikon } from 'assets/icons';

export const SetupDomain = () => {
  return (
    <article className="content1">
      <div className="max_width760">
        <div className="text_center">
          <h1>
            <strong>Setup domain for your online store</strong>
          </h1>
          <div className="mt_30"></div>
          <h3>
            This is the last step of the process before you deploy your website
            is to get a domain.
          </h3>
        </div>
        <div className="mt_40"></div>
        <DomainOptionList
          icon="https://cdn.icon-icons.com/icons2/2596/PNG/512/check_one_icon_155665.png"
          title="Do you already have a domain"
          description="If you already have a domain you can transfer it to our system."
          link="/setupdomain/existingdomain"
        />
        <div className="mt_25"></div>
        <DomainOptionList
          icon="https://cdn.icon-icons.com/icons2/2596/PNG/512/check_one_icon_155665.png"
          title="Purchase a new domain"
          description="You can purchase a new domain"
          link="/setupdomain/newdomain"
        />
      </div>
    </article>
  );
};

const DomainOptionList = ({ icon, title, description, link }) => {
  const navigate = useNavigate();
  return (
    <button className={`button_choose`} onClick={() => navigate(link)}>
      <div>
        <div className="ikon">
          <Ikon domain />
        </div>
      </div>
      <div>
        <h5>{title}</h5>
        <h6>{description}</h6>
      </div>
    </button>
  );
};
