import { Button } from 'components/button';
import React, { useState } from 'react';
import styles from './index.module.css';
import PrepareToItemAccordion from '../PrepareToItemAccordion';
import img from '../../../assets/Images/Img.png';
export default ({
  toPrepareMixtures = [],
  toPrepareRecipes = [],
  newBatchModal = false,
  setNewBatchModal,
  refetch
}) => {
  const [toPrepareType, setToPrepareType] = useState('Recipes');
  const [selectedItem, setSelectedItem] = useState('');

  return (
    <>
      <div className="mt_30"></div>
      <div className={styles.block}>
        <div className={styles.head}>
          <h5>To Prepare</h5>

          <div className={styles.btn_con}>
            <Button
              className={styles.btn}
              onClick={() => {
                setToPrepareType('Recipes');
                setSelectedItem('');
              }}
              type="button"
              label={'Recipes'}
              color={toPrepareType === 'Recipes' ? 'bg-button' : 'outline-lite'}
            />
            <Button
              className={styles.btn}
              onClick={() => {
                setToPrepareType('mixture');
                setSelectedItem('');
              }}
              type="button"
              label={'Mixtures'}
              color={toPrepareType === 'mixture' ? 'bg-button' : 'outline-lite'}
            />
          </div>
        </div>

        {toPrepareType === 'Recipes' ? (
          <div>
            {toPrepareRecipes.map((item, i) => (
              <PrepareToItemAccordion
                // setOverridePrediction={setOverridePrediction}
                onClick={() => {
                  if (selectedItem === i) {
                    setSelectedItem('');
                    return;
                  }
                  setSelectedItem(i);
                }}
                isOpen={selectedItem === i}
                image={img}
                name={item?.recipe_name}
                stockAvailable={item?.totalInventoryQuantity}
                stockRequired={item?.total_servings}
                unit={item?.recipeUnit || 'Servings'}
                setNewBatchModal={setNewBatchModal}
                item={item}
                refetch={refetch}
              />
            ))}
          </div>
        ) : (
          <div>
            {
              // toPrepareMixtures.
              [1, 1].map((item, i) => (
                <PrepareToItemAccordion
                  // setOverridePrediction={setOverridePrediction}
                  onClick={() => {
                    if (selectedItem === i) {
                      setSelectedItem('');
                      return;
                    }
                    setSelectedItem(i);
                  }}
                  isOpen={selectedItem === i}
                  image={img}
                  name={item?.mixture_name}
                  stockAvailable={item?.totalInventoryQuantity}
                  stockRequired={item?.total_servings}
                  unit={item?.recipeUnit || 'Servings'}
                  setNewBatchModal={setNewBatchModal}
                  item={item}
                  isMixture={true}
                  refetch={refetch}
                />
              ))
            }
          </div>
        )}
      </div>
    </>
  );
};
