import { CheckboxField } from 'components/checkbox';
import React from 'react';

export const AllOrders = () => {
  return (
    <>
      <div className="mt_30"></div>
      <table cellPadding={0} cellSpacing={0} className="table">
        <thead>
          <tr>
            <th></th>
            <th>Order No</th>
            <th>ORder Time</th>
            <th>Time Served</th>
            <th>Brand</th>
            <th>Order Mode</th>
            <th>Order Type</th>
            <th>ORder VAlue</th>
          </tr>
        </thead>
        <tbody>
          {/* item row */}
          {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((item, index) => (
            <tr key={index}>
              <td>
                <CheckboxField name={`order${index}_${item}`}>
                  &nbsp;
                </CheckboxField>
              </td>
              <td>Château Lafite Rothschild (Bordeaux)</td>
              <td>12:31 Pm</td>
              <td>12:31 Pm</td>
              <td>KFC</td>
              <td>Zewst Online</td>
              <td>5th</td>
              <td>13$</td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};
