import { useState } from 'react';
import { RenderIf } from 'utilities/helpers';
import styles from './index.module.css';

export const ThemeTabs = ({ tabs, panels, isSearch, isSearchFull }) => {
  const [activeIndex, setActiveIndex] = useState(1);
  const handleClick = (index) => setActiveIndex(index);
  const checkActive = (index, className) =>
    activeIndex === index ? className : '';
  return (
    <>
      <RenderIf isTrue={isSearch}>
        <div className={styles.tabsBox}>
          <div>
            <div className={styles.tabs}>
              {tabs?.length &&
                tabs?.map((tab, index) => (
                  <button
                    key={index}
                    className={`${styles.tab} ${checkActive(
                      index + 1,
                      styles.active
                    )}`}
                    style={{ margin: '5px' }}
                    onClick={() => handleClick(index + 1)}
                  >
                    {tab?.label}
                  </button>
                ))}
            </div>
          </div>
          <div>{isSearch}</div>
        </div>
      </RenderIf>
      <RenderIf isTrue={isSearchFull}>
        <div className={styles.tabsBox}>
          <div>
            <div className={styles.tabs}>
              {tabs?.length &&
                tabs?.map((tab, index) => (
                  <button
                    key={index}
                    className={`${styles.tab} ${checkActive(
                      index + 1,
                      styles.active
                    )}`}
                    onClick={() => handleClick(index + 1)}
                  >
                    {tab?.label}
                  </button>
                ))}
            </div>
          </div>
        </div>
        {isSearchFull}
      </RenderIf>
      <RenderIf isTrue={!isSearch && !isSearchFull}>
        <div className={styles.tabs}>
          {tabs?.length &&
            tabs?.map((tab, index) => (
              <button
                key={index}
                className={`${styles.tab} ${checkActive(
                  index + 1,
                  styles.active
                )}`}
                onClick={() => handleClick(index + 1)}
              >
                {tab?.label}
              </button>
            ))}
        </div>
      </RenderIf>
      <div className={styles.panels}>
        {panels?.length &&
          panels?.map((panel, i) => (
            <div
              key={i}
              className={`${styles.panel} ${checkActive(i + 1, styles.active)}`}
            >
              {panel.content}
            </div>
          ))}
      </div>
    </>
  );
};
