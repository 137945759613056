import create from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { persist } from 'zustand/middleware';

export const useStore = create(
  persist(
    immer((set) => ({
      user: null,
      setUser: (user) =>
        set((state) => {
          state.user = user;
          localStorage.setItem('user', JSON.stringify(user));
        }),
      onboardStep: 1,
      handleOnboardStep: () =>
        set((state) => ({ ...state, onboardStep: state.onboardStep + 1 })),
      branchesSameMenu: true,
      handleBranchesSameMenu: () =>
        set((state) => ({
          ...state,
          branchesSameMenu: !state.branchesSameMenu
        })),
      branch: null,
      brand: null,
      setBranch: (branch) =>
        set((state) => {
          state.branch = branch;
          window.localStorage.setItem(
            'defaultBranch',
            branch ? JSON.stringify(branch) : null
          );
        }),
      setBrand: (brand) =>
        set((state) => {
          state.brand = brand;
          window.localStorage.setItem(
            'defaultBrand',
            brand ? JSON.stringify(brand) : null
          );
          window.localStorage.setItem(
            'onboardLocationId',
            brand ? brand.id : null
          );
        }),
      loyaltyId: null,
      setLoyaltyId: (loyaltyId) =>
        set((state) => {
          state.loyaltyId = loyaltyId;
        }),
      buisnessId: null,
      setBuisnessId: (buisnessId) =>
        set((state) => {
          state.buisnessId = buisnessId;
        }),
      selectedBuilderMode: 'basic',
      setSelectedBuilderMode: (mode) =>
        set((state) => {
          state.selectedBuilderMode = mode;
        }),
      isWebMenu: true,
      setIsWebMenu: (isWebMenu) => {
        set((state) => {
          state.isWebMenu = isWebMenu;
        });
      }
    })),
    {
      name: 'zewst-portal-maininfo'
    }
  )
);

export const StoreBuilderStore = create(
  //persist(
  immer(
    (set) => ({
      data: null,
      setData: (data) =>
        set((state) => {
          state.data = data;
        }),
      setStorethemeColor: (color) =>
        set((state) => {
          state.data.theme.color = color;
        }),
      setStorethemeTemplate: (template) =>
        set((state) => {
          state.data.theme.template = template;
        }),
      setStoreHeaderImage: (image) =>
        set((state) => {
          state.data.header.image = image;
        }),
      setStoreHomeCarouselNewSlide: () =>
        set((state) => {
          state.data.homeCarousel.push({
            image: '',
            textClass: '',
            title: '',
            description: ''
          });
        }),
      removeStoreHomeCarouselNewSlide: (index) =>
        set((state) => {
          state.data.homeCarousel = state.data.homeCarousel.filter(
            (item, i) => i !== index
          );
        }),
      setStoreHomeCarouselImage: (image, index) =>
        set((state) => {
          state.data.homeCarousel[index].image = image;
        }),
      setStoreHomeCarouselTextClass: (color, index) =>
        set((state) => {
          state.data.homeCarousel[index].textClass = color;
        }),
      setStoreHomeCarouselTitle: (title, index) =>
        set((state) => {
          state.data.homeCarousel[index].title = title;
        }),
      setStoreHomeCarouselDescription: (description, index) =>
        set((state) => {
          state.data.homeCarousel[index].description = description;
        }),
      setStoreHomeFeaturedSectionHeading: (heading) =>
        set((state) => {
          state.data.homeFeatured.homeFeaturedSection.heading = heading;
        }),
      setStoreHomeFeaturedSectionTitle: (title) =>
        set((state) => {
          state.data.homeFeatured.homeFeaturedSection.title = title;
        }),
      setStoreHomeFeaturedSectionDescription: (description) =>
        set((state) => {
          state.data.homeFeatured.homeFeaturedSection.description = description;
        }),
      setStoreHomeFeaturedNewSlide: () =>
        set((state) => {
          state.data.homeFeatured.homeFeaturedItems.push({
            image: '',
            title: '',
            description: '',
            category: ''
          });
        }),
      removeStoreHomeFeaturedNewSlide: (index) =>
        set((state) => {
          state.data.homeFeatured.homeFeaturedItems =
            state.data.homeFeatured.homeFeaturedItems.filter(
              (item, i) => i !== index
            );
        }),
      setStoreHomeFeaturedCarouselImage: (image, index) =>
        set((state) => {
          state.data.homeFeatured.homeFeaturedItems[index].image = image;
        }),
      setStoreHomeFeaturedCarouselTitle: (title, index) =>
        set((state) => {
          state.data.homeFeatured.homeFeaturedItems[index].title = title;
        }),
      setStoreHomeFeaturedCarouselDescription: (description, index) =>
        set((state) => {
          state.data.homeFeatured.homeFeaturedItems[index].description =
            description;
        }),
      setStoreHomeFeaturedCarouselCategory: (category, index) =>
        set((state) => {
          state.data.homeFeatured.homeFeaturedItems[index].category = category;
        }),
      setStoreHomeFooterSectionImage: (image) =>
        set((state) => {
          state.data.homeFooterSection.image = image;
        }),
      setStoreHomeFooterSectionTextClass: (color) =>
        set((state) => {
          state.data.homeFooterSection.textClass = color;
        }),
      setStoreHomeFooterSectionTitle: (title) =>
        set((state) => {
          state.data.homeFooterSection.title = title;
        }),
      setStoreFooterFacebookURL: (url) =>
        set((state) => {
          state.data.footer.socials.facebook = url;
        }),
      setStoreFooterTwitterURL: (url) =>
        set((state) => {
          state.data.footer.socials.twitter = url;
        }),
      setStoreFooterInstagramURL: (url) =>
        set((state) => {
          state.data.footer.socials.instagram = url;
        }),
      setStoreFooterEmail: (email) =>
        set((state) => {
          state.data.footer.socials.email = email;
        }),
      setStoreFooterCopyright: (label) =>
        set((state) => {
          state.data.footer.copyright = label;
        }),
      setStoreAboutBannerNewSlide: () =>
        set((state) => {
          state.data.aboutCarousel.push({
            image: '',
            textClass: '',
            title: '',
            description: ''
          });
        }),
      setStoreAboutBannerImage: (image, index) =>
        set((state) => {
          state.data.aboutCarousel[index].image = image;
        }),
      setStoreAboutBannerTextClass: (color, index) =>
        set((state) => {
          state.data.aboutCarousel[index].textClass = color;
        }),
      setStoreAboutBannerTitle: (title, index) =>
        set((state) => {
          state.data.aboutCarousel[index].title = title;
        }),
      setStoreAboutBannerDescription: (description, index) =>
        set((state) => {
          state.data.aboutCarousel[index].description = description;
        }),
      setStoreAboutSectionsImage: (image, index) =>
        set((state) => {
          state.data.aboutSections[index].image = image;
        }),
      setStoreAboutSectionsTitle: (title, index) =>
        set((state) => {
          state.data.aboutSections[index].title = title;
        }),
      setStoreAboutSectionsDescription: (description, index) =>
        set((state) => {
          state.data.aboutSections[index].description = description;
        }),
      setStoreAboutFooterSectionImage: (image) =>
        set((state) => {
          state.data.aboutFooterSection.image = image;
        }),
      setStoreAboutFooterSectionTextClass: (color) =>
        set((state) => {
          state.data.aboutFooterSection.textClass = color;
        }),
      setStoreAboutFooterSectionTitle: (title) =>
        set((state) => {
          state.data.aboutFooterSection.title = title;
        }),
      setStoreContactCarouselNewSlide: () =>
        set((state) => {
          state.data.contactCarousel.push({
            image: '',
            textClass: '',
            title: '',
            description: ''
          });
        }),
      setStoreContactCarouselImage: (image, index) =>
        set((state) => {
          state.data.contactCarousel[index].image = image;
        }),
      setStoreContactCarouselTextClass: (color, index) =>
        set((state) => {
          state.data.contactCarousel[index].textClass = color;
        }),
      setStoreContactCarouselTitle: (title, index) =>
        set((state) => {
          state.data.contactCarousel[index].title = title;
        }),
      setStoreContactCarouselDescription: (description, index) =>
        set((state) => {
          state.data.contactCarousel[index].description = description;
        }),
      setStoreContactFooterSectionImage: (image) =>
        set((state) => {
          state.data.contactFooterSection.image = image;
        }),
      setStoreContactFooterSectionTextClass: (color) =>
        set((state) => {
          state.data.contactFooterSection.textClass = color;
        }),
      setStoreContactFooterSectionTitle: (title) =>
        set((state) => {
          state.data.contactFooterSection.title = title;
        }),
      setStoreLoyaltyId: (loyaltyId, buisnessId) =>
        set((state) => {
          state.data.theme = {
            ...state.data.theme,
            loyaltyId: loyaltyId,
            buisnessId: buisnessId
          };
        })
    })
    // ),
    // {
    //   name: 'website-builder'
    // }
  )
);

export const BuilderJSON = create(
  // persist(
  immer((set) => ({
    pages: {
      projectName: 'Zewst Online Store',
      pageName: 'Landing Page',
      global: {
        content: {
          title: `ZEWST - Store`,
          description: `ZEWST Online store description here...`,
          favicon: `https://placehold.co/32x32.png`,
          logo: `https://placehold.co/140x32.png`,
          logoWidth: 140,
          social: {
            twitter: `https://www.twitter.com`,
            discord: `https://www.discord.com`,
            instagram: `https://www.instagram.com`,
            facebook: ``,
            snapchat: `https://www.snapchat.com`,
            tiktok: ``
          }
        },
        style: {
          colors: [
            { code: `` },
            { code: `rgba(46, 39, 42, 1)` },
            { code: `rgba(0, 0, 0, 1)` },
            { code: `rgba(12, 163, 79, 1)` },
            { code: `rgba(240, 97, 110, 1)` },
            { code: `rgba(7, 113, 132, 1)` }
          ],
          headerFont: ``,
          bodyFont: ``,
          buttonFont: ``,
          backgroundColor: `rgba(254, 251, 249, 1)`,
          backgroundImage: ``,
          foregroundColor: `rgba(0, 0, 0, 1)`,
          button: {
            primary: {
              backgroundColor: `rgba(20, 20, 20, 1)`,
              textColor: `rgba(255, 255, 255, 1)`,
              roundedCorners: 10
            },
            secondary: {
              backgroundColor: `rgba(217, 217, 217, 1)`,
              textColor: `rgba(26, 26, 26, 1)`,
              roundedCorners: 10
            }
          }
        }
      },
      header: {
        content: {
          layout: 1,
          nav: [{ label: ``, url: `` }],
          ctaButtons: [{ label: ``, url: `` }],
          showLogo: true,
          logoSizeAsGlobal: true,
          logoWidth: 140,
          sticky: false,
          roundedCorners: 0,
          contentInContainer: true,
          socials: false
        },
        style: {
          backgroundColor: ``,
          backgroundGradient: ``,
          backgroundImage: ``,
          foregroundColor: ``,
          navColor: ``,
          marginTop: 0,
          marginBottom: 0,
          paddingTop: 10,
          paddingBottom: 10
        }
      },
      footer: {
        content: {
          layout: 1,
          nav: [
            { label: `Privacy Policy`, url: `` },
            { label: `Terms of services`, url: `` }
          ],
          copyright: `© 2022 ZEWST. All rights reserved.`,
          email: `store@zewst.xyz`,
          showLogo: true,
          logoWidth: 100,
          roundedCorners: 0,
          contentInContainer: true,
          socials: true
        },
        style: {
          backgroundColor: ``,
          backgroundGradient: ``,
          backgroundImage: ``,
          foregroundColor: ``,
          navColor: ``,
          marginTop: 0,
          marginBottom: 0,
          paddingTop: 24,
          paddingBottom: 24
        }
      },
      landing: {
        renderOrder: [
          { id: '0', title: 'Carousel Section' },
          { id: '1', title: 'Product Section' },
          { id: '2', title: 'Featured Section' },
          { id: '3', title: 'Figure Section' }
        ],
        productSections: [
          {
            title: 'Product Section',
            content: {
              layout: 1,
              title: ``,
              description: ``,
              contentInContainer: false,
              products: [
                {
                  media: `https://placehold.co/250x250.png`,
                  title: `Carousel Section slide 1 main heading`,
                  description: `Thanks for reading my blog! Happy coding!!!`,
                  price: 10
                },
                {
                  media: `https://placehold.co/250x250.png`,
                  title: `Carousel Section slide 2 main heading`,
                  description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.`,
                  price: 10
                },
                {
                  media: `https://placehold.co/250x250.png`,
                  title: `Carousel Section slide 3 main heading`,
                  description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.`,
                  price: 10
                },
                {
                  media: `https://placehold.co/250x250.png`,
                  title: `Carousel Section slide 4 main heading`,
                  description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.`,
                  price: 10
                },
                {
                  media: `https://placehold.co/250x250.png`,
                  title: `Carousel Section slide 5 main heading`,
                  description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.`,
                  price: 10
                }
              ]
            },
            style: {
              backgroundColor: ``,
              backgroundGradient: ``,
              backgroundImage: ``,
              foregroundColor: ``,
              marginTop: 0,
              marginBottom: 0,
              paddingTop: 16,
              paddingBottom: 16
            }
          }
        ],
        carouselSections: [
          {
            title: 'Carousel Section',
            content: {
              layout: 1,
              title: ``,
              description: ``,
              contentInContainer: false,
              contentOverlay: false,
              slides: [
                {
                  media: `https://placehold.co/1600x750.png`,
                  title: `Carousel Section slide 1 main heading`,
                  description: `Thanks for reading my blog! Happy coding!!!`
                },
                {
                  media: `https://placehold.co/1600x750.png`,
                  title: `Carousel Section slide 2 main heading`,
                  description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.`
                }
              ]
            },
            style: {
              backgroundColor: ``,
              backgroundGradient: ``,
              backgroundImage: ``,
              foregroundColor: ``,
              overlayBackgroundColor: `rgba(0, 0, 0, 1)`,
              overlayOpacity: 50,
              marginTop: 0,
              marginBottom: 0,
              paddingTop: 0,
              paddingBottom: 0
            }
          }
        ],
        featuredSections: [
          {
            title: 'Featured Section',
            content: {
              layout: 1,
              title: `Featured section main heading`,
              subTitle: `Featured section sub heading`,
              description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.`,
              contentInContainer: true,
              slides: [
                {
                  category: `Enter Category`,
                  title: `Featured section slide title`,
                  description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.`,
                  media: `https://placehold.co/400x400.png`
                }
              ]
            },
            style: {
              backgroundColor: ``,
              backgroundGradient: ``,
              backgroundImage: ``,
              foregroundColor: ``,
              marginTop: 0,
              marginBottom: 0,
              paddingTop: 71,
              paddingBottom: 71
            }
          }
        ],
        figureSections: [
          {
            title: 'Figure Section',
            content: {
              layout: 1,
              title: `Figure section main heading`,
              description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.`,
              media: `https://placehold.co/1600x750.png`,
              contentInContainer: false,
              contentOverlay: false
            },
            style: {
              backgroundColor: ``,
              backgroundGradient: ``,
              backgroundImage: ``,
              foregroundColor: ``,
              overlayBackgroundColor: `rgba(0, 0, 0, 1)`,
              overlayOpacity: 50,
              marginTop: 0,
              marginBottom: 0,
              paddingTop: 0,
              paddingBottom: 0
            }
          }
        ]
      }
    },

    setPages: (data) =>
      set((state) => {
        state.pages = data;
      }),
    setProjectName: (data) =>
      set((state) => {
        state.pages.projectName = data;
      }),
    setPageName: (data) =>
      set((state) => {
        state.pages.pageName = data;
      }),

    //global content settings
    setTitle: (data) =>
      set((state) => {
        state.pages.global.content.title = data;
      }),
    setDescription: (data) =>
      set((state) => {
        state.pages.global.content.description = data;
      }),
    setFavicon: (data) =>
      set((state) => {
        state.pages.global.content.favicon = data;
      }),
    setLogo: (data) =>
      set((state) => {
        state.pages.global.content.logo = data;
      }),
    setLogoWidth: (data) =>
      set((state) => {
        state.pages.global.content.logoWidth = data;
      }),
    setFacebook: (data) =>
      set((state) => {
        state.pages.global.content.social.facebook = data;
      }),
    setInstagram: (data) =>
      set((state) => {
        state.pages.global.content.social.instagram = data;
      }),
    setTwitter: (data) =>
      set((state) => {
        state.pages.global.content.social.twitter = data;
      }),
    setTiktok: (data) =>
      set((state) => {
        state.pages.global.content.social.tiktok = data;
      }),
    setDiscord: (data) =>
      set((state) => {
        state.pages.global.content.social.discord = data;
      }),
    setSnapchat: (data) =>
      set((state) => {
        state.pages.global.content.social.snapchat = data;
      }),

    //global style settings
    setGlobalColor: (data) =>
      set((state) => {
        state.pages.global.style.colors.push({
          code: data
        });
      }),
    setHeaderFont: (data) =>
      set((state) => {
        state.pages.global.style.headerFont = data;
      }),
    setBodyFont: (data) =>
      set((state) => {
        state.pages.global.style.bodyFont = data;
      }),
    setButtonFont: (data) =>
      set((state) => {
        state.pages.global.style.buttonFont = data;
      }),
    setBackgroundColor: (data) =>
      set((state) => {
        state.pages.global.style.backgroundColor = data;
      }),
    setBackgroundImage: (data) =>
      set((state) => {
        state.pages.global.style.backgroundImage = data;
      }),
    setForegroundColor: (data) =>
      set((state) => {
        state.pages.global.style.foregroundColor = data;
      }),
    setButtonPrimaryBackgroundColor: (data) =>
      set((state) => {
        state.pages.global.style.button.primary.backgroundColor = data;
      }),
    setButtonPrimaryTextColor: (data) =>
      set((state) => {
        state.pages.global.style.button.primary.textColor = data;
      }),
    setButtonPrimaryRoundedCorners: (data) =>
      set((state) => {
        state.pages.global.style.button.primary.roundedCorners = data;
      }),
    setButtonSecondaryBackgroundColor: (data) =>
      set((state) => {
        state.pages.global.style.button.secondary.backgroundColor = data;
      }),
    setButtonSecondaryTextColor: (data) =>
      set((state) => {
        state.pages.global.style.button.secondary.textColor = data;
      }),
    setButtonSecondaryRoundedCorners: (data) =>
      set((state) => {
        state.pages.global.style.button.secondary.roundedCorners = data;
      }),

    //header content settings
    setHeaderLayout: (data) =>
      set((state) => {
        state.pages.header.content.layout = data;
      }),
    setHeaderNav: () =>
      set((state) => {
        state.pages.header.content.nav.push({
          label: ``,
          url: ``
        });
      }),
    setHeaderNavLabel: (data, i) =>
      set((state) => {
        state.pages.header.content.nav[i].label = data;
      }),
    setHeaderNavUrl: (data, i) =>
      set((state) => {
        state.pages.header.content.nav[i].url = data;
      }),
    setHeaderCtaButton: () =>
      set((state) => {
        state.pages.header.content.ctaButtons.push({
          label: ``,
          url: ``
        });
      }),
    setHeaderCtaButtonLabel: (data, i) =>
      set((state) => {
        state.pages.header.content.ctaButtons[i].label = data;
      }),
    setHeaderCtaButtonUrl: (data, i) =>
      set((state) => {
        state.pages.header.content.ctaButtons[i].url = data;
      }),
    setHeaderShowLogo: () =>
      set((state) => {
        state.pages.header.content.showLogo =
          !state.pages.header.content.showLogo;
      }),
    setHeaderLogoSizeAsGlobal: () =>
      set((state) => {
        state.pages.header.content.logoSizeAsGlobal =
          !state.pages.header.content.logoSizeAsGlobal;
      }),
    setHeaderLogoWidth: (data) =>
      set((state) => {
        state.pages.header.content.logoWidth = data;
      }),
    setHeaderSticky: () =>
      set((state) => {
        state.pages.header.content.sticky = !state.pages.header.content.sticky;
      }),
    setHeaderRoundedCorners: (data) =>
      set((state) => {
        state.pages.header.content.roundedCorners = data;
      }),
    setHeaderContentInContainer: () =>
      set((state) => {
        state.pages.header.content.contentInContainer =
          !state.pages.header.content.contentInContainer;
      }),
    setHeaderSocials: () =>
      set((state) => {
        state.pages.header.content.socials =
          !state.pages.header.content.socials;
      }),

    //header style settings
    setHeaderBackgroundColor: (data) =>
      set((state) => {
        state.pages.header.style.backgroundColor = data;
      }),
    setHeaderBackgroundGradient: (data) =>
      set((state) => {
        state.pages.header.style.backgroundGradient = data;
      }),
    setHeaderBackgroundImage: (data) =>
      set((state) => {
        state.pages.header.style.backgroundImage = data;
      }),
    setHeaderForegroundColor: (data) =>
      set((state) => {
        state.pages.header.style.foregroundColor = data;
      }),
    setHeaderNavColor: (data) =>
      set((state) => {
        state.pages.header.style.navColor = data;
      }),
    setHeaderMarginTop: (data) =>
      set((state) => {
        state.pages.header.style.marginTop = data;
      }),
    setHeaderMarginBottom: (data) =>
      set((state) => {
        state.pages.header.style.marginBottom = data;
      }),
    setHeaderPaddingTop: (data) =>
      set((state) => {
        state.pages.header.style.paddingTop = data;
      }),
    setHeaderPaddingBottom: (data) =>
      set((state) => {
        state.pages.header.style.paddingBottom = data;
      }),

    //footer content settings
    setFooterLayout: (data) =>
      set((state) => {
        state.pages.footer.content.layout = data;
      }),
    setFooterNav: () =>
      set((state) => {
        state.pages.footer.content.nav.push({
          label: ``,
          url: ``
        });
      }),
    setFooterNavLabel: (data, i) =>
      set((state) => {
        state.pages.footer.content.nav[i].label = data;
      }),
    setFooterNavUrl: (data, i) =>
      set((state) => {
        state.pages.footer.content.nav[i].url = data;
      }),
    setFooterCopyright: (data) =>
      set((state) => {
        state.pages.footer.content.copyright = data;
      }),
    setFooterEmail: (data) =>
      set((state) => {
        state.pages.footer.content.email = data;
      }),
    setFooterShowLogo: () =>
      set((state) => {
        state.pages.footer.content.showLogo =
          !state.pages.footer.content.showLogo;
      }),
    setFooterLogoWidth: (data) =>
      set((state) => {
        state.pages.footer.content.logoWidth = data;
      }),
    setFooterRoundedCorners: (data) =>
      set((state) => {
        state.pages.footer.content.roundedCorners = data;
      }),
    setFooterContentInContainer: () =>
      set((state) => {
        state.pages.footer.content.contentInContainer =
          !state.pages.footer.content.contentInContainer;
      }),
    setFooterSocials: () =>
      set((state) => {
        state.pages.footer.content.socials =
          !state.pages.footer.content.socials;
      }),

    //footer style settings
    setFooterBackgroundColor: (data) =>
      set((state) => {
        state.pages.footer.style.backgroundColor = data;
      }),
    setFooterBackgroundGradient: (data) =>
      set((state) => {
        state.pages.footer.style.backgroundGradient = data;
      }),
    setFooterBackgroundImage: (data) =>
      set((state) => {
        state.pages.footer.style.backgroundImage = data;
      }),
    setFooterForegroundColor: (data) =>
      set((state) => {
        state.pages.footer.style.foregroundColor = data;
      }),
    setFooterNavColor: (data) =>
      set((state) => {
        state.pages.footer.style.navColor = data;
      }),
    setFooterMarginTop: (data) =>
      set((state) => {
        state.pages.footer.style.marginTop = data;
      }),
    setFooterMarginBottom: (data) =>
      set((state) => {
        state.pages.footer.style.marginBottom = data;
      }),
    setFooterPaddingTop: (data) =>
      set((state) => {
        state.pages.footer.style.paddingTop = data;
      }),
    setFooterPaddingBottom: (data) =>
      set((state) => {
        state.pages.footer.style.paddingBottom = data;
      }),

    //landing page sections
    //renderOrder array settings
    addRenderOrderItem: (item) =>
      set((state) => {
        state.pages.landing.renderOrder.push(item);
      }),
    removeRenderOrderItem: (tit) =>
      set((state) => {
        state.pages.landing.renderOrder.map(
          (item, i) =>
            item.title === tit && state.pages.landing.renderOrder.splice(i, 1)
        );
        state.pages.landing.renderOrder.map(
          (item, index) => (item.id = `${index}`)
        );
      }),
    setRenderOrder: (newOrder) => {
      set((state) => {
        state.pages.landing.renderOrder = newOrder;
      });
    },

    //product settings
    setAddProductSection: (section) =>
      set((state) => {
        state.pages.landing.productSections.push(section);
      }),
    setRemoveProductSection: (tit) =>
      set((state) => {
        state.pages.landing.productSections.map((sec, i) =>
          sec.title === tit
            ? state.pages.landing.productSections.splice(i, 1)
            : null
        );
      }),

    //product content settings
    setProductSectionLayout: (data, tit) =>
      set((state) => {
        state.pages.landing.productSections.filter(
          (sec) => sec.title === tit
        )[0].content.layout = data;
      }),
    setProductSectionTitle: (data, tit) =>
      set((state) => {
        state.pages.landing.productSections.filter(
          (sec) => sec.title === tit
        )[0].content.title = data;
      }),
    setProductSectionDescription: (data, tit) =>
      set((state) => {
        state.pages.landing.productSections.filter(
          (sec) => sec.title === tit
        )[0].content.description = data;
      }),
    setProductSectionContentInContainer: (tit) =>
      set((state) => {
        state.pages.landing.productSections.filter(
          (sec) => sec.title === tit
        )[0].content.contentInContainer =
          !state.pages.landing.productSections.filter(
            (sec) => sec.title === tit
          )[0].content.contentInContainer;
      }),
    setAddProductSlide: (tit) =>
      set((state) => {
        state.pages.landing.productSections
          .filter((sec) => sec.title === tit)[0]
          .content.products.push({
            media: `https://placehold.co/250x250.png`,
            title: `Product Section slide ${
              state.pages.landing.productSections.filter(
                (sec) => sec.title === tit
              )[0].content.products.length + 1
            } title`,
            description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit,`,
            price: 0
          });
      }),
    setRemoveProductSlide: (tit, i) =>
      set((state) => {
        state.pages.landing.productSections
          .filter((sec) => sec.title === tit)[0]
          .content.products.splice(i, 1);
      }),
    setProductSlideMedia: (data, tit, i) =>
      set((state) => {
        state.pages.landing.productSections.filter(
          (sec) => sec.title === tit
        )[0].content.products[i].media = data;
      }),
    setProductSlideTitle: (data, tit, i) =>
      set((state) => {
        state.pages.landing.productSections.filter(
          (sec) => sec.title === tit
        )[0].content.products[i].title = data;
      }),
    setProductSlideDescription: (data, tit, i) =>
      set((state) => {
        state.pages.landing.productSections.filter(
          (sec) => sec.title === tit
        )[0].content.products[i].description = data;
      }),
    setProductSlidePrice: (data, tit, i) =>
      set((state) => {
        state.pages.landing.productSections.filter(
          (sec) => sec.title === tit
        )[0].content.products[i].price = data;
      }),

    //product style settings
    setProductSectionBackgroundColor: (data, tit) =>
      set((state) => {
        state.pages.landing.productSections.filter(
          (sec) => sec.title === tit
        )[0].style.backgroundColor = data;
      }),
    setProductSectionBackgroundGradient: (data, tit) =>
      set((state) => {
        state.pages.landing.productSections.filter(
          (sec) => sec.title === tit
        )[0].style.backgroundGradient = data;
      }),
    setProductSectionBackgroundImage: (data, tit) =>
      set((state) => {
        state.pages.landing.productSections.filter(
          (sec) => sec.title === tit
        )[0].style.backgroundImage = data;
      }),
    setProductSectionForegroundColor: (data, tit) =>
      set((state) => {
        state.pages.landing.productSections.filter(
          (sec) => sec.title === tit
        )[0].style.foregroundColor = data;
      }),
    setProductSectionMarginTop: (data, tit) =>
      set((state) => {
        state.pages.landing.productSections.filter(
          (sec) => sec.title === tit
        )[0].style.marginTop = data;
      }),
    setProductSectionMarginBottom: (data, tit) =>
      set((state) => {
        state.pages.landing.productSections.filter(
          (sec) => sec.title === tit
        )[0].style.marginBottom = data;
      }),
    setProductSectionPaddingTop: (data, tit) =>
      set((state) => {
        state.pages.landing.productSections.filter(
          (sec) => sec.title === tit
        )[0].style.paddingTop = data;
      }),
    setProductSectionPaddingBottom: (data, tit) =>
      set((state) => {
        state.pages.landing.productSections.filter(
          (sec) => sec.title === tit
        )[0].style.paddingBottom = data;
      }),

    //carousel settings
    setAddCarouselSection: (section) =>
      set((state) => {
        state.pages.landing.carouselSections.push(section);
      }),
    setRemoveCarouselSection: (tit) =>
      set((state) => {
        state.pages.landing.carouselSections.map((sec, i) =>
          sec.title === tit
            ? state.pages.landing.carouselSections.splice(i, 1)
            : null
        );
      }),

    //carousel content settings
    setCarouselSectionLayout: (data, tit) =>
      set((state) => {
        state.pages.landing.carouselSections.filter(
          (sec) => sec.title === tit
        )[0].content.layout = data;
      }),
    setCarouselSectionTitle: (data, tit) =>
      set((state) => {
        state.pages.landing.carouselSections.filter(
          (sec) => sec.title === tit
        )[0].content.title = data;
      }),
    setCarouselSectionDescription: (data, tit) =>
      set((state) => {
        state.pages.landing.carouselSections.filter(
          (sec) => sec.title === tit
        )[0].content.description = data;
      }),
    setCarouselSectionContentInContainer: (tit) =>
      set((state) => {
        state.pages.landing.carouselSections.filter(
          (sec) => sec.title === tit
        )[0].content.contentInContainer =
          !state.pages.landing.carouselSections.filter(
            (sec) => sec.title === tit
          )[0].content.contentInContainer;
      }),
    setCarouselSectionContentOverlay: (tit) =>
      set((state) => {
        state.pages.landing.carouselSections.filter(
          (sec) => sec.title === tit
        )[0].content.contentOverlay =
          !state.pages.landing.carouselSections.filter(
            (sec) => sec.title === tit
          )[0].content.contentOverlay;
      }),
    setAddCarouselSlide: (tit) =>
      set((state) => {
        state.pages.landing.carouselSections
          .filter((sec) => sec.title === tit)[0]
          .content.slides.push({
            media: `https://placehold.co/1600x750.png`,
            title: `Carousel Section slide ${
              state.pages.landing.carouselSections.filter(
                (sec) => sec.title === tit
              )[0].content.slides.length + 1
            } main heading`,
            description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.`
          });
      }),
    setRemoveCarouselSlide: (tit, i) =>
      set((state) => {
        state.pages.landing.carouselSections
          .filter((sec) => sec.title === tit)[0]
          .content.slides.splice(i, 1);
      }),
    setCarouselSlideMedia: (data, tit, i) =>
      set((state) => {
        state.pages.landing.carouselSections.filter(
          (sec) => sec.title === tit
        )[0].content.slides[i].media = data;
      }),
    setCarouselSlideTitle: (data, tit, i) =>
      set((state) => {
        state.pages.landing.carouselSections.filter(
          (sec) => sec.title === tit
        )[0].content.slides[i].title = data;
      }),
    setCarouselSlideDescription: (data, tit, i) =>
      set((state) => {
        state.pages.landing.carouselSections.filter(
          (sec) => sec.title === tit
        )[0].content.slides[i].description = data;
      }),

    //carousel style settings
    setCarouselSectionBackgroundColor: (data, tit) =>
      set((state) => {
        state.pages.landing.carouselSections.filter(
          (sec) => sec.title === tit
        )[0].style.backgroundColor = data;
      }),
    setCarouselSectionBackgroundGradient: (data, tit) =>
      set((state) => {
        state.pages.landing.carouselSections.filter(
          (sec) => sec.title === tit
        )[0].style.backgroundGradient = data;
      }),
    setCarouselSectionBackgroundImage: (data, tit) =>
      set((state) => {
        state.pages.landing.carouselSections.filter(
          (sec) => sec.title === tit
        )[0].style.backgroundImage = data;
      }),
    setCarouselSectionForegroundColor: (data, tit) =>
      set((state) => {
        state.pages.landing.carouselSections.filter(
          (sec) => sec.title === tit
        )[0].style.foregroundColor = data;
      }),
    setCarouselSectionOverlayBackgroundColor: (data, tit) =>
      set((state) => {
        state.pages.landing.carouselSections.filter(
          (sec) => sec.title === tit
        )[0].style.overlayBackgroundColor = data;
      }),
    setCarouselSectionOverlayOpacity: (data, tit) =>
      set((state) => {
        state.pages.landing.carouselSections.filter(
          (sec) => sec.title === tit
        )[0].style.overlayOpacity = data;
      }),
    setCarouselSectionMarginTop: (data, tit) =>
      set((state) => {
        state.pages.landing.carouselSections.filter(
          (sec) => sec.title === tit
        )[0].style.marginTop = data;
      }),
    setCarouselSectionMarginBottom: (data, tit) =>
      set((state) => {
        state.pages.landing.carouselSections.filter(
          (sec) => sec.title === tit
        )[0].style.marginBottom = data;
      }),
    setCarouselSectionPaddingTop: (data, tit) =>
      set((state) => {
        state.pages.landing.carouselSections.filter(
          (sec) => sec.title === tit
        )[0].style.paddingTop = data;
      }),
    setCarouselSectionPaddingBottom: (data, tit) =>
      set((state) => {
        state.pages.landing.carouselSections.filter(
          (sec) => sec.title === tit
        )[0].style.paddingBottom = data;
      }),

    //featured settings
    setAddFeaturedSection: (section) =>
      set((state) => {
        state.pages.landing.featuredSections.push(section);
      }),
    setRemoveFeaturedSection: (tit) =>
      set((state) => {
        state.pages.landing.featuredSections.map((sec, i) =>
          sec.title === tit
            ? state.pages.landing.featuredSections.splice(i, 1)
            : null
        );
      }),

    //featured content settings
    setFeaturedSectionLayout: (data, tit) =>
      set((state) => {
        state.pages.landing.featuredSections.filter(
          (sec) => sec.title === tit
        )[0].content.layout = data;
      }),
    setFeaturedSectionTitle: (data, tit) =>
      set((state) => {
        state.pages.landing.featuredSections.filter(
          (sec) => sec.title === tit
        )[0].content.title = data;
      }),
    setFeaturedSectionSubTitle: (data, tit) =>
      set((state) => {
        state.pages.landing.featuredSections.filter(
          (sec) => sec.title === tit
        )[0].content.subTitle = data;
      }),
    setFeaturedSectionDescription: (data, tit) =>
      set((state) => {
        state.pages.landing.featuredSections.filter(
          (sec) => sec.title === tit
        )[0].content.description = data;
      }),
    setFeaturedSectionContentInContainer: (tit) =>
      set((state) => {
        state.pages.landing.featuredSections.filter(
          (sec) => sec.title === tit
        )[0].content.contentInContainer =
          !state.pages.landing.featuredSections.filter(
            (sec) => sec.title === tit
          )[0].content.contentInContainer;
      }),
    setAddFeaturedSlide: (tit) =>
      set((state) => {
        state.pages.landing.featuredSections
          .filter((sec) => sec.title === tit)[0]
          .content.slides.push({
            category: `Enter Category`,
            title: `Featured section slide title`,
            description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.`,
            media: `https://placehold.co/400x400.png`
          });
      }),
    setRemoveFeaturedSlide: (tit, i) =>
      set((state) => {
        state.pages.landing.featuredSections
          .filter((sec) => sec.title === tit)[0]
          .content.slides.splice(i, 1);
      }),
    setFeaturedSlideCategory: (data, tit, i) =>
      set((state) => {
        state.pages.landing.featuredSections.filter(
          (sec) => sec.title === tit
        )[0].content.slides[i].category = data;
      }),
    setFeaturedSlideTitle: (data, tit, i) =>
      set((state) => {
        state.pages.landing.featuredSections.filter(
          (sec) => sec.title === tit
        )[0].content.slides[i].title = data;
      }),
    setFeaturedSlideDescription: (data, tit, i) =>
      set((state) => {
        state.pages.landing.featuredSections.filter(
          (sec) => sec.title === tit
        )[0].content.slides[i].description = data;
      }),
    setFeaturedSlideMedia: (data, tit, i) =>
      set((state) => {
        state.pages.landing.featuredSections.filter(
          (sec) => sec.title === tit
        )[0].content.slides[i].media = data;
      }),

    //featured style settings
    setFeaturedSectionBackgroundColor: (data, tit) =>
      set((state) => {
        state.pages.landing.featuredSections.filter(
          (sec) => sec.title === tit
        )[0].style.backgroundColor = data;
      }),
    setFeaturedSectionBackgroundGradient: (data, tit) =>
      set((state) => {
        state.pages.landing.featuredSections.filter(
          (sec) => sec.title === tit
        )[0].style.backgroundGradient = data;
      }),
    setFeaturedSectionBackgroundImage: (data, tit) =>
      set((state) => {
        state.pages.landing.featuredSections.filter(
          (sec) => sec.title === tit
        )[0].style.backgroundImage = data;
      }),
    setFeaturedSectionForegroundColor: (data, tit) =>
      set((state) => {
        state.pages.landing.featuredSections.filter(
          (sec) => sec.title === tit
        )[0].style.foregroundColor = data;
      }),
    setFeaturedSectionMarginTop: (data, tit) =>
      set((state) => {
        state.pages.landing.featuredSections.filter(
          (sec) => sec.title === tit
        )[0].style.marginTop = data;
      }),
    setFeaturedSectionMarginBottom: (data, tit) =>
      set((state) => {
        state.pages.landing.featuredSections.filter(
          (sec) => sec.title === tit
        )[0].style.marginBottom = data;
      }),
    setFeaturedSectionPaddingTop: (data, tit) =>
      set((state) => {
        state.pages.landing.featuredSections.filter(
          (sec) => sec.title === tit
        )[0].style.paddingTop = data;
      }),
    setFeaturedSectionPaddingBottom: (data, tit) =>
      set((state) => {
        state.pages.landing.featuredSections.filter(
          (sec) => sec.title === tit
        )[0].style.paddingBottom = data;
      }),

    //figure settings
    setAddFigureSection: (section) =>
      set((state) => {
        state.pages.landing.figureSections.push(section);
      }),
    setRemoveFigureSection: (tit) =>
      set((state) => {
        state.pages.landing.figureSections.map((sec, i) =>
          sec.title === tit
            ? state.pages.landing.figureSections.splice(i, 1)
            : null
        );
      }),

    //figure content settings
    setFigureSectionLayout: (data, tit) =>
      set((state) => {
        state.pages.landing.figureSections.filter(
          (sec) => sec.title === tit
        )[0].content.layout = data;
      }),
    setFigureSectionTitle: (data, tit) =>
      set((state) => {
        state.pages.landing.figureSections.filter(
          (sec) => sec.title === tit
        )[0].content.title = data;
      }),
    setFigureSectionDescription: (data, tit) =>
      set((state) => {
        state.pages.landing.figureSections.filter(
          (sec) => sec.title === tit
        )[0].content.description = data;
      }),
    setFigureSectionMedia: (data, tit) =>
      set((state) => {
        state.pages.landing.figureSections.filter(
          (sec) => sec.title === tit
        )[0].content.media = data;
      }),
    setFigureSectionContentInContainer: (tit) =>
      set((state) => {
        state.pages.landing.figureSections.filter(
          (sec) => sec.title === tit
        )[0].content.contentInContainer =
          !state.pages.landing.figureSections.filter(
            (sec) => sec.title === tit
          )[0].content.contentInContainer;
      }),
    setFigureSectionContentOverlay: (tit) =>
      set((state) => {
        state.pages.landing.figureSections.filter(
          (sec) => sec.title === tit
        )[0].content.contentOverlay =
          !state.pages.landing.figureSections.filter(
            (sec) => sec.title === tit
          )[0].content.contentOverlay;
      }),

    //figure style settings
    setFigureSectionBackgroundColor: (data, tit) =>
      set((state) => {
        state.pages.landing.figureSections.filter(
          (sec) => sec.title === tit
        )[0].style.backgroundColor = data;
      }),
    setFigureSectionBackgroundGradient: (data, tit) =>
      set((state) => {
        state.pages.landing.figureSections.filter(
          (sec) => sec.title === tit
        )[0].style.backgroundGradient = data;
      }),
    setFigureSectionBackgroundImage: (data, tit) =>
      set((state) => {
        state.pages.landing.figureSections.filter(
          (sec) => sec.title === tit
        )[0].style.backgroundImage = data;
      }),
    setFigureSectionForegroundColor: (data, tit) =>
      set((state) => {
        state.pages.landing.figureSections.filter(
          (sec) => sec.title === tit
        )[0].style.foregroundColor = data;
      }),
    setFigureSectionOverlayBackgroundColor: (data, tit) =>
      set((state) => {
        state.pages.landing.figureSections.filter(
          (sec) => sec.title === tit
        )[0].style.overlayBackgroundColor = data;
      }),
    setFigureSectionOverlayOpacity: (data, tit) =>
      set((state) => {
        state.pages.landing.figureSections.filter(
          (sec) => sec.title === tit
        )[0].style.overlayOpacity = data;
      }),
    setFigureSectionMarginTop: (data, tit) =>
      set((state) => {
        state.pages.landing.figureSections.filter(
          (sec) => sec.title === tit
        )[0].style.marginTop = data;
      }),
    setFigureSectionMarginBottom: (data, tit) =>
      set((state) => {
        state.pages.landing.figureSections.filter(
          (sec) => sec.title === tit
        )[0].style.marginBottom = data;
      }),
    setFigureSectionPaddingTop: (data, tit) =>
      set((state) => {
        state.pages.landing.figureSections.filter(
          (sec) => sec.title === tit
        )[0].style.paddingTop = data;
      }),
    setFigureSectionPaddingBottom: (data, tit) =>
      set((state) => {
        state.pages.landing.figureSections.filter(
          (sec) => sec.title === tit
        )[0].style.paddingBottom = data;
      }),

    // others
    screenView: 'WEB_VIEW',
    setScreenView: (data) =>
      set((state) => {
        state.screenView = data;
      }),
    websiteBuilder: false,
    setWebsiteBuilder: (val) =>
      set((state) => {
        state.websiteBuilder = val;
      })
  }))
  // {
  //   name: 'ZEWST-BUILDER'
  // }
  // )
);
