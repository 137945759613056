import { AXIOS_INSTANCE_CUSTOMER_ORDERS } from 'utilities/db';
import { useQuery } from 'react-query';

export const useFetchOrdersHook = (keyName = 'orders', brandId, branchId, fromDate, toDate) => {
  const { data, error, isFetching, isLoading, refetch } = useQuery(
    [keyName, brandId, branchId, fromDate, toDate],
    () => getOrdersByBranch(brandId, branchId, fromDate, toDate)
  );

  return {
    ordersData: data,
    ordersError: error,
    ordersFetching: isFetching,
    ordersLoading: isLoading,
    ordersRefetch: refetch
  };
};
const getOrdersByBranch = async (brandId, branchId, fromDate, toDate) => {
  let result = await AXIOS_INSTANCE_CUSTOMER_ORDERS.get(`/orders/list?brandId=${brandId}&branchId=${branchId}&from=${fromDate}&to=${toDate}`);
  return result?.data;
};
