import { Button } from 'components/button';
import { TextField } from 'components/input';
import { ModalComponent } from 'components/modal';
import { CustomSelectDropdown } from 'components/select/multi';
import { TextArea } from 'components/textarea';
import { Toasts } from 'components/toasts';
import { useFetchBranches, useFetchBrands } from 'hooks/resourceHooks';
import { useFetchRolesHook } from 'hooks/roles/useFetchRolesHook';
import { useFetchTeamHook } from 'hooks/team/useFetchTeamHook';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useStore } from 'store';
import { client } from 'utilities/db';

export const RoleModal = ({ type = '', Role = false, setRole }) => {
  const brandSelectRef = useRef();
  const branchSelectRef = useRef();
  const roleSelectRef = useRef();
  const [tempBrand, setTempBrand] = useState(null);
  const [tempBranch, setTempBranch] = useState(null);
  const [tempRole, setTempRole] = useState(null);
  const { user, brand } = useStore((state) => state);
  const { data: brandsList } = useFetchBrands(user?.id);
  const { data: branchList } = useFetchBranches(tempBrand?.value);
  const { roleData: Roles, roleRefetch } = useFetchRolesHook(
    'roles',
    brand?.id
  );

  const { teamRefetch } = useFetchTeamHook('employ', brand?.id);

  const { mutate, isLoading, isError, error, isSuccess } = useMutation(
    (data) => {
      return client.post(
        type == 'Add Role' ? '/role/add' : '/team/addTeam',
        data
      );
    }
  );
  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
    reset
  } = useForm({
    defaultValues:
      type == 'Add Role'
        ? {
            name: '',
            description: '',
            brand_id: '',
            branch_id: ''
          }
        : {
            name: '',
            email: '',
            phoneNumber: '',
            role_id: ''
          }
  });

  const closeModal = () => {
    setRole(false);
  };
  const handleSubmitData = (data) => {
    if (type == 'Add Role') {
      const { name, description } = data;
      mutate({
        name: name,
        brand_id: tempBrand?.value,
        branch_id: tempBranch?.value
      });
    } else {
      const { name, email, phoneNumber } = data;
      mutate({
        name: name,
        email: email,
        phone: phoneNumber,
        role_id: tempRole?.value
      });
    }
  };
  useMemo(() => {
    if (isSuccess) {
      if (type == 'Add Role') {
        roleRefetch();
        Toasts.success('Role added successfully');
      } else {
        teamRefetch();
        Toasts.success('Role Assign successfully');
      }
      closeModal();
    }
  });
  useEffect(() => {
    if (!isError) return;
    Toasts.error(error?.response?.data?.message);
  }, [isError]);
  return (
    <>
      <ModalComponent
        isOpen={Role}
        modal={closeModal}
        handleModal={closeModal}
        modalMiddle={false}
        rightModal={true}
      >
        <h4>
          <span className="ff_semi">{type}</span>
        </h4>
        <div className="mt_40"></div>
        <div className="pt_10">
          {type == 'Add Role' ? (
            <form onSubmit={handleSubmit(handleSubmitData)}>
              <TextField
                label="Name"
                placeholder="Name"
                name="name"
                error={
                  errors?.name?.type === 'required' ? 'Name is required' : ''
                }
                register={register}
                validation={{
                  required: true
                }}
              />
              <TextArea
                name="description"
                label={'Description'}
                placeholder="Description"
                rows="7"
                register={register}
                validation={{
                  required: false
                }}
              />
              <p>Brand</p>
              <CustomSelectDropdown
                ref={brandSelectRef}
                name="link"
                placeholder="Choose brand"
                options={brandsList?.map((item) => ({
                  label: item?.brand_name,
                  value: item?.id
                }))}
                onChange={setTempBrand}
                value={tempBrand}
              />

              {branchList?.length > 0 && (
                <>
                  <p>Branch</p>
                  <CustomSelectDropdown
                    ref={branchSelectRef}
                    name="link"
                    placeholder="Choose branch"
                    options={branchList?.map((item) => ({
                      label: item?.branch_name || item?.kitchen_name,
                      value: item?.id
                    }))}
                    onChange={setTempBranch}
                    value={tempBranch}
                  />
                </>
              )}

              <div className="mt_30"></div>
              <hr className="hr_modal_right" />
              <div className="flex item_hend mt_30">
                <Button
                  onClick={closeModal}
                  color="outline-lite"
                  label={'Cancel'}
                />
                <Button
                  label={'Submit'}
                  color="bg-primary"
                  type="submit"
                  // disabled={isLoading}
                />
              </div>
            </form>
          ) : (
            <form onSubmit={handleSubmit(handleSubmitData)}>
              <TextField
                label="Name"
                placeholder="Name"
                name="name"
                error={
                  errors?.name?.type === 'required' ? 'Name is required' : ''
                }
                register={register}
                validation={{
                  required: true
                }}
              />
              <TextField
                label="Email"
                placeholder="Email"
                name="email"
                error={
                  errors?.email?.type === 'required' ? 'Email is required' : ''
                }
                register={register}
                validation={{
                  required: true
                }}
              />

              <TextField
                label="Phone Number"
                placeholder="Phone Number"
                name="phoneNumber"
                error={
                  errors?.phoneNumber?.type === 'required'
                    ? 'Phone Number is required'
                    : ''
                }
                register={register}
                validation={{
                  required: true
                }}
              />
              <p>Role</p>
              <CustomSelectDropdown
                ref={roleSelectRef}
                name="link"
                placeholder="Choose role"
                options={Roles?.map((role) => ({
                  label: role?.name,
                  value: role?.id
                }))}
                onChange={setTempRole}
                value={tempRole}
              />
              <div className="mt_10"></div>
              <div className="mt_30"></div>
              <hr className="hr_modal_right" />
              <div className="flex item_hend mt_30">
                <Button
                  onClick={closeModal}
                  color="outline-lite"
                  label={'Cancel'}
                />
                <Button
                  label={'Submit'}
                  color="bg-primary"
                  type="submit"
                  disabled={isLoading}
                />
              </div>
            </form>
          )}
        </div>
      </ModalComponent>
    </>
  );
};
