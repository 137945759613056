import { Ikon } from 'assets/icons';

export const OptionButton = (props) => {
  const { type, children, onClick = () => null } = props;
  return (
    <button
      onClick={onClick}
      type={type ? type : 'button'}
      className={`optionbutton`}
    >
      <div>{children}</div>
      <div>
        <Ikon linkArrow />
      </div>
    </button>
  );
};
