import { useNavigate, Link } from 'react-router-dom';
import { RenderIf } from 'utilities/helpers';
import { Button } from 'components/button';
import { Ikon } from 'assets/icons';

export const BranchesListing = ({ brandId, branchesData }) => {
  const navigate = useNavigate();
  console.log(branchesData, 'blabalbalbalbla');

  return (
    <>
      <div className="mt_20"></div>
      <h2>
        <span className="ff_semi">Branches</span>
      </h2>
      <div className="mt_5"></div>
      <div className="shadow_box1">
        <div className="grid grid_gap30 grid_gapV30">
          {branchesData?.map((branch, index) => (
            <div key={index} className="collg6">
              <Link
                className={`card_link`}
                to={`/portfolio/branch/details/${brandId}/${branch?.id}`}
              >
                <div className="card_item2">
                  <picture>
                    <RenderIf isTrue={!branch?.image_url}>
                      <div className="placeholder">
                        <Ikon name="imgplaceholder" />
                      </div>
                    </RenderIf>
                    <RenderIf isTrue={branch?.image_url}>
                      <source srcSet={branch?.image_url} />
                      <img
                        src={branch?.image_url}
                        alt={branch?.branch_name || branch?.kitchen_name}
                      />
                    </RenderIf>
                  </picture>
                  <div className="card_item2_box">
                    <RenderIf isTrue={branch?.address?.line1}>
                      <p className="f12">
                        {branch?.address?.line1}, {branch?.address?.line2}
                      </p>
                      <div className="mt_10"></div>
                    </RenderIf>
                    <h6 className="ff_semi">
                      {branch?.branch_name || branch?.kitchen_name}
                    </h6>
                    <div className="mt_8"></div>
                    <div className="flex">
                      <Button
                        className="alink f12"
                        label="View Details"
                        color="titary"
                        onClick={() =>
                          navigate(
                            `/portfolio/branch/details/${brandId}/${branch?.id}`
                          )
                        }
                      />
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
