import { useParams } from 'react-router-dom';
import { useFetchSingleModifierGroup } from 'hooks/menu/useMenuHook';
import { RenderIf } from 'utilities/helpers';
import { AddMenuModifier } from '../add';
import { useState } from 'react';
import { Button } from 'components/button';
import { Toasts } from 'components/toasts';
import { client } from 'utilities/db';
import { Loading } from 'components/loading';

export const MenuModifierDetail = () => {
  const { id } = useParams();
  const {
    data: fetchModifierGroup = {},
    isLoading: fetchedModifierGroupLoading,
    refetch
  } = useFetchSingleModifierGroup(id);

  const [isOpenModal, setIsOpenModal] = useState(false);

  const [formData, setFormData] = useState([
    {
      option_name: '',
      option_price: '',
      add_separate: false
    }
  ]);

  const addMenu = async (val) => {

    try {
      await client.post(
        `/modifierGroup/update/${fetchModifierGroup?.modifierGroup?.id}`,
        {
          modifier_group_name: val?.name,
          menu_item_id:
            val?.link_menu_items?.value || val?.link_menu_items?.[0]?.value,
          settings: {
            free_choice_quantity: val?.free_choice_quantity,
            free_choice: val?.free_choice,
            modifier_charges: val?.modifier_charges
          }
        }
      );
      refetch();
      Toasts.success('Modifier group updated successfully');
      setIsOpenModal(false);
    } catch (error) {
      Toasts.error(error?.response?.data?.message || 'Something went wrong');
    }

    // mutate({
    //   brand_id: brand?.id,
    //   branch_id: branch?.id,
    //   modifier_group_name: val?.name,
    //   modifier_group_description: '',
    //   modifier_group_level: 'BRANCH',
    //   is_required: val?.is_required === 'REQUIRED' ? true : false,
    //   image: '',
    //   menu_item_id: val?.link_menu_items?.value,
    //   modifiers:
    //     val?.modifiers?.map((item) => ({
    //       modifier_name: item?.modifier,
    //       modifier_description: '',
    //       modifier_level: 'BRANCH',
    //       additional_price: item?.modifier_price,
    //       calories: 0,
    //       image: ''
    //     })) || [],
    //   settings: {
    //     free_choice_quantity: val?.free_choice_quantity,
    //     free_choice: val?.free_choice,
    //     modifier_charges: val?.modifier_charges
    //   }
    // });
  };

  return (
    <>
      <article>
        <RenderIf isTrue={fetchedModifierGroupLoading}>
          <div className={`loading_full`}>
            <Loading />
          </div>
        </RenderIf>
        <RenderIf isTrue={!fetchedModifierGroupLoading}>
          <div className="grid grid_gap30">
            <div className="collg8">
              <div className="mt_40"></div>
              <Button
                color="bg-titary"
                label={'Update modifier group'}
                onClick={() => setIsOpenModal(!isOpenModal)}
              />
              <p>
                <small>
                  Created at:{' '}
                  <b>
                    {
                      fetchModifierGroup?.modifierGroup?.created_at?.split(
                        'T'
                      )?.[0]
                    }
                  </b>
                </small>
              </p>
              <div className="mt_10"></div>
              <h1>
                <b>{fetchModifierGroup?.modifierGroup?.modifier_group_name}</b>
              </h1>
              <div className="mt_30"></div>
              <hr />
              <div className="flex item_vcenter item_space_btn mt_10">
                <div>
                  <p>Modifier group level:</p>
                  <h6 className="mt_4">
                    <b>
                      {fetchModifierGroup?.modifierGroup
                        ?.modifier_group_level === 'BRAND'
                        ? 'Brand'
                        : 'Branch'}
                    </b>
                  </h6>
                </div>
                <div>
                  <p>Optional?</p>
                  <h6 className="mt_4">
                    <b>
                      {fetchModifierGroup?.modifierGroup?.is_required === true
                        ? 'No'
                        : 'Yes'}
                    </b>
                  </h6>
                </div>
              </div>
              <div className="mt_10"></div>
              <hr />
              <div className="mt_30"></div>
              <RenderIf
                isTrue={
                  fetchModifierGroup?.modifierGroup?.modifiers?.length > 0
                }
              >
                <div className="mt_40"></div>
                <h6>
                  <strong>Group items/options</strong>
                </h6>
                <div className="pt_15"></div>
                {fetchModifierGroup?.modifierGroup?.modifiers?.map(
                  (modifier, index) => (
                    <div key={index} className="flex item_space_btn">
                      <p className="ff_semi">
                        Item name: {modifier?.modifier_name}
                      </p>
                      <p className="ff_semi">
                        Item Price: $ {modifier?.additional_price}
                      </p>
                    </div>
                  )
                )}
              </RenderIf>
              <div className="mt_20"></div>
              <hr />
              <div className="mt_20"></div>
              <h5>
                <span className="ff_semi">Group Settings</span>
              </h5>
              <div className="mt_10"></div>
              <p>
                Free choice quantity:{' '}
                <span className="ff_semi">
                  {
                    fetchModifierGroup?.modifierGroup?.settings
                      ?.free_choice_quantity
                  }
                </span>
              </p>
              <p className="flex item_vcenter mt_10">
                <span>Group charges: </span>
                <span className="badge">
                  {
                    fetchModifierGroup?.modifierGroup?.settings
                      ?.modifier_charges
                  }
                </span>
              </p>
            </div>
          </div>
        </RenderIf>

        {isOpenModal && (
          <AddMenuModifier
            isLoading={false}
            addMenu={addMenu}
            onClose={() => setIsOpenModal(false)}
            isOpenModal={isOpenModal}
            formData={formData}
            setFormData={setFormData}
            existingModifierGroup={fetchModifierGroup?.modifierGroup}
            action="update"
          />
        )}
      </article>
    </>
  );
};
