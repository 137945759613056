import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts';
import styles from './index.module.css';

const data = [
  { name: 'Group A', value: 250 },
  { name: 'Group B', value: 120 },
  { name: 'Group C', value: 400 }
];
const data02 = [{ name: 'No value', value: 770 }];
const COLORS = [
  'rgba(165, 97, 216, 1)',
  'rgba(193, 59, 139, 1)',
  'rgba(60, 75, 180, 1)'
];

export const DashboardCategoryPiechart = ({ chartData }) => {
  return (
    <div className={styles.bx}>
      <h4>Top Categories</h4>
      <div className={styles.legendRw}>
        {chartData?.map((item, index) => (
          <div key={index}>
            <span style={{ background: `${COLORS[index]}` }}></span>
            <p>{item?.categoryName}</p>
          </div>
        ))}
      </div>
      <div style={{ width: `100%`, height: 205 }}>
        <ResponsiveContainer>
          <PieChart>
            <Pie
              data={data02}
              dataKey="value"
              cx="50%"
              cy="50%"
              innerRadius={41}
              outerRadius={100}
              fill="rgba(238, 240, 250, 1)"
              stroke="none"
            />
            <Pie
              data={chartData || data}
              cx="50%"
              cy="50%"
              innerRadius={60}
              outerRadius={81}
              paddingAngle={0}
              dataKey="value"
              stroke="none"
            >
              {data.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
            </Pie>
          </PieChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};
