import { RenderIf } from 'utilities/helpers';
import { Ikon } from 'assets/icons';
import styles from './index.module.css';

export const TextArea = (props) => {
  const {
    label,
    rows,
    cols,
    id,
    name,
    value,
    onChange = () => {},
    placeholder,
    error,
    register = () => {},
    validation = {},
    info,
    infoChild,
    required,
    refEl
  } = props;

  return (
    <article>
      <div className={styles.bx}>
        <RenderIf isTrue={label}>
          <div className={styles.labelBox}>
            <label htmlFor={id} className={styles.label}>
              {label} {required && <span>*</span>}
            </label>
            <div>
              {info ? (
                <div className={styles.infobox}>
                  <Ikon info />
                  <div className={styles.infoChild}>
                    <Ikon indicatorArrow />
                    <div className={styles.infoContent}>{infoChild}</div>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </RenderIf>
        <textarea
          {...register(name, validation)}
          name={name}
          className={styles.textarea}
          id={id}
          onChange={onChange}
          placeholder={placeholder ? placeholder : ' '}
          rows={rows}
          cols={cols ? cols : ''}
          value={value}
          // ref={refEl}
        >
          {value}
        </textarea>
      </div>
      <RenderIf isTrue={error}>
        <div className={styles.error}>{error}</div>
      </RenderIf>
    </article>
  );
};
