import { DeploymentClient } from 'utilities/db';
import { useQuery } from 'react-query';

export const useFetchDomainSettingsHook = (
  keyName,
  brandId,
  brandName,
  enviorment
) => {
  const { status, data, error, isFetching, isLoading, refetch } = useQuery(
    [keyName, brandId, brandName, enviorment],
    () => getData(brandId, brandName, enviorment),
    {
      enabled: !!brandId && !!brandName && !!enviorment,
      refetchInterval: 30000
    }
  );

  return {
    domainSettingStatus: status,
    domainSettingData: data,
    domainSettingError: error,
    domainSettingFetching: isFetching,
    domainSettingLoading: isLoading,
    domainSettingRefetch: refetch
  };
};

const getData = async (brandId, brandName, enviorment) => {
  let result = await DeploymentClient.get(
    `/deployment/getExistingDeploymentRecord?brandId=${brandId}&brandName=${brandName}&enviorment=${enviorment}`
  );
  result = result.data;
  return result;
};
