import check from '../../assets/Images/whiteCheckIcon.svg';

export default function ProgressSteps({ active = 1 }) {
  return (
    <div className="flex items-center">
      {[1, 2, 3, 4].map((item, i) => (
        <div
          className={`flex item_vcenter item_hcenter`}
          style={{ flex: `${item > 1 ? '1 1 0%' : 'initial'} ` }}
          key={i}
        >
          {item > 1 && (
            <div
              style={{
                flex: '1 1 0%',
                height: '2px',
                backgroundColor: `${active >= item ? '#A561D8' : '#CBD5E0'}`
              }}
              className={`flex`}
            />
          )}
          <div
            style={{
              width: '32px',
              height: '32px',
              borderRadius: '50%',
              border: `2px solid ${active >= item ? '#A561D8' : '#CBD5E0'}`,
              backgroundColor: `${active > item ? '#A561D8' : 'var(--white)'}`
            }}
            className={`flex item_vcenter item_hcenter`}
          >
            {active <= item ? (
              <div
                style={{
                  width: '10px',
                  height: '10px',
                  borderRadius: '50%',
                  backgroundColor: `${
                    active === item ? '#A561D8' : 'var(--white)'
                  }`
                }}
              />
            ) : (
              <img src={check} alt="" />
            )}
          </div>
        </div>
      ))}
    </div>
  );
}
