import { client } from 'utilities/db';
import { useQuery } from 'react-query';

export const useFetchRolesHook = (keyName = 'roles', brandId) => {
  const { status, data, error, isFetching, isLoading, refetch } = useQuery(
    [keyName, brandId],
    () => getRoles(brandId)
  );

  return {
    roleStatus: status,
    roleData: data,
    roleError: error,
    roleFetching: isFetching,
    roleLoading: isLoading,
    roleRefetch: refetch
  };
};

const getRoles = async (brandId) => {
  let result = await client.get(`/role/findAllByBrand/${brandId}`);
  return result.data.roles;
};
