import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { TextField } from 'components/input';
import { CheckboxField } from 'components/checkbox';
import { Button } from 'components/button';
import { client } from 'utilities/db';
import { useMutation } from 'react-query';
import {
  useFetchBranches,
  useFetchBrands,
  useFetchRoles
} from '../../../../hooks/resourceHooks';
import { useState } from 'react';
import { getLocationId, getUser } from 'utilities/helpers/auth';
import { useNavigate } from 'react-router-dom';
import { RenderIf } from 'utilities/helpers';
import { useFetchManagerHook } from 'hooks/managers/useFetchManagerHooks';
import { phoneRegex } from 'utilities/regex';

export const AddBrandEmployeeForm = ({
  onChangeStep,
  onboarding = false,
  onSubmit
}) => {
  const user = getUser();

  const { data: brandsData } = useFetchBrands();

  const { data: branchData } = useFetchBranches(brandsData?.[0]?.id);

  const { mutate, isLoading, isSuccess, isError, error } = useMutation(
    (userData) => {
      return client.post(`/team/add`, userData);
    }
  );
  const navigate = useNavigate();
  const locationId = getLocationId();

  const [iAmManager, setIAmManager] = useState(null);
  const [selectedRole, setSelectedRole] = useState('');
  const {
    handleSubmit,
    register,
    formState: { errors }
  } = useForm();
  const onManagerSubmit = (val, event) => {
    event.preventDefault();
    mutate({
      email: val?.managerEmail,
      user_type_id: 1,
      name: val?.managerName,
      phone: val?.managerPhone,
      role_id: 2,
      brand_id: locationId,
      branch_id: null,
      existing_user_id: null,
      team_level: onboarding ? 'brand_manager' : 'branch_manager',
      cloud_id: null
    });
  };

  useEffect(() => {
    if (!isError) return;
    // alert(error);
  }, [isError, error]);

  useEffect(() => {
    if (!isSuccess) return;
    navigate('/');
  }, [isSuccess, onChangeStep]);
  return (
    <>
      <form onSubmit={handleSubmit(onManagerSubmit)}>
        <CheckboxField
          value={iAmManager}
          onChange={(e) => setIAmManager(!iAmManager)}
          id="iAmManager"
        >
          <b>Add a brand manager.</b>
        </CheckboxField>
        <RenderIf isTrue={iAmManager}>
          <div className="mt_16">
            <TextField
              name="managerName"
              label={`Manager's Name`}
              type="text"
              error={
                errors?.managerName?.type === 'required'
                  ? 'Manager naem is required'
                  : ''
              }
              placeholder={'John Doe'}
              validation={{
                required: true
              }}
              register={register}
              required
            />
            <TextField
              placeholder={'kfc@kfc.com'}
              name="managerEmail"
              label={`Manager's Email`}
              type="text"
              error={
                errors?.managerEmail?.type === 'required'
                  ? 'Manager email is required'
                  : ''
              }
              validation={{
                required: true
              }}
              register={register}
              required
            />
            <TextField
              placeholder={'456787654'}
              name="managerPhone"
              label={`Manager's Phone number`}
              type="number"
              error={
                errors?.managerPhone?.type === 'required' ? 'Phone is required' : errors?.managerPhone?.type === 'pattern'
                ? 'Phone is invalid'
                : ''
              }
              validation={{
                required: true,
                pattern: phoneRegex
              }}
              register={register}
              required
            />
            <div className="mt_26"></div>
            <div className="onfooter">
              <div></div>
              <div className="flex item_vcenter">
                {/* <Button
              onClick={() => onChangeStep(2)}
              type="button"
              label={'Back'}
              color="outline-lite"
            /> */}
                <Button
                  type="submit"
                  label={'Next'}
                  color="bg-button"
                  isLoading={isLoading}
                />
              </div>
            </div>
          </div>
        </RenderIf>
        <RenderIf isTrue={!iAmManager}>
          <div className="mt_16"></div>
          <div className="onfooter">
            <div></div>
            <div className="flex item_vcenter">
              <Button
                onClick={() => {
                  if (branchData?.length > 0) {
                    window.location.reload();
                  } else {
                    navigate('/add-branch');
                  }
                }}
                type="button"
                label={'Next'}
                color="bg-button"
                isLoading={isLoading}
              />
            </div>
          </div>
        </RenderIf>
      </form>
      {error && (
        <div>
          <p style={{ color: 'red', fontWeight: '800' }}>
            {error?.response?.data?.message}
          </p>
        </div>
      )}
    </>
  );
};

export const AddBrandEmployee = ({ onChangeStep }) => {
  return (
    <>
      <div className="box1">
        <h2>
          <strong>Add Brand</strong>
        </h2>
        <h5>Manager's Information</h5>
        <div className="mt_40"></div>
        <AddBrandEmployeeForm onChangeStep={onChangeStep} />
      </div>
    </>
  );
};
