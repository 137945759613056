import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts';

const data = [
  { name: 'Group A', value: 120 },
  { name: 'Group B', value: 200 },
  { name: 'Group C', value: 450 },
  { name: 'Group D', value: 180 },
  { name: 'Group E', value: 185 },
  { name: 'Group F', value: 180 },
  { name: 'Group G', value: 170 }
];
const COLORS = [
  '#90CD3F',
  '#C13B8B',
  '#6A2DAB',
  '#3C4BB4',
  '#2C6FBC',
  '#4CACCD',
  '#52B234'
];

export const PiechartComponent = ({ chartData }) => {
  return (
    <div style={{ width: `100%`, height: 256 }}>
      <ResponsiveContainer>
        <PieChart>
          <Pie
            data={data}
            innerRadius={60}
            outerRadius={85}
            paddingAngle={-2}
            dataKey="value"
          >
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
};
