import { RenderIf } from 'utilities/helpers';
import styles from './index.module.css';

export const BuilderSwitchField = ({
  label,
  name,
  id,
  onChange,
  defaultChecked
}) => {
  return (
    <article>
      <label className={`${styles.label}`} htmlFor={id}>
        <input
          type={`checkbox`}
          name={name}
          id={id}
          defaultChecked={defaultChecked}
          onChange={onChange}
        />
        <span className={styles.checkmark}></span>
        <RenderIf isTrue={label}>{label}</RenderIf>
      </label>
    </article>
  );
};
