import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { RenderIf } from 'utilities/helpers';
import { useFetchBrands } from 'hooks/resourceHooks';
import { getUser } from 'utilities/helpers/auth';
import { Button } from 'components/button';
import { Ikon } from 'assets/icons';

function KDSPage() {
  const navigate = useNavigate();

  const user = getUser();
  const { data: brandsData, isLoading } = useFetchBrands(user?.id);

  const [selectedBrand, setSelectedBrand] = useState(null);

  return (
    <>
      <div className={`mt_40`}></div>
      <div className={`flex item_space_btn pt_10`}>
        <div>
          <h1>
            <strong>Select Brands</strong>
          </h1>
          <h5 className={`mt_4`}>Lorem ipsum dolor ist meunda.</h5>
        </div>
      </div>
      <RenderIf isTrue={!isLoading}>
        <div className={`grid grid_gap30`}>
          {brandsData?.map((brand, index) => (
            <div key={index} className={`collg4`}>
              <button
                className={
                  selectedBrand?.id === brand?.id
                    ? `card_item3 card_item3_md active mt_30`
                    : `card_item3 card_item3_md mt_30`
                }
                onClick={() => setSelectedBrand(brand)}
              >
                <div>
                  <picture>
                    <RenderIf isTrue={!brand?.image_url}>
                      <div className={`placeholder`}>
                        <Ikon name={`imgplaceholder`} />
                      </div>
                    </RenderIf>
                    <RenderIf isTrue={brand?.image_url}>
                      <source srcSet={brand?.image_url} />
                      <img src={brand?.image_url} alt="" />
                    </RenderIf>
                  </picture>
                  <div className={`mt_15`}></div>
                  <h4>
                    <strong>{brand?.brand_name}</strong>
                  </h4>
                </div>
              </button>
            </div>
          ))}
        </div>
        <div className={`mt_30`}></div>
        <div className={`flex item_hend`}>
          <Button
            onClick={() => {
              navigate(`/BranchView`, {
                state: { brand: selectedBrand }
              });
            }}
            className={`button button_radius b_wid160`}
            color="bg-primary"
            label={`Next`}
            disabled={!selectedBrand}
          />
        </div>
      </RenderIf>
    </>
  );
}

export default KDSPage;
