import { CustomSelectDropdown } from 'components/select/multi';
import { Toasts } from 'components/toasts';
import { useFetchInventoryHook } from 'hooks/inventory/useFetchInventoryHook';
import { useFetchProductsCategoryHook } from 'hooks/products/useFetchProductsCategoryHook';
import { useFetchProductsGroupHook } from 'hooks/products/useFetchProductsGroupHook';
import { useFetchBranches, useFetchBrands } from 'hooks/resourceHooks';
import { useState } from 'react';
import { Button } from 'components/button';
import { client } from 'utilities/db';
import { useStore } from 'store';

const UNITS = [
  'kg',
  'pounds',
  'grams',
  'ounces',
  'liters',
  'teaspoon',
  'number/pieces',
  'tablespoon',
  'dozen'
];

const TEMP_DATA = {
  id: 41,
  reciept_id: '4635c502-519e-4149-8929-614a25ee6545',
  user_id: 6,
  vendor_id: 2,
  invoice_number: null,
  total_amount: 633.14,
  created_at: '2022-09-29',
  updated_at: '2022-09-29',
  deleted_at: null,
  InventoryReceiptItemModel: [
    {
      id: 79,
      upc: '340830581',
      description: 'BIL-CHANTI',
      unit_quantity: 3,
      case_quantity: 0,
      unit_price: 38,
      price: 114,
      quantity: 3,
      inventory_reciept_id: 41,
      created_at: '2022-09-29',
      updated_at: '2022-09-29',
      deleted_at: null
    },
    {
      id: 80,
      upc: '340830582',
      description: 'KETLE ONE',
      unit_quantity: 1,
      case_quantity: 0,
      unit_price: 10,
      price: 10,
      quantity: 1,
      inventory_reciept_id: 41,
      created_at: '2022-09-29',
      updated_at: '2022-09-29',
      deleted_at: null
    },
    {
      id: 81,
      upc: '340830583',
      description: 'Grigliata App',
      unit_quantity: 3,
      case_quantity: 0,
      unit_price: 19,
      price: 57,
      quantity: 3,
      inventory_reciept_id: 41,
      created_at: '2022-09-29',
      updated_at: '2022-09-29',
      deleted_at: null
    },
    {
      id: 82,
      upc: '340830584',
      description: 'ANTIPASTO',
      unit_quantity: 2,
      case_quantity: 0,
      unit_price: 20,
      price: 40,
      quantity: 2,
      inventory_reciept_id: 41,
      created_at: '2022-09-29',
      updated_at: '2022-09-29',
      deleted_at: null
    },
    {
      id: 83,
      upc: '340830585',
      description: 'CAESAR SALAD',
      unit_quantity: 4,
      case_quantity: 0,
      unit_price: 8,
      price: 32,
      quantity: 4,
      inventory_reciept_id: 41,
      created_at: '2022-09-29',
      updated_at: '2022-09-29',
      deleted_at: null
    },
    {
      id: 84,
      upc: '340830586',
      description: 'ORATA FILET',
      unit_quantity: 1,
      case_quantity: 0,
      unit_price: 35,
      price: 35,
      quantity: 1,
      inventory_reciept_id: 41,
      created_at: '2022-09-29',
      updated_at: '2022-09-29',
      deleted_at: null
    },
    {
      id: 85,
      upc: '340830588',
      description: 'SEABASS ESCAROLA',
      unit_quantity: 1,
      case_quantity: 0,
      unit_price: 35,
      price: 35,
      quantity: 1,
      inventory_reciept_id: 41,
      created_at: '2022-09-29',
      updated_at: '2022-09-29',
      deleted_at: null
    },
    {
      id: 86,
      upc: '340830590',
      description: 'VEGITABLE TERINË',
      unit_quantity: 1,
      case_quantity: 0,
      unit_price: 9,
      price: 9,
      quantity: 1,
      inventory_reciept_id: 41,
      created_at: '2022-09-29',
      updated_at: '2022-09-29',
      deleted_at: null
    },
    {
      id: 87,
      upc: '340830592',
      description: 'LASAGNA CINGHIAL',
      unit_quantity: 3,
      case_quantity: 0,
      unit_price: 24,
      price: 72,
      quantity: 3,
      inventory_reciept_id: 41,
      created_at: '2022-09-29',
      updated_at: '2022-09-29',
      deleted_at: null
    },
    {
      id: 88,
      upc: '340830594',
      description: 'MACH PESCE SPADA SICLIA',
      unit_quantity: 1,
      case_quantity: 0,
      unit_price: 26,
      price: 26,
      quantity: 1,
      inventory_reciept_id: 41,
      created_at: '2022-09-29',
      updated_at: '2022-09-29',
      deleted_at: null
    },
    {
      id: 89,
      upc: '340830596',
      description: 'V. CHOP SPC VALDOSTANA\n20 %',
      unit_quantity: 1,
      case_quantity: 0,
      unit_price: 68,
      price: 68,
      quantity: 1,
      inventory_reciept_id: 41,
      created_at: '2022-09-29',
      updated_at: '2022-09-29',
      deleted_at: null
    },
    {
      id: 90,
      upc: '340830598',
      description: '20%Gratuity',
      unit_quantity: 1,
      case_quantity: 0,
      unit_price: -99.9,
      price: -99.9,
      quantity: 1,
      inventory_reciept_id: 41,
      created_at: '2022-09-29',
      updated_at: '2022-09-29',
      deleted_at: null
    }
  ]
};

export const ReceiptDataPage = ({ recieptData, recieptId, setRecieptData }) => {
  const { brand, branch } = useStore((state) => state);

  const [data, setData] = useState(
    (
      recieptData?.InventoryReceiptItemModel ||
      recieptData ||
      TEMP_DATA?.InventoryReceiptItemModel
    )
      ?.filter(
        (inv) =>
          inv?.description && inv?.quantity && inv?.price && inv?.created_at
      )
      ?.map((inv) => ({
        ...inv,
        unit: '',
        group: '',
        category: '',
        expiry_date: '',
        branch: ''
      }))
  );
  const { inventoryRefetch } = useFetchInventoryHook(
    `inventory/${brand?.id}`,
    branch?.id
  );

  const handleAddInventory = async () => {
    try {
      if (data?.length > 0) {
        let tempData = [...data];
        let unit = tempData?.filter((item) => item.unit === '');
        let group = tempData?.filter((item) => item.group === '');
        let category = tempData?.filter((item) => item.category === '');
        let expiry_date = tempData?.filter((item) => item.expiry_date === '');
        let branch = tempData?.filter((item) => item.branch === '');

        if (
          unit?.length > 0 ||
          group?.length > 0 ||
          category?.length > 0 ||
          expiry_date?.length > 0 ||
          branch?.length > 0
        ) {
          Toasts.error('Please fill all the fields');
          return;
        } else {
          await client.post('/inventory/addInventoryProducts', {
            brand_id: brand?.id,
            products: data,
            reciept_id: recieptId || data?.[0]?.reciept_id
          });
          inventoryRefetch();
          if (data?.[0]?.inventory_reciept_id) {
            localStorage.removeItem('restaurantDepoDataHasStartedFetching');
          }
          Toasts.success('Data added successfully');
          setRecieptData(null);
        }
      }
    } catch (error) {
      Toasts.error(error?.response?.data?.message);
    }
  };

  return (
    <>
      <div className="mt_40"></div>
      <div className="flex item_space_btn pt_10">
        <div>
          <h1>
            <strong>Add Reciept Inventory</strong>
          </h1>
        </div>
      </div>
      <div className="mt_40"></div>
      {data
        ?.filter((d) => d?.description !== '')
        ?.map((item, index) => {
          return (
            <ItemsList
              item={item}
              index={index}
              data={data}
              setData={setData}
            />
          );
        })}
      <div className="mt_30"></div>
      <Button
        label="Add Item"
        color="bg-primary"
        onClick={handleAddInventory}
      />
    </>
  );
};

const ItemsList = ({ item, index, data, setData }) => {
  const { brand, branch } = useStore((state) => state);

  const { productCategoryData } = useFetchProductsCategoryHook(
    'products-categories',
    branch?.id
  );
  const { productGroupData } = useFetchProductsGroupHook(
    'products-groups',
    branch?.id
  );
  // const { data: branchesData } = useFetchBranches(brand?.id);

  return (
    <>
      <div className="flexbox">
        <div className="fbitem">
          <div>{item?.description}</div>
        </div>
        <div className="fbitem">
          <div>Quantity</div>
          <div>{item?.quantity}</div>
        </div>
        <div className="fbitem">
          <div>Price</div>
          <div>{item?.price}</div>
        </div>
        <div className="fbitem">
          <CustomSelectDropdown
            select2={true}
            label="Unit"
            placeholder=""
            options={UNITS?.map((u) => ({
              value: u,
              label: u
            }))}
            onChange={(e) => {
              setData((prev) => {
                const newData = [...prev];
                newData[index].unit = e.value;
                return newData;
              });
            }}
          />
        </div>
        <div className="fbitem">
          <CustomSelectDropdown
            select2={true}
            label="Group"
            placeholder=""
            options={productGroupData?.map((u) => ({
              value: u?.id,
              label: u?.group_name
            }))}
            onChange={(e) => {
              setData((prev) => {
                const newData = [...prev];
                newData[index].group = e.value;
                return newData;
              });
            }}
          />
        </div>
        <div className="fbitem">
          <CustomSelectDropdown
            select2={true}
            label="Category"
            placeholder=""
            options={productCategoryData?.map((u) => ({
              value: u?.id,
              label: u?.category_name
            }))}
            onChange={(e) => {
              setData((prev) => {
                const newData = [...prev];
                newData[index].category = e.value;
                return newData;
              });
            }}
          />
        </div>
        <div className="fbitem">
          <div>Date of purchase</div>
          <div> {item?.created_at} </div>
        </div>
        <div className="fbitem">
          <label className="label2">Date of expiry</label>
          <input
            className="input3"
            type="date"
            onChange={(e) => {
              setData((prev) => {
                const newData = [...prev];
                newData[index].expiry_date = e.target.value;
                return newData;
              });
            }}
          />
        </div>
        <div className="fbitem">
          <CustomSelectDropdown
            select2={true}
            label="Branch"
            placeholder=""
            options={[
              {
                value: branch?.id,
                label: branch?.branch_name || branch?.kitchen_name || '-'
              }
            ]}
            onChange={(e) => {
              setData((prev) => {
                const newData = [...prev];
                newData[index].branch = e.value;
                return newData;
              });
            }}
          />
        </div>
      </div>
    </>
  );
};
