import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStore } from 'store';
import { DeploymentClient } from 'utilities/db';
import { RenderIf } from 'utilities/helpers';
import { Loading } from 'components/loading';
import { useFetchOnlineContentHook } from 'hooks/builder/useFetchOnlineContentHook';
import { useFetchDomainSettingsHook } from 'hooks/domain/useFetchDomainSettingsHook';
import { useFetchDeploymentStatusHook } from 'hooks/domain/useFetchDeploymentStatusHook';
import { useFetchCustomDomainStatusHook } from 'hooks/domain/useFetchCustomDomainStatusHook';
import { Steps } from 'rsuite';
import { DomainsLayout } from '../layout';
import { Toasts } from 'components/toasts';
import styles from "./index.module.css"
import { Ikon } from 'assets/icons';

export const Domains = () => {
  const navigate = useNavigate();

  const [submittingDeployment, setSubmittingDeployment] = useState(false);

  const { brand } = useStore((state) => state);

  const { onlineContentData, onlineContentLoading } = useFetchOnlineContentHook(
    `onlineContent/${brand?.id}`,
    brand?.id
  );

  const { domainSettingData, domainSettingLoading, domainSettingRefetch } =
    useFetchDomainSettingsHook(
      `domainSettings/${brand?.id}`,
      brand?.id,
      brand?.brand_name,
      'PRODUCTION'
    );

  const { deploymentData, deploymentRefetch } = useFetchDeploymentStatusHook(
    `deploymentStatus/${brand?.id}`,
    brand?.id,
    brand?.brand_name,
    'PRODUCTION'
  );

  const { customDomainRefetch } = useFetchCustomDomainStatusHook(
    `customDomainStatus/${brand?.id}`,
    brand?.id,
    brand?.brand_name,
    'PRODUCTION'
  );

  const onlineStoreData = onlineContentData?.[0]?.value;
  const deploymentRecordData = domainSettingData?.existingDeploymentRecord;

  const handleDeployments = async () => {
    setSubmittingDeployment(true);
    try {
      const response = await DeploymentClient.post(`/deployment/startBuild`, {
        brandId: `${brand?.id}`,
        brandName: brand?.brand_name,
        enviorment: 'PRODUCTION'
      });
      domainSettingRefetch();
      Toasts.success('Deployment started successfully');
      setSubmittingDeployment(false);
    } catch (error) {
      setSubmittingDeployment(false);
      Toasts.error(error?.response?.data?.error || error?.message || 'Error');
    }
  };

  useEffect(() => {
    if (deploymentRecordData?.deploymentStatus !== 'READY') {
      deploymentRefetch();
    }
    if (deploymentRecordData?.customDomainConfig) {
      customDomainRefetch();
    }
  }, [deploymentRecordData]);

  return (
    <>
      <DomainsLayout>
        <header className={styles.header}>
          <div>
            <h2>Domains</h2>
            <h6>Manage, connect and transfer domains.</h6>
          </div>
        </header>

        <RenderIf isTrue={onlineContentLoading || domainSettingLoading}>
          <Loading />
        </RenderIf>
        <div className={styles.content}>
          <RenderIf isTrue={!onlineContentLoading && !onlineStoreData}>
            <div className={styles.alert}>
              Please create your online Store first.
            </div>
          </RenderIf>
          <RenderIf
            isTrue={
              !domainSettingLoading &&
              onlineStoreData &&
              domainSettingData?.error &&
              !deploymentData
            }
          >
            <button
              type={`button`}
              className={styles.button}
              disabled={submittingDeployment}
              onClick={handleDeployments}
              isLoading={submittingDeployment}
            >
              <div>
                <Ikon name={`domain-www`} />
                <div>
                  <h4>Make your store live</h4>
                </div>
              </div>
            </button>

            <button 
              type={`button`}
              className={`${styles.button} ${styles.button_1}`}
              disabled={true} 
            >
              <div>
                <Ikon name={`domain-http`} />
                <div>
                  <h4>Add an existing domain</h4>
                </div>
              </div>
            </button>
            <button 
              type={`button`}
              className={`${styles.button} ${styles.button_1}`}
              disabled={true}
            >
              <div>
                <Ikon name={`domain-com`} />
                <div>
                  <h4>Buy new domain</h4>
                </div>
              </div>
            </button>
          </RenderIf>
        </div>
      
        <RenderIf
          isTrue={
            deploymentRecordData &&
            deploymentData?.message !== 'Deployment ready to use'
          }
        >
          <div className={styles.stepsBx}>
            <Steps
              current={
                deploymentData?.message === 'Deployment BUILDING'
                  ? 1
                  : deploymentData?.message === 'Deployment READY'
                  ? 2
                  : deploymentData?.message === 'Deployment ready to use'
                  ? 3
                  : 1
              }
            >
              <Steps.Item title="Initializing" />
              <Steps.Item title="Deploying" />
              <Steps.Item title="Connecting domain" />
            </Steps>
            <h5>{deploymentData?.message}</h5>
          </div>
        </RenderIf>

        <RenderIf
          isTrue={
            deploymentRecordData &&
            deploymentData?.message === 'Deployment ready to use'
          }
        >
          <div className={styles.content}>
            <button
              type={`button`}
              className={styles.button}
              onClick={() => {
                window.open('https://' + deploymentData?.url, '_blank');
              }}
            >
              <div>
                <Ikon name={`domain-www`} />
                <div>
                  <h4>Zewst Domain - <b>Click to View</b></h4>
                  <div className={styles.activeBx}>
                      <span className={styles.activeDot}></span>
                    <p style={{ color: `var(--primary)`, fontWeight: `500`, }}>
                      {deploymentData?.url}
                    </p>
                  </div>
                </div>
              </div>
            </button>

            <button
              type={`button`}
              className={styles.button}
              onClick={() => {
                if (
                  !deploymentRecordData?.customDomain ||
                  !deploymentRecordData?.customDomainConfig ||
                  !deploymentRecordData?.customDomainVerified
                ) {
                  navigate('/setupdomain/existingdomain', {
                    state: {
                      deploymentRecordData,
                      brand
                    }
                  });
                } else {
                  window.open(
                    'https://' + deploymentRecordData?.customDomain,
                    '_blank'
                  );
                }
              }}
            >
              <div>
                <Ikon name={`domain-http`} />
                <div>
                  <h4>
                    Connect Pre-owned Domain -{' '}
                    <b>
                      {!deploymentRecordData?.customDomainConfig ||
                      !deploymentRecordData?.customDomainVerified ||
                      !deploymentRecordData?.customDomain
                        ? 'Click to setup'
                        : 'Click to view'}
                    </b>
                  </h4>
                  <RenderIf
                    isTrue={
                      deploymentRecordData?.customDomainConfig &&
                      deploymentRecordData?.customDomainVerified
                    }
                  >
                    <div className={styles.activeBx}>
                      <span className={styles.activeDot}></span>
                      <p style={{ color: `var(--primary)`, fontWeight: `500`, }}>
                        {deploymentRecordData?.customDomain}
                      </p>
                    </div>
                  </RenderIf>
                </div>
              </div>
            </button>

            <button
              type={`button`}
              className={styles.button}
              onClick={() => {
                if (
                  !deploymentRecordData?.purchasedDomain ||
                  !deploymentRecordData?.purchasedDomainBuyDate ||
                  !deploymentRecordData?.purchasedDomainExpiry
                ) {
                  navigate('/setupdomain/newdomain', {
                    state: {
                      deploymentRecordData,
                      brand
                    }
                  });
                } else {
                  window.open(
                    'https://' + deploymentRecordData?.purchasedDomain,
                    '_blank'
                  );
                }
              }}
            >
              <div>
                <Ikon name={`domain-com`} />
                <div>
                  <h4>
                    Buy a Domain -{' '}
                    <b>
                      {!deploymentRecordData?.purchasedDomain ||
                      !deploymentRecordData?.purchasedDomainBuyDate ||
                      !deploymentRecordData?.purchasedDomainExpiry
                        ? 'Click to setup'
                        : 'Click to view'}
                    </b>
                  </h4>
                  <RenderIf isTrue={deploymentRecordData?.purchasedDomain}>
                    <div className={styles.activeBx}>
                      <span className={styles.activeDot}></span>
                      <p style={{ color: `var(--primary)`, fontWeight: `500`, }}>
                        {deploymentRecordData?.purchasedDomain}
                      </p>
                    </div>
                  </RenderIf>
                </div>
              </div>
            </button>
          </div>
        </RenderIf>
      </DomainsLayout>
    </>
  );
};
