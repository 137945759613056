import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { RequireAuth } from 'utilities/protectedhoc';
import { MainLayout } from 'layouts/main';
import { AuthLayout } from 'layouts/auth';
import { OnBoardLayout } from 'layouts/onboard';
import { StoreBuilder } from 'layouts/builder';
import { LogInView } from 'views/login';
import { SignupView } from 'views/signup';
import { LinkSquareView } from 'views/linksquare';
import { AddBrandsView } from 'views/addbrands';
import { AddMenuView } from 'views/addmenu';
import { AddBranchView } from 'views/addbranch';
import DashboardView from 'views/dashboard';
import { StoreBuilderView } from 'views/storebuilder';
import KDS from 'views/kds';
import BranchView from 'views/kds/kdsbranches';
import KDSCategory from 'views/kds/kdsCategory';
import KDSSelected from 'views/kds/kdsselection';
import { EmployRole } from 'views/employmanagment/role';
import { Employes } from 'views/employmanagment/employ';
import {
  SetupDomainView,
  BuyDomain,
  ExistingDomain,
  Domains
} from 'views/setupdomain';
import { AddUpdateRecipePage, RecipeView } from 'views/recipe';
import { MenuView } from 'views/menu';
import { BrandsView } from 'views/brands';
import { BrandDetailPage } from 'pages/brands/detail';
import { KitchenView } from 'views/kitchen';
import { InventoryView } from 'views/inventory';
import { AddInventoryItemView } from 'views/inventory/add';
import { ProductsView } from 'views/products';
import { VendorsView } from 'views/vendors';
import { ForgotView } from 'views/forgotpassword';
import {
  AddUpdateBrandPage,
  PortfolioAddBranchManagerPage,
  PortfolioAddCompanyPage,
  PortfolioAddManagerPage,
  PortfolioBranchDetailsPage,
  PortfolioBranchesAddUpdatePage,
  PortfolioBrandDetailsPage,
  PortfolioView
} from 'views/portfolio';
import DemandPlanningView from 'views/demandplanning';
import KitchenPlanningView from 'views/kitchenplanning';
import { SDSPage } from 'pages/sds';
import { SdsDetailScreen } from 'pages/sds/details';
import { KDSListings } from 'pages/kds/listing';
import { RequestConnections } from 'pages/portfolio/branches/details/requestconnection';
import { ViewItemDetails } from 'pages/inventory/viewitemdetail';
import { InventoryOverviewView } from 'views/inventory/overview';
import { OrdersView } from 'views/orders';
import { OrderDetailView } from 'views/orders/detail';
import { DeliveryListing } from 'pages/settings/delivery/listing';
import Payments from 'pages/finix-payments/payments';
import CreatePaymentForm from 'pages/finix-payments/payments/create';
import { Devices } from 'pages/finix-payments/devices';
import { CreateDevice } from 'pages/finix-payments/devices/create';
import { DeviceDetails } from 'pages/finix-payments/devices/details';
import BrandPaymentContracts from 'pages/finix-payments/brand-contract';
import UpdatePaymentForm from 'pages/finix-payments/update';
import UploadDocumentsFinix from 'pages/finix-payments/upload-documents';

// for build of production

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        {/* MainLayout Auth routes */}
        <Route element={<RequireAuth />}>
          <Route
            path="/sds/details"
            element={
              <MainLayout>
                <SdsDetailScreen />
              </MainLayout>
            }
          />
          <Route
            path="/sds"
            element={
              <MainLayout>
                <SDSPage />
              </MainLayout>
            }
          />
          <Route
            path="/kitchen"
            element={
              <MainLayout>
                <KitchenView />
              </MainLayout>
            }
          />
          <Route
            path="/menu-engineering/categories"
            element={
              <MainLayout>
                <MenuView category />
              </MainLayout>
            }
          />
          <Route
            path="/menu-engineering/category/detail/:id"
            element={
              <MainLayout>
                <MenuView categoryDetail />
              </MainLayout>
            }
          />
          <Route
            path="/menu-engineering/items"
            element={
              <MainLayout>
                <MenuView item />
              </MainLayout>
            }
          />
          <Route
            path="/menu-engineering/item/detail/:id"
            element={
              <MainLayout>
                <MenuView itemDetail />
              </MainLayout>
            }
          />
          <Route
            path="/menu-engineering/modifiers"
            element={
              <MainLayout>
                <MenuView modifier />
              </MainLayout>
            }
          />
          <Route
            path="/menu-engineering/modifier/detail/:id"
            element={
              <MainLayout>
                <MenuView modifierDetail />
              </MainLayout>
            }
          />
          <Route
            path="/demand-planning"
            element={
              <MainLayout>
                <DemandPlanningView />
              </MainLayout>
            }
          />
          <Route
            path="/kitchenplanning"
            element={
              <MainLayout>
                <KitchenPlanningView />
              </MainLayout>
            }
          />
          <Route
            path="/dashboard"
            element={
              <MainLayout>
                <DashboardView />
              </MainLayout>
            }
          />{' '}
          <Route
            path="/kds-listing"
            element={
              <MainLayout>
                <KDSListings />
              </MainLayout>
            }
          />
          <Route
            path="/KDS"
            element={
              <MainLayout>
                <KDS />
              </MainLayout>
            }
          />
          <Route
            path="/BranchView"
            element={
              <MainLayout>
                <BranchView />
              </MainLayout>
            }
          />
          <Route
            path="/KDSCategory"
            element={
              <MainLayout>
                <KDSCategory />
              </MainLayout>
            }
          />
          <Route
            path="/KDSSelected"
            element={
              <MainLayout>
                <KDSSelected />
              </MainLayout>
            }
          />
          <Route
            path="/recipe-engineering/recipe/detail/:id"
            element={
              <MainLayout>
                <RecipeView steps={3} isMixture={false} />
              </MainLayout>
            }
          />
          <Route
            path="/recipe-engineering/mixture/detail/:id"
            element={
              <MainLayout>
                <RecipeView steps={5} isMixture />
              </MainLayout>
            }
          />
          <Route
            path="/recipe-engineering"
            element={
              <MainLayout>
                <RecipeView steps={1} />
              </MainLayout>
            }
            exact
          />
          <Route
            path="/recipe-engineering/update/:type"
            element={
              <MainLayout>
                <AddUpdateRecipePage />
              </MainLayout>
            }
          />
          <Route
            path="/brands"
            element={
              <MainLayout>
                <BrandsView />
              </MainLayout>
            }
          />
          <Route
            path="/brand/detail/:id"
            element={
              <MainLayout>
                <BrandDetailPage />
              </MainLayout>
            }
          />
          <Route
            path="/inventory/overview"
            element={
              <MainLayout>
                <InventoryOverviewView />
              </MainLayout>
            }
          />
          <Route
            path="/inventory/listing"
            element={
              <MainLayout>
                <InventoryView />
              </MainLayout>
            }
          />
          <Route
            path="/inventory/add"
            element={
              <MainLayout>
                <AddInventoryItemView />
              </MainLayout>
            }
          />
          <Route
            path="/inventory/product/:id"
            element={
              <MainLayout>
                <ViewItemDetails />
              </MainLayout>
            }
          />
          <Route
            path="/inventory/vendors"
            element={
              <MainLayout>
                <VendorsView />
              </MainLayout>
            }
          />
          <Route
            path="/inventory/products"
            element={
              <MainLayout>
                <ProductsView />
              </MainLayout>
            }
          />
        </Route>
        {/* onboarding Auth routes */}
        <Route element={<RequireAuth />}>
          <Route path="/add-menu" element={<AddMenuView />} />
          <Route
            path="/add-brand"
            element={
              <OnBoardLayout>
                <AddBrandsView />
              </OnBoardLayout>
            }
          />
          <Route
            path="/add-branch"
            element={
              <OnBoardLayout>
                <AddBranchView />
              </OnBoardLayout>
            }
          />

          <Route
            path="/link-square"
            element={
              <AuthLayout>
                <LinkSquareView />
              </AuthLayout>
            }
          />
          <Route
            path="/online-store/builder"
            element={
              <StoreBuilder>
                <StoreBuilderView />
              </StoreBuilder>
            }
          />
          <Route
            path="/online-store/domains"
            element={
              <MainLayout>
                <Domains />
              </MainLayout>
            }
          />
          <Route
            path="/setupdomain"
            element={
              <MainLayout>
                <SetupDomainView />
              </MainLayout>
            }
          />
          <Route
            path="/setupdomain/existingdomain"
            element={
              <MainLayout>
                <ExistingDomain />
              </MainLayout>
            }
          />
          <Route
            path="/setupdomain/newdomain"
            element={
              <MainLayout>
                <BuyDomain />
              </MainLayout>
            }
          />
          <Route
            path="/portfolio"
            element={
              <MainLayout>
                <PortfolioView />
              </MainLayout>
            }
          />
          <Route
            path="/portfolio/brand/update"
            element={
              <MainLayout>
                <AddUpdateBrandPage />
              </MainLayout>
            }
          />
          <Route
            path="/portfolio/brand/add"
            element={
              <MainLayout>
                <AddUpdateBrandPage />
              </MainLayout>
            }
          />
          <Route
            path="/portfolio/brand/details/:id"
            element={
              <MainLayout>
                <PortfolioBrandDetailsPage />
              </MainLayout>
            }
          />
          <Route
            path="/portfolio/branch/update/:brandId"
            element={
              <MainLayout>
                <PortfolioBranchesAddUpdatePage />
              </MainLayout>
            }
          />
          <Route
            path="/portfolio/branch/add/:brandId"
            element={
              <MainLayout>
                <PortfolioBranchesAddUpdatePage />
              </MainLayout>
            }
          />
          <Route
            path="/portfolio/branch/details/:brandId/:branchId"
            element={
              <MainLayout>
                <PortfolioBranchDetailsPage />
              </MainLayout>
            }
          />
          <Route
            path="/portfolio/brand/manager/add/:brandId"
            element={
              <MainLayout>
                <PortfolioAddManagerPage />
              </MainLayout>
            }
          />
          <Route
            path="/portfolio/branch/manager/add/:brandId/:branchId"
            element={
              <MainLayout>
                <PortfolioAddBranchManagerPage />
              </MainLayout>
            }
          />
          <Route
            path="/portfolio/brand/company/add/:brandId"
            element={
              <MainLayout>
                <PortfolioAddCompanyPage />
              </MainLayout>
            }
          />
          <Route
            path="/portfolio/brand/company/update/:brandId"
            element={
              <MainLayout>
                <PortfolioAddCompanyPage />
              </MainLayout>
            }
          />
          <Route
            path="/portfolio/cloudbranch/:branchId"
            element={
              <MainLayout>
                <RequestConnections />
              </MainLayout>
            }
          />
          <Route
            path="/role"
            element={
              <MainLayout>
                <EmployRole />
              </MainLayout>
            }
          />
          <Route
            path="/Employes"
            element={
              <MainLayout>
                <Employes />
              </MainLayout>
            }
          />
        </Route>
        {/* Public routes */}
        <Route
          path="/"
          element={
            <AuthLayout>
              <LogInView />
            </AuthLayout>
          }
        />
        <Route
          path="/login"
          element={
            <AuthLayout>
              <LogInView />
            </AuthLayout>
          }
        />
        <Route
          path="/forgot-password"
          element={
            <AuthLayout>
              <ForgotView />
            </AuthLayout>
          }
        />
        <Route
          path="/signup"
          element={
            <AuthLayout>
              <SignupView />
            </AuthLayout>
          }
        />
        <Route
          path="/orders/history"
          element={
            <MainLayout>
              <OrdersView />
            </MainLayout>
          }
        />
        <Route
          path="/orders/detail/:id"
          element={
            <MainLayout>
              <OrderDetailView />
            </MainLayout>
          }
        />
        <Route
          path="/delivery-settings"
          element={
            <MainLayout>
              <DeliveryListing />
            </MainLayout>
          }
        />
        <Route
          path="/payment-settings"
          element={
            <MainLayout>
              <Payments />
            </MainLayout>
          }
        />
        <Route
          path="/payments/create"
          element={
            <MainLayout>
              <CreatePaymentForm />
            </MainLayout>
          }
        />
        <Route
          path="/payments/update"
          element={
            <MainLayout>
              <UpdatePaymentForm />
            </MainLayout>
          }
        />
        <Route
          path="/payments/documents"
          element={
            <MainLayout>
              <UploadDocumentsFinix />
            </MainLayout>
          }
        />
        <Route
          path="/device-settings"
          element={
            <MainLayout>
              <Devices />
            </MainLayout>
          }
        />
        <Route
          path="/devices/create"
          element={
            <MainLayout>
              <CreateDevice />
            </MainLayout>
          }
        />
        <Route
          path="/devices/details/:id"
          element={
            <MainLayout>
              <DeviceDetails />
            </MainLayout>
          }
        />
        <Route
          path="/brand-payment-contracts"
          element={
            <MainLayout>
              <BrandPaymentContracts />
            </MainLayout>
          }
        />
      </Routes>
    </BrowserRouter>
  );
}
