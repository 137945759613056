import React, { useState } from 'react';
import { RenderIf } from 'utilities/helpers';
import { AllOrders } from './allorders';
import { Tickets } from './tickets';

export default function Kitchen() {
  const [step, setStep] = useState(0);
  return (
    <>
      <div className={`mt_30`}></div>
      <div className={`tabs`}>
        <button
          onClick={() => setStep(0)}
          className={step === 0 ? `tab active` : `tab`}
        >
          <span>KDS/Tickets</span>
        </button>
        <button
          onClick={() => setStep(1)}
          className={step === 1 ? `tab active` : `tab`}
        >
          <span>Order Type</span>
        </button>
        <button
          onClick={() => setStep(2)}
          className={step === 2 ? `tab active` : `tab`}
        >
          <span>All orders</span>
        </button>
        <button
          onClick={() => setStep(3)}
          className={step === 3 ? `tab active` : `tab`}
        >
          <span>Order mode</span>
        </button>
      </div>

      <div>
        <RenderIf isTrue={step === 0}>
          <Tickets />
        </RenderIf>
        <RenderIf isTrue={step === 2}>
          <AllOrders />
        </RenderIf>
      </div>
    </>
  );
}
