import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../components/button';
import { FieldInput } from '../../components/input';
import { useForm } from 'react-hook-form';
import { Toasts } from 'components/toasts';
import { useFetchBranches, useFetchBrands } from 'hooks/resourceHooks';
import { useStore } from 'store';
import { emailRegex } from 'utilities/regex';
import { AXIOS_INSTANCE_ONBOARDING } from 'utilities/db';

export const LoginPage = () => {
  const { setBranch, setBrand, setUser, user } = useStore((state) => state);

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = React.useState(false);
  const [error, setError] = React.useState(null);

  const { data: brandData, isLoading: brandLoading } = useFetchBrands(user?.id);
  const { data: branchData, isLoading: branchLoading } = useFetchBranches(
    brandData?.[0]?.id
  );

  const {
    handleSubmit,
    register,
    formState: { errors }
  } = useForm();

  const onSubmitLogin = async (data) => {
    try {
      setError(null);
      setIsLoading(true);
      let response = (
        await AXIOS_INSTANCE_ONBOARDING.post('/authentication/login', {
          email: data?.email,
          password: data?.password
        })
      )?.data;
      let userReponse = await AXIOS_INSTANCE_ONBOARDING.get(
        `/authentication/me?fetch=roles,business,brands,branches`,
        {
          headers: {
            Authorization: `Bearer ${response?.access_token}`
          }
        }
      );
      userReponse = userReponse?.data;

      setUser(userReponse);
      setBrand(userReponse?.brands?.[0]);
      setBranch(userReponse?.branches?.[0]);

      Toasts.success(`Logged in successfully`);
      setIsLoading(false);
      navigate('/dashboard');
    } catch (error) {
      setError(error?.response?.data?.message || `Something went wrong`);
      Toasts.error(error?.response?.data?.message || `Something went wrong`);
      setIsLoading(false);
    }
  };

  return (
    <article className="full">
      <form onSubmit={handleSubmit(onSubmitLogin)}>
        <div className="auth_box">
          <div className="mt_40"></div>
          <div className="pt_40"></div>
          <div className="mt_40"></div>
          <h2 className="text_center">
            <span className="ff_semi">Welcome!</span>
          </h2>
          <h6 className="mt_12 text_center">Log in to ZEWST</h6>
          <div className="mt_46"></div>
          <div className="auth_form">
            <FieldInput
              name="email"
              label={'Email address'}
              type="text"
              error={
                errors?.email?.type === 'required'
                  ? 'Email is required'
                  : errors?.email?.type === 'pattern'
                  ? 'Email is invalid'
                  : ''
              }
              register={register}
              validation={{
                required: true,
                pattern: emailRegex
              }}
              required
            />
            <div style={{ marginTop: 16 }}></div>
            <FieldInput
              name="password"
              label={'Password'}
              type="password"
              error={
                errors?.password?.type === 'required'
                  ? 'Password is required'
                  : errors?.password?.type === 'minLength'
                  ? 'Password must be at least 8 characters'
                  : errors?.password?.type === 'maxLength'
                  ? 'Password must be at most 20 characters'
                  : ''
              }
              register={register}
              validation={{
                required: true,
                minLength: 8,
                maxLength: 20
              }}
              required
            />
            <div className="mt_30"></div>
            <div className={`field`}>
              <Button
                isLoading={isLoading}
                disabled={isLoading}
                type="submit"
                label={'Sign in'}
                color="bg-primary"
                full
              />
            </div>
            {/* <div className={`forgot_link_row`}>
              <div>
                <div className="mt_20"></div>
                <label className="label">
                  Not a Customer?{' '}
                  <Link to="/signup" className="label">
                    Get Started Today!
                  </Link>
                </label>
              </div>
              <div>
                <div className="mt_20"></div>
                <label className="label">
                  <Link to="/forgot-password" className="label">
                    Forgot Password?
                  </Link>
                </label>
              </div>
            </div> */}
          </div>
        </div>
      </form>
      {error && (
        <div>
          <p style={{ color: 'red', fontWeight: '800' }}>
            {error?.response?.data?.message}
          </p>
        </div>
      )}
    </article>
  );
};
