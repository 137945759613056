export const Button = (props) => {
  const {
    type,
    className,
    full,
    color,
    label,
    onClick = () => null,
    isLoading,
    disabled,
    style,
    children
  } = props;
  return (
    <button
      onClick={onClick}
      type={type ? type : 'button'}
      className={
        className
          ? className
          : full
          ? `button button_radius full`
          : `button button_radius`
      }
      color={color}
      style={style || null}
      disabled={isLoading || disabled}
    >
      {isLoading ? 'Loading' : label}
      {children}
    </button>
  );
};

export const PrimaryButton = ({
  type = `button`,
  buttonFull,
  onClick = () => {},
  isLoading,
  disabled,
  children,
  inlineStyle
}) => {
  return (
    <button
      onClick={onClick}
      type={type}
      disabled={isLoading || disabled}
      style={{
        outline: `none`,
        boxShadow: `none`,
        cursor: `pointer`,
        fontSize: `var(--buttonFontSize)`,
        lineHeight: `var(--buttonLineHeight)`,
        height: `var(--buttonHeight)`,
        display: `flex`,
        alignItems: `center`,
        justifyContent: `center`,
        gap: 8,
        paddingRight: `var(--buttonHPadding)`,
        paddingLeft: `var(--buttonHPadding)`,
        borderRadius: `var(--buttonRadius)`,
        backgroundColor: disabled ? 'grey' : `var(--primary)`,
        color: `var(--white)`,
        fontWeight: `600`,
        width: buttonFull ? `100%` : ``,
        ...inlineStyle
      }}
    >
      {isLoading ? 'Loading' : children}
    </button>
  );
};
