import { useMemo, useState } from 'react';
import { Table } from 'rsuite';
import { RenderIf } from 'utilities/helpers';
import { RestDepoVendorPage } from './restdepo';
import { ManualVendorPage } from './manual';
import { useFetchVendorsHook } from 'hooks/vendors/useFetchVendorsHook';
import { CustomTable } from 'components/datatable';
import { useFetchVendorHook } from 'hooks/vendors/useFetchVendorHook';
import { AddVendorType } from './addtype';
import { TextField } from 'components/input';
import { Ikon } from 'assets/icons';
import styles from './index.module.css';
import { useStore } from 'store';

const { Column, HeaderCell, Cell } = Table;

const VendorCell = ({ rowData, dataKey, ...props }) => (
  <Cell {...props}>
    <b>{rowData?.name}</b>
  </Cell>
);

const ActionsCell = ({ setVendorId, vendorId, rowData, dataKey, ...props }) => {
  return (
    <Cell {...props}>
      <button
        type={`button`}
        className="button_sm button_radius"
        color="bg-primary"
        onClick={() => {
          setVendorId(rowData?.vendorId);
        }}
      >
        Update
      </button>
    </Cell>
  );
};

export const VendorPage = () => {
  const { brand } = useStore((state) => state);

  const [showAddVendor, setShowAddVendor] = useState(true);
  const [additionType, setAdditionType] = useState('');
  const [vendorId, setVendorId] = useState('');
  const [showUpdateVendor, setShowUpdateVendor] = useState(false);
  const [addVendorType, setAddVendorType] = useState(false);
  const [searchField, setSearchField] = useState('');

  const { vendorData, vendorLoading } = useFetchVendorsHook(
    'vendors',
    brand?.id
  );
  const { singleVendorData, singleVendorLoading } = useFetchVendorHook(
    `vendor/${vendorId}`,
    vendorId
  );

  useMemo(() => {
    if (vendorId && singleVendorData && !singleVendorLoading) {
      if (singleVendorData?.vendor_type === 'STREET_VENDOR') {
        setAdditionType('local');
        setShowUpdateVendor(true);
        setShowAddVendor(false);
      }
      if (singleVendorData?.vendor_type === 'RESTAURANT_DEPO') {
        setAdditionType('restaurant-depot');
        setShowUpdateVendor(true);
        setShowAddVendor(false);
      }
    }
  }, [vendorId, singleVendorData, singleVendorLoading]);

  const filteredData = vendorData?.filter((item) => {
    return (
      item?.vendor_information?.name
        ?.toLowerCase()
        ?.includes(searchField?.toLowerCase()) ||
      item?.vendor_information?.email
        ?.toLowerCase()
        ?.includes(searchField?.toLowerCase()) ||
      item?.vendor_information?.phone
        ?.toLowerCase()
        ?.includes(searchField?.toLowerCase()) ||
      item?.vendor_information?.address
        ?.toLowerCase()
        ?.includes(searchField?.toLowerCase()) ||
      item?.vendor_type?.toLowerCase()?.includes(searchField?.toLowerCase())
    );
  });

  return (
    <>
      <div className="mt_40"></div>
      <div className="flex item_space_btn pt_10">
        <div>
          <h1>
            <strong>Vendors</strong>
          </h1>
        </div>
        <div>
          {/* <Button
            label="Add New Vendor"
            color="bg-titary"
            onClick={() => {
              setAddVendorType(true);
              setVendorId('');
              setShowUpdateVendor(false);
            }}
            //disabled={vendorData?.length === 0}
          /> */}
        </div>
      </div>
      <div className={styles.pt_32}></div>
      {/* <RenderIf isTrue={vendorData?.length === 0}> */}
      <div className="add_new_grid" style={{ marginBottom: '30px' }}>
        <AddVendorCards
          name={`fileChecked`}
          heading="Add New Local Vendor"
          description="Please add a new local vendor."
          onClick={() => setAdditionType('local')}
        />
        <AddVendorCards
          name={`fileScratch`}
          heading="Add New Restaurant Depot Vendor"
          description="Please add a new restaurant depot vendor."
          onClick={() => setAdditionType('restaurant-depot')}
        />
      </div>
      {/* </RenderIf> */}
      <div>
        <RenderIf isTrue={additionType === 'local'}>
          <ManualVendorPage
            setShowAddVendor={setShowAddVendor}
            setAdditionType={setAdditionType}
            showUpdateVendor={showUpdateVendor}
            setShowUpdateVendor={setShowUpdateVendor}
            updateData={singleVendorData}
            setVendorId={setVendorId}
          />
        </RenderIf>
        <RenderIf isTrue={additionType === 'restaurant-depot'}>
          <RestDepoVendorPage
            setShowAddVendor={setShowAddVendor}
            setAdditionType={setAdditionType}
            showUpdateVendor={showUpdateVendor}
            setShowUpdateVendor={setShowUpdateVendor}
            updateData={singleVendorData}
            setVendorId={setVendorId}
          />
        </RenderIf>
        <RenderIf isTrue={addVendorType}>
          <AddVendorType
            addVendorType={addVendorType}
            setAddVendorType={setAddVendorType}
            setAdditionType={setAdditionType}
          />
        </RenderIf>
      </div>
      <div>
        <div style={{ marginTop: 40 }}></div>
        <h4>
          <span className="ff_semi">Vendors</span>
        </h4>
        <div style={{ marginTop: 24 }}></div>
        <div className="box2 flex item_vcenter item_space_btn">
          <div className="w_356">
            <TextField
              onChange={(e) => setSearchField(e?.target?.value)}
              value={searchField}
              placeholder={'Type and see result'}
            />
          </div>
          <div></div>
        </div>
        <div style={{ marginTop: 24 }}></div>
        {/* <div className={styles.search_grid}>
          <div>
            <input
              className={`text_field`}
              type={`text`}
              name={`search`}
              placeholder={`Type and see result`}
              onChange={handleChange}
            />
          </div>
          <div></div>
        </div> */}
        <RenderIf isTrue={filteredData?.length === 0}>
          <div className={`no_data1`}>No data found.</div>
        </RenderIf>
        <RenderIf isTrue={filteredData?.length > 0}>
          <CustomTable
            rows={filteredData?.map((vendor, index) => {
              return {
                id: index + 1,
                name: vendor?.vendor_information?.name || '-',
                email: vendor?.vendor_information?.email || '-',
                password: vendor?.vendor_information?.password || '-',
                phone: vendor?.vendor_information?.phone || '-',
                address: vendor?.vendor_information?.address || '-',
                vendor_type: vendor?.vendor_type,
                vendorId: vendor?.id
              };
            })}
            loading={vendorLoading}
            pageLimit={10}
            pagination={filteredData?.length > 10 ? true : false}
          >
            <Column width={50} align="center" fixed>
              <HeaderCell>Id</HeaderCell>
              <Cell dataKey="id" />
            </Column>
            <Column width={200} sortable>
              <HeaderCell>Vendor Name</HeaderCell>
              <VendorCell dataKey="name" />
            </Column>
            <Column width={220} sortable>
              <HeaderCell>Email</HeaderCell>
              <Cell dataKey="email" />
            </Column>
            {/* <Column width={120} sortable>
            <HeaderCell>Password</HeaderCell>
            <Cell dataKey="password" />
          </Column> */}
            <Column width={140} sortable>
              <HeaderCell>Phone #</HeaderCell>
              <Cell dataKey="phone" />
            </Column>
            <Column flexGrow={1} sortable>
              <HeaderCell>Location</HeaderCell>
              <Cell dataKey="address" />
            </Column>
            <Column width={150} sortable>
              <HeaderCell>Vendor Type</HeaderCell>
              <Cell dataKey="vendor_type" />
            </Column>
            <Column width={120} align="center" fixed="right">
              <HeaderCell>Actions</HeaderCell>
              <ActionsCell
                setVendorId={setVendorId}
                vendorId={vendorId}
                dataKey="actions"
              />
            </Column>
          </CustomTable>
        </RenderIf>
      </div>
    </>
  );
};

const AddVendorCards = ({ name, heading, description, onClick }) => {
  return (
    <div>
      <button type={`button`} className="add_new_button" onClick={onClick}>
        <div>
          <div>
            <Ikon name={name} />
          </div>
          <h4>{heading}</h4>
          <p>{description}</p>
        </div>
      </button>
    </div>
  );
};
