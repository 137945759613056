import { RecipeEngineering } from 'pages/recipe';
import { AddUpdateRecipePage } from 'pages/recipe/update';

export const RecipeView = ({ steps, isMixture }) => {
  return (
    <>
      <RecipeEngineering isMixture={isMixture} steps={steps} />
    </>
  );
};

export { AddUpdateRecipePage };
