import { useQuery } from 'react-query';
import { client } from 'utilities/db';

export const useFetchDashboardHook = (keyName, branchId) => {
  const { status, data, error, isFetching, isLoading, refetch } = useQuery(
    [keyName, branchId],
    () => getData(branchId),
    {
      enabled: !!branchId,
      refetchInterval: 60000
    }
  );

  return {
    dashboardStatus: status,
    dashboardData: data,
    dashboardError: error,
    dashboardFetching: isFetching,
    dashboardLoading: isLoading,
    dashboardRefetch: refetch
  };
};

const getData = async (branchId) => {
  let result = await client.post(`/reporting/overview/getByBranchId`, {
    branch_id: branchId
  });
  result = result.data;
  return result;
};
