import { useQuery } from 'react-query';
import { client } from 'utilities/db';

export const useFetchBankDetailsHook = (keyName, id) => {
  const { status, data, error, isFetching, isLoading, refetch } = useQuery(
    [keyName, id],
    () => getBankData(id),
    {
      enabled: !!id,
      refetchInterval: 60000
    }
  );

  return {
    bankStatus: status,
    bankData: data,
    bankError: error,
    bankFetching: isFetching,
    bankLoading: isLoading,
    bankRefetch: refetch
  };
};

const getBankData = async (id) => {
  let result = await client.get(`/bankDetails/findAllByBrand/${id}`);
  result = result.data;
  result = result?.bankDetails;

  return result;
};
