import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { RenderIf } from 'utilities/helpers';
import ProgressSteps from 'components/steps';
import { CardBranchList } from 'components/cards/branchlist';
import { Button } from 'components/button';
import { client } from 'utilities/db';
import { useQuery } from 'react-query';
import { Loading } from 'components/loading';

export const ChooseBranchsForMenu = ({
  onChangeStep,
  locationId,
  branchArr,
  setBranchArr
}) => {
  const {
    isLoading: branchesLoading,
    error: branchesError,
    data: branchesData = [],
    isSuccess: branchesSuccess
  } = useQuery(['fetchBranches'], async () => {
    const data = await client.get(`/branch/findAll/${locationId}`);
    return data.data.branchDetails;
  });

  const { register } = useForm();

  useEffect(() => {
    if (!branchesError) return;
    alert(branchesError?.message);
  }, [branchesError]);

  const handleCheckboxChange = (e) => {
    let { value, checked } = e.target;
    if (checked && !branchArr.includes(value)) {
      setBranchArr((arr) => [...arr, value]);
    }
    if (!checked && branchArr.includes(value)) {
      setBranchArr(
        branchArr.filter(function (val) {
          return val !== value;
        })
      );
    }
  };

  return (
    <>
      <div style={{ minHeight: window.innerHeight }} className={`onflexlay`}>
        <div className="onbox">
          <h2>
            <strong>Branchs for menu</strong>
          </h2>
          <h5>Choose Branchs</h5>
          <ProgressSteps active={3} />
          <h5 className="mt_15">
            <b>Branches</b>
          </h5>
          <div className="pt_15"></div>
          <RenderIf isTrue={branchesLoading}>
            <div className={`loading_full`}>
              <Loading />
            </div>
          </RenderIf>
          <RenderIf isTrue={branchesLoading ? false : branchesSuccess}>
            {branchesData?.map((item, index) => (
              <CardBranchList
                key={index}
                title={item?.address?.line1}
                street={item?.address?.line2}
                town={item?.state}
                city={item?.city}
                selection={true}
                register={register}
                value={item?.id}
                name={`branch${item?.id}`}
                id={`branch${item?.id}`}
                onChange={(e) => handleCheckboxChange(e)}
              />
            ))}
          </RenderIf>
          <div className="pt_40"></div>
          <div className="onfooter">
            <div></div>
            <div className="flex item_vcenter">
              <Button
                onClick={() => onChangeStep(1)}
                type="button"
                label={'Back'}
                color="outline-lite"
              />
              <Button
                type="button"
                label={'Next'}
                color="bg-button"
                onClick={() => onChangeStep(2)}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
