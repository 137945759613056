// import { useState } from 'react';
import { CardImgMenuList } from 'components/cards/imgmenu';
import { Button } from 'components/button';
import { CardMenuList } from 'components/cards/menulist';
import { TEMPImgs } from 'theme/images';
import { Ikon } from 'assets/icons';
import styles from './index.module.css';
import { RenderIf } from 'utilities/helpers';
import { client } from 'utilities/db';
import { useQuery } from 'react-query';
import { useEffect, useState } from 'react';
import { Loading } from 'components/loading';

export const CategoryList = ({
  menuStep,
  setMenuStep,
  onChangeStep,
  locationId,
  selectedCategory,
  setSelectedCategory
}) => {
  const {
    refetch,
    isLoading,
    error,
    data = [],
    isSuccess
  } = useQuery(['fetchCategoriesOfBranches'], async () => {
    const data = await client.get(`/branchCategory/findAll/${locationId}`);
    return data.data.data;
  });
  const {
    isLoading: menuLoading,
    error: menuError,
    data: menuData = [],
    isSuccess: menuSuccess,
    refetch: menuRefetch
  } = useQuery(
    ['fetchMenuOfBranches'],
    async () => {
      const data = await client.post(
        `/branchMenu/findAllBranchMenuByCategory`,
        {
          restaurant_location_id: selectedCategory?.locationId,
          location_category_id: selectedCategory?.categoryId
        }
      );
      return data.data.data;
    },
    {
      enabled: !!selectedCategory?.locationId
    }
  );
  const [selectedMenuItem, setSelectedMenuItem] = useState({});

  useEffect(() => {
    if (!selectedCategory?.locationId) return;
    menuRefetch();
  }, [selectedCategory]);

  return (
    <>
      {isLoading ? (
        <div className={`loading_full`}>
          <Loading />
        </div>
      ) : (
        <div>
          <h6 className="ff_semi">Categories</h6>
          <div className="mt_15"></div>
          <div className={styles.listWrap}>
            {data?.map((item, i) => (
              <CardImgMenuList
                onClick={() =>
                  setSelectedCategory({
                    categoryId: item?.id,
                    locationId: item?.restaurant_location_id,
                    name: item?.category_name,
                    description: item?.description
                  })
                }
                key={i}
                media={item?.image}
                title={item?.category_name}
                active={item?.id === selectedCategory?.categoryId}
              />
            ))}
            <button className="add_button" onClick={() => setMenuStep(2)}>
              <span>
                <Ikon plus />
              </span>
              <span>Add Category</span>
            </button>
          </div>
        </div>
      )}
      <RenderIf isTrue={selectedCategory}>
        {menuLoading ? (
          <div className={`loading_full`}>
            <Loading />
          </div>
        ) : (
          <>
            <hr className="mt_40" />
            <div className="pt_15"></div>
            <CardMenuList
              title={selectedCategory?.name}
              description={selectedCategory?.description}
              types={[]}
            />
            <div className="mt_20"></div>

            <div className={styles.listWrap}>
              {menuData?.map((item, i) => (
                <CardImgMenuList
                  onClick={() => setSelectedMenuItem(item)}
                  key={i}
                  media={item?.image}
                  title={item?.item}
                  active={selectedMenuItem?.id === item?.id}
                />
              ))}
              <button className="add_button" onClick={() => setMenuStep(3)}>
                <span>
                  <Ikon plus />
                </span>
                <span>Add Item</span>
              </button>
            </div>
            <div className="mt_25"></div>
            <RenderIf isTrue={selectedMenuItem?.id}>
              <CardMenuList
                title={selectedMenuItem?.item}
                description={selectedMenuItem?.description}
                types={[selectedMenuItem?.price || '']}
              />
            </RenderIf>
          </>
        )}
      </RenderIf>
    </>
  );
};
