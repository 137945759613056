import { useNavigate, Link } from 'react-router-dom';
import { Button } from 'components/button';
import { TextField } from 'components/input';
import { useFetchBrands } from 'hooks/resourceHooks';
import { useStore } from 'store';
import { RenderIf } from 'utilities/helpers';
import { Ikon } from 'assets/icons';
import { useState } from 'react';
import { ViewConnectionsForBrand } from './viewconnectionforbrand';
import { ModalComponent } from 'components/modal';
import { Loading } from 'components/loading';

export const PortfolioPage = () => {
  const navigate = useNavigate();

  const { user } = useStore((state) => state);

  const { data: brandData, isLoading: brandLoading } = useFetchBrands(user?.id);

  const [requestModal, setRequestModal] = useState(false);

  return (
    <>
      <div className="mt_40"></div>
      <div className="flex item_space_btn pt_10">
        <div>
          <h1>
            <strong>Your Portfolio</strong>
          </h1>
          <h5 className="mt_4">Lorem ipsum dolor ist meunda.</h5>
        </div>
        <div className="flex">
          <Button
            label="Requests"
            color="outline-primary"
            onClick={() => setRequestModal(true)}
          />
          <Button
            label="Add a Brand"
            color="bg-titary"
            onClick={() => navigate('/portfolio/brand/add')}
          />
        </div>
      </div>
      <div className="mt_32"></div>
      <div className="flex item_vcenter item_space_btn">
        <div className="w_356">
          <TextField placeholder={'Search'} />
        </div>
        <div></div>
      </div>
      <div className="mt_10"></div>
      <RenderIf isTrue={brandLoading}>
        <div className={`loading_full`}>
          <Loading />
        </div>
      </RenderIf>
      <RenderIf isTrue={brandData?.length}>
        <div className="grid grid_gap30">
          {brandData?.map((brand, index) => (
            <div key={index} className="collg4">
              <div className="card_item3 mt_30">
                <div>
                  <Link to={`/portfolio/brand/details/${brand?.id}`}>
                    <div className={`pt_15`}></div>
                    <picture>
                      <RenderIf isTrue={!brand?.image_url}>
                        <div className="placeholder">
                          <Ikon name="imgplaceholder" />
                        </div>
                      </RenderIf>
                      <RenderIf isTrue={brand?.image_url}>
                        <source srcSet={brand?.image_url} />
                        <img src={brand?.image_url} alt={brand?.brand_name} />
                      </RenderIf>
                    </picture>
                    <div className="card_item1_box">
                      <h6>
                        <span className="ff_semi">{brand?.brand_name}</span>
                      </h6>
                      <div className="mt_8"></div>
                      <hr className="hr1" />
                      <div className="mt_16"></div>
                      <div className="flex item_hend">
                        <Button
                          type={`button`}
                          className={`button button_radius button_sm`}
                          label="View Details"
                          color="outline-titary"
                          onClick={() =>
                            navigate(`/portfolio/brand/details/${brand?.id}`)
                          }
                        />
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>
      </RenderIf>

      <ModalComponent
        modal={requestModal}
        handleModal={() => setRequestModal(!requestModal)}
        modalMiddle={false}
      >
        <ViewConnectionsForBrand setRequestModal={setRequestModal} />
      </ModalComponent>
    </>
  );
};
