import { Ikon } from 'assets/icons';
import styles from './index.module.css';

export const SimpleImageUpload = ({
  name,
  image,
  label,
  onChange = () => {},
  required,
  error,
  register = () => {},
  validation = {},
  info,
  infoChild
}) => {
  return (
    <>
      <div className={styles.fieldbox}>
        <div className={styles.field}>
          <label className={styles.label}>{label}</label>
          {/* {required ? <span className={styles.required}>*</span> : null} */}
          <div>
            {info ? (
              <div className={styles.infobox}>
                <Ikon info />
                <div className={styles.infoChild}>
                  <Ikon indicatorArrow />
                  <div className={styles.infoContent}>{infoChild}</div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
        <div className={styles.upload}>
          <input
            id={name}
            className={styles.filefield}
            type="file"
            name={name}
            accept="image/*"
            {...register(name, validation)}
            onChange={onChange}
          />
          <img src={image} height={50} />
        </div>
      </div>
      <div className="fieldbox">
        <div className="field">
          {error && <div className="error">{error}</div>}
        </div>
      </div>
    </>
  );
};
