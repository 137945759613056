import React, { useState } from 'react';
import {
  AreaChart, 
  Area,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  CartesianGrid
} from 'recharts';
import { RenderIf } from 'utilities/helpers';
import styles from './index.module.css';

export const OrderAreaChart = ({ data }) => {

  return (
    <div className={styles.bx}>
      <h4>Sales</h4>
      <ResponsiveContainer height={429}>
        <AreaChart
          data={data}
          margin={{
            top: 0,
            right: 0,
            left: 0,
            bottom: 20,
          }}
        >
          <defs>
            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#A561D8" stopOpacity={0.24}/>
              <stop offset="95%" stopColor="#A561D8" stopOpacity={0}/>
            </linearGradient>
          </defs>
          <CartesianGrid stroke={`rgba(183, 184, 193, 1)`} horizontal={true} vertical={false} /> 
          <YAxis 
            tick={{ stroke: '#FFFFFF', strokeWidth: 0 }}
            tickFormatter={(value) =>
              new Intl.NumberFormat("en-US", {
                notation: "compact",
                compactDisplay: "short",
              }).format(value)
            }
          />
          <Tooltip />
          <Legend />
          <Area type="monotone" dataKey="uv" stroke="rgba(165, 97, 216, 1)" strokeWidth={4} fill="url(#colorUv)" fillOpacity={1} />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};
