import { Ikon } from 'assets/icons';
import { RenderIf } from 'utilities/helpers';
import { CheckboxField } from 'components/checkbox';
import styles from './index.module.css';

export const CardBranchList = (props) => {
  const {
    title,
    street,
    town,
    city,
    handleEdit,
    handleDelete,
    selection,
    onChange,
    value,
    name,
    id,
    register
  } = props;

  return (
    <>
      <article className={styles.box}>
        <div>
          <h5>
            {title}, {street}, {town}, {city}
          </h5>
        </div>
        <RenderIf isTrue={handleEdit || handleDelete}>
          <div>
            <button className="alink" onClick={handleEdit}>
              <Ikon pencil />
            </button>
            <button className="alink" onClick={handleDelete}>
              <Ikon del />
            </button>
          </div>
        </RenderIf>
        <RenderIf isTrue={selection}>
          <div>
            <CheckboxField
              register={register}
              value={value}
              name={name}
              id={id}
              onChange={onChange}
            />
          </div>
        </RenderIf>
      </article>
    </>
  );
};
