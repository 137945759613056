import { useEffect, useState } from 'react';
import { RenderIf } from 'utilities/helpers';
import { Ikon } from 'assets/icons';
import styles from './index.module.css';
import axios from 'axios';
import { UploadLoading } from '../loading';

export const BuilderUploadImage = ({
  label,
  name,
  mediaInfo,
  error,
  defaultImage,
  setImage,
  title,
  slideIndex = null,
  mediaRecommendations
}) => {
  const [fileWithStatus, setFileWithStatus] = useState({
    image_url: null,
    loading: false,
    preview: defaultImage,
    link: defaultImage
  });

  useEffect(() => {
    if (title && slideIndex === null) {
      setImage(fileWithStatus?.link, title);
    }
    if (title && slideIndex !== null) {
      setImage(fileWithStatus?.link, title, slideIndex);
    }
    if (!title && slideIndex === null) {
      setImage(fileWithStatus?.link);
    }
  }, [setImage, fileWithStatus, title, slideIndex]);

  const handleUpload = async (e) => {
    const selectedFile = e.target.files[0];
    const formData = new FormData();

    if (selectedFile) {
      setFileWithStatus({
        image_url: selectedFile,
        loading: true,
        preview: URL.createObjectURL(selectedFile),
        link: ''
      });

      formData.append('image_url', selectedFile);
      formData.append('type', 'image');
      formData.append('filename', 'demo');
    }

    try {
      const config = {
        headers: {
          'content-type': 'multipart/form-data'
        }
      };

      const response = (
        await axios.post(
          `${process.env.REACT_APP_API_URL}/upload`,
          formData,
          config
        )
      )?.data;

      if (response.status === `success`) {
        const uploadedLink = response?.data?.[0]?.image_url;
        setFileWithStatus((prevState) => ({
          ...prevState,
          loading: false,
          link: uploadedLink
        }));
      } else {
        setFileWithStatus((prevState) => ({
          ...prevState,
          loading: false,
          link: ''
        }));
      }
    } catch (error) {
      setFileWithStatus((prevState) => ({
        ...prevState,
        loading: false,
        link: ''
      }));
    }
  };

  return (
    <article className={styles.article}>
      <RenderIf isTrue={label}>
        <label>{label}</label>
      </RenderIf>
      <div className={styles.box}>
        <RenderIf
          isTrue={fileWithStatus?.preview === '' || fileWithStatus?.loading}
        >
          <Ikon name={`upload`} fillColor={`rgba(var(--black-rgb), 0.6)`} />
          <p className={styles.media_info}>
            {mediaInfo
              ? mediaInfo
              : `Upload only png, or jpeg, maximum size 5mb`}
          </p>
        </RenderIf>
        <RenderIf
          isTrue={
            (fileWithStatus?.preview !== '' || fileWithStatus?.link !== '') &&
            !fileWithStatus?.loading
          }
        >
          <div
            style={{
              position: 'relative'
            }}
          >
            <RenderIf
              isTrue={
                fileWithStatus?.link?.includes('png') ||
                fileWithStatus?.link?.includes('jpg') ||
                fileWithStatus?.link?.includes('jpeg') ||
                fileWithStatus?.link?.includes('jfif') ||
                fileWithStatus?.link?.includes('pjpeg') ||
                fileWithStatus?.link?.includes('pjp') ||
                fileWithStatus?.link?.includes('tif') ||
                fileWithStatus?.link?.includes('avif') ||
                fileWithStatus?.link?.includes('gif') ||
                fileWithStatus?.link?.includes('webp')
              }
            >
              <img
                src={fileWithStatus?.link}
                alt="Preview"
                style={{ opacity: fileWithStatus?.loading ? '0.5' : '' }}
                height={'auto'}
                width={100}
              />
            </RenderIf>
            <RenderIf
              isTrue={
                !fileWithStatus?.link?.includes('png') &&
                !fileWithStatus?.link?.includes('jpg') &&
                !fileWithStatus?.link?.includes('jpeg') &&
                !fileWithStatus?.link?.includes('jfif') &&
                !fileWithStatus?.link?.includes('pjpeg') &&
                !fileWithStatus?.link?.includes('pjp') &&
                !fileWithStatus?.link?.includes('tif') &&
                !fileWithStatus?.link?.includes('avif') &&
                !fileWithStatus?.link?.includes('gif') &&
                !fileWithStatus?.link?.includes('webp')
              }
            >
              <video
                src={fileWithStatus?.link}
                controls={false}
                width={100}
                height={'auto'}
              />
            </RenderIf>
            <button
              className={styles.removeImage}
              onClick={() => {
                setFileWithStatus({
                  image_url: null,
                  loading: false,
                  preview: '',
                  link: ''
                });
                if (title && !slideIndex) {
                  setImage('', title);
                }
                if (title && slideIndex) {
                  setImage('', title, slideIndex);
                }
                if (!title && !slideIndex) {
                  setImage('');
                }
              }}
            >
              <Ikon
                name={`close-x`}
                fillColor={`rgba(var(--black-rgb), 0.6)`}
              />
            </button>
          </div>
        </RenderIf>
        <RenderIf isTrue={fileWithStatus?.loading}>
          <div
            style={{
              position: 'absolute',
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
              display: `flex`,
              flexDirection: `column`,
              alignItems: `center`,
              justifyContent: `center`,
              background: `rgba(var(--black-rgb), 0.76)`,
              borderRadius: 16
            }}
          >
            <UploadLoading />
          </div>
        </RenderIf>
        <RenderIf isTrue={fileWithStatus?.link === ''}>
          <input
            type={'file'}
            //accept="image/*"
            accept={[
              'image/png',
              'image/pjpeg',
              'image/jfif',
              'image/pjp',
              'image/jpeg',
              'image/jpg',
              'image/webp',
              'image/gif',
              'image/svg',
              'image/tif',
              'image/avif'
            ]}
            name={name}
            id={name}
            disabled={fileWithStatus?.loading}
            onChange={handleUpload}
          />
        </RenderIf>
      </div>
      <RenderIf isTrue={mediaRecommendations}>
        <div className={styles.recommended}>{mediaRecommendations}</div>
      </RenderIf>
      <RenderIf isTrue={error}>
        <div className={styles.error}>{error}</div>
      </RenderIf>
    </article>
  );
};
