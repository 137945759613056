import React, { useState } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import styles from './index.module.css';
export default () => {
  const [value, onChange] = useState(new Date());
  return (
    <>
      <div className="mt_30"></div>
      <div className="shadow_box">
        <div className={`grid grid_gap30`}>
          <div className={`collg4 ${styles.border_right}`}>
            <div className="mt_20"></div>
            <h4 className={styles.h4_2_1}>
              <span className="ff_semi">Predicted Sales</span>
            </h4>
          </div>
          <div className={`collg8`}>
            <div className="mt_20"></div>
            <h4 className={styles.h4_2_2}>
              <span className="ff_semi">Events</span>
            </h4>
          </div>
        </div>
        <hr className="border_color" />
        <div className="grid grid_gap30">
          <div className={`collg4 `}>
            <div className="mt_10"></div>
            <Calendar
              className={styles.calendar}
              onChange={onChange}
              value={value}
            />
          </div>

          <div className="collg8">
            <div className={styles.content_2_2}>
              <div className="grid grid_gap20">
                <div className="collg6">
                  <div className="mt_20"></div>
                  <div className="shadow_box p10">
                    <p color="primary">
                      <span className="ff_semi">
                        Pakistan vs India Cricket Match
                      </span>
                    </p>
                    <p className="mt_10 f10">
                      <span className="ff_semi t_up" color="tableTh">
                        Number of people at the event
                      </span>
                    </p>
                    <p className="mt_10 f12">
                      <span className="ff_semi">68324638</span>
                    </p>
                  </div>
                </div>
                <div className="collg6">
                  <div className="mt_20"></div>
                  <div className="shadow_box p10">
                    <p color="primary">
                      <span className="ff_semi">
                        Pakistan vs India Cricket Match
                      </span>
                    </p>
                    <p className="mt_10 f10">
                      <span className="ff_semi t_up" color="tableTh">
                        Number of people at the event
                      </span>
                    </p>
                    <p className="mt_10 f12">
                      <span className="ff_semi">68324638</span>
                    </p>
                  </div>
                </div>
                <div className="collg6">
                  <div className="mt_20"></div>
                  <div className="shadow_box p10">
                    <p color="primary">
                      <span className="ff_semi">
                        Pakistan vs India Cricket Match
                      </span>
                    </p>
                    <p className="mt_10 f10">
                      <span className="ff_semi t_up" color="tableTh">
                        Number of people at the event
                      </span>
                    </p>
                    <p className="mt_10 f12">
                      <span className="ff_semi">68324638</span>
                    </p>
                  </div>
                </div>
                <div className="collg6">
                  <div className="mt_20"></div>
                  <div className="shadow_box p10">
                    <p color="primary">
                      <span className="ff_semi">
                        Pakistan vs India Cricket Match
                      </span>
                    </p>
                    <p className="mt_10 f10">
                      <span className="ff_semi t_up" color="tableTh">
                        Number of people at the event
                      </span>
                    </p>
                    <p className="mt_10 f12">
                      <span className="ff_semi">68324638</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr className="border_color" />
        <div className={styles.content_2_1}>
          <div className="mt_20"></div>
          <p>Chart library comes here</p>
        </div>
      </div>
    </>
  );
};
