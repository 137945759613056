import { useMutation } from 'react-query';
import { client } from 'utilities/db';

export const useDeleteBranch = () => {
  const { mutate, isLoading, isSuccess, isError, error, data } = useMutation(
    (data) => {
      return client.post(`/branch/delete/${data?.id}`);
    },
  );

  return {
    mutate,
    isLoading,
    isSuccess,
    isError,
    error,
    data
  };
};
