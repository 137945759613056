import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { client } from 'utilities/db';
import { useMutation } from 'react-query';
import { FieldInput } from 'components/input';
import { Toasts } from 'components/toasts';
import { Button } from 'components/button';

export const ResetPage = ({ email }) => {
  const navigate = useNavigate();
  const { mutate, isLoading, isSuccess, isError, error, data } = useMutation(
    (userData) => {
      return client.post(`/user/passwordReset`, userData);
    }
  );
  const {
    handleSubmit,
    register,
    formState: { errors }
  } = useForm();

  const onSubmitLogin = (val) => {
    mutate({
      email,
      token: val?.token,
      password: val?.password
    });
  };

  useEffect(() => {
    const user = window.localStorage.getItem('user');
    if (user) {
      navigate('/');
    }
    return;
  }, []);

  useEffect(() => {
    if (!isError) return;
    Toasts.error(error?.response?.data?.message);
  }, [isError]);

  useEffect(() => {
    if (!isSuccess) return;
    Toasts.success('Password changed successfully');
    navigate('/login');
  }, [isSuccess]);

  return (
    <article className="full">
      <form onSubmit={handleSubmit(onSubmitLogin)}>
        <div className="auth_box">
          <div className="mt_40"></div>
          <div className="pt_40"></div>
          <div className="mt_40"></div>
          {/* <div className="otp_emoji pt_30">
            <picture>
              <source srcSet={TEMPImgs?.ImgResetEmoji} />
              <img src={TEMPImgs?.ImgResetEmoji} alt="OTP Emoji" width={36} />
            </picture>
          </div> */}
          <h2 className="text_center">
            <span className="ff_semi">Check your email</span>
          </h2>
          <h6 className="mt_12 text_center">
            If an account exists for {email}.<br />a password reset OTP has been
            sent
          </h6>
          <div className="mt_30"></div>
          <div className="auth_form">
            <FieldInput
              name="token"
              label={'OTP'}
              type="text"
              error={
                errors?.token?.type === 'required' ? 'token is required' : ''
              }
              register={register}
              validation={{
                required: true
              }}
              required
            />
            <FieldInput
              name="password"
              label={'Password'}
              type="password"
              error={
                errors?.password?.type === 'required'
                  ? 'Password is required'
                  : errors?.password?.type === 'minLength'
                  ? 'Password must be at least 8 characters'
                  : errors?.password?.type === 'maxLength'
                  ? 'Password must be at most 20 characters'
                  : ''
              }
              register={register}
              validation={{
                required: true,
                minLength: 8,
                maxLength: 20
              }}
              required
            />
            <div className="mt_30"></div>
            <div className={`field`}>
              <Button
                isLoading={isLoading}
                disabled={isLoading}
                type="submit"
                label={'Save'}
                color="bg-primary"
                full
              />
            </div>
          </div>
        </div>
      </form>
    </article>
  );
};
