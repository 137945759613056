import { client } from 'utilities/db';
import { useQuery } from 'react-query';

export const useFetchTeamHook = (keyName = 'employ', brandId) => {
  const { status, data, error, isFetching, isLoading, refetch } = useQuery(
    [keyName, brandId],
    () => getTeamByBrand(brandId)
  );

  return {
    teamStatus: status,
    teamData: data,
    teamError: error,
    teamFetching: isFetching,
    teamLoading: isLoading,
    teamRefetch: refetch
  };
};
const getTeamByBrand = async (brandId) => {
  let result = await client.get(`/team/getTeamByBrand/${brandId}`);
  return result?.data?.users;
};
