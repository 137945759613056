import { CustomTable } from 'components/datatable';
import { useFetchOrders } from 'hooks/brands/useFetchOrders';
import { Table, IconButton } from 'rsuite';
import { RenderIf } from 'utilities/helpers';
import CollaspedOutlineIcon from '@rsuite/icons/CollaspedOutline';
import ExpandOutlineIcon from '@rsuite/icons/ExpandOutline';
import { useState } from 'react';
import moment from 'moment';
import { Loading } from 'components/loading';

const { Column, HeaderCell, Cell } = Table;

const rowKey = 'id';
const ExpandCell = ({
  rowData,
  dataKey,
  expandedRowKeys,
  onChange,
  ...props
}) => (
  <Cell {...props} style={{ padding: 5 }}>
    <IconButton
      appearance="subtle"
      onClick={() => {
        onChange(rowData);
      }}
      icon={
        expandedRowKeys.some((key) => key === rowData[rowKey]) ? (
          <CollaspedOutlineIcon />
        ) : (
          <ExpandOutlineIcon />
        )
      }
    />
  </Cell>
);

const renderRowExpanded = (rowData) => {
  //console.log('🚀 ~ file: index.jsx:37 ~ renderRowExpanded ~ rowData', rowData);
  return (
    <div>
      {rowData?.items?.map((product) => (
        <>
          <div
            style={{
              width: 60,
              height: 60,
              float: 'left',
              marginRight: 10,
              background: '#eee'
            }}
          >
            <img
              src={
                product?.itemId?.image ||
                'https://i.pinimg.com/564x/dd/9d/c9/dd9dc9d83423bc037b511d73b29e6b80.jpg'
              }
              alt={``}
              style={{ width: 60 }}
            />
          </div>
          <p>Email: {product?.itemId?.description}</p>
          <p>Phone: {product?.unitPrice}</p>
        </>
      ))}
    </div>
  );
};

export const BrandOrders = ({ brandId }) => {
  const { orderData, orderLoading } = useFetchOrders(
    `orders/${brandId}`,
    brandId
  );

  const [expandedRowKeys, setExpandedRowKeys] = useState([]);

  const handleExpanded = (rowData, dataKey) => {
    let open = false;
    const nextExpandedRowKeys = [];

    expandedRowKeys.forEach((key) => {
      if (key === rowData[rowKey]) {
        open = true;
      } else {
        nextExpandedRowKeys.push(key);
      }
    });

    if (!open) {
      nextExpandedRowKeys.push(rowData[rowKey]);
    }

    setExpandedRowKeys(nextExpandedRowKeys);
  };

  return (
    <div>
      <RenderIf isTrue={orderLoading}>
        <div className={`loading_full`}>
          <Loading />
        </div>
      </RenderIf>
      <RenderIf isTrue={orderData}>
        <div className="mt_40"></div>
        <CustomTable
          rows={orderData?.map((order, index) => {
            return {
              id: index,
              customerName: order?.customer?.fullName,
              type: order?.type,
              payment: order?.payment,
              total: order?.total,
              orderedOn: moment(order?.orderedOn).format('DD MMM YYYY, h:mm'),
              items: order?.items
            };
          })}
          loading={orderLoading}
          pageLimit={10}
          pagination
          expandedRowKeys={expandedRowKeys}
          renderRowExpanded={renderRowExpanded}
          rowKey={rowKey}
        >
          <Column width={46} align="center" fixed>
            <HeaderCell>#</HeaderCell>
            <ExpandCell
              dataKey="id"
              expandedRowKeys={expandedRowKeys}
              onChange={handleExpanded}
            />
          </Column>
          <Column flexGrow={1}>
            <HeaderCell>Customer Name</HeaderCell>
            <Cell dataKey="customerName" />
          </Column>
          <Column width={140} sortable>
            <HeaderCell>Order Type</HeaderCell>
            <Cell dataKey="type" />
          </Column>
          <Column width={160} sortable>
            <HeaderCell>Payment Status</HeaderCell>
            <Cell dataKey="payment" />
          </Column>
          <Column width={140} sortable>
            <HeaderCell>Total</HeaderCell>
            <Cell dataKey="total" />
          </Column>

          <Column width={150} sortable fixed="right">
            <HeaderCell>Purchase Date</HeaderCell>
            <Cell dataKey="orderedOn" />
          </Column>
        </CustomTable>
      </RenderIf>
    </div>
  );
};
