import { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { RenderIf } from 'utilities/helpers';
import { useFetchOneBrandHook } from 'hooks/brands/useFetchOneBrandHook';
import { useFetchBranches } from 'hooks/resourceHooks';
//import { useFetchManagerHook } from 'hooks/managers/useFetchManagerHooks';
import { ThemeTabs } from 'components/tabs';
import { BranchesListing } from './branchlisting';
import { ManagerListingPage } from './managerlisting';
import { CompanyInfoPage } from './company';
import { FileADba } from './dba';
import { Button } from 'components/button';
import { Ikon } from 'assets/icons';
import styles from './index.module.css';
import { ModalComponent } from 'components/modal';
import { BrandOrders } from './payments';
import { Loading } from 'components/loading';

export const PortfolioBrandDetailsPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const { oneBrandData, oneBrandLoading } = useFetchOneBrandHook(
    `brand/${id}`,
    id
  );
  const { data: branchData, isLoading: branchLoading } = useFetchBranches(id);
  //const { managerData } = useFetchManagerHook(`managers`, id);

  const [makeRequestModal, setMakeRequestModal] = useState(false);

  return (
    <>
      <div className="mt_40"></div>
      <h2>
        <span className="ff_semi">
          {oneBrandData?.brand_type === 'CLOUD' ? 'Cloud Kitchen' : 'Brand'}{' '}
          Details
        </span>
      </h2>
      <div className="mt_20"></div>
      <RenderIf isTrue={oneBrandLoading || branchLoading}>
        <div className={`loading_full`}>
          <Loading />
        </div>
      </RenderIf>
      <RenderIf isTrue={!oneBrandLoading}>
        <div className="shadow_box1">
          <div className={styles.header_bd1}>
            <div className={styles.header_bd_left}>
              <picture className={styles.picture1}>
                <RenderIf isTrue={!oneBrandData?.image_url}>
                  <div className="placeholder">
                    <Ikon name="imgplaceholder" />
                  </div>
                </RenderIf>
                <RenderIf isTrue={oneBrandData?.image_url}>
                  <source srcSet={oneBrandData?.image_url} />
                  <img src={oneBrandData?.image_url} alt="brand logo" />
                </RenderIf>
              </picture>
              <div>
                <h1>
                  <b>{oneBrandData?.brand_name}</b>
                </h1>
                <div className={styles.flex_row1}>
                  <div className="spans">
                    <span>
                      <Ikon name="email" />
                    </span>
                    <span>{oneBrandData?.brand_email}</span>
                  </div>
                  <div className="spans">
                    <span>
                      <Ikon name="phone" />
                    </span>
                    <span>{oneBrandData?.phone_number}</span>
                  </div>
                  <div className="spans">
                    <span>
                      <Ikon name="tag" />
                    </span>
                    <span>{oneBrandData?.ein}</span>
                  </div>
                </div>
              </div>
            </div>
            <div style={{ display: 'flex' }}>
              <div
                style={{ margin: '5px', display: 'flex', alignItems: 'center' }}
              >
                <Button
                  label="Update a Brand"
                  color="bg-titary"
                  onClick={() =>
                    navigate(`/portfolio/brand/update`, {
                      state: { brandToUpdate: oneBrandData }
                    })
                  }
                />
                <div style={{ marginLeft: '10px' }}>
                  <Button
                    label="Add a Branch"
                    color="bg-titary"
                    onClick={() => navigate(`/portfolio/branch/add/${id}`)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="shadow_box1">
          <div className={styles.flex_row2}>
            <div className={styles.row2_col}>
              <div className={styles.icon1}>
                <Ikon name="google" />
              </div>
              <div>
                <h4>
                  <span className="ff_semi">4.5</span>
                </h4>
                <div>
                  <Rate defaultValue={2.5} allowHalf size="xs" readOnly />
                </div>
              </div>
            </div>
            <div className={styles.row2_col}>
              <div className={styles.icon1}>
                <Ikon name="upcoming" />
              </div>
              <div>
                <h4>
                  <span className="ff_semi">$1023232</span>
                </h4>
                <div className="f11" color="liteLabel">
                  Upcoming Payment
                </div>
              </div>
            </div>
            <div className={styles.row2_col}>
              <div className={styles.icon1}>
                <Ikon name="branches" />
              </div>
              <div>
                <h4>
                  <span className="ff_semi">{branchData?.length || 0}</span>
                </h4>
                <div className="f11" color="liteLabel">
                  Branches
                </div>
              </div>
            </div>
            <div className={styles.row2_col}>
              <div className={styles.icon1}>
                <Ikon name="employee" />
              </div>
              <div>
                <h4>
                  <span className="ff_semi">112</span>
                </h4>
                <div className="f11" color="liteLabel">
                  Employees
                </div>
              </div>
            </div>
            <div className={styles.row2_col}>
              <div className={styles.icon1}>
                <Ikon name="manager" />
              </div>
              <div>
                <h4>
                  <span className="ff_semi">{managerData ? 1 : 0}</span>
                </h4>
                <div className="f11" color="liteLabel">
                  Managers
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <div className="mt_40"></div>
        <ThemeTabs
          tabs={[
            { label: 'Branches' },
            { label: 'Payments' },
            { label: 'Company' },
            { label: 'DBA' },
            { label: 'Managers' }
          ]}
          panels={[
            {
              content: (
                <BranchesListing brandId={id} branchesData={branchData} />
              )
            },
            { content: <BrandOrders brandId={id} /> },
            { content: <CompanyInfoPage brandId={id} /> },
            { content: <FileADba brandId={id} /> },
            { content: <ManagerListingPage brandId={id} /> }
          ]}
          isSearch={<div></div>}
        />
      </RenderIf>

      <ModalComponent
        modal={makeRequestModal}
        handleModal={() => setMakeRequestModal(!makeRequestModal)}
      >
        ankdajhdasj
      </ModalComponent>
    </>
  );
};
