import React, { useState } from 'react';
import { AsideContentItem } from '..';
import styles from './index.module.css';

export const BuilderTabs = ({ tabs, panels }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <>
      <AsideContentItem>
        <div className={styles.tabs}>
          {tabs?.length &&
            tabs?.map((tab, index) => (
              <button
                key={index}
                className={styles.tab}
                style={{
                  background:
                    index === activeIndex
                      ? `rgba(var(--black-rgb), 0.76)`
                      : `rgba(var(--black-rgb), 0.16)`,
                  color:
                    index === activeIndex
                      ? `var(--white)`
                      : `rgba(var(--black-rgb), 0.76)`
                }}
                onClick={() => setActiveIndex(index)}
              >
                {tab?.label}
              </button>
            ))}
        </div>
      </AsideContentItem>
      {panels?.length &&
        panels
          ?.filter((pan, index) => pan && index === activeIndex)
          ?.map((panel, idx) => (
            <React.Fragment key={idx}>{panel.content}</React.Fragment>
          ))}
    </>
  );
};
