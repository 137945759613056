import { useQuery } from 'react-query';
import { client } from 'utilities/db';

export const useFetchKitchenPlanningDataByBrand = (brandId) => {
  const {
    isLoading,
    data = [],
    isSuccess,
    isError,
    refetch
  } = useQuery(
    ['FetchKitchenPlanningDataByBrand'],
    async () => {
      const data = await client.get(
        `/kitchenPlanning/findAllByBrand/${brandId}`
      );
      return data.data;
    },
    {
      enable: !!brandId
    }
  );

  return {
    data,
    isLoading,
    isSuccess,
    isError,
    refetch
  };
};

export const useFetchKitchenPlanningDataByBranch = (branchId) => {
  const {
    isLoading,
    data = [],
    isSuccess,
    isError,
    refetch
  } = useQuery(
    ['FetchKitchenPlanningDataByBranch'],
    async () => {
      const data = await client.get(
        `/kitchenPlanning/findAllByBranch/${branchId}`
      );
      return data.data;
    },
    {
      enable: !!branchId
    }
  );

  return {
    data,
    isLoading,
    isSuccess,
    isError,
    refetch
  };
};
