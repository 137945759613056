import { BuilderJSON } from 'store';
import { AsideContentItem } from '../..';
import { BuilderLayoutButton } from 'pages/storebuilder/components/forms/buttons/layout';
import { BuilderTextareaField } from 'pages/storebuilder/components/forms/textarea';
import { BuilderSwitchField } from 'pages/storebuilder/components/forms/switch';
import { Ikon } from 'assets/icons';
import { BuilderUploadImage } from 'pages/storebuilder/components/forms/upload';

export const FigureContent = ({ title }) => {
  const {
    pages,
    setFigureSectionLayout,
    setFigureSectionTitle,
    setFigureSectionDescription,
    setFigureSectionMedia,
    setFigureSectionContentOverlay,
    setFigureSectionContentInContainer,
    setRemoveFigureSection,
    removeRenderOrderItem
  } = BuilderJSON((state) => state);
  const { content } = pages?.landing?.figureSections?.filter(
    (sec) => sec?.title === title
  )[0];

  return (
    <>
      <AsideContentItem label={`Layout`}>
        <div className={`builder-layout-items`}>
          <div>
            <BuilderLayoutButton
              hasTextOnly={true}
              isActive={content?.layout === 1 ? true : false}
              handleOnClick={() => {
                setFigureSectionLayout(1, title);
              }}
            >
              1
            </BuilderLayoutButton>
          </div>
          <div>
            <BuilderLayoutButton
              hasTextOnly={true}
              isActive={content?.layout === 2 ? true : false}
              handleOnClick={() => {
                setFigureSectionLayout(2, title);
              }}
            >
              2
            </BuilderLayoutButton>
          </div>
        </div>
      </AsideContentItem>
      <AsideContentItem label={`Container`}>
        <div className={`builder_items`}>
          <BuilderTextareaField
            label={`Title`}
            defaultValue={content?.title}
            onChange={(e) => {
              setFigureSectionTitle(e?.target?.value, title);
            }}
          />
          <BuilderTextareaField
            label={`Description`}
            defaultValue={content?.description}
            onChange={(e) => {
              setFigureSectionDescription(e?.target?.value, title);
            }}
          />
          <BuilderUploadImage
            title={title}
            label={`Image`}
            mediaRecommendations={`Recommended dimensions are 1600 x 750, types are jpg, jpeg, png, gif or svg`}
            defaultImage={content?.media}
            setImage={setFigureSectionMedia}
          />
          <BuilderSwitchField
            id={`FIGURE_CONTENT_IN_CONTAINER`}
            label={`Content container`}
            defaultChecked={content?.contentInContainer}
            onChange={() => {
              setFigureSectionContentInContainer(title);
            }}
          />
          <BuilderSwitchField
            id={`FIGURE_CONTENT_OVERLAY`}
            label={`Content overlay`}
            defaultChecked={content?.contentOverlay}
            onChange={() => {
              setFigureSectionContentOverlay(title);
            }}
          />
        </div>
      </AsideContentItem>
      <AsideContentItem label={`Remove section`}>
        <div className={`builder_items`}>
          <div>
            <button
              type={`button`}
              className={`builder-button-link`}
              color={`gray`}
              onClick={() => {
                setRemoveFigureSection(title);
                removeRenderOrderItem(title);
              }}
            >
              <Ikon name={`close-x`} fillColor={`var(--grey)`} />
              <span>remove this section</span>
            </button>
          </div>
        </div>
      </AsideContentItem>
    </>
  );
};
