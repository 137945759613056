import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { client } from 'utilities/db';
import { RenderIf } from 'utilities/helpers';
import { CardListOne } from 'components/cards/list';
import { TextField } from 'components/input';
import { Button } from 'components/button';
import { Ikon } from 'assets/icons';
import { getLocationData } from 'utilities/helpers/auth';
import { useNavigate } from 'react-router-dom';
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import { Toasts } from 'components/toasts';
import { useStore } from 'store';
import Autocomplete from 'react-google-autocomplete';
import { usePlacesWidget } from 'react-google-autocomplete';
import { CustomSelectDropdown } from 'components/select/multi';
import { US_STATES } from 'utilities/constants';

export const AddBranchForm = ({
  onChangeStep,
  onboarding = false,
  setAddMenu = false,
  onGoBack = () => {},
  locationId = 1,
  step = 1,
  branchEditableValues,
  clearEditableValues,
  singleBranch
}) => {
  const { setBranch } = useStore((state) => state);

  const [selectedStreetAdress, setSelectedStreetAdress] = useState('');
  const [streetAddress, setStreetAddress] = useState('');
  const locationData = getLocationData();
  console.log(
    '🚀 ~ file: index.jsx ~ line 32 ~ locationDatsdasdasa',
    locationData
  );
  const navigate = useNavigate();
  const [predictedPlace, setPredictedPlace] = useState(null);
  const [strAddressFocus, setStrAddressFocus] = useState(false);
  const {
    address = {},
    city = '',
    state = '',
    tax = '',
    id: editableId = '',
    brand_id: restaurant_id = ''
  } = branchEditableValues;
  const { mutate, isLoading, isSuccess, isError, error, data } = useMutation(
    (userData) => {
      if (locationData?.brand_type === 'STANDARD') {
        return client.post(
          editableId ? `/branch/update/${editableId}` : `/branch/add`,
          userData
        );
      } else {
        return client.post(
          editableId
            ? `/cloudKitchen/update/${editableId}`
            : `/cloudKitchen/add`,
          userData
        );
      }
    }
  );
  const {
    mutate: mutateManager,
    isLoading: managerIsloading,
    isSuccess: managerIssuccess,
    isError: managerIserror,
    error: managerError
  } = useMutation((userData) => {
    return client.post(`/user/signup`, userData);
  });
  const [newBranchAdded, setNewBranchAdded] = useState('');
  const [iAmManager, setIAmManager] = useState(false);
  const {
    handleSubmit,
    register,
    formState: { errors },
    control
  } = useForm({
    defaultValues: {
      form: [{ email: '', role: 'BRANCH_MANAGER' }],
      tax,
      city,
      state,
      name: address?.line1,
      address: address?.line2
    }
  });

  console.log('branchEditableValues', singleBranch);

  const onSubmit = (val) => {
    if (singleBranch) {
      mutate({
        brand_id: locationId,
        address: {
          line1: val?.street_address_one,
          line2: val?.street_address_two || locationData?.brand_name
        },
        postal_code: val.postal_code,
        city: val.city,
        state: val.state.value,
        tax: parseInt(val.tax),
        is_default: false,
        geo_location: {
          key: 'some key',
          value: 'some value'
        },
        singlular: true,
        kitchen_name: locationData?.brand_name
      });
      return;
    }
    mutate({
      branch_name: val?.name,
      brand_id: locationId,
      address: {
        line1: val?.street_address_one,
        line2: val?.street_address_two
      },
      postal_code: val.postal_code,
      city: val.city,
      state: val.state.value,
      tax: parseInt(val?.tax),
      is_default: false,
      geo_location: {
        key: 'some key',
        value: 'some value'
      },
      singlular: false
    });
  };

  useEffect(() => {
    if (isError || managerIserror) {
      Toasts.error(error?.response?.data?.message || '');
    }
  }, [isError, managerIserror]);

  useEffect(() => {
    if (isSuccess) {
      setNewBranchAdded(data?.data?.data?.restaurant_id);
      console.log(
        '🚀 ~ file: index.jsx ~ line 129 ~ useEffect ~ data',
        data?.data
      );
      clearEditableValues();
      if (singleBranch) {
        setBranch(data?.data?.user_brand_branch);
        window.localStorage.setItem('onBoardOnFinish', true);
        navigate('/dashboard');
        return;
      }
      onGoBack();
    }
  }, [isSuccess]);

  const {
    placesService,
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading
  } = usePlacesService({
    apiKey: 'AIzaSyBoZtTiuyPSY1sCdmxpuaSRhwEy4KenKcM'
  });

  useEffect(() => {
    // fetch place details for the first element in placePredictions array
    if (placePredictions.length)
      placesService.getDetails(
        { placeId: placePredictions[0].place_id },
        (place, status) => {
          if (status === 'OK') {
            setPredictedPlace(place?.formatted_address);
          }
        }
      );
  }, [placePredictions]);

  const { ref, autocompleteRef } = usePlacesWidget({
    apiKey: 'AIzaSyBoZtTiuyPSY1sCdmxpuaSRhwEy4KenKcM',
    onPlaceSelected: (place) => {
      console.log(place);
    }
  });

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="onshadowbox">
          {/* <RenderIf isTrue={onboarding}>
            <div className="onheader1">
              <div className="flex item_vcenter">
                {!singleBranch && (
                  <Button
                    onClick={() => {
                      onGoBack();
                      clearEditableValues();
                    }}
                    type="button"
                    label={'Cancel'}
                    color="outline-lite"
                  />
                )}
              </div>
            </div>
          </RenderIf> */}
          <RenderIf isTrue={!singleBranch}>
            <TextField
              name="name"
              label={'Branch Name'}
              placeholder={'Branch Name'}
              type="text"
              error={
                errors?.name?.type === 'required'
                  ? 'Branch Name is required'
                  : ''
              }
              validation={{
                required: true
              }}
              register={register}
              required
            />
            <div className="mt_15"></div>

            <div className="mt_16"></div>
          </RenderIf>
          <div className="field_box">
            {/* <input ref={ref} type="text" placeholder="Enter a location" /> */}

            <TextField
              name="street_address_one"
              label={'Street Address 1'}
              placeholder={'Street Address 1'}
              type="text"
              register={register}
              error={
                errors?.street_address_one?.type === 'required'
                  ? 'Street Address One is required'
                  : ''
              }
              validation={{
                required: true
              }}
            />
          </div>
          <div className="mt_16"></div>
          <TextField
            name="street_address_two"
            label={'Street Address 2 (Optional)'}
            placeholder={'Street Address 2'}
            type="text"
            register={register}
            error={
              errors?.street_address_two?.type === 'required'
                ? 'Street Address Two is required'
                : ''
            }
            validation={
              {
                // required: true
              }
            }
          />
          <div className="mt_16"></div>
          <TextField
            name="tax"
            label="Tax"
            type="text"
            placeholder={'Tax'}
            register={register}
            error={errors?.tax?.type === 'required' ? 'Tax is required' : ''}
            validation={{
              required: true
            }}
          />
          <div className="grid grid_gap16">
            <div className="colxs6">
              <div className="mt_16"></div>
              <TextField
                name="postal_code"
                label={'Zipcode'}
                placeholder={'Zipcode'}
                type="text"
                error={
                  errors?.postal_code?.type === 'required'
                    ? 'Postal Code is required'
                    : ''
                }
                validation={{
                  required: true
                }}
                register={register}
              />
            </div>
            <div className="colxs6">
              <div className="mt_16"></div>
              <TextField
                name="city"
                placeholder={'City'}
                label={'City'}
                type="text"
                error={
                  errors?.city?.type === 'required' ? 'City is required' : ''
                }
                validation={{
                  required: true
                }}
                register={register}
                required
              />
            </div>
            <div className="colxs6">
              <div className="mt_16"></div>
              <Controller
                name="state"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <CustomSelectDropdown
                    hookForm={true}
                    field={field}
                    label="State"
                    placeholder="Choose state"
                    options={US_STATES}
                    error={
                      errors?.country?.type === 'required'
                        ? 'State is required'
                        : ''
                    }
                  />
                )}
              />
            </div>
          </div>
          <RenderIf isTrue={(newBranchAdded || restaurant_id) && onboarding}>
            <div>
              <div className="onsection1 mt_15">
                <h6>Custom Menu</h6>
                <div>
                  <button
                    type="button"
                    className="ikonlink"
                    color="primary"
                    onClick={() => setAddMenu(true)}
                  >
                    <Ikon plus />
                    &nbsp;&nbsp;Add new menu
                  </button>
                </div>
              </div>
              <RenderIf isTrue={step === 3}>
                <div className="mt_15">
                  <CardListOne title="Menu 1">
                    10 Categories, 40 Menu Items
                  </CardListOne>
                </div>
              </RenderIf>
            </div>
          </RenderIf>
        </div>
        {/* <RenderIf isTrue={onboarding}> */}
        <div className="mt_24"></div>
        <div className="onfooter">
          <div></div>
          <div className="flex item_vcenter">
            <Button
              isLoading={isLoading || managerIsloading}
              type="submit"
              label={'Save'}
              color="bg-primary"
            />
          </div>
        </div>
        {/* </RenderIf> */}
      </form>
    </>
  );
};

export const AddBranchPage = () => {
  const [branchEditableValues, setBranchEditableValues] = useState({});

  const clearEditableValues = () => {
    setBranchEditableValues({});
  };

  return (
    <>
      <div className="box1">
        <h2>
          <strong>Add Branch</strong>
        </h2>
        <h5>Branch Information</h5>
        <div className="mt_15"></div>
        <AddBranchForm
          branchEditableValues={branchEditableValues}
          clearEditableValues={clearEditableValues}
        />
      </div>
    </>
  );
};
