import React, { useEffect, useState, useRef } from 'react';
import { useMutation } from 'react-query';
import { RenderIf } from 'utilities/helpers';
import {
  useDeleteMixtureIngredient,
  useDeleteRecipeIngredient,
  useFetchRecipeMixtures,
  useFetchRecipeProductGroups
} from 'hooks/recipes/useRecipesHook';
import { client } from 'utilities/db';
import { allUnits, getBranchId, getLocationId } from 'utilities/helpers/auth';
import { CustomAccordion } from '../update';
import { ModalComponent } from '../../../components/modal';
import { ThemeTabs } from 'components/tabs';
import { SelectDropdown } from 'components/selectdropdown';
import { TextField, TextFieldReffed } from 'components/input';
import { TextArea } from 'components/textarea';
import { CheckboxField } from 'components/checkbox';
import { Button } from 'components/button';
import { Toasts } from 'components/toasts';
import { Ikon } from 'assets/icons';
import styles from './index.module.css';

export const AddRecipePage = ({
  modifier,
  onChangeStep,
  isMixture,
  selectedData
}) => {
  console.log('🚀 ~ modifier:', modifier);
  const branchId = getBranchId();
  const locationId = getLocationId();
  const [search, setSearch] = useState('');
  const [stepsModal, setStepsModal] = useState(false);
  const [bulkModal, setBulkModal] = useState(false);
  const [recipeSteps, setRecipeSteps] = useState([]);
  console.log('🚀 ~ onPressEnterKeyAddNewStep ~ content:', recipeSteps);
  const [unit, setUnit] = useState('');
  const [totalQuantity, setTotalQuantity] = useState('');
  const [servingUnit, setServingUnit] = useState('');
  const [servingQuantity, setServingQuantity] = useState('');
  const [isSubmit, setIsSubmit] = useState(false);
  const [isBulk, setIsBulk] = useState(false);
  const { data } = useFetchRecipeProductGroups(branchId);
  const { data: mixturesData } = useFetchRecipeMixtures(branchId);
  const [selectedIngredients, setselectedIngredients] = useState([]);
  const [cookingTime, setCookingTime] = useState('00 : 00');
  const [mixtureName, setMixtureName] = useState('');
  const [isEdit, setIsEdit] = useState(false);
  const [unitDropdownIndex, setUnitDropdownIndex] = useState(null);
  const { mutate, isLoading, isSuccess, isError, error } = useMutation(
    (userData) => {
      return client.post(isMixture ? '/mixture/add' : `/recipe/add`, userData);
    }
  );
  const addStepInput = useRef();

  const { mutate: deleteIngredient, error: deleteIngredientError } =
    useDeleteRecipeIngredient();

  const {
    mutate: deleteMixtureIngredient,
    error: deleteMixtureIngredientError
  } = useDeleteMixtureIngredient();

  const {
    mutate: updateRecipe,
    isLoading: updateRecipeLoading,
    isSuccess: updateIsSuccess,
    isError: updateIsError,
    error: updateError
  } = useMutation((userData) => {
    return client.post(
      isMixture
        ? `/mixture/update-mixture/${userData?.id}`
        : `/recipe/update-recipe/${userData?.id}`,
      userData
    );
  });

  useEffect(() => {
    if (Object.keys(selectedData).length > 0) {
      if (isMixture) {
        const { mixture_name = '', MixtureRecipeModel = [] } = selectedData;
        setIsEdit(true);
        setselectedIngredients(MixtureRecipeModel);
        setMixtureName(mixture_name);
        return;
      }
      const {
        recipe = {},
        recipeIngredients = [],
        allergens,
        // this will be the chart data
        productHealthFactor = [],
        productNutrientsDetails = []
      } = selectedData;
      setRecipeSteps(recipe?.steps ? JSON.parse(recipe?.steps) : []);
      setUnit(recipe?.total_quantity?.unit || '');
      setTotalQuantity(recipe?.total_quantity?.quantity || '');
      setServingUnit(recipe?.serving_quantity?.unit || '');
      setServingQuantity(recipe?.serving_quantity?.quantity || '');
      setselectedIngredients(recipeIngredients);
      setCookingTime(recipe?.cooking_time || '');
      setIsEdit(true);
      if (recipe?.total_quantity?.quantity) {
        setIsBulk(true);
      }
    }
  }, [selectedData]);

  const createNewRecipe = () => {
    console.log('createNewRecipe payload: ', {
      brand_id: locationId,
      branch_id: branchId,
      mixture_name: mixtureName,
      expiry_unit: '',
      expiry_duration: '',
      ingredients: selectedIngredients?.map((item) => ({
        unit: item?.unit,
        quantity: item?.quantity,
        product_id: item?.id,
        mixture_id: null,
        product_group_id: null,
        ingredient_type: item?.type?.toUpperCase()
      }))
    });

    setIsSubmit(true);
    if (
      isBulk &&
      (totalQuantity === '' || unit === '' || servingQuantity === '')
    )
      return;
    if (selectedIngredients.length === 0) {
      Toasts.error('Formula is required');
      return;
    }
    if (
      selectedIngredients?.find(
        (item) =>
          `${item?.quantity}`?.trim() === '' || item?.unit?.trim() === ''
      )
    ) {
      Toasts.error('Quantity and units is required');
      return;
    }
    if (isMixture) {
      if (mixtureName.trim() === '') {
        Toasts.error('Mixture name is required');
        return;
      }
      if (isEdit) {
        updateRecipe({
          mixture_name: mixtureName,
          expiry_unit: '',
          expiry_duration: '',
          id: selectedData?.id,
          ingredients: selectedIngredients?.map((item) => ({
            unit: item?.unit,
            quantity: item?.quantity,
            product_id: item?.ProductModel?.id || item?.id,
            product_group_id: null,
            ingredient_type:
              item?.ingredient_type?.toUpperCase() || item?.type?.toUpperCase(),
            id: item?.ProductModel?.id ? item?.id : null
          }))
        });
        return;
      }
      mutate({
        brand_id: locationId,
        branch_id: branchId,
        mixture_name: mixtureName,
        expiry_unit: '',
        expiry_duration: '',
        ingredients: selectedIngredients?.map((item) => ({
          unit: item?.unit,
          quantity: item?.quantity,
          product_id: item?.id,
          mixture_id: null,
          product_group_id: null,
          ingredient_type: item?.type?.toUpperCase()
        }))
      });
      return;
    }
    if (isEdit) {
      updateRecipe({
        cooking_time: cookingTime,
        ingredients: selectedIngredients?.map((item) => ({
          unit: item?.unit,
          quantity: parseInt(item?.quantity),
          product_id:
            item?.ingredient_type?.toUpperCase() === 'RAW' ||
            item?.type?.toUpperCase() === 'RAW'
              ? item?.ProductModel?.id || item?.id
              : null,
          mixture_id:
            item?.ingredient_type?.toUpperCase() === 'MIXTURE' ||
            item?.type?.toUpperCase() === 'MIXTURE'
              ? item?.MixtureModel?.id || item?.id
              : null,
          product_group_id:
            item?.ingredient_type?.toUpperCase() === 'PRODUCT_GROUP' ||
            item?.type?.toUpperCase() === 'PRODUCT_GROUP'
              ? item?.ProductGroupModel?.id || item?.id
              : null,
          ingredient_type:
            item?.ingredient_type?.toUpperCase() || item?.type?.toUpperCase(),
          id:
            item?.ProductModel?.id ||
            item?.MixtureModel?.id ||
            item?.ProductGroupModel?.id
              ? item?.id
              : null
        })),
        is_bulk: isBulk,
        total_quantity: {
          quantity: isBulk ? totalQuantity : null,
          unit: isBulk ? unit : null
        },
        serving_quantity: {
          quantity: isBulk ? servingQuantity : null,
          unit: isBulk ? unit : null
        },
        steps: recipeSteps,
        id: selectedData?.recipe?.id
      });
      return;
    }
    mutate({
      brand_id: locationId,
      branch_id: branchId,
      recipe_name: modifier?.modifier_name,
      menu_item_id: modifier?.menu_item_id,
      modifier_group_id: modifier?.modifier_group_id,
      modifier_id: modifier?.id,
      cooking_time: cookingTime,
      ingredients: selectedIngredients?.map((item) => ({
        unit: item?.unit,
        quantity: item?.quantity,
        product_id: item?.type?.toUpperCase() === 'RAW' ? item?.id : null,
        mixture_id: item?.type?.toUpperCase() === 'MIXTURE' ? item?.id : null,
        product_group_id:
          item?.type?.toUpperCase() === 'PRODUCT_GROUP' ? item?.id : null,
        ingredient_type: item?.type?.toUpperCase()
      })),
      is_bulk: isBulk,
      total_quantity: {
        quantity: isBulk ? totalQuantity : null,
        unit: isBulk ? unit : null
      },
      serving_quantity: {
        quantity: isBulk ? servingQuantity : null,
        unit: isBulk ? unit : null
      },
      steps: recipeSteps
    });
  };
  const onChangeIngredients = (val) => {
    const same = selectedIngredients?.find(
      (item) =>
        item.id === val.id ||
        item?.ProductModel?.id === val?.id ||
        item?.MixtureModel?.id === val?.id
    )?.id;
    if (same) return;
    const ing = [...selectedIngredients];
    ing.push(val);
    setselectedIngredients(ing);
  };
  const removeIngredeint = (val) => {
    const ing = [...selectedIngredients];
    setselectedIngredients(ing?.filter((item) => item?.id !== val?.id));
    if (isMixture) {
      deleteMixtureIngredient(val?.id);
      return;
    }
    deleteIngredient(val.id);
  };

  const onChangeQuantity = (quantity, id) => {
    const ing = [...selectedIngredients];
    ing[id] = { ...ing[id], quantity };
    setselectedIngredients(ing);
  };

  const onChangeUnit = (unit, id) => {
    const ing = [...selectedIngredients];
    ing[id] = { ...ing[id], unit };
    setselectedIngredients(ing);
  };

  useEffect(() => {
    if (isSuccess) {
      onChangeStep(1);
      Toasts.success('Recipe addedd successfully');
    }
  }, [isSuccess]);
  useEffect(() => {
    if (updateIsSuccess) {
      window.location.reload();
    }
  }, [updateIsSuccess]);

  useEffect(() => {
    if (isSuccess) {
      Toasts.error(error?.response?.data?.message);
    }
  }, [isError]);

  useEffect(() => {
    if (deleteIngredientError) {
      Toasts.error(deleteIngredientError?.response?.data?.message);
    }
    if (deleteMixtureIngredientError) {
      Toasts.error(deleteMixtureIngredientError?.response?.data?.message);
    }
  }, [deleteIngredientError, deleteMixtureIngredientError]);

  const onChangeSteps = (content, index) => {
    console.log('🚀 ~ onChangeSteps ~ content:', content);
    const allSteps = [...recipeSteps];
    allSteps[index] = { step: allSteps[index]?.step, content };
    setRecipeSteps(allSteps);
  };

  // let kksteps = [];

  const onPressEnterKeyAddNewStep = (content) => {
    setRecipeSteps((prevSteps) => {
      const newStep = {
        step: prevSteps.length + 1,
        content
      };
      return [...prevSteps, newStep];
    });
  };

  const removeStep = (index) => {
    const steps = [...recipeSteps];
    setRecipeSteps(steps.filter((item, i) => i !== index));
  };

  const panelRaw = () => {
    return (
      <>
        <div className={styles.accList}>
          {data
            ?.filter((item) =>
              item?.group_name?.toLowerCase()?.includes(search?.toLowerCase())
            )
            ?.map((item, i) => (
              <React.Fragment key={i}>
                <CustomAccordion
                  group={item.group_name}
                  products={item?.Products?.map((data) => ({
                    name: data?.product_name || data?.mixture_name,
                    id: data?.id || data?.MixtureModel?.id,
                    quantity: '',
                    type: 'raw',
                    ...(data?.ingredient_type === 'MIXTURE'
                      ? data?.MixtureModel
                      : data),
                    unit: ''
                  }))}
                  handleClick={onChangeIngredients}
                  productGroup={item}
                />
              </React.Fragment>
            ))}
        </div>
      </>
    );
  };

  const panelMixtures = () => {
    return (
      <>
        <div className={styles.accList}>
          {mixturesData
            ?.filter((item) => item?.mixture_name?.includes(search))
            ?.map((item, i) => (
              <React.Fragment key={i}>
                <CustomAccordion
                  group={item.mixture_name}
                  products={[]}
                  onClickHeading={() =>
                    onChangeIngredients({
                      ...item,
                      quantity: '',
                      unit: '',
                      type: 'MIXTURE',
                      name: item?.mixture_name,
                      id: item?.id
                    })
                  }
                  isMixture
                />
              </React.Fragment>
            ))}
        </div>
      </>
    );
  };

  useEffect(() => {
    addStepInput?.current?.addEventListener('keypress', handleEnterKeyPress);
    return () => {
      addStepInput?.current?.removeEventListener(
        'keypress',
        handleEnterKeyPress
      );
    };
  }, []);

  // useEffect(() => {
  //   setRecipeStepIndex(recipeSteps?.length + 1);
  // }, [recipeSteps]);

  const handleEnterKeyPress = (event) => {
    if (event?.key === 'Enter' && addStepInput?.current?.value !== '') {
      event?.preventDefault();
      onPressEnterKeyAddNewStep(addStepInput.current.value);
      addStepInput.current.value = '';
    }
  };

  return (
    <>
      <article className={styles.article}>
        <div className="flex item_space_btn pt_10">
          <div>
            <h1>
              <strong>Recipe engineering</strong>
            </h1>
            <h5 className="mt_4">Manage and create recipes here.</h5>
          </div>
          <div className="flex item_vcenter gap_10">
            <Button
              onClick={() => {
                if (isEdit) {
                  window.location.reload();
                  return;
                }
                onChangeStep(1);
              }}
              type="button"
              label={'Cancel'}
              color={'outline-lite'}
            />
            <Button
              onClick={() => {
                if (isMixture) {
                  createNewRecipe();
                  return;
                }
                setBulkModal(true);
              }}
              type="button"
              label={'Save'}
              color={'bg-button'}
              isLoading={isLoading || updateRecipeLoading}
            />
          </div>
        </div>
        <div className={styles.r1Bx}>
          <div className={styles.left}>
            <div>
              <RenderIf isTrue={isMixture}>
                <TextField
                  name="mixture_name"
                  id="mixture_name"
                  type="text"
                  label={``}
                  placeholder="Mixture name"
                  onChange={(val) => setMixtureName(val.target.value)}
                  value={mixtureName}
                />
              </RenderIf>
              <RenderIf isTrue={!isMixture}>
                <div className="flex item_vcenter">
                  <h4 className="mr_8">
                    <span className="ff_semi">
                      {isEdit
                        ? selectedData?.recipe?.recipe_name
                        : modifier?.modifier_name}
                    </span>
                  </h4>
                  <span>
                    <Ikon name="edit" />
                  </span>
                </div>
              </RenderIf>
            </div>
            <div>
              <ThemeTabs
                tabs={[{ label: 'Raw' }, { label: 'Mixtures' }]}
                panels={[{ content: panelRaw() }, { content: panelMixtures() }]}
                isSearchFull={
                  <div className="mt_12">
                    <TextField
                      name="search"
                      id="search"
                      type="text"
                      placeholder="Search"
                      onChange={(val) => setSearch(val.target.value)}
                      value={search}
                    />
                  </div>
                }
              />
            </div>
            <TextField
              name="cookingTime"
              id="cookingTime"
              type="text"
              label="Prep time"
              placeholder="hh:mm"
              onChange={(val) => setCookingTime(val.target.value)}
              value={cookingTime}
            />
          </div>
          <div className={styles.middle}>
            <div className={styles.formulaBx}>
              <h4>Formula</h4>
              <div className={styles.ingList}>
                {selectedIngredients?.map((item, i) => (
                  <div className={styles.ingItem} key={i}>
                    <div>
                      <h6>
                        {item?.name ||
                          item?.ProductModel?.product_name ||
                          item?.MixtureModel?.mixture_name ||
                          item?.group_name ||
                          item?.ProductGroupModel?.group_name}
                      </h6>
                      {/* <div
                        className={`badge ${
                          item.type === 'raw' ||
                          item?.ingredient_type?.toLowerCase() === 'raw'
                            ? 'badge_lite1'
                            : 'badge_lite2'
                        }`}
                      >
                        {item?.type || item?.ingredient_type?.toLowerCase()}
                      </div> */}
                    </div>
                    <div>
                      <div>
                        <input
                          type="number"
                          name="quantity"
                          className={styles.inputQty}
                          id="quantity"
                          placeholder="0"
                          onChange={(val) =>
                            onChangeQuantity(val.target.value, i)
                          }
                          value={item?.quantity}
                        />
                      </div>
                      <div className={styles.unitItem}>
                        <button
                          type="button"
                          className={styles.inputUnit}
                          onClick={() => {
                            if (
                              unitDropdownIndex === null ||
                              unitDropdownIndex !== i
                            ) {
                              setUnitDropdownIndex(i);
                            } else {
                              setUnitDropdownIndex(null);
                            }
                          }}
                        >
                          {item?.unit}
                        </button>
                        <RenderIf isTrue={unitDropdownIndex === i}>
                          <div className={styles.unitDropdownList}>
                            {allUnits
                              ?.filter((unt) => unt !== '')
                              ?.map((unt, idx) => (
                                <button
                                  key={idx}
                                  type="button"
                                  className={styles.unitDropdownItem}
                                  onClick={() => {
                                    onChangeUnit(unt?.toUpperCase(), i);
                                    setUnitDropdownIndex(null);
                                  }}
                                >
                                  {unt}
                                </button>
                              ))}
                          </div>
                        </RenderIf>
                        {/* <SelectDropdown
                          fieldSm
                          data={[
                            ...allUnits.map((item) => ({
                              value: item?.toUpperCase(),
                              label: item?.toUpperCase()
                            }))
                          ]}
                          onChange={(e) => onChangeUnit(e.target.value, i)}
                          value={item?.unit}
                        /> */}
                      </div>
                      <button
                        className={styles.buttonIng}
                        onClick={() => removeIngredeint(item)}
                      >
                        <Ikon name={`closex`} />
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className={styles.right}>
            <div>
              <TextFieldReffed
                refEl={addStepInput}
                type="text"
                name="description"
                id="description"
                label={''}
                placeholder={'Add recipe step detail then press enter'}
              />
            </div>
            <h4>Steps for Recipe</h4>
            <div className={styles.stepList}>
              {recipeSteps?.map((item, index) => (
                <div key={index} className={styles.stepItem}>
                  <div>{item?.step}</div>
                  <div>{item?.content}</div>
                  <div></div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <RenderIf isTrue={!isMixture}>
          <Button
            onClick={() => {
              setStepsModal(true);
              if (recipeSteps.length === 0) {
                setRecipeSteps([
                  {
                    step: 1,
                    content: ''
                  }
                ]);
              }
            }}
            type="button"
            label={'Steps'}
            color={'bg-button'}
          />
        </RenderIf>
      </article>
      <ModalComponent
        modal={bulkModal}
        handleModal={() => setBulkModal(false)}
        modalMiddle={true}
      >
        <div className="modal_box1">
          <h6>
            <span className="ff_semi">Serving type</span>
          </h6>
          <div className="mt_10"></div>
          <CheckboxField
            checked={isBulk}
            onChange={(e) => setIsBulk(!isBulk)}
            id="bulk"
          >
            <strong>This serving is bulk quantity</strong>
          </CheckboxField>
          {isBulk && (
            <div className="grid grid_gap8">
              <div className="colxs6">
                <div className="mt_10"></div>
                <TextField
                  fieldSm
                  name="quantity"
                  id="quantity"
                  type="number"
                  placeholder="Quantity"
                  label={'Total quantity'}
                  onChange={(val) => setTotalQuantity(val.target.value)}
                  value={totalQuantity}
                  error={
                    isSubmit && isBulk && totalQuantity === ''
                      ? 'Total quantity is required'
                      : ''
                  }
                />
              </div>
              <div className="colxs6">
                <div className="mt_10"></div>
                <SelectDropdown
                  fieldSm
                  label="Unit"
                  data={[
                    { value: '', label: 'choose option' },
                    ...allUnits.map((item) => ({
                      value: item?.toUpperCase(),
                      label: item?.toUpperCase()
                    }))
                  ]}
                  onChange={(e) => setUnit(e.target.value)}
                  error={
                    isSubmit && isBulk && unit === ''
                      ? 'Total unit is required'
                      : ''
                  }
                  value={unit}
                />
              </div>
              <div className="colxs6">
                <div className="mt_10"></div>
                <TextField
                  name="servingQuantity"
                  id="servingQuantity"
                  type="number"
                  placeholder="Quantity"
                  label={'Serving quantity'}
                  onChange={(val) => setServingQuantity(val.target.value)}
                  value={servingQuantity}
                  error={
                    isSubmit && isBulk && servingQuantity === ''
                      ? 'Serving quantity is required'
                      : ''
                  }
                />
              </div>
              <div className="colxs6">
                <div className="mt_10"></div>
                {/* <SelectDropdown
                    label="Unit"
                    data={[
                      { value: '', label: 'choose option' },
                      ...allUnits.map((item) => ({
                        value: item?.toUpperCase(),
                        label: item?.toUpperCase()
                      }))
                    ]}
                    onChange={(e) => setUnit(e.target.value)}
                    error={
                      isSubmit && isBulk && unit === ''
                        ? 'Serving Unit is required'
                        : ''
                    }
                    value={unit}
                  /> */}
              </div>
            </div>
          )}
          <div className="flex item_vcenter item_hend mt_10">
            <div className="flex item_vcenter">
              <Button
                onClick={() => {
                  setBulkModal(false);
                }}
                type="button"
                label={'Cancel'}
                color={'outline-lite'}
              />
              <Button
                onClick={createNewRecipe}
                type="button"
                label={'Save'}
                color={'bg-button'}
                isLoading={isLoading}
              />
            </div>
          </div>
        </div>
      </ModalComponent>
      <ModalComponent
        modal={stepsModal}
        handleModal={() => setStepsModal(false)}
        modalMiddle={true}
      >
        <div className="modal_box2">
          <h6>
            <span className="ff_semi">Recipe steps</span>
          </h6>
          <div className="mt_10"></div>
          {recipeSteps?.map((item, i) => (
            <React.Fragment key={i}>
              <div className="flex item_vcenter item_space_btn">
                <p>
                  <strong>Step {i + 1}</strong>
                </p>
                {/* <RenderIf isTrue={recipeSteps?.length > 1}> */}
                <button
                  className="ikonlink svg_w10"
                  color="danger"
                  onClick={() => removeStep(i)}
                >
                  <Ikon closex />
                </button>
                {/* </RenderIf> */}
              </div>
              <TextArea
                name="description_1"
                label={''}
                rows="4"
                placeholder={'Step details'}
                key={i}
                onChange={(e) => onChangeSteps(e.target.value, i)}
                value={item?.content}
              />
            </React.Fragment>
          ))}
          <div className="flex item_hend mt_10">
            <button
              onClick={() =>
                setRecipeSteps([
                  ...recipeSteps,
                  { step: recipeSteps.length + 1, content: '' }
                ])
              }
              className="alink"
              color="primary"
            >
              + Add step
            </button>
          </div>
          <div className="flex item_vcenter item_hend mt_10">
            <div className="flex item_vcenter">
              <Button
                onClick={() => setStepsModal(false)}
                type="button"
                label={'Cancel'}
                color={'outline-lite'}
              />
              <Button
                onClick={() => setStepsModal(false)}
                type="button"
                label={'Save'}
                color={'bg-button'}
                isLoading={isLoading}
              />
            </div>
          </div>
        </div>
      </ModalComponent>
    </>
  );
};
