import ProgressSteps from 'components/steps';
import { Button } from 'components/button';
import { OptionButton } from 'components/optionbutton';
import { useNavigate } from 'react-router-dom';

export const AddMenuMethods = ({ onChangeStep, onGoBack }) => {
  const navigate = useNavigate();
  return (
    <div style={{ minHeight: window.innerHeight }} className={`onflexlay`}>
      <div className="onbox">
        <h2>
          <strong>Add Menu</strong>
        </h2>
        <h5>About Menu</h5>
        <ProgressSteps active={3} />
        {/* <div className="mt_15"></div>
        <OptionButton type="button" onClick={() => onChangeStep(7)}>
          <h4 className="ff_semi">Fill a template</h4>
          <p>
            <strong>About template</strong>
          </p>
        </OptionButton> */}
        <div className="mt_15"></div>
        {/* <OptionButton type="button" onClick={() => onChangeStep(3)}>
          <h4 className="ff_semi">Import a menu</h4>
          <p>
            <strong>About Menu</strong>
          </p>
        </OptionButton>
        <div className="mt_15"></div> */}
        <OptionButton type="button" onClick={() => onChangeStep(6)}>
          <h4 className="ff_semi">Create a new menu</h4>
          <p>
            <strong>About new menu</strong>
          </p>
        </OptionButton>
        <div className="mt_15"></div>
        <div className="pt_40"></div>
        <div className="onfooter">
          <Button
            type="button"
            label={`I'll do this later`}
            onClick={() => navigate('/dashboard')}
            className="alink"
            color="dark"
          />
          <div className="flex item_vcenter">
            <Button
              onClick={onGoBack}
              type="button"
              label={'Back'}
              color="outline-lite"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
