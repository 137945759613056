import { Ikon } from 'assets/icons';
import { Button } from 'components/button';
import { useFetchSds } from 'hooks/sds/useFetchSds';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStore } from 'store';
import { RenderIf } from 'utilities/helpers';
import { AddUpdateSdsScreen } from './addupdate';
import { Loading } from 'components/loading';

export const SDSPage = () => {
  const navigate = useNavigate();

  const [modal, setModal] = useState(false);
  const { branch } = useStore((state) => state);

  const { sdsData, sdsLoading } = useFetchSds(`sds/${branch?.id}`, branch?.id);

  return (
    <div>
      <div className="add_new_grid" style={{ margin: '40px' }}>
        <AddInventoryCards
          name="fileChecked"
          heading={`Add New Screen`}
          description={`Please add your screen to access the passcode`}
          onClick={() => setModal(true)}
        />
      </div>
      <RenderIf isTrue={sdsLoading}>
        <div className={`loading_full`}>
          <Loading />
        </div>
      </RenderIf>
      <RenderIf isTrue={sdsData?.length}>
        <div className="grid grid_gap30">
          {sdsData?.map((sds, index) => (
            <div key={index} className="collg4">
              <div className="card_item1 mt_30">
                <picture>
                  <RenderIf isTrue={!sds?.image_url}>
                    <div className="placeholder">
                      <Ikon name="imgplaceholder" />
                    </div>
                  </RenderIf>
                  <RenderIf isTrue={sds?.image_url}>
                    <source srcSet={sds?.image_url} />
                    <img src={sds?.image_url} alt={sds?.brand_name} />
                  </RenderIf>
                </picture>
                <div className="card_item1_box">
                  <h6>
                    <span className="ff_semi">{sds?.screen_name}</span>
                  </h6>
                  <div className="mt_8"></div>
                  <hr className="hr1" />
                  <div className="mt_16"></div>
                  <div className="flex item_hend">
                    <Button
                      label="View Details"
                      color="bg-titary"
                      onClick={() =>
                        navigate(`/sds/details`, {
                          state: {
                            sds
                          }
                        })
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </RenderIf>
      <AddUpdateSdsScreen modal={modal} setModal={setModal} action="add" />
    </div>
  );
};

const AddInventoryCards = ({ name, heading, description, onClick }) => {
  return (
    <>
      <div>
        <button className="add_new_button" onClick={onClick}>
          <div>
            <div>
              <Ikon name={name} />
            </div>
            <h4>{heading}</h4>
            <p>{description}</p>
          </div>
        </button>
      </div>
    </>
  );
};
