import axios from "axios";
import { useQuery } from "react-query";

export const useFetchGoogleWebfonts = () => {
  const { data, error, isFetching, isLoading } = useQuery([], () =>
    getGoogleWebfonts()
  );

  return {
    fontData: data,
    fontError: error,
    fontIsFetching: isFetching,
    fontIsLoading: isLoading,
  };
};

const getGoogleWebfonts = async () => {
  let result = await axios.get(
    `https://www.googleapis.com/webfonts/v1/webfonts?key=AIzaSyCk3GJ5FvJ2c_j7t6-NV-b5rZTJRT-lDMU`
  );
  return result?.data;
};
