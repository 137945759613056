import { useEffect, useState, useRef } from 'react';
import { SketchPicker } from 'react-color';
import { BuilderJSON } from 'store';
import { RenderIf } from 'utilities/helpers';
import { Ikon } from 'assets/icons';
import styles from './index.module.css';

export const BuilderColorPicker = ({
  activeColor = ``,
  handleActiveColor,
  title,
  label
}) => {
  const { pages, setGlobalColor } = BuilderJSON((state) => state);
  const ref = useRef(null);
  const [sketchPickerActive, setSketchPickerActive] = useState(false);
  const [sketchPickerColor, setSketchPickerColor] = useState({
    r: '241',
    g: '112',
    b: '19',
    a: '1'
  });
  // destructuring rgba from state
  const { r, g, b, a } = sketchPickerColor;
  const { colors } = pages?.global?.style;

  const handleClickButton = () => {
    if (sketchPickerActive) {
      setGlobalColor(`rgba(${r},${g},${b},${a})`);
    }
    setSketchPickerActive(!sketchPickerActive);
  };

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (ref.current && !ref.current.contains(e.target)) {
        setSketchPickerActive(false);
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  return (
    <article className={styles.article}>
      <RenderIf isTrue={label}>
        <label>{label}</label>
      </RenderIf>
      <div className={styles.itemBx}>
        <RenderIf isTrue={colors?.length > 0}>
          {colors?.map((color, index) => (
            <button
              title={`${color?.code}`}
              type={`button`}
              key={index}
              className={styles.colorItem}
              style={{
                backgroundColor:
                  color?.code === `none` || color?.code === ``
                    ? `transparent`
                    : color?.code,
                border: `1px solid rgb(var(--black-rgb))`,
                boxShadow:
                  activeColor === color?.code && color?.code !== ``
                    ? `inset 0px 0px 0px 1px rgba(255, 255, 255, 1)`
                    : ``,
                lineHeight: 0
              }}
              onClick={() =>
                title
                  ? handleActiveColor(color?.code, title)
                  : handleActiveColor(color?.code)
              }
            >
              <RenderIf
                isTrue={activeColor !== `` && activeColor === color?.code}
              >
                <Ikon
                  name={`builder-selected-color`}
                  fillColor={`rgb(var(--black-rgb))`}
                />
              </RenderIf>
              <RenderIf isTrue={color?.code === `none` || color?.code === ``}>
                <Ikon
                  name={`builder-none`}
                  fillColor={`rgb(var(--black-rgb))`}
                />
              </RenderIf>
            </button>
          ))}
        </RenderIf>
        <div ref={ref}>
          <button
            type={`button`}
            className={styles.buttonNewColor}
            onClick={() => {
              handleClickButton();
            }}
            style={{
              backgroundColor: sketchPickerActive
                ? `rgba(${r},${g},${b},${a})`
                : `transparent`
            }}
          >
            <RenderIf isTrue={!sketchPickerActive}>
              <Ikon name={`builder-plus`} fillColor={`rgb(var(--black-rgb))`} />
            </RenderIf>
            <RenderIf isTrue={sketchPickerActive}>
              <Ikon name={`builder-tick`} fillColor={`rgb(var(--black-rgb))`} />
            </RenderIf>
          </button>
          <RenderIf isTrue={sketchPickerActive}>
            <div
              style={{
                position: `absolute`,
                top: `calc(100% + 4px)`,
                left: `-28px`,
                zIndex: `calc(var(--headerZIndex) + 13)`,
              }}
            >
              <SketchPicker
                onChange={(color) => {
                  setSketchPickerColor(color.rgb);
                }}
                color={sketchPickerColor}
              />
            </div>
          </RenderIf>
        </div>
      </div>
    </article>
  );
};
