import { client } from 'utilities/db';
import { useQuery } from 'react-query';

export const useFetchManagerHook = (keyName = 'managers', id) => {
  const { status, data, error, isFetching, isLoading, refetch, isSuccess } =
    useQuery(
      [keyName],
      async () => {
        let result = await client.get(`/brand/getManagers/${id}`);
        return result?.data?.brandManagers;
      },
      {
        enabled: !!id,
        refetchInterval: 60000
      }
    );
  return {
    managerStatus: status,
    managerData: data,
    managerError: error,
    managerFetching: isFetching,
    managerLoading: isLoading,
    managerRefetch: refetch
  };
};

const getManagersData = async (id) => {
  let result = await client.get(`/brand/getManagers/${id}`);
  result = result?.data;
  result = result?.brandManagers;

  return result;
};
