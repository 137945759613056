import styles from './index.module.css';

export const BuilderLayoutButton = ({
  isActive,
  handleOnClick,
  children,
  hasTextOnly,
  hasAddItem
}) => {
  return (
    <button
      type={`button`}
      className={styles.button}
      onClick={handleOnClick}
      style={{
        lineHeight: hasTextOnly || hasAddItem ? `normal` : 0,
        padding: hasTextOnly ? `21px 8px` : hasAddItem ? `9px 12px` : 8,
        background: hasTextOnly
          ? `var(--borderColor)`
          : hasAddItem
          ? `rgba(var(--black-rgb), 0.08))`
          : `transparent`,
        height: hasAddItem ? 44 : `auto`,
        borderRadius: hasTextOnly ? 8 : hasAddItem ? 10 : 2,
        color:
          (hasTextOnly || hasAddItem) && isActive
            ? `rgb(var(--black-rgb))`
            : `rgb(var(--black-rgb), 0.76)`,
        border:
          isActive && (hasTextOnly || hasAddItem)
            ? `var(--primary) 3px solid`
            : `transparent 3px solid`
      }}
    >
      {children}
    </button>
  );
};
