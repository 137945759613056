import { client } from 'utilities/db';
import { useQuery } from 'react-query';

export const useFetchProductsHook = (keyName, brandId, branchId) => {
  console.log('🚀 ~ useFetchProductsHook ~ brandId:', brandId, branchId);
  const { status, data, error, isFetching, isLoading, refetch } = useQuery(
    [keyName, brandId, branchId],
    () => getProducts(brandId, branchId),
    {
      enabled: !!brandId && !!branchId,
      refetchInterval: 60000
    }
  );

  return {
    productsStatus: status,
    productsData: data,
    productsError: error,
    productsFetching: isFetching,
    productsLoading: isLoading,
    productsRefetch: refetch
  };
};

const getProducts = async (brandId, branchId) => {
  let result = await client.get(`/product/findAll/${brandId}/${branchId}`);
  result = result.data;
  result = result?.products;

  return result;
};
