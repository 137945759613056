import { BuilderJSON } from 'store';
import styles from './index.module.css';
import { RenderIf } from 'utilities/helpers';
import { Ikon } from 'assets/icons';

export const BuilderThemeFooter = () => {
  const { pages, screenView } = BuilderJSON((state) => state);
  const { content, style } = pages?.footer;
  const twitter = pages?.global?.content?.social?.twitter;
  const discord = pages?.global?.content?.social?.discord;
  const instagram = pages?.global?.content?.social?.instagram;
  const facebook = pages?.global?.content?.social?.facebook;
  const snapchat = pages?.global?.content?.social?.snapchat;
  const tiktok = pages?.global?.content?.social?.tiktok;

  return (
    <>
      <footer
        id={`Footer`}
        className={
          screenView === `WEB_VIEW`
            ? styles.footer
            : `${styles.footer} ${styles.mobileView}`
        }
        style={{
          backgroundColor: style?.backgroundColor,
          backgroundImage: `url(${style?.backgroundImage})`,
          color: style?.foregroundColor,
          marginTop: `${style?.marginTop}px`,
          marginBottom: `${style?.marginBottom}px`,
          paddingTop: `${style?.paddingTop}px`,
          paddingBottom: `${style?.paddingBottom}px`,
          borderRadius: `${content?.roundedCorners}px`
        }}
      >
        <div
          className={content?.contentInContainer ? `contBx` : ``}
          style={{
            paddingLeft:
              screenView === `WEB_VIEW` && content?.contentInContainer
                ? `var(--builder-theme-horizontal-padding)`
                : screenView !== `WEB_VIEW` && content?.contentInContainer
                ? `16px`
                : ``,
            paddingRight:
              screenView === `WEB_VIEW` && content?.contentInContainer
                ? `var(--builder-theme-horizontal-padding)`
                : screenView !== `WEB_VIEW` && content?.contentInContainer
                ? `16px`
                : ``
          }}
        >
          <div
            className={content?.layout === 1 ? styles.layout1 : styles.layout1}
            style={{
              flexDirection: content?.layout === 1 ? `row` : `row-reverse`
            }}
          >
            <div>
              <RenderIf isTrue={content?.socials}>
                <div className={styles.socials}>
                  <RenderIf isTrue={twitter !== ``}>
                    <a href={twitter} rel={`noreferrer`}>
                      <Ikon
                        name={`twitter`}
                        fillColor={style?.foregroundColor}
                      />
                    </a>
                  </RenderIf>
                  <RenderIf isTrue={discord !== ``}>
                    <a href={discord} rel={`noreferrer`}>
                      <Ikon
                        name={`discord`}
                        fillColor={style?.foregroundColor}
                      />
                    </a>
                  </RenderIf>
                  <RenderIf isTrue={instagram !== ``}>
                    <a href={instagram} rel={`noreferrer`}>
                      <Ikon
                        name={`instagram`}
                        fillColor={style?.foregroundColor}
                      />
                    </a>
                  </RenderIf>
                  <RenderIf isTrue={facebook !== ``}>
                    <a href={facebook} rel={`noreferrer`}>
                      <Ikon
                        name={`facebook`}
                        fillColor={style?.foregroundColor}
                      />
                    </a>
                  </RenderIf>
                  <RenderIf isTrue={snapchat !== ``}>
                    <a href={snapchat} rel={`noreferrer`}>
                      <Ikon
                        name={`snapchat`}
                        fillColor={style?.foregroundColor}
                      />
                    </a>
                  </RenderIf>
                  <RenderIf isTrue={tiktok !== ``}>
                    <a href={tiktok} rel={`noreferrer`}>
                      <Ikon
                        name={`tiktok`}
                        fillColor={style?.foregroundColor}
                      />
                    </a>
                  </RenderIf>
                </div>
              </RenderIf>
            </div>
            <div>
              <RenderIf isTrue={content?.showLogo}>
                <picture>
                  <source srcSet={pages?.global?.content?.logo} />
                  <img
                    src={pages?.global?.content?.logo}
                    alt={`Logo`}
                    style={{
                      width: content?.logoWidth
                        ? `${content?.logoWidth}px`
                        : `${pages?.global?.content?.logoWidth}px`
                    }}
                  />
                </picture>
              </RenderIf>
            </div>
          </div>
          <div
            style={{
              width: `100%`,
              height: 1,
              background: style?.foregroundColor
                ? style?.foregroundColor
                : `rgba(0, 0, 0, 1)`,
              opacity: 0.16,
              margin: `16px auto`
            }}
          ></div>
          <div
            className={
              content?.layout === 1
                ? `${styles.barBx} ${styles.barBxLayout2}`
                : styles.barBx
            }
          >
            <div>
              <RenderIf isTrue={content?.copyright !== ``}>
                <p style={{ whiteSpace: `pre-line` }}>{content?.copyright}</p>
              </RenderIf>
            </div>
            <div>
              <RenderIf isTrue={content?.email !== ``}>
                <p>{content?.email}</p>
              </RenderIf>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};
