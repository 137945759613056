import { Ikon } from 'assets/icons';
import styles from './index.module.css';

export const CardImgMenuList = (props) => {
  const { media, title, active, onClick, onDelete } = props;

  return (
    <>
      <article
      onClick={onClick}
        className={active ? `${styles.box} ${styles.active}` : styles.box}
      >
        {onDelete && <button onClick={onDelete}>
          <Ikon closex />
        </button>}
        <picture>
          <source srcSet={media} />
          <img src={media} alt="" />
        </picture>
        <h6>{title}</h6>
      </article>
    </>
  );
};
