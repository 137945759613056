import { client } from 'utilities/db';
import { useQuery } from 'react-query';

export const useFetchOneDbaHook = (keyName, id) => {
  const { status, data, error, isFetching, isLoading, refetch } = useQuery(
    [keyName],
    async () => {
      let result = await client.get(`/DBA/findByBrandId/${id}`);
      return result.data
    },
    {
      enabled: !!id,
      refetchInterval: 60000
    }
  );

  return {
    status,
    data,
    error,
    isFetching,
    isLoading,
    refetch
  };
};

export const useFetchOneBrandHook = (keyName, id) => {
  const { status, data, error, isFetching, isLoading, refetch } = useQuery(
    [keyName],
    () => getBrand(id),
    {
      enabled: !!id,
      refetchInterval: 60000
    }
  );

  return {
    oneBrandStatus: status,
    oneBrandData: data,
    oneBrandError: error,
    oneBrandFetching: isFetching,
    oneBrandLoading: isLoading,
    oneBrandRefetch: refetch
  };
};

const getBrand = async (id) => {
  let result = await client.get(`/brand/findOne/${id}`);
  result = result.data;
  result = result?.brand;
  return result;
};
