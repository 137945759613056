import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { client } from 'utilities/db';
import { useMutation } from 'react-query';
import { FieldInput } from 'components/input';
import { Button } from 'components/button';
import { emailRegex } from 'utilities/regex';

export const ForgotPage = ({ setStep, setEmail }) => {
  const navigate = useNavigate();
  const { mutate, isLoading, isSuccess, isError, error, data } = useMutation(
    (userData) => {
      return client.post(`/user/passwordResetRequest`, userData);
    }
  );
  const {
    handleSubmit,
    register,
    formState: { errors }
  } = useForm();

  const onSubmitLogin = (val) => {
    setEmail(val?.email);
    mutate(val);
  };

  useEffect(() => {
    const user = window.localStorage.getItem('user');
    if (user) {
      navigate('/');
    }
    return;
  }, []);

  useEffect(() => {
    if (!isError) return;
    // alert(error?.message)
  }, [isError]);

  useEffect(() => {
    if (!isSuccess) return;
    setStep(2);
  }, [isSuccess]);

  return (
    <article className="full">
      <form onSubmit={handleSubmit(onSubmitLogin)}>
        <div className="auth_box">
          <div className="mt_40"></div>
          <div className="pt_40"></div>
          <div className="mt_40"></div>
          {/* <div className="otp_emoji pt_30">
            <picture>
              <source srcSet={TEMPImgs?.ImgResetEmoji} />
              <img src={TEMPImgs?.ImgResetEmoji} alt="OTP Emoji" width={28} />
            </picture>
          </div> */}
          <h2 className="text_center">
            <span className="ff_semi">Password Reset</span>
          </h2>
          <h6 className="mt_12 text_center">
            Enter the email associated with your account and we'll send an email
            with instructions to reset your password.
          </h6>
          <div className="mt_30"></div>
          <div className="auth_form">
            <FieldInput
              name="email"
              label={'Email address'}
              type="text"
              error={
                errors?.email?.type === 'required'
                  ? 'Email is required'
                  : errors?.email?.type === 'pattern'
                  ? 'Email is invalid'
                  : ''
              }
              register={register}
              validation={{
                required: true,
                pattern: emailRegex
              }}
              required
            />
            <div className="mt_30"></div>
            <div className={`grid grid_gap20`}>
              <div className={`colxs6`}>
                <Button
                  type="button"
                  label={'Back to log in'}
                  color="outline-grey"
                  full
                  onClick={() => navigate('/login')}
                />
              </div>
              <div className={`colxs6`}>
                <Button
                  isLoading={isLoading}
                  disabled={isLoading}
                  type="submit"
                  label={'Reset password'}
                  color="bg-primary"
                  full
                />
              </div>
            </div>
          </div>
        </div>
      </form>
    </article>
  );
};
