import { RenderIf } from 'utilities/helpers';
import { Ikon } from 'assets/icons';
import styles from './index.module.css';

export const BadgeCheckbox = (props) => {
  const {
    value,
    onChange = () => {},
    register = () => {},
    validation = {},
    info,
    infoChild,
    labelOne,
    labels,
    required
  } = props;

  return (
    <>
      <div className={styles.fieldbox}>
        <RenderIf isTrue={labelOne}>
          <div className={styles.field}>
            {/* {required ? <span className={styles.required}>*</span> : null} */}
            <label className={styles.label1}>{labelOne}</label>
            <div>
              {info ? (
                <div className={styles.infobox}>
                  <Ikon info />
                  <div className={styles.infoChild}>
                    <Ikon indicatorArrow />
                    <div className={styles.infoContent}>{infoChild}</div>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
          <div className="pt_5"></div>
        </RenderIf>
        <div className={styles.field}>
          {labels.map((lbl, i) => (
            <>
              <label htmlFor={lbl?.id} className={styles.label} key={i}>
                <input
                  {...register(lbl?.name, validation)}
                  type="checkbox"
                  name={lbl?.name}
                  className={styles.input}
                  id={lbl?.id}
                  value={value || lbl?.name}
                  onChange={onChange}
                />
                <span className={styles.checks}>{lbl?.label}</span>
              </label>
            </>
          ))}
        </div>
      </div>
    </>
  );
};
