import { useEffect, useState } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { client } from 'utilities/db';
import ImageDropzone from 'components/imageDropzone/imageDropzone';
import { TextField } from 'components/input';
import { Button } from 'components/button';
import { Toasts } from 'components/toasts';
import styles from './index.module.css';
import { RenderIf } from 'utilities/helpers';
import { useFetchOneBrandHook } from 'hooks/brands/useFetchOneBrandHook';
import { emailRegex, phoneRegex, urlRegex } from 'utilities/regex';
import { CustomSelectDropdown } from 'components/select/multi';
import { US_STATES } from 'utilities/constants';

export const PortfolioAddCompanyPage = () => {
  const navigate = useNavigate();
  const { brandId } = useParams();
  const { oneBrandData, oneBrandLoading } = useFetchOneBrandHook(
    `brand/${brandId}`,
    brandId
  );
  const action = useLocation()?.pathname?.split('/')[4];
  const location = useLocation();
  const { companyType } = location?.state;

  const [submitting, setSubmitting] = useState(false);
  const [repFrontImage, setRepFrontImage] = useState(null);
  const [repBackImage, setRepBackImage] = useState(null);
  const [ownerFrontImage, setOwnerFrontImage] = useState(null);
  const [ownerBackImage, setOwnerBackImage] = useState(null);
  const [execFrontImage, setExecFrontImage] = useState(null);
  const [execBackImage, setExecBackImage] = useState(null);

  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue,
    control
  } = useForm();

  // useEffect(() => {
  //   if (oneBrandData?.id) {
  //     console.log({oneBrandData})
  //     setValue('buisness_name', oneBrandData?.business_information.buisness_name)
  //     setValue('buisness_email', oneBrandData?.business_information.buisness_email)
  //     setValue('buisness_phone', oneBrandData?.business_information.buisness_phone)
  //     setValue('buisness_url', oneBrandData?.business_information.buisness_url)
  //     setValue('buisness_description', oneBrandData?.business_information.buisness_description)
  //     setValue('buisness_city', oneBrandData?.business_information.city)
  //     setValue('buisness_state', oneBrandData?.business_information.state)
  //     setValue('buisness_zip', oneBrandData?.business_information.zip)
  //     setValue('buisness_address', oneBrandData?.business_information.address)
  //     setValue('first_name', oneBrandData?.representative_information.first_name)
  //     setValue('last_name', oneBrandData?.representative_information.last_name)
  //     setValue('email', oneBrandData?.representative_information.email)
  //     setValue('dob', oneBrandData?.representative_information.dob)
  //     setValue('phone', oneBrandData?.representative_information.phone)
  //     setValue('country', oneBrandData?.representative_information.country)
  //     setValue('address', oneBrandData?.representative_information.address)
  //     setValue('city', oneBrandData?.representative_information.city)
  //     setValue('state', oneBrandData?.representative_information.state)
  //     setValue('zip', oneBrandData?.representative_information.zip)
  //     setValue('ssn', oneBrandData?.representative_information.ssn)

  //     setRepFrontImage(oneBrandData?.representative_information.front_image)
  //     setRepBackImage(oneBrandData?.representative_information.back_image)
  //   }
  // }, [oneBrandData])

  const onSubmit = async (val) => {
    try {
      if (!repFrontImage || !repBackImage) {
        Toasts.error('Please upload Proof of Identity');
        return;
      }

      if (companyType === 'BUSINESS') {
        if (
          !ownerFrontImage ||
          !ownerBackImage ||
          !execFrontImage ||
          !execBackImage
        ) {
          Toasts.error('Please upload Proof of Identity');
          return;
        }
      }

      if (action === 'add') {
        setSubmitting(true);

        let body = {
          brand_id: parseInt(brandId),
          business_information: {
            buisness_name: val.buisness_name,
            buisness_email: val.buisness_email,
            buisness_phone: val.buisness_phone,
            buisness_url: val.buisness_url,
            buisness_description: val.buisness_description,
            city: val.buisness_city,
            state: val.buisness_state?.value,
            zip: val.buisness_zip,
            address: val.buisness_address
          },
          representative_information: {
            first_name: val.first_name,
            last_name: val.last_name,
            email: val.email,
            dob: val.dob,
            phone: val.phone,
            country: val.country,
            address: val.address,
            city: val.city,
            state: val.state?.value,
            zip: val.zip,
            ssn: val.ssn,
            front_image: repFrontImage,
            back_image: repBackImage
          }
        };

        if (companyType === 'BUSINESS') {
          body = {
            ...body,
            owner_information: {
              first_name: val.owner_first_name,
              last_name: val.owner_last_name,
              email: val.owner_email,
              dob: val.owner_dob,
              phone: val.owner_phone,
              country: val.owner_country,
              address: val.owner_address,
              city: val.owner_city,
              state: val.owner_state?.value,
              zip: val.owner_zip,
              ssn: val.owner_ssn,
              front_image: ownerFrontImage,
              back_image: ownerBackImage
            },
            executive_information: {
              first_name: val.executive_first_name,
              last_name: val.executive_last_name,
              email: val.executive_email,
              dob: val.executive_dob,
              phone: val.executive_phone,
              country: val.executive_country,
              address: val.executive_address,
              city: val.executive_city,
              state: val.executive_state?.value,
              zip: val.executive_zip,
              ssn: val.executive_ssn,
              front_image: execFrontImage,
              back_image: execBackImage
            }
          };
        }

        await client.post(`/brand/addBusinessRepresentativeInformation`, body);
        setSubmitting(false);
        navigate(`/portfolio/brand/details/${brandId}`);
        Toasts.success('Company Info added successfully');
      }
      if (action === 'update') {
        const response = await client.post(
          `/brand/addBusinessRepresentativeInformation`,
          {
            brand_id: brandId,
            business_information: {
              buisness_name: val.buisness_name,
              buisness_email: val.buisness_email,
              buisness_phone: val.buisness_phone,
              buisness_url: val.buisness_url,
              buisness_description: val.buisness_description,
              city: val.buisness_city,
              state: val.buisness_state?.value,
              zip: val.buisness_zip,
              address: val.buisness_address
            },
            representative_information: {
              first_name: val.first_name,
              last_name: val.last_name,
              email: val.email,
              dob: val.dob,
              phone: val.phone,
              country: val.country,
              address: val.address,
              city: val.city,
              state: val.state?.value,
              zip: val.zip,
              ssn: val.ssn,
              front_image: repFrontImage,
              back_image: repBackImage
            },
            owner_information: {
              first_name: val.owner_first_name,
              last_name: val.owner_last_name,
              email: val.owner_email,
              dob: val.owner_dob,
              phone: val.owner_phone,
              country: val.owner_country,
              address: val.owner_address,
              city: val.owner_city,
              state: val.owner_state?.value,
              zip: val.owner_zip,
              ssn: val.owner_ssn,
              front_image: ownerFrontImage,
              back_image: ownerBackImage
            },
            executive_information: {
              first_name: val.executive_first_name,
              last_name: val.executive_last_name,
              email: val.executive_email,
              dob: val.executive_dob,
              phone: val.executive_phone,
              country: val.executive_country,
              address: val.executive_address,
              city: val.executive_city,
              state: val.executive_state?.value,
              zip: val.executive_zip,
              ssn: val.executive_ssn,
              front_image: execFrontImage,
              back_image: execBackImage
            }
          }
        );

        setSubmitting(false);
        navigate(`/portfolio/brand/details/${brandId}`);
        Toasts.success('Company Info updated successfully');
      }
    } catch (error) {
      console.log('🚀 ~ file: index.jsx ~ line 195 ~ onSubmit ~ error', error);
      setSubmitting(false);
      Toasts.error(error?.response?.data?.message);
    }
  };

  return (
    <>
      <div className="mt_40"></div>
      <div className={styles.box}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <h2>
            <span className="ff_semi">Buisness Information</span>
          </h2>
          <div className="mt_16"></div>
          <TextField
            name="buisness_name"
            label="Buisness Name"
            type="text"
            error={
              errors?.buisness_name?.type === 'required'
                ? 'Buisness Name is required'
                : ''
            }
            validation={{
              required: true
            }}
            register={register}
            required
          />
          <TextField
            name="buisness_email"
            label="Buisness Email"
            type="text"
            error={
              errors?.buisness_email?.type === 'required'
                ? 'Email is required'
                : errors?.buisness_email?.type === 'pattern'
                ? 'Email is invalid'
                : ''
            }
            validation={{
              required: true,
              pattern: emailRegex
            }}
            register={register}
            required
          />
          <TextField
            name="buisness_phone"
            label="Buisness Phone"
            type="number"
            error={
              errors?.buisness_phone?.type === 'required'
                ? 'Phone is required'
                : errors?.buisness_phone?.type === 'pattern'
                ? 'Phone is invalid'
                : ''
            }
            validation={{
              required: true,
              pattern: phoneRegex
            }}
            register={register}
            required
          />
          <TextField
            name="buisness_url"
            label="Buisness URL"
            type="text"
            placeholder="www.google.com"
            error={
              errors?.buisness_url?.type === 'required'
                ? 'Buisness URL is required'
                : errors?.buisness_url?.type === 'pattern'
                ? 'Buisness URL is invalid'
                : ''
            }
            validation={{
              required: true,
              pattern: urlRegex
            }}
            register={register}
            required
          />
          <TextField
            name="buisness_description"
            label="Buisness Description"
            type="text"
            error={
              errors?.buisness_description?.type === 'required'
                ? 'Buisness Description is required'
                : ''
            }
            validation={{
              required: true
            }}
            register={register}
            required
          />
          <TextField
            name="buisness_city"
            label="City"
            type="text"
            error={
              errors?.buisness_city?.type === 'required'
                ? 'City is required'
                : ''
            }
            validation={{
              required: true
            }}
            register={register}
            required
          />

          <Controller
            name="buisness_state"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <CustomSelectDropdown
                hookForm={true}
                field={field}
                label="State"
                placeholder="Choose state"
                options={US_STATES}
                error={
                  errors?.buisness_state?.type === 'required'
                    ? 'State is required'
                    : ''
                }
              />
            )}
          />
          <TextField
            name="buisness_zip"
            label="Zip Code"
            type="text"
            error={
              errors?.buisness_zip?.type === 'required'
                ? 'Zip Code is required'
                : ''
            }
            validation={{
              required: true
            }}
            register={register}
            required
          />
          <TextField
            name="buisness_address"
            label="Address"
            type="text"
            error={
              errors?.buisness_address?.type === 'required'
                ? 'Address is required'
                : ''
            }
            validation={{
              required: true
            }}
            register={register}
            required
          />
          <div className="mt_26"></div>
          <h2>
            <span className="ff_semi">Representative Information</span>
          </h2>
          <div className="mt_16"></div>
          <TextField
            name="first_name"
            label="First Name"
            type="text"
            error={
              errors?.first_name?.type === 'required'
                ? 'First Name is required'
                : ''
            }
            validation={{
              required: true
            }}
            register={register}
            required
          />
          <TextField
            name="last_name"
            label="Last Name"
            type="text"
            error={
              errors?.last_name?.type === 'required'
                ? 'Last Name is required'
                : ''
            }
            validation={{
              required: true
            }}
            register={register}
            required
          />
          <TextField
            name="email"
            label="Email"
            type="text"
            error={
              errors?.email?.type === 'required'
                ? 'Email is required'
                : errors?.email?.type === 'pattern'
                ? 'Email is invalid'
                : ''
            }
            validation={{
              required: true,
              pattern: emailRegex
            }}
            register={register}
            required
          />
          <TextField
            name="dob"
            label="Date of Birth"
            type="date"
            error={
              errors?.dob?.type === 'required'
                ? 'Date of Birth is required'
                : ''
            }
            validation={{
              required: true
            }}
            register={register}
            required
          />
          <TextField
            name="phone"
            label="Phone Number"
            type="number"
            placeholder="13334445555"
            error={
              errors?.phone?.type === 'required'
                ? 'Phone is required'
                : errors?.phone?.type === 'pattern'
                ? 'Phone is invalid'
                : ''
            }
            validation={{
              required: true,
              pattern: phoneRegex
            }}
            register={register}
            required
          />
          <TextField
            name="country"
            label="Country"
            type="text"
            error={
              errors?.country?.type === 'required' ? 'Country is required' : ''
            }
            validation={{
              required: true
            }}
            register={register}
            required
          />
          <TextField
            name="address"
            label="Address"
            type="text"
            error={
              errors?.address?.type === 'required' ? 'Address is required' : ''
            }
            validation={{
              required: true
            }}
            register={register}
            required
          />
          <TextField
            name="city"
            label="City"
            type="text"
            error={errors?.city?.type === 'required' ? 'City is required' : ''}
            validation={{
              required: true
            }}
            register={register}
            required
          />
          <Controller
            name="state"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <CustomSelectDropdown
                hookForm={true}
                field={field}
                label="State"
                placeholder="Choose state"
                options={US_STATES}
                error={
                  errors?.state?.type === 'required' ? 'State is required' : ''
                }
              />
            )}
          />
          <TextField
            name="zip"
            label="Zip Code"
            type="text"
            error={
              errors?.zip?.type === 'required' ? 'Zip Code is required' : ''
            }
            validation={{
              required: true
            }}
            register={register}
            required
          />
          <TextField
            name="ssn"
            label="Social Security Number"
            type="text"
            error={
              errors?.ssn?.type === 'required'
                ? 'Social Security Number is required'
                : ''
            }
            validation={{
              required: true
            }}
            register={register}
            required
          />
          <div className="mt_16">
            <label htmlFor="exampleFormControlInput1" className="label">
              Front Image of ID
            </label>
            <ImageDropzone image={repFrontImage} setImage={setRepFrontImage} />
          </div>
          <div className="mt_16">
            <label htmlFor="exampleFormControlInput1" className="label">
              Back Image of ID
            </label>
            <ImageDropzone image={repBackImage} setImage={setRepBackImage} />
          </div>

          <RenderIf isTrue={companyType === 'BUSINESS'}>
            <div className="mt_26"></div>
            <h2>
              <span className="ff_semi">Owner Information</span>
            </h2>
            <TextField
              name="owner_first_name"
              label="First Name"
              type="text"
              error={
                errors?.owner_first_name?.type === 'required'
                  ? 'First Name is required'
                  : ''
              }
              validation={{
                required: true
              }}
              register={register}
              required
            />
            <TextField
              name="owner_last_name"
              label="Last Name"
              type="text"
              error={
                errors?.owner_last_name?.type === 'required'
                  ? 'Last Name is required'
                  : ''
              }
              validation={{
                required: true
              }}
              register={register}
              required
            />
            <TextField
              name="owner_email"
              label="Email"
              type="text"
              error={
                errors?.owner_email?.type === 'required'
                  ? 'Email is required'
                  : errors?.owner_email?.type === 'pattern'
                  ? 'Email is invalid'
                  : ''
              }
              validation={{
                pattern: emailRegex,
                required: true
              }}
              register={register}
              required
            />
            <TextField
              name="owner_dob"
              label="Date of Birth"
              type="date"
              error={
                errors?.owner_dob?.type === 'required'
                  ? 'Date of Birth is required'
                  : ''
              }
              validation={{
                required: true
              }}
              register={register}
              required
            />
            <TextField
              name="owner_phone"
              label="Phone Number"
              error={
                errors?.owner_phone?.type === 'required'
                  ? 'Phone is required'
                  : errors?.owner_phone?.type === 'pattern'
                  ? 'Phone is invalid'
                  : ''
              }
              validation={{
                required: true,
                pattern: phoneRegex
              }}
              register={register}
              required
            />
            <TextField
              name="owner_country"
              label="Country"
              type="text"
              error={
                errors?.owner_country?.type === 'required'
                  ? 'Country is required'
                  : ''
              }
              validation={{
                required: true
              }}
              register={register}
              required
            />
            <TextField
              name="owner_address"
              label="Address"
              type="text"
              error={
                errors?.owner_address?.type === 'required'
                  ? 'Address is required'
                  : ''
              }
              validation={{
                required: true
              }}
              register={register}
              required
            />
            <TextField
              name="owner_city"
              label="City"
              type="text"
              error={
                errors?.owner_city?.type === 'required'
                  ? 'City is required'
                  : ''
              }
              validation={{
                required: true
              }}
              register={register}
              required
            />

            <Controller
              name="owner_state"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <CustomSelectDropdown
                  hookForm={true}
                  field={field}
                  label="State"
                  placeholder="Choose state"
                  options={US_STATES}
                  error={
                    errors?.owner_state?.type === 'required'
                      ? 'State is required'
                      : ''
                  }
                />
              )}
            />
            <TextField
              name="owner_zip"
              label="Zip Code"
              type="text"
              error={
                errors?.owner_zip?.type === 'required'
                  ? 'Zip Code is required'
                  : ''
              }
              validation={{
                required: true
              }}
              register={register}
              required
            />
            <TextField
              name="owner_ssn"
              label="Social Security Number"
              type="text"
              error={
                errors?.owner_ssn?.type === 'required'
                  ? 'Social Security Number is required'
                  : ''
              }
              validation={{
                required: true
              }}
              register={register}
              required
            />
            <div className="mt_16">
              <label htmlFor="exampleFormControlInput1" className="label">
                Front Image of ID
              </label>
              <ImageDropzone
                image={ownerFrontImage}
                setImage={setOwnerFrontImage}
              />
            </div>
            <div className="mt_16">
              <label htmlFor="exampleFormControlInput1" className="label">
                Back Image of ID
              </label>
              <ImageDropzone
                image={ownerBackImage}
                setImage={setOwnerBackImage}
              />
            </div>

            <div className="mt_26"></div>
            <h2>
              <span className="ff_semi">Executive Information</span>
            </h2>
            <TextField
              name="executive_first_name"
              label="First Name"
              type="text"
              error={
                errors?.executive_first_name?.type === 'required'
                  ? 'First Name is required'
                  : ''
              }
              validation={{
                required: true
              }}
              register={register}
              required
            />
            <TextField
              name="executive_last_name"
              label="Last Name"
              type="text"
              error={
                errors?.executive_last_name?.type === 'required'
                  ? 'Last Name is required'
                  : ''
              }
              validation={{
                required: true
              }}
              register={register}
              required
            />
            <TextField
              name="executive_email"
              label="Email"
              type="text"
              error={
                errors?.executive_email?.type === 'required'
                  ? 'EMAIL is required'
                  : ''
              }
              validation={{
                pattern: emailRegex,
                required: true
              }}
              register={register}
              required
            />
            <TextField
              name="executive_dob"
              label="Date of Birth"
              type="date"
              error={
                errors?.executive_dob?.type === 'required'
                  ? 'Date of Birth is required'
                  : ''
              }
              validation={{
                required: true
              }}
              register={register}
              required
            />
            <TextField
              name="executive_phone"
              label="Phone Number"
              type="number"
              error={
                errors?.executive_phone?.type === 'required'
                  ? 'Phone Number is required'
                  : ''
              }
              validation={{
                required: true
              }}
              register={register}
              required
            />
            <TextField
              name="executive_country"
              label="Country"
              type="text"
              error={
                errors?.executive_country?.type === 'required'
                  ? 'Country is required'
                  : ''
              }
              validation={{
                required: true
              }}
              register={register}
              required
            />
            <TextField
              name="executive_address"
              label="Address"
              type="text"
              error={
                errors?.executive_address?.type === 'required'
                  ? 'Address is required'
                  : ''
              }
              validation={{
                required: true
              }}
              register={register}
              required
            />
            <TextField
              name="executive_city"
              label="City"
              type="text"
              error={
                errors?.executive_city?.type === 'required'
                  ? 'City is required'
                  : ''
              }
              validation={{
                required: true
              }}
              register={register}
              required
            />

            <Controller
              name="executive_state"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <CustomSelectDropdown
                  hookForm={true}
                  field={field}
                  label="State"
                  placeholder="Choose state"
                  options={US_STATES}
                  error={
                    errors?.executive_state?.type === 'required'
                      ? 'State is required'
                      : ''
                  }
                />
              )}
            />

            <TextField
              name="executive_zip"
              label="Zip Code"
              type="text"
              error={
                errors?.executive_zip?.type === 'required'
                  ? 'Zip Code is required'
                  : ''
              }
              validation={{
                required: true
              }}
              register={register}
              required
            />
            <TextField
              name="executive_ssn"
              label="Social Security Number"
              type="text"
              error={
                errors?.executive_ssn?.type === 'required'
                  ? 'Social Security Number is required'
                  : ''
              }
              validation={{
                required: true
              }}
              register={register}
              required
            />
            <div className="mt_16">
              <label htmlFor="exampleFormControlInput1" className="label">
                Front Image of ID
              </label>
              <ImageDropzone
                image={execFrontImage}
                setImage={setExecFrontImage}
              />
            </div>
            <div className="mt_16">
              <label htmlFor="exampleFormControlInput1" className="label">
                Back Image of ID
              </label>
              <ImageDropzone
                image={execBackImage}
                setImage={setExecBackImage}
              />
            </div>
          </RenderIf>

          <div className="mt_24"></div>
          <div className="onfooter">
            <div></div>
            <div className="flex item_vcenter">
              <Button
                type="submit"
                label="Save"
                color="bg-button"
                disabled={submitting}
              />
            </div>
          </div>
        </form>
      </div>
    </>
  );
};
