import React, { useState } from 'react';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import { Button } from 'components/button';
import { ModalComponent } from 'components/modal';
import { client } from 'utilities/db';
import { RenderIf } from 'utilities/helpers';

export const ImageCropUploader = ({ imageSrc, handleImage }) => {
  const [openModal, setOpenModal] = useState(false);
  const [image, setImage] = useState('');
  const [cropData, setCropData] = useState('#');
  const [cropper, setCropper] = useState();
  const [croppingImage, setCroppingImage] = useState(false);

  const onChange = (e) => {
    e.preventDefault();

    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    const reader = new FileReader();
    reader.onload = () => {
      setOpenModal(true);
      setImage(reader.result);
    };
    reader.readAsDataURL(files[0]);
  };

  const getCropData = () => {
    if (typeof cropper !== 'undefined') {
      setCroppingImage(true);
      setCropData(cropper.getCroppedCanvas().toDataURL());

      fetch(cropper.getCroppedCanvas().toDataURL())
        .then((res) => res.blob())
        .then(async (blob) => {
          const file = new File([blob], 'File name', { type: 'image/png' });

          const formData = new FormData();
          formData.append('image_url', file);
          formData.append('type', 'image');
          formData.append('filename', 'demo');

          const response = (await client.post('/upload', formData))?.data;

          handleImage(response.data?.[0]?.image_url);
          setCroppingImage(false);
          setOpenModal(false);
        })
        .catch((err) => {
          console.log(err);
          setCroppingImage(false);
        });
    }
  };

  return (
    <>
      <article className={`input_browse`} style={{ position: `relative` }}>
        <div className="flex item_hcenter text_center upload_content1">
          <RenderIf isTrue={!imageSrc}>
            <div>
              <h4 color="greylite">Drag &amp; drop to upload file</h4>
              <h4 color="greylite" className="mt_16">
                OR
              </h4>
              <Button
                type="button"
                className="button_browse mt_16"
                label={'Browse file'}
              />
            </div>
          </RenderIf>
          <RenderIf isTrue={imageSrc}>
            <div>
              <img
                src={imageSrc}
                alt="uploadedimage"
                height={'auto'}
                width={100}
              />
            </div>
          </RenderIf>
        </div>
        <input
          onChange={onChange}
          type="file"
          className={`browse_input`}
          // ref={imageRef}
        />
      </article>
      <ModalComponent modal={openModal} handleModal={() => setOpenModal(false)}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          <div style={{ width: '100%' }}>
            <br />
            <br />
            <Cropper
              style={{ height: 400, width: '100%' }}
              zoomTo={0.5}
              initialAspectRatio={1}
              preview=".img-preview"
              src={image}
              viewMode={1}
              minCropBoxHeight={10}
              minCropBoxWidth={10}
              background={false}
              responsive={true}
              autoCropArea={1}
              checkOrientation={false}
              onInitialized={(instance) => {
                setCropper(instance);
              }}
              guides={true}
            />
          </div>
          <br />
          <Button
            label={croppingImage ? 'Cropping...' : 'Crop Image'}
            color="bg-titary"
            onClick={getCropData}
            disabled={croppingImage}
          />
        </div>
      </ModalComponent>
    </>
  );
};
