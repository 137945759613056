import { client } from 'utilities/db';
import { useQuery } from 'react-query';

export const useFetchSds = (keyName, id) => {
  const { status, data, error, isFetching, isLoading, refetch } = useQuery(
    [keyName, id],
    () => getData(id),
    {
      enabled: !!id,
      refetchInterval: 60000
    }
  );

  return {
    sdsStatus: status,
    sdsData: data,
    sdsError: error,
    sdsFetching: isFetching,
    sdsLoading: isLoading,
    sdsRefetch: refetch
  };
};

const getData = async (id) => {
  let result = await client.get(`/sdsscreen/findAllByBranch/${id}`);
  result = result.data;
  result = result?.sdsscreens;
  return result;
};
