import { useEffect, useRef, useState } from 'react';
import { RenderIf } from 'utilities/helpers';
import { Ikon } from 'assets/icons';
import styles from './index.module.css';

export const ButtonDropdown = ({
  children,
  positionX = 'right',
  dropdownTitle = '',
  titleChildren,
  isSimple = false
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  return (
    <>
      <div className={styles.controler} ref={ref}>
        <RenderIf isTrue={dropdownTitle !== ''}>
          <button
            type="button"
            className="button button_radius"
            color="bg-primary"
            onClick={() => setIsOpen(!isOpen)}
          >
            {dropdownTitle}
          </button>
        </RenderIf>
        <RenderIf isTrue={titleChildren}>
          {isSimple ? (
            <button type="button" onClick={() => setIsOpen(!isOpen)}>
              {titleChildren}
            </button>
          ) : (
            <button
              type="button"
              className={`imglink ${styles.account}`}
              onClick={() => setIsOpen(!isOpen)}
            >
              <span>{titleChildren}</span>
            </button>
          )}
        </RenderIf>
        <RenderIf isTrue={dropdownTitle === '' && !titleChildren}>
          <button
            type="button"
            className={styles.button}
            onClick={() => setIsOpen(!isOpen)}
          >
            <Ikon name="dotmore" />
          </button>
        </RenderIf>
        <RenderIf isTrue={isOpen}>
          <div
            className={styles.dropdown}
            style={{
              left:
                positionX === 'right' || positionX === 'rzero'
                  ? 'auto'
                  : positionX === 'lzero'
                  ? '0px'
                  : '100%',
              right:
                positionX === 'left' || positionX === 'lzero'
                  ? 'auto'
                  : positionX === 'rzero'
                  ? '0px'
                  : '100%'
            }}
          >
            {children}
          </div>
        </RenderIf>
      </div>
    </>
  );
};
