import ProgressSteps from 'components/steps';
import { AddBrandForm } from 'pages/brands/add';

export const AddBrand = ({ onChangeStep }) => {
  return (
    <>
      <div className="onbox">
        <h1>
          <strong>Add Brand</strong>
        </h1>
        <h5 className="mt_4">About Brand</h5>
        <div className="mt_16"></div>
        <div className="w_368 center_auto">
          <ProgressSteps active={1} />
        </div>
        <div className="mt_16"></div>
        <AddBrandForm onChangeStep={onChangeStep} onboarding={true} />
      </div>
    </>
  );
};
