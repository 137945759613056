import { BuilderJSON } from 'store';
import { RenderIf } from 'utilities/helpers';
import { AsideContentItem } from '../..';
import { BuilderLayoutButton } from 'pages/storebuilder/components/forms/buttons/layout';
import { BuilderTextField } from 'pages/storebuilder/components/forms/input';
import { BuilderSwitchField } from 'pages/storebuilder/components/forms/switch';
import { BuilderTextareaField } from 'pages/storebuilder/components/forms/textarea';

export const FooterContent = () => {
  const {
    pages,
    setFooterLayout,
    setFooterShowLogo,
    setFooterLogoWidth,
    setFooterCopyright,
    setFooterEmail,
    setFooterRoundedCorners,
    setFooterContentInContainer,
    setFooterSocials
  } = BuilderJSON((state) => state);
  const { content } = pages?.footer;

  return (
    <>
      <AsideContentItem label={`Layout`}>
        <div className={`builder-layout-items`}>
          <div>
            <BuilderLayoutButton
              hasTextOnly={true}
              isActive={content?.layout === 1 ? true : false}
              handleOnClick={() => {
                setFooterLayout(1);
              }}
            >
              1
            </BuilderLayoutButton>
          </div>
          <div>
            {/* <BuilderLayoutButton 
              hasTextOnly={true}
              isActive={content?.layout === 2 ? true : false}
              handleOnClick={() => {
                setHeaderLayout(2);
              }}
            >
              2
            </BuilderLayoutButton> */}
          </div>
        </div>
      </AsideContentItem>
      <AsideContentItem label={`Copyright`}>
        <div className={`builder_items`}>
          <BuilderTextareaField
            label={`Copyright`}
            defaultValue={content?.copyright}
            onChange={(e) => {
              setFooterCopyright(e?.target?.value);
            }}
          />
        </div>
      </AsideContentItem>
      <AsideContentItem label={`Email address`}>
        <div className={`builder_items`}>
          <BuilderTextField
            type={`email`}
            label={`Email`}
            defaultValue={content?.email}
            onChange={(e) => {
              setFooterEmail(e?.target?.value);
            }}
          />
        </div>
      </AsideContentItem>
      <AsideContentItem label={`Logo`}>
        <div className={`builder_items`}>
          <BuilderSwitchField
            id={`LOGO_VISIBILITY`}
            label={`Show logo`}
            defaultChecked={content?.showLogo}
            onChange={() => {
              setFooterShowLogo();
            }}
          />
          <RenderIf isTrue={content?.showLogo}>
            <BuilderTextField
              type={`number`}
              label={`Logo width`}
              defaultValue={content?.logoWidth}
              onChange={(e) => {
                setFooterLogoWidth(e?.target?.value);
              }}
            />
          </RenderIf>
        </div>
      </AsideContentItem>
      <AsideContentItem label={`Container`}>
        <div className={`builder_items`}>
          <BuilderTextField
            type={`number`}
            label={`Cornors radius`}
            defaultValue={content?.roundedCorners}
            onChange={(e) => {
              setFooterRoundedCorners(e?.target?.value);
            }}
          />
          <BuilderSwitchField
            id={`FOO_CONTENT_IN_CONTAINER`}
            label={`Content container`}
            defaultChecked={content?.contentInContainer}
            onChange={() => {
              setFooterContentInContainer();
            }}
          />
        </div>
      </AsideContentItem>
      <AsideContentItem label={`Social Links`}>
        <div className={`builder_items`}>
          <BuilderSwitchField
            id={`FOO_SOCIALS_VISIBILITY`}
            label={`Show socials`}
            defaultChecked={content?.socials}
            onChange={() => {
              setFooterSocials();
            }}
          />
        </div>
      </AsideContentItem>
    </>
  );
};
