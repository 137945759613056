import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { BuilderJSON } from 'store';
import { RenderIf } from 'utilities/helpers';
import { BuilderTabs } from './tabs';
import { GlobalContent } from './global/content';
import { GlobalStyle } from './global/style';
import { HeaderContent } from './header/content';
import { HeaderStyle } from './header/style';
import { FooterContent } from './footer/content';
import { FooterStyle } from './footer/style';
import { ProductContent } from './product/content';
import { ProductStyle } from './product/style';
import { CarouselContent } from './carousel/content';
import { CarouselStyle } from './carousel/style';
import { FeaturedContent } from './featured/content';
import { FeaturedStyle } from './featured/style';
import { FigureContent } from './figure/content';
import { FigureStyle } from './figure/style';
import { Ikon } from 'assets/icons';
import styles from './index.module.css';
import { BuilderLayoutButton } from '../components/forms/buttons/layout';
import { BuilderTextField } from '../components/forms/input';
import { useStore } from 'store';
import { ModalComponent } from 'components/modal';
import { PrimaryButton } from 'components/button';

export const AsideBuilder = () => {
  const { selectedBuilderMode, setSelectedBuilderMode } = useStore(
    (state) => state
  );

  const {
    pages,
    setWebsiteBuilder,
    setAddProductSection,
    setAddCarouselSection,
    setAddFeaturedSection,
    setAddFigureSection,
    addRenderOrderItem,
    setRenderOrder
  } = BuilderJSON((state) => state);
  const renderOrder = pages?.landing?.renderOrder;
  const productSections = pages?.landing?.productSections;
  const carouselSections = pages?.landing?.carouselSections;
  const featuredSections = pages?.landing?.featuredSections;
  const figureSections = pages?.landing?.figureSections;
  const location = useLocation();
  const [isAddSection, setIsAddSection] = useState(false);
  const [sectionsOrder, setSectionsOrder] = useState(false);
  const [addSectionName, setAddSectionName] = useState(``);
  const [newSectionName, setNewSectionName] = useState(``);
  const [newSectionNameError, setNewSectionNameError] = useState(``);
  const [isAdvancedTheme, setIsAdvancedTheme] = useState(false);

  useEffect(() => {
    setIsAddSection(false);
    setSectionsOrder(false);
  }, []);

  useEffect(() => {
    if (location?.pathname?.includes(`online-store`)) {
      setWebsiteBuilder(true);
    }
  }, [location?.pathname, setWebsiteBuilder]);

  const handleAddNewSection = () => {
    const handleDuplicateSectionName = renderOrder?.filter(
      (it) => it?.title?.toLowerCase() === newSectionName?.toLowerCase()
    );
    if (handleDuplicateSectionName?.length > 0) {
      setNewSectionNameError(`Duplicate section name is not allowed.`);
    } else {
      if (addSectionName === `PRODUCT`) {
        setAddProductSection({
          title: newSectionName,
          content: {
            layout: 1,
            title: ``,
            description: ``,
            contentInContainer: false,
            products: [
              {
                media: `https://placehold.co/250x250.png`,
                title: `Carousel Section slide 1 main heading`,
                description: `Thanks for reading my blog! Happy coding!!!`,
                price: 10
              },
              {
                media: `https://placehold.co/250x250.png`,
                title: `Carousel Section slide 2 main heading`,
                description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.`,
                price: 10
              },
              {
                media: `https://placehold.co/250x250.png`,
                title: `Carousel Section slide 3 main heading`,
                description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.`,
                price: 10
              }
            ]
          },
          style: {
            backgroundColor: ``,
            backgroundGradient: ``,
            backgroundImage: ``,
            foregroundColor: ``,
            marginTop: 0,
            marginBottom: 0,
            paddingTop: 16,
            paddingBottom: 16
          }
        });
      }
      if (addSectionName === `CAROUSEL`) {
        setAddCarouselSection({
          title: newSectionName,
          content: {
            layout: 1,
            title: ``,
            description: ``,
            contentInContainer: false,
            contentOverlay: false,
            slides: [
              {
                media: `https://placehold.co/1600x750.png`,
                title: `${newSectionName} slide 1 main heading`,
                description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.`
              }
            ]
          },
          style: {
            backgroundColor: ``,
            backgroundGradient: ``,
            backgroundImage: ``,
            foregroundColor: ``,
            overlayBackgroundColor: `rgba(0, 0, 0, 1)`,
            overlayOpacity: 50,
            marginTop: 0,
            marginBottom: 0,
            paddingTop: 0,
            paddingBottom: 0
          }
        });
      }
      if (addSectionName === `FEATURED`) {
        setAddFeaturedSection({
          title: newSectionName,
          content: {
            layout: 1,
            title: `${newSectionName} main heading`,
            subTitle: `${newSectionName} sub heading`,
            description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.`,
            contentInContainer: true,
            slides: [
              {
                category: `Enter Category`,
                title: `${newSectionName} slide title`,
                description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.`,
                media: `https://placehold.co/400x400.png`
              }
            ]
          },
          style: {
            backgroundColor: ``,
            backgroundGradient: ``,
            backgroundImage: ``,
            foregroundColor: ``,
            marginTop: 0,
            marginBottom: 0,
            paddingTop: 71,
            paddingBottom: 71
          }
        });
      }
      if (addSectionName === `FIGURE`) {
        setAddFigureSection({
          title: newSectionName,
          content: {
            layout: 1,
            title: `${newSectionName} main heading`,
            description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.`,
            media: `https://placehold.co/1600x750.png`,
            contentInContainer: false,
            contentOverlay: false
          },
          style: {
            backgroundColor: ``,
            backgroundGradient: ``,
            backgroundImage: ``,
            foregroundColor: ``,
            overlayBackgroundColor: `rgba(0, 0, 0, 1)`,
            overlayOpacity: 50,
            marginTop: 0,
            marginBottom: 0,
            paddingTop: 0,
            paddingBottom: 0
          }
        });
      }
      addRenderOrderItem({
        id: `${renderOrder?.length}`,
        title: newSectionName
      });
      setIsAddSection(!isAddSection);
      setAddSectionName(``);
      setNewSectionName(``);
    }
  };

  const SectionComponent = ({ tit }) => {
    return (
      <article className={styles.articleOutline}>
        <div className={styles.dragableTitle}>{tit}</div>
        <div>
          <button type="button">
            <Ikon name={`drag`} fillColor={`var(--grey)`} />
          </button>
        </div>
      </article>
    );
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: 'none',
    padding: 0,
    margin: 0,
    background: isDragging ? 'transparent' : 'transparent',
    ...draggableStyle
  });

  const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? 'transparent' : 'transparent',
    padding: 0,
    width: '100%'
  });

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const newItems = reorder(
      renderOrder,
      result.source.index,
      result.destination.index
    );

    setRenderOrder(newItems);
  };

  return (
    <>
      <aside className={styles.aside}>
        <RenderIf isTrue={!isAddSection && !sectionsOrder}>
          <div className={styles.title}>Theme type</div>
          <div className={`builder-layout-items`}>
            <div>
              <BuilderLayoutButton
                hasTextOnly={true}
                isActive={selectedBuilderMode === `basic`}
                handleOnClick={() => {
                  setSelectedBuilderMode(`basic`);
                }}
              >
                BASIC
              </BuilderLayoutButton>
            </div>
            <div>
              <BuilderLayoutButton
                hasTextOnly={true}
                isActive={selectedBuilderMode === `advanced`}
                handleOnClick={() => {
                  setIsAdvancedTheme(true);
                }}
              >
                ADVANCED
              </BuilderLayoutButton>
            </div>
          </div>
          <div></div>
          <div className={styles.title}>Theme settings</div>
          <AsideItem label={`Global settings`} icon={`setting`}>
            <BuilderTabs
              tabs={[{ label: `Content` }, { label: `Style` }]}
              panels={[
                { content: <GlobalContent /> },
                { content: <GlobalStyle /> }
              ]}
            />
          </AsideItem>
          <AsideItem label={`Header`} icon={`item`}>
            <BuilderTabs
              tabs={[{ label: `Content` }, { label: `Style` }]}
              panels={[
                { content: <HeaderContent /> },
                { content: <HeaderStyle /> }
              ]}
            />
          </AsideItem>
          <AsideItem label={`Footer`} icon={`item`}>
            <BuilderTabs
              tabs={[{ label: `Content` }, { label: `Style` }]}
              panels={[
                { content: <FooterContent /> },
                { content: <FooterStyle /> }
              ]}
            />
          </AsideItem>
          <div></div>
          <div className={styles.title}>Home page sections</div>
          {renderOrder?.map((ord, index) => (
            <React.Fragment key={index}>
              <RenderIf isTrue={selectedBuilderMode === `advanced`}>
                {productSections
                  ?.filter((item) => item?.title === ord?.title)
                  ?.map((sec, i) => (
                    <AsideItem label={sec?.title} icon={`item`} key={i}>
                      <BuilderTabs
                        tabs={[{ label: `Content` }, { label: `Style` }]}
                        panels={[
                          { content: <ProductContent title={sec?.title} /> },
                          { content: <ProductStyle title={sec?.title} /> }
                        ]}
                      />
                    </AsideItem>
                  ))}
              </RenderIf>
              {carouselSections
                ?.filter((item) => item?.title === ord?.title)
                ?.map((sec, i) => (
                  <AsideItem label={sec?.title} icon={`item`} key={i}>
                    <BuilderTabs
                      tabs={[{ label: `Content` }, { label: `Style` }]}
                      panels={[
                        { content: <CarouselContent title={sec?.title} /> },
                        { content: <CarouselStyle title={sec?.title} /> }
                      ]}
                    />
                  </AsideItem>
                ))}

              {featuredSections
                ?.filter((item) => item?.title === ord?.title)
                ?.map((sec, i) => (
                  <AsideItem label={sec?.title} icon={`item`} key={i}>
                    <BuilderTabs
                      tabs={[{ label: `Content` }, { label: `Style` }]}
                      panels={[
                        { content: <FeaturedContent title={sec?.title} /> },
                        { content: <FeaturedStyle title={sec?.title} /> }
                      ]}
                    />
                  </AsideItem>
                ))}

              {figureSections
                ?.filter((item) => item?.title === ord?.title)
                ?.map((sec, i) => (
                  <AsideItem label={sec?.title} icon={`item`} key={i}>
                    <BuilderTabs
                      tabs={[{ label: `Content` }, { label: `Style` }]}
                      panels={[
                        { content: <FigureContent title={sec?.title} /> },
                        { content: <FigureStyle title={sec?.title} /> }
                      ]}
                    />
                  </AsideItem>
                ))}
            </React.Fragment>
          ))}
          <RenderIf isTrue={selectedBuilderMode === `advanced`}>
            <div>
              <button
                type={`button`}
                className={styles.primary}
                onClick={() => {
                  setIsAddSection(!isAddSection);
                }}
              >
                <Ikon name={`builder-plus`} fillColor={`var(--white)`} /> Add a
                section
              </button>
            </div>
            <div className={styles.linkCenter}>
              <button
                type={`button`}
                className={`builder-button-link`}
                onClick={() => {
                  setSectionsOrder(!sectionsOrder);
                }}
                color={`gray`}
              >
                <Ikon name={`item`} fillColor={`var(--grey)`} /> Sections order
                setting
              </button>
            </div>
          </RenderIf>{' '}
        </RenderIf>

        <RenderIf isTrue={isAddSection}>
          <h5>Choose section to add</h5>
          <BuilderLayoutButton
            hasAddItem={true}
            isActive={addSectionName === `PRODUCT` ? true : false}
            handleOnClick={() => {
              setAddSectionName(`PRODUCT`);
            }}
          >
            Add product section
          </BuilderLayoutButton>
          <BuilderLayoutButton
            hasAddItem={true}
            isActive={addSectionName === `CAROUSEL` ? true : false}
            handleOnClick={() => {
              setAddSectionName(`CAROUSEL`);
            }}
          >
            Add carousel section
          </BuilderLayoutButton>
          <BuilderLayoutButton
            hasAddItem={true}
            isActive={addSectionName === `FEATURED` ? true : false}
            handleOnClick={() => {
              setAddSectionName(`FEATURED`);
            }}
          >
            Add featured section
          </BuilderLayoutButton>
          <BuilderLayoutButton
            hasAddItem={true}
            isActive={addSectionName === `FIGURE` ? true : false}
            handleOnClick={() => {
              setAddSectionName(`FIGURE`);
            }}
          >
            Add figure section
          </BuilderLayoutButton>
          <BuilderTextField
            label={`Section name`}
            name={`sectionName`}
            placeholder={
              addSectionName === `PRODUCT`
                ? `Product section`
                : addSectionName === `CAROUSEL`
                ? `Carousel section`
                : addSectionName === `FEATURED`
                ? `Featured section`
                : addSectionName === `FIGURE`
                ? `Figure section`
                : `New section name`
            }
            onChange={(e) => {
              setNewSectionName(e?.target?.value);
            }}
            error={newSectionNameError}
          />
          <div></div>
          <div></div>
          <div>
            <button
              type={`button`}
              className={styles.primary}
              onClick={handleAddNewSection}
              disabled={
                addSectionName === `` || newSectionName === `` ? true : false
              }
            >
              Add section
            </button>
          </div>
          <div className={styles.linkCenter}>
            <button
              type={`button`}
              className={`builder-button-link`}
              onClick={() => {
                setIsAddSection(!isAddSection);
              }}
            >
              Cancel
            </button>
          </div>
        </RenderIf>
        <RenderIf isTrue={sectionsOrder}>
          <h5>Set sections order</h5>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided, snapshot) => {
                return (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={getListStyle(snapshot.isDraggingOver)}
                    className={styles.dragableList}
                  >
                    {renderOrder?.map((item, index) => (
                      <Draggable
                        key={item?.id}
                        draggableId={item?.id}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style
                            )}
                          >
                            <SectionComponent tit={item?.title} />
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                );
              }}
            </Droppable>
          </DragDropContext>
          <div>
            <button
              type={`button`}
              className={styles.primary}
              onClick={() => {
                setSectionsOrder(!sectionsOrder);
              }}
            >
              Done!
            </button>
          </div>
        </RenderIf>
      </aside>
      <ModalComponent
        modal={isAdvancedTheme}
        handleModal={() => {
          setIsAdvancedTheme(false);
        }}
      >
        <div className={styles.advanceModal}>
          <h4>You have pay 100 USD for this subscription</h4>
          <PrimaryButton
            onClick={() => {
              setIsAdvancedTheme(false);
              setSelectedBuilderMode(`advanced`);
            }}
          >
            Advanced
          </PrimaryButton>
        </div>
      </ModalComponent>
    </>
  );
};

export const AsideItem = ({ label, icon = ``, children }) => {
  const [itemActive, setItemActive] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (ref?.current && ref?.current.contains(e?.target)) {
        const scrollToRef = ref?.current?.title?.split(` `)?.join(`_`);
        const scrollToElement = document.getElementById(`${scrollToRef}`);
        scrollToElement &&
          scrollToElement?.scrollIntoView({
            behavior: 'smooth',
            block: 'center'
          });
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  return (
    <>
      <article className={styles.asideitem} ref={ref} title={label}>
        <button
          type={`button`}
          className={styles.itemhead}
          onClick={() => {
            setItemActive(!itemActive);
          }}
          style={{
            background: itemActive
              ? `rgba(var(--primary-rgb), 0.76)`
              : `rgba(var(--black-rgb), 0.08)`,
            color: itemActive ? `var(--white)` : `rgba(var(--black-rgb), 0.76)`
          }}
        >
          <div>
            <Ikon
              name={icon}
              fillColor={
                itemActive ? `var(--white)` : `rgba(var(--black-rgb), 0.76)`
              }
            />
            <label>{label}</label>
          </div>
          <Ikon
            name={itemActive ? `top-cert` : `bottom-cert`}
            fillColor={
              itemActive ? `var(--white)` : `rgba(var(--black-rgb), 0.76)`
            }
          />
        </button>
        <RenderIf isTrue={itemActive}>
          <div className={styles.itembody}>{children}</div>
        </RenderIf>
      </article>
    </>
  );
};

export const AsideContentItem = ({ label, children }) => {
  return (
    <>
      <div className={styles.content_item}>
        <RenderIf isTrue={label}>
          <div className={styles.content_item_title}>{label}</div>
        </RenderIf>
        <div className={styles.content_item_body}>{children}</div>
      </div>
    </>
  );
};
