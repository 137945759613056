import { Ikon } from 'assets/icons';
import styles from './index.module.css';

export const CardListOne = (props) => {
  const { title, children, handleEdit, handleDelete } = props;

  return (
    <>
      <article className={styles.box}>
        <div>
          <h5>
            <b>{title}</b>
          </h5>
          <div className="mt_10"></div>
          <p>{children}</p>
        </div>
        <div>
          <button className="alink" onClick={handleEdit}>
            <Ikon pencil />
          </button>
          <button className="alink" onClick={handleDelete}>
            <Ikon del />
          </button>
        </div>
      </article>
    </>
  );
};
