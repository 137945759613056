import { useForm } from 'react-hook-form';
import { RenderIf } from 'utilities/helpers';
import { TextField } from 'components/input';
import { Button } from 'components/button';
import { useNavigate } from 'react-router-dom';
import { CustomSelectDropdown } from 'components/select/multi';
import { useState } from 'react';
import { useMutation } from 'react-query';
import { client } from 'utilities/db';
import { getLocationId } from 'utilities/helpers/auth';
import { useEffect } from 'react';
import { Toasts } from 'components/toasts';

export const AddBankInformationForm = ({
  onChangeStep,
  handleModal,
  onGoToMenu,
  setData,
  onboarding = false
}) => {
  const locationId = getLocationId();
  const { mutate, isLoading, isSuccess, isError, error, data } = useMutation(
    (data) => {
      return client.post(`bankDetails/add`, data);
    }
  );
  const [companyType, setCompanyType] = useState([]);
  const navigate = useNavigate();
  const {
    handleSubmit,
    register,
    formState: { errors }
  } = useForm();
  const onManagerSubmit = (val) => {
    mutate({
      brand_id: locationId,
      company_name: val?.companyName,
      company_type: companyType?.[0]?.value,
      bank_account_number: val?.bankAccountNumber,
      account_holder_name: val?.accountName,
      routing_number: val?.routing
    });
  };

  useEffect(() => {
    if (!isSuccess) return;
    onChangeStep(3);
  }, [isSuccess]);

  useEffect(() => {
    if (!isError) return;
    Toasts.error(error?.response?.data?.message)
  }, [error]);

  return (
    <>
      <form onSubmit={handleSubmit(onManagerSubmit)}>
        <div>
          <TextField
            name="companyName"
            label={`Company's Name`}
            type="text"
            error={
              errors?.managerName?.type === 'required'
                ? 'Company name is required'
                : ''
            }
            placeholder={'ABC Corp'}
            validation={{
              required: true
            }}
            register={register}
            required
          />
          <div className="mt_16"></div>
          <label className="label">Company Type</label>
          <CustomSelectDropdown
            isMulti
            name="demo"
            placeholder="Choose options"
            options={
              companyType.length > 0
                ? []
                : [
                    { value: 'INDIVIDUAL', label: 'Individual' },
                    { value: 'BUSINESS', label: 'Business' }
                  ]
            }
            onChange={setCompanyType}
          />
          <div className="mt_16"></div>
          <TextField
            placeholder={'ABC Corp'}
            name="bankAccountNumber"
            label={`Bank Account Number`}
            type="text"
            error={
              errors?.managerPhone?.type === 'required'
                ? 'Bank account number is required'
                : ''
            }
            validation={{
              required: true
            }}
            register={register}
            required
          />
          <TextField
            placeholder={'ABC Corp'}
            name="accountName"
            label={`Account Holder's Name`}
            error={
              errors?.accountName?.type === 'required'
                ? 'account name is required'
                : ''
            }
            register={register}
            validation={{
              required: true
            }}
            required
          />
          <TextField
            placeholder={'6 to 11 digits'}
            name="routing"
            label={'Routing Number'}
            type="number"
            error={
              errors?.routing?.type === 'required'
                ? 'routing number is required'
                : ''
            }
            register={register}
            validation={{
              required: true
            }}
            required
          />
          <div className="mt_26"></div>
          <div className="onfooter">
            {/* <div>
              <RenderIf isTrue={onboarding}>
                <Button
                  type="button"
                  label={`I'll do this later`}
                  onClick={() => navigate('/')}
                  className="alink"
                  color="dark"
                  disabled={isLoading}
                />
              </RenderIf>
            </div> */}
            <div />
            <div className="flex item_vcenter">
              {/* <Button
                onClick={() => onChangeStep(1)}
                type="button"
                label={'Back'}
                color="outline-lite"
                disabled={isLoading}
              /> */}
              <Button
                disabled={companyType.length === 0}
                isLoading={isLoading}
                type="submit"
                label={'Next'}
                color="bg-button"
              />
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export const AddBankInformation = ({
  onChangeStep,
  onGoToMenu,
  handleModal,
  setData
}) => {
  return (
    <>
      <div className="box1">
        <h2>
          <strong>Add Brand</strong>
        </h2>
        <h5>Bank's Information</h5>
        <div className="mt_40"></div>
        <AddBankInformationForm
          handleModal={handleModal}
          onGoToMenu={onGoToMenu}
          setData={setData}
        />
      </div>
    </>
  );
};
