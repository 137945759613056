import { client } from 'utilities/db';
import { useQuery } from 'react-query';

export const useFetchRequestsAgainstCloudKitchen = (keyName, id) => {
  const { status, data, error, isFetching, isLoading, refetch } = useQuery(
    [keyName, id],
    () => getData(id),
    {
      enabled: !!id,
      refetchInterval: 60000
    }
  );

  return {
    cloudRequestStatus: status,
    cloudRequestData: data,
    cloudRequestError: error,
    cloudRequestFetching: isFetching,
    cloudRequestLoading: isLoading,
    cloudRequestRefetch: refetch
  };
};

const getData = async (id) => {
  let result = await client.get(`/cloudKitchen/getBrandBranches/${id}`);
  result = result.data;
  result = result?.cloudKitchenRestaurants;
  return result;
};
