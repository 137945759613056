import { useState } from 'react';
import { useStore } from 'store';
import { RenderIf } from 'utilities/helpers';
import { useFetchVendorsHook } from 'hooks/vendors/useFetchVendorsHook';
import { ManualInventoryPage } from '../manual';
import { ReceiptInventoryPage } from '../reciept';
import { RestaurantDepoInventoryPage } from '../restaurantdepo';
import { ReceiptDataPage } from '../reciept/addinventory';
import { PrimaryButton } from 'components/button';
import { Ikon } from 'assets/icons';
import styles from './index.module.css';

export const AddInventoryItemPage = () => {
  const { brand } = useStore((state) => state);
  const [addInventoryType, setAddInventoryType] = useState('');
  const [recieptData, setRecieptData] = useState(null);
  const [recieptId, setRecieptId] = useState(null);

  const { vendorData } = useFetchVendorsHook('vendors', brand?.id);

  return (
    <article className={styles.article}>
      <RenderIf isTrue={!recieptData}>
        <header className={styles.header}>
          <div>
            <h2>Add new Inventory Item</h2>
            <h5>We hope you're having a good day.</h5>
          </div>
          <div>
            <PrimaryButton onClick={() => {}}>Save & Publish</PrimaryButton>
          </div>
        </header>
        <div className={styles.chooseType}>
          <div>
            <AddInventoryCards
              name="document-text"
              heading={`Add New Inventory Item Manually`}
              description={`Please import your menu by populating this template.`}
              onClick={() => setAddInventoryType('manual')}
              isActive={addInventoryType === `manual` ? true : false}
            />
          </div>
          <div>
            <AddInventoryCards
              name="qr-code"
              heading={`Scan / Upload a Reciept`}
              description={`Create your ZEWST menu from scratch.`}
              onClick={() => setAddInventoryType('receipt')}
              isActive={addInventoryType === `receipt` ? true : false}
            />
          </div>

          <RenderIf
            isTrue={vendorData?.find(
              (vendor) => vendor?.vendor_type === 'RESTAURANT_DEPO'
            )}
          >
            <div>
              <AddInventoryCards
                name="document-arrow-down"
                heading={`Import From Restaurant Depot`}
                description={`Create your ZEWST menu from scratch.`}
                onClick={() => setAddInventoryType('restaurant-depo')}
                isActive={addInventoryType === `restaurant-depo` ? true : false}
              />
            </div>
          </RenderIf>
        </div>
        <RenderIf isTrue={addInventoryType === `manual`}>
          <ManualInventoryPage />
        </RenderIf>
        <RenderIf isTrue={addInventoryType === `receipt`}>
          <ReceiptInventoryPage
            setRecieptData={setRecieptData}
            recieptData={recieptData}
            setRecieptId={setRecieptId}
          />
        </RenderIf>
        <RenderIf isTrue={addInventoryType === `restaurant-depo`}>
          <RestaurantDepoInventoryPage setRecieptData={setRecieptData} />
        </RenderIf>
      </RenderIf>
      <RenderIf isTrue={recieptData}>
        <ReceiptDataPage
          recieptData={recieptData}
          setRecieptData={setRecieptData}
          recieptId={recieptId}
        />
      </RenderIf>
    </article>
  );
};

const AddInventoryCards = ({
  name,
  heading,
  description,
  onClick,
  isActive
}) => {
  return (
    <>
      <button
        className={
          isActive ? `${styles.typeButton} ${styles.active}` : styles.typeButton
        }
        onClick={onClick}
      >
        <div>
          <Ikon name={name} />
        </div>
        <div>
          <h6>{heading}</h6>
          <p>{description}</p>
        </div>
      </button>
    </>
  );
};
