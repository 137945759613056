import { useMutation, useQuery } from 'react-query';
import { client } from 'utilities/db';

export const useFetchSingleModifier = (id) => {
  const {
    isLoading,
    data = [],
    isSuccess,
    isError,
    refetch
  } = useQuery(
    ['fetchSingleModifier'],
    async () => {
      const data = await client.get(`/modifierGroup/findOne/${id}`);
      return data.data.modifierGroup;
    },
    {
      enable: !!id
    }
  );

  return {
    data,
    isLoading,
    isSuccess,
    isError,
    refetch
  };
};

export const useFetchModifiersByBranch = (id) => {
  const {
    isLoading,
    data = [],
    isSuccess,
    isError,
    refetch
  } = useQuery(
    ['fetchAllModifiersByBranch'],
    async () => {
      const data = await client.get(`/modifierGroup/findAllByBranch/${id}`);
      return data.data.modifierGroups;
    },
    {
      enable: !!id
    }
  );

  return {
    data,
    isLoading,
    isSuccess,
    isError,
    refetch
  };
};

export const useFetchModifiersByBrand = (id) => {
  const {
    isLoading,
    data = [],
    isSuccess,
    isError,
    refetch
  } = useQuery(
    ['fetchAllModifiersByBrand'],
    async () => {
      const data = await client.get(`/modifierGroup/findAllByBranch/${id}`);
      return data.data.modifierGroups;
    },
    {
      enable: !!id
    }
  );

  return {
    data,
    isLoading,
    isSuccess,
    isError,
    refetch
  };
};
