import { Button } from 'components/button';
import { TextField } from 'components/input';
import { ModalComponent } from 'components/modal';
import {
  useFetchKitchenPlanningDataByBranch,
  useFetchKitchenPlanningDataByBrand
} from 'hooks/kitchenPlanning/useKitchenPlanningHook';
import React, { useEffect, useState } from 'react';
import { useStore } from 'store';
import Stockitem from 'components/kitchenplanning/stockitem';
import Toprepare from 'components/kitchenplanning/toprepare';
import Foodwaste from 'components/kitchenplanning/foodwaste';

export default function KitchenPlanning() {
  const { branch } = useStore((state) => state);
  console.log(
    '🚀 ~ file: index.js ~ line 24 ~ KitchenPlanning ~ branch',
    branch
  );

  const {
    data = [],
    isLoading,
    refetch
  } = useFetchKitchenPlanningDataByBranch(branch?.id);
  console.log('lll', data);
  const {
    toPrepareRecipes = [],
    toPrepareMixtures = [],
    expiringMixtures = [],
    expiringProducts = [],
    expiringRecipes = []
  } = data;
  const [search, setSearch] = useState('');

  // aply discount
  const [applyDiscountModal, setApplyDiscountModal] = useState(false);
  const [appliedDiscountType, setAppliedDiscountType] = useState('Flat');
  const [disountPercentage, setDisountPercentage] = useState('');
  const [startDiscountPercentage, setStartDiscountPercentage] = useState('');
  const [endDiscountPercentage, setEndDiscountPercentage] = useState('');

  // new batch
  const [newBatchModal, setNewBatchModal] = useState(false);
  const [foodItems, setfoodItems] = useState([]);
  const [selectedFoodItem, setSelectedFoodItem] = useState({});
  const [numberOfServings, setNumberOfServings] = useState('');
  const [expiryDate, setexpiryDate] = useState('');
  const [applyDiscountToBatch, setApplyDiscountToBatch] = useState(false);
  const [newBatchDiscountType, setNewBatchDiscountType] = useState('Flat');
  const [newBatchDiscountPercentage, setNewBatchDiscountPercentage] =
    useState('');
  const [newBatchStartDiscountPercentage, setNewBatchStartDiscountPercentage] =
    useState('');
  const [newBatchEndDiscountPercentage, setNewBatchEndDiscountPercentage] =
    useState('');

  // override prediction

  const onCloseApplyDiscountModal = () => {
    setDisountPercentage('');
    setStartDiscountPercentage('');
    setEndDiscountPercentage('');
    setAppliedDiscountType('Flat');
    setApplyDiscountModal(false);
  };

  const onCloseNewBatchModal = () => {
    setNewBatchModal(false);
    setSelectedFoodItem({});
    setNumberOfServings('');
    setexpiryDate('');
    setApplyDiscountToBatch(false);
    setNewBatchDiscountType('Flat');
    setNewBatchDiscountPercentage('');
    setNewBatchStartDiscountPercentage('');
    setNewBatchEndDiscountPercentage('');
  };

  // TODO change this function
  const onApplyDiscount = () => {
    onCloseApplyDiscountModal();
  };

  // TODO change this function
  const onApplyNewBatch = () => {
    onCloseNewBatchModal();
  };

  // TODO change this function

  if (isLoading) {
    return <h1>Loading</h1>;
  }

  return (
    <div>
      <h1>Kitchen Planning</h1>
      <h5>We hope you’re having a good day.</h5>

      <Stockitem search={search} setSearch={setSearch} />
      <Toprepare
        toPrepareMixtures={toPrepareMixtures}
        toPrepareRecipes={toPrepareRecipes}
        newBatchModal={newBatchModal}
        setNewBatchModal={setNewBatchModal}
        refetch={refetch}
      />

      <Foodwaste
        expiringMixtures={expiringMixtures}
        expiringProducts={expiringProducts}
        expiringRecipes={expiringRecipes}
        setNewBatchModal={setNewBatchModal}
        refetch={refetch}
      />
    </div>
  );
}
