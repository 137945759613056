import { BuilderJSON } from 'store';
import { AsideContentItem } from '../..';
import { BuilderColorPicker } from 'pages/storebuilder/components/forms/colorpicker';
import { BuilderUploadImage } from 'pages/storebuilder/components/forms/upload';
import { BuilderTextField } from 'pages/storebuilder/components/forms/input';

export const FigureStyle = ({ title }) => {
  const {
    pages,
    setFigureSectionBackgroundColor,
    setFigureSectionBackgroundImage,
    setFigureSectionForegroundColor,
    setFigureSectionOverlayBackgroundColor,
    setFigureSectionOverlayOpacity,
    setFigureSectionMarginTop,
    setFigureSectionMarginBottom,
    setFigureSectionPaddingTop,
    setFigureSectionPaddingBottom
  } = BuilderJSON((state) => state);
  const { style } = pages?.landing?.figureSections?.filter(
    (sec) => sec?.title === title
  )[0];

  return (
    <>
      <AsideContentItem label={`Background`}>
        <div className={`builder_items`}>
          <BuilderColorPicker
            title={title}
            label={`Color`}
            activeColor={style?.backgroundColor}
            handleActiveColor={setFigureSectionBackgroundColor}
          />
          <BuilderUploadImage
            title={title}
            label={`Image`}
            mediaRecommendations={`Recommended dimensions are 1600 x 750, types are jpg, jpeg, png, gif or svg`}
            defaultImage={style?.backgroundImage}
            setImage={setFigureSectionBackgroundImage}
          />
        </div>
      </AsideContentItem>
      <AsideContentItem label={`Foreground`}>
        <div className={`builder_items`}>
          <BuilderColorPicker
            title={title}
            label={`Color`}
            activeColor={style?.foregroundColor}
            handleActiveColor={setFigureSectionForegroundColor}
          />
        </div>
      </AsideContentItem>
      <AsideContentItem label={`Overlay`}>
        <div className={`builder_items`}>
          <BuilderColorPicker
            title={title}
            label={`Color`}
            activeColor={style?.overlayBackgroundColor}
            handleActiveColor={setFigureSectionOverlayBackgroundColor}
          />
          <BuilderTextField
            type={`number`}
            label={`Opacity`}
            defaultValue={style?.overlayOpacity}
            onChange={(e) => {
              setFigureSectionOverlayOpacity(e?.target?.value, title);
            }}
          />
        </div>
      </AsideContentItem>
      <AsideContentItem label={`Section spaces`}>
        <div className={`builder_items`}>
          <BuilderTextField
            type={`number`}
            label={`Margin top`}
            defaultValue={style?.marginTop}
            onChange={(e) => {
              setFigureSectionMarginTop(e?.target?.value, title);
            }}
          />
          <BuilderTextField
            type={`number`}
            label={`Margin bottom`}
            defaultValue={style?.marginBottom}
            onChange={(e) => {
              setFigureSectionMarginBottom(e?.target?.value, title);
            }}
          />
          <BuilderTextField
            type={`number`}
            label={`Padding top`}
            defaultValue={style?.paddingTop}
            onChange={(e) => {
              setFigureSectionPaddingTop(e?.target?.value, title);
            }}
          />
          <BuilderTextField
            type={`number`}
            label={`Padding bottom`}
            defaultValue={style?.paddingBottom}
            onChange={(e) => {
              setFigureSectionPaddingBottom(e?.target?.value, title);
            }}
          />
        </div>
      </AsideContentItem>
    </>
  );
};
