import { useMutation, useQuery } from 'react-query';
import { client } from 'utilities/db';

export const useFetchRecipes = (branchId, brandId) => {
  const {
    isLoading,
    data = [],
    isSuccess,
    isError,
    refetch
  } = useQuery(
    ['fetchAllRecipes'],
    async () => {
      const data = await client.get(
        `/recipe/fetch-modifiers/${brandId}/${branchId}`
      );
      return data.data.modifiers;
    },
    {
      enable: !!branchId
    }
  );

  return {
    data,
    isLoading,
    isSuccess,
    isError,
    refetch
  };
};

export const useFetchRecipeProductGroups = (branchId) => {
  const {
    isLoading,
    data = [],
    isSuccess,
    isError,
    refetch
  } = useQuery(
    ['fetchAllRecipeProductGroups'],
    async () => {
      const data = await client.get(`/recipe/fetch-product-groups/${branchId}`);
      return data.data.groups;
    },
    {
      enable: !!branchId
    }
  );

  return {
    data,
    isLoading,
    isSuccess,
    isError,
    refetch
  };
};

export const useFetchRecipeProductCategories = (branchId) => {
  const {
    isLoading,
    data = [],
    isSuccess,
    isError,
    refetch
  } = useQuery(
    ['fetchAllRecipeProductCategories'],
    async () => {
      const data = await client.get(
        `/productCategories/findAllByBranch/${branchId}`
      );
      return data.data.productCategories;
    },
    {
      enable: !!branchId
    }
  );

  return {
    data,
    isLoading,
    isSuccess,
    isError,
    refetch
  };
};

export const useFetchRecipeMixtures = (branchId) => {
  const {
    isLoading,
    data = [],
    isSuccess,
    isError,
    refetch
  } = useQuery(
    ['fetchAllRecipeMixtures'],
    async () => {
      const data = await client.get(`/mixture/findAllByBranch/${branchId}`);
      return data.data.mixtures;
    },
    {
      enable: !!branchId
    }
  );

  return {
    data,
    isLoading,
    isSuccess,
    isError,
    refetch
  };
};

export const useFetchSingleRecipe = (id) => {
  const {
    isLoading,
    data = [],
    isSuccess,
    isError,
    refetch
  } = useQuery(
    ['fetchSingleRecipe'],
    async () => {
      const data = await client.get(`recipe/get-recipe-details/${id}`);
      return data.data;
    },
    {
      enable: !!id
    }
  );

  return {
    data,
    isLoading,
    isSuccess,
    isError,
    refetch
  };
};

export const useFetchSingleMixture = (id) => {
  const {
    isLoading,
    data = [],
    isSuccess,
    isError,
    refetch
  } = useQuery(
    ['fetchSingleMixtures'],
    async () => {
      const data = await client.get(`/mixture/getDetails/${id}`);
      return data.data?.mixture;
    },
    {
      enable: !!id
    }
  );

  return {
    data,
    isLoading,
    isSuccess,
    isError,
    refetch
  };
};

export const useDeleteRecipeIngredient = () => {
  const { mutate, isLoading, isSuccess, isError, error } = useMutation((id) => {
    return client.post(`/recipe/delete-ingredients/${id}`);
  });
  return {
    isLoading,
    isSuccess,
    isError,
    error,
    mutate
  };
};

export const useDeleteMixtureIngredient = () => {
  const { mutate, isLoading, isSuccess, isError, error } = useMutation((id) => {
    return client.post(`/mixture/delete-ingredients/${id}`);
  });
  return {
    isLoading,
    isSuccess,
    isError,
    error,
    mutate
  };
};
