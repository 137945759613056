import { ModalComponent } from 'components/modal';
import { Ikon } from 'assets/icons';
import styles from './index.module.css';

export const AddVendorType = ({
  addVendorType,
  setAddVendorType,
  setAdditionType
}) => {
  return (
    <>
      <ModalComponent
        modal={addVendorType}
        handleModal={() => setAddVendorType(!addVendorType)}
        chooseOption={true}
      >
        <div className={styles.box}>
          <h1>
            <strong>Add Vendor</strong>
          </h1>
          <div className="mt_4"></div>
          <h6>About Vendor</h6>
          <button
            className={styles.buttonChOpt}
            onClick={() => {
              setAdditionType('restaurant-depot');
              setAddVendorType(false);
            }}
          >
            <div>
              <h4>
                <span className="ff_semi">New Restaurant Depot</span>
              </h4>
              <p className="mt_4">New Restaurant Depot</p>
            </div>
            <div>
              <Ikon linkArrow />
            </div>
          </button>
          <button
            className={styles.buttonChOpt}
            onClick={() => {
              setAdditionType('local');
              setAddVendorType(false);
            }}
          >
            <div>
              <h4>
                <span className="ff_semi">New Local Vendor</span>
              </h4>
              <p className="mt_4">New Local Vendor</p>
            </div>
            <div>
              <Ikon linkArrow />
            </div>
          </button>
          <div className="mt_35"></div>
          <div className="flex item_hend">
            <button
              className="button button_radius"
              color="outline-lite"
              onClick={() => setAddVendorType(false)}
            >
              Cancel
            </button>
          </div>
        </div>
      </ModalComponent>
    </>
  );
};
