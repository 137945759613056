import { client } from 'utilities/db';
import { useQuery } from 'react-query';

export const useFetchProductsGroupHook = (keyName, id) => {
  const { status, data, error, isFetching, isLoading, refetch } = useQuery(
    [keyName, id],
    () => getProductGroup(id),
    {
      nabled: !!id,
      refetchInterval: 60000
    }
  );

  return {
    productGroupStatus: status,
    productGroupData: data,
    productGroupError: error,
    productGroupFetching: isFetching,
    productGroupLoading: isLoading,
    productGroupRefetch: refetch
  };
};

const getProductGroup = async (id) => {
  let result = await client.get(`/productGroup/findAllByBranch/${id}`);
  result = result.data;
  result = result?.productGroups;

  return result;
};
