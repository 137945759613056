import React from 'react';
import { TextField } from 'components/input';
import styles from './index.module.css';
import { Button } from 'components/button';
import img from '../../../assets/Images/Img.png';
export default () => {
  return (
    <>
      <div className="mt_32"></div>
      <div className="flex item_vcenter item_hend">
        <div className="mr_30">
          <Button
            onClick={() => null}
            label={'View Inventory'}
            color="bg-button"
          />
        </div>
        <input
          type="date"
          id="birthday"
          name="birthday"
          className={styles.date_picker}
        />
      </div>
      <div className="mt_24"></div>
      <div className="shadow_box1">
        <div className="flex item_vcenter item_space_btn">
          <h4>
            <span className="ff_semi">Predictions</span>
          </h4>
          <TextField
            name="search"
            id="search"
            type="text"
            placeholder="search"
            // onChange={(e) => setSearch(e.target.value)}
          />
        </div>

        <div>
          <div className={`${styles.item_3}`}>
            <div className="flex item_vcenter item_space_btn">
              <div className="flex item_vcenter">
                <picture>
                  <source srcSet={img} />
                  <img src={img} alt="" />
                </picture>
                <div>
                  <span className="ff_semi">Bryaani</span>
                </div>
              </div>
              <div>
                <div className="f12">
                  <span className="ff_semi" color="tableTh">
                    Date
                  </span>
                </div>
                <div className="f12">
                  <span className="ff_semi">25 May - 27 May</span>
                </div>
              </div>
            </div>
          </div>
          <div className={`${styles.item_3}`}>
            <div className="flex item_vcenter item_space_btn">
              <div className="flex item_vcenter">
                <picture>
                  <source srcSet={img} />
                  <img src={img} alt="" />
                </picture>
                <div>
                  <span className="ff_semi">Bryaani</span>
                </div>
              </div>
              <div>
                <div className="f12">
                  <span className="ff_semi" color="tableTh">
                    Date
                  </span>
                </div>
                <div className="f12">
                  <span className="ff_semi">25 May - 27 May</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
