import { BuilderJSON } from 'store';
import { RenderIf } from 'utilities/helpers';
import styles from './index.module.css';

export const BuilderThemeHomeFigure = ({ title }) => {
  const { pages, screenView } = BuilderJSON((state) => state);
  const { content, style } = pages?.landing?.figureSections?.filter(
    (sec) => sec?.title === title
  )[0];

  return (
    <>
      <article
        id={title?.split(` `)?.join(`_`)}
        className={styles.article}
        style={{
          backgroundColor: style?.backgroundColor,
          backgroundImage: `url(${style?.backgroundImage})`,
          color: style?.foregroundColor,
          marginTop: `${style?.marginTop}px`,
          marginBottom: `${style?.marginBottom}px`,
          paddingTop: `${style?.paddingTop}px`,
          paddingBottom: `${style?.paddingBottom}px`
        }}
      >
        <div
          className={content?.contentInContainer ? `contBx` : ``}
          style={{
            paddingLeft:
              screenView === `WEB_VIEW` && content?.contentInContainer
                ? `var(--builder-theme-horizontal-padding)`
                : screenView !== `WEB_VIEW` && content?.contentInContainer
                ? `16px`
                : ``,
            paddingRight:
              screenView === `WEB_VIEW` && content?.contentInContainer
                ? `var(--builder-theme-horizontal-padding)`
                : screenView !== `WEB_VIEW` && content?.contentInContainer
                ? `16px`
                : ``
          }}
        >
          <div
            className={content?.layout === 1 ? styles.layout1 : styles.layout2}
            style={{ color: style?.foregroundColor }}
          >
            <RenderIf isTrue={content?.media}>
              <picture>
                <source srcSet={content?.media} />
                <img src={content?.media} alt={content?.title} />
              </picture>
            </RenderIf>
            <RenderIf isTrue={content?.contentOverlay}>
              <div
                className={styles.overlay}
                style={{
                  background: style?.overlayBackgroundColor,
                  opacity: style?.overlayOpacity / 100
                }}
              ></div>
            </RenderIf>
            <div className={styles.content}>
              <div
                className={!content?.contentInContainer ? `contBx` : ``}
                style={{
                  paddingLeft:
                    screenView === `WEB_VIEW` && !content?.contentInContainer
                      ? `var(--builder-theme-horizontal-padding)`
                      : screenView !== `WEB_VIEW` &&
                        !content?.contentInContainer
                      ? `16px`
                      : ``,
                  paddingRight:
                    screenView === `WEB_VIEW` && !content?.contentInContainer
                      ? `var(--builder-theme-horizontal-padding)`
                      : screenView !== `WEB_VIEW` &&
                        !content?.contentInContainer
                      ? `16px`
                      : ``
                }}
              >
                <div className={styles.contentInner}>
                  <RenderIf isTrue={content?.title}>
                    <h1 style={{ whiteSpace: `pre-line` }}>{content?.title}</h1>
                  </RenderIf>
                  <RenderIf isTrue={content?.description}>
                    <p style={{ whiteSpace: `pre-line` }}>
                      {content?.description}
                    </p>
                  </RenderIf>
                </div>
              </div>
            </div>
          </div>
        </div>
      </article>
    </>
  );
};
