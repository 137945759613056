import { useState, useMemo } from 'react';
import axios from 'axios';
import { useForm, Controller } from 'react-hook-form';
import { useMutation } from 'react-query';
import { client } from 'utilities/db';
import { RenderIf } from 'utilities/helpers';
import { useFetchVendorsHook } from 'hooks/vendors/useFetchVendorsHook';
import { CustomSelectDropdown } from 'components/select/multi';
import { Button } from 'components/button';
import { Toasts } from 'components/toasts';
import ImageDropzone from 'components/imageDropzone/imageDropzone';
import { useStore } from 'store';
import { Loading } from 'components/loading';
import { useNavigate } from 'react-router-dom';
import styles from './index.module.css';
import { Ikon } from 'assets/icons';

export const ReceiptInventoryPage = ({
  setRecieptData,
  recieptData,
  setRecieptId
}) => {
  const { brand } = useStore((state) => state);
  const user_id = JSON.parse(localStorage.getItem('user'))?.id;
  const navigate = useNavigate();

  const [recieptImage, setRecieptImage] = useState(null);
  const [uploadingReciept, setUploadingReciept] = useState(false);

  const { vendorData, vendorLoading } = useFetchVendorsHook(
    'vendors',
    brand?.id
  );

  const { mutate, isError, error, isSuccess, data } = useMutation((invData) => {
    return client.post('/inventoryReceipt/uploadReciept', invData);
  });

  const {
    handleSubmit,
    formState: { errors },
    control
  } = useForm({
    defaultValues: {
      vendor: null
    }
  });

  const handleImageUpload = async (e) => {
    const file = e.target.files[0];
    let formData = new FormData();
    formData.append('image_url', file);
    formData.append('type', 'image');
    formData.append('filename', 'demo');

    const config = {
      headers: {
        'content-type': 'multipart/form-data'
      }
    };

    const response = (
      await axios.post(
        `${process.env.REACT_APP_API_URL}/upload`,
        formData,
        config
      )
    )?.data;
    const imageUrl = response?.data?.[0]?.image_url;
    setRecieptImage(imageUrl);
  };

  const handleAddReceiptInventory = async (data) => {
    setUploadingReciept(true);
    mutate({
      reciept_url: recieptImage,
      vendor_id: data?.vendor?.value,
      user_id: user_id
    });
  };

  const getRecipeptData = async (id) => {
    try {
      const response = await client.get(
        `/inventoryReceipt/getRecieptItems/${id}`
      );
      if (
        response?.data?.inventoryReceipt?.InventoryReceiptItemModel?.length > 0
      ) {
        setRecieptData(response?.data?.inventoryReceipt);
      } else {
        Toasts.error('No items found in the reciept');
      }
    } catch (error) {
      setUploadingReciept(false);
    }
  };

  useMemo(() => {
    if (isSuccess) {
      let recieptId = data?.data?.reciept_id;
      setTimeout(() => {
        getRecipeptData(recieptId);
        setRecieptId(recieptId);
        setUploadingReciept(false);
      }, 10000);
    }
  }, [isSuccess, data]);

  useMemo(() => {
    if (isError) {
      Toasts.error(error?.response?.data?.message);
      setUploadingReciept(false);
    }
  }, [isError, error]);

  return (
    <>
      <RenderIf isTrue={!uploadingReciept && !recieptData}>
        <form onSubmit={handleSubmit(handleAddReceiptInventory)}>
          <div className={styles.bx}>
            <div className={styles.row}>
              <div>
                <ImageDropzone
                  customComponent={
                    <>
                      <div className={styles.uploadBx}>
                        <div>
                          <Ikon name={`upload-file`} fillColor={``} />
                        </div>
                        <div>Drag &amp; Drop to Upload File</div>
                      </div>
                      <div className={styles.uploadContent}>
                        <div>
                          <h6>Upload your receipt</h6>
                          <p>
                            Works with any .JPG, .PNG, or .GIF file from the web
                          </p>
                        </div>
                        <div>
                          <Button
                            type="button"
                            className="button_browse"
                            label={'Browse'}
                          />
                        </div>
                      </div>
                    </>
                  }
                  setIsLoading={setUploadingReciept}
                  image={recieptImage}
                  setImage={setRecieptImage}
                  handleImageUpload={handleImageUpload}
                />
              </div>
              <div>
                <Controller
                  name="vendor"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <CustomSelectDropdown
                      hookForm={true}
                      field={field}
                      label="Vendor"
                      placeholder="Choose options"
                      options={vendorData?.map((vendor) => ({
                        value: vendor.id,
                        label:
                          vendor?.vendor_information?.name ||
                          vendor?.vendor_information?.email
                      }))}
                      error={
                        errors?.vendor?.type === 'required'
                          ? 'Vendor is required'
                          : ''
                      }
                      isLoading={vendorLoading}
                    />
                  )}
                />
              </div>
            </div>
            <div className={styles.footer}>
              <Button
                onClick={() => {
                  navigate(`/inventory/listing`);
                }}
                color="outline-lite"
                label={'Cancel'}
              />
              <Button
                label="Continue"
                color="bg-primary"
                type="submit"
                disabled={!recieptImage}
              />
            </div>
          </div>
        </form>
      </RenderIf>
      <RenderIf isTrue={uploadingReciept && !recieptData}>
        <div className={`loading_full`}>
          <Loading />
        </div>
      </RenderIf>
    </>
  );
};
