import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation } from 'react-query';
import { client } from 'utilities/db';
import { getBranchId, getLocationId, getUser } from 'utilities/helpers/auth';
import { useFetchColors } from 'hooks/resourceHooks';
import {
  useFetchRecipeMixtures,
  useFetchRecipes
} from 'hooks/recipes/useRecipesHook';
import { ThemeTabs } from 'components/tabs';
import { ButtonDropdown } from 'components/select/button';
import { TextField } from 'components/input';
import { Toasts } from 'components/toasts';
import { Ikon } from 'assets/icons';
import styles from './index.module.css';
import { RenderIf } from 'utilities/helpers';
import { PrimaryButton } from 'components/button';
import ImgEmptyRecipe from 'assets/Images/empty_recipe.png';

export const EngineeringRecipe = ({ onChangeStep, onChangeModifiers }) => {
  const locationId = getLocationId();
  const branchId = getBranchId();
  const [searchField, setSearchField] = useState('');
  const { mutate, isLoading, isSuccess, isError, error, data } = useMutation(
    (userData) => {
      return client.post(
        isEdit ? `/category/update/${selectedCategory?.id}` : `/category/add`,
        userData
      );
    }
  );
  const { data: colorsList } = useFetchColors();
  const {
    refetch: refetchMenu,
    isLoading: menuIsLoading,
    error: menuError,
    data: recipesData = [],
    isSuccess: menuIsSuccess
  } = useFetchRecipes(branchId, locationId);
  const { data: mixturesData } = useFetchRecipeMixtures(branchId);
  const navigate = useNavigate();
  const user = getUser();
  const [selectedTypes, setselectedTypes] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [categoryText, setCategoryText] = useState('');
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [uploadImage, setUploadImage] = useState('');
  const [isActivate, setIsActivate] = useState(true);
  const [selectedCategory, setselectedCategory] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const [linkedResturant, setLinkedResturant] = useState([]);

  useEffect(() => {
    const types = [...categoryOptions];
    types.push({
      name: categoryText,
      id: categoryText
    });
    setCategoryOptions(types);
  }, [categoryText]);

  useEffect(() => {
    if (!isSuccess) return;
    refetchMenu();
    onClose();
    setLinkedResturant([]);
  }, [isSuccess]);

  useEffect(() => {
    if (!user?.id) {
      navigate('/login');
      return;
    }
  }, [user]);

  const onEditClick = (val) => {
    // setIsEdit(true);
    // setselectedCategory(val);
    // setAdditionType('add');
    // setIsOpenModal(true);
    // setIsActivate(val?.is_active);
    // setLinkedResturant(
    //   brands
    //     ?.filter((item) => item.id === val?.brand_id)
    //     ?.map((item) => ({ label: item?.name, value: item?.id }))
    // );
  };
  const handleLogout = () => {
    localStorage.clear();
    window.location.href = '/';
  };

  const addMenu = (val) => {
    mutate({
      name: val?.name,
      brand_id: locationId,
      is_deleted: false,
      is_active: isActivate,
      color_id: 1,
      parent_id: null,
      image: uploadImage,
      description: val?.description,
      category_types: selectedTypes?.map((item) => ({
        name: item?.label,
        description: item?.label
      })),
      branch_ids:
        linkedResturant.length > 0
          ? linkedResturant.map((item) => item.value)
          : null,
      category_level: linkedResturant.length > 0 ? 'BRANCH' : 'BRAND'
    });
  };

  const onClose = () => {
    setIsOpenModal(false);
    setselectedCategory({});
    setIsEdit(false);
    setIsActivate(true);
    setLinkedResturant([]);
  };

  const panelRecipes = () => {
    return (
      <>
        <RenderIf isTrue={recipesData
        ?.filter((item) =>
          item?.MenuItemModel?.menu_item_name
            ?.toLowerCase()
            ?.includes(searchField?.toLowerCase())
        )?.length === 0}>
          <div style={{ marginTop: 24, }}></div>
          <div className={styles.emptyCard}>
            <h2>You currently have no items here</h2>
            <picture>
              <source srcSet={ImgEmptyRecipe} />
              <img src={ImgEmptyRecipe} alt='No Recipe' />
            </picture>
            <PrimaryButton onClick={() => {onChangeStep(4);}}>Add recipe</PrimaryButton>
          </div>
          <div style={{ marginTop: 24, }}></div>
        </RenderIf>
        <RenderIf isTrue={recipesData
        ?.filter((item) =>
          item?.MenuItemModel?.menu_item_name
            ?.toLowerCase()
            ?.includes(searchField?.toLowerCase())
        )?.length > 0}>
          <div className={styles.grid}>
            {recipesData
            ?.filter((item) =>
              item?.MenuItemModel?.menu_item_name
                ?.toLowerCase()
                ?.includes(searchField?.toLowerCase())
            )
            ?.map((item, i) => (
              <div key={i} className={styles.card}>
                <div>
                  <div>
                    <div className={styles.cardIcon}>
                      {item?.MenuItemModel?.menu_item_name?.split(' ')?.[0]?.charAt(0)}
                      {item?.MenuItemModel?.menu_item_name?.split(' ')?.[1]?.charAt(0)}
                    </div>
                    <h4 onClick={() => onEditClick(item)}>
                      <span className="ff_semi">
                        {item?.MenuItemModel?.menu_item_name}
                        {item?.modifier_name !== 'BASE'
                          ? ' - ' + item?.modifier_name
                          : ''}
                      </span>
                    </h4>
                  </div>
                  <div>
                    <p>Cost: {item?.additional_price}</p>
                    <h6 className="mt_8">{item?.modifier_description}</h6>
                  </div>
                </div>
                <div>
                  <button
                    onClick={() => {
                      if (item?.RecipeModel?.id) {
                        navigate(
                          `/recipe-engineering/recipe/detail/${item?.RecipeModel?.id}`
                        );
                        return;
                      }
                      onChangeStep(item?.RecipeModel?.id ? 3 : 2);
                      onChangeModifiers(item);
                    }}
                  >
                    <Ikon name="eye-view" />
                    {item?.RecipeModel?.id ? 'View details' : 'Add recipe'}
                  </button>
                </div>
              </div>
            ))}
            <button className={styles.card} onClick={(e) => {onChangeStep(4);}}>
              <div className={styles.hvCenter}>
                <div>
                  <div className={styles.cardPlusIcon}>
                    <Ikon name={`add-plus`} />
                  </div>
                </div>
              </div>
              <div>
                <div className={styles.asButton}>
                  Add recipe
                </div>
              </div>
            </button>
          </div>
        </RenderIf>
      </>
    );
  };

  const panelMixture = () => {
    return (
      <>
        <RenderIf isTrue={mixturesData
          ?.filter((item) =>
            item?.mixture_name
              ?.toLowerCase()
              ?.includes(searchField?.toLowerCase())
          )?.length === 0}>
          <div style={{ marginTop: 24, }}></div>
          <div className={styles.emptyCard}>
            <h2>You currently have no items here</h2>
            <picture>
              <source srcSet={ImgEmptyRecipe} />
              <img src={ImgEmptyRecipe} alt='No Mixture' />
            </picture>
            <PrimaryButton onClick={() => {onChangeStep(4);}}>Add mixture</PrimaryButton>
          </div>
          <div style={{ marginTop: 24, }}></div>
        </RenderIf>
        <RenderIf isTrue={mixturesData
          ?.filter((item) =>
            item?.mixture_name
              ?.toLowerCase()
              ?.includes(searchField?.toLowerCase())
          )?.length > 0}>
          <div className={styles.grid}>
          {mixturesData
            ?.filter((item) =>
              item?.mixture_name
                ?.toLowerCase()
                ?.includes(searchField?.toLowerCase())
            )
            ?.map((item, i) => (
              <div key={i} className={styles.card}>
                <div>
                  <div>
                    <div className={styles.cardIcon}>
                      {item?.mixture_name?.split(' ')?.[0]?.charAt(0)}
                      {item?.mixture_name?.split(' ')?.[1]?.charAt(0)}
                    </div>
                    <h4 onClick={() => onEditClick(item)}>
                      <span className="ff_semi">{item?.mixture_name}</span>
                    </h4>
                  </div>
                </div>
                <div>
                  <button
                    onClick={() => {
                      if (item?.id) {
                        navigate(
                          `/recipe-engineering/mixture/detail/${item?.id}`
                        );
                        return;
                      }
                    }}
                  >
                    <Ikon name="eye-view" />
                    {'View details'}
                  </button>
                </div>
              </div>
            ))}
            <button className={styles.card} onClick={(e) => {onChangeStep(4);}}>
              <div className={styles.hvCenter}>
                <div>
                  <div className={styles.cardPlusIcon}>
                    <Ikon name={`add-plus`} />
                  </div>
                </div>
              </div>
              <div>
                <div className={styles.asButton}>
                  Add mixture
                </div>
              </div>
            </button>
          </div>
        </RenderIf>
      </>
    );
  };

  useEffect(() => {
    if (!isError) return;
    Toasts.error(
      error?.response?.data?.message ||
        'Some thing went wrong please try again later'
    );
  }, [isError]);

  return (
    <>
      <div className="mt_40"></div>
      <div className="flex item_space_btn pt_10">
        <div>
          <h1>
            <strong>Recipe engineering</strong>
          </h1>
          <h5 className="mt_4">Manage and create recipes here.</h5>
        </div>

        <div>
          <ButtonDropdown dropdownTitle="Add recipe" positionX="rzero">
            <div className="bdd1 width_216">
              <button
                type="button"
                className="bdd_item"
                onClick={(e) => onChangeStep(4)}
              >
                Mixture
              </button>
            </div>
          </ButtonDropdown>
        </div>
      </div>
      <div className="mt_32"></div>
      <ThemeTabs
        tabs={[{ label: 'Recipes' }, { label: 'Mixture' }]}
        panels={[{ content: panelRecipes() }, { content: panelMixture() }]}
        isSearch={
          <TextField
            placeholder="Search"
            onChange={(e) => setSearchField(e?.target?.value)}
            value={searchField}
          />
        }
      />
      <div className="mt_40"></div>
    </>
  );
};
