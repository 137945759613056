import { useMutation, useQuery } from 'react-query';
import { client } from 'utilities/db';

export const useMutateBrand = () => {
  const { mutate, isLoading, isSuccess, isError, error, data } = useMutation(
    (userData) => {
      return client.post(`/brand/add`, userData);
    }
  );

  return {
    mutate,
    isLoading,
    isSuccess,
    isError,
    error,
    data
  };
};

export const useFetchSingleBrand = (id) => {
  const {
    isLoading,
    data = [],
    isSuccess,
    isError,
    refetch
  } = useQuery(
    ['fetchAllBrands'],
    async () => {
      const data = await client.get(`/brand/findOne/${id}`);
      return data.data.brandDetails || data?.data?.brand;
    },
    {
      enable: !!id
    }
  );

  return {
    data,
    isLoading,
    isSuccess,
    isError,
    refetch
  };
};
