import { BuilderJSON } from 'store';
import { AsideContentItem } from '../..';
import { BuilderColorPicker } from 'pages/storebuilder/components/forms/colorpicker';
import { BuilderUploadImage } from 'pages/storebuilder/components/forms/upload';
import { BuilderTextField } from 'pages/storebuilder/components/forms/input';

export const HeaderStyle = () => {
  const {
    pages,
    setHeaderBackgroundColor,
    setHeaderBackgroundImage,
    setHeaderForegroundColor,
    setHeaderMarginTop,
    setHeaderMarginBottom,
    setHeaderPaddingTop,
    setHeaderPaddingBottom
  } = BuilderJSON((state) => state);
  const { style } = pages?.header;

  return (
    <>
      <AsideContentItem label={`Background`}>
        <div className={`builder_items`}>
          <BuilderColorPicker
            label={`Color`}
            activeColor={style?.backgroundColor}
            handleActiveColor={setHeaderBackgroundColor}
          />
          <BuilderUploadImage
            label={`Image`}
            mediaRecommendations={`Recommended dimensions are 1600 x 140, types are jpg, jpeg, png, gif or svg`}
            defaultImage={style?.backgroundImage}
            setImage={setHeaderBackgroundImage}
          />
        </div>
      </AsideContentItem>
      <AsideContentItem label={`Foreground`}>
        <div className={`builder_items`}>
          <BuilderColorPicker
            label={`Color`}
            activeColor={style?.foregroundColor}
            handleActiveColor={setHeaderForegroundColor}
          />
        </div>
      </AsideContentItem>
      <AsideContentItem label={`Section spaces`}>
        <div className={`builder_items`}>
          <BuilderTextField
            type={`number`}
            label={`Margin top`}
            defaultValue={style?.marginTop}
            onChange={(e) => {
              setHeaderMarginTop(e?.target?.value);
            }}
          />
          <BuilderTextField
            type={`number`}
            label={`Margin bottom`}
            defaultValue={style?.marginBottom}
            onChange={(e) => {
              setHeaderMarginBottom(e?.target?.value);
            }}
          />
          <BuilderTextField
            type={`number`}
            label={`Padding top`}
            defaultValue={style?.paddingTop}
            onChange={(e) => {
              setHeaderPaddingTop(e?.target?.value);
            }}
          />
          <BuilderTextField
            type={`number`}
            label={`Padding bottom`}
            defaultValue={style?.paddingBottom}
            onChange={(e) => {
              setHeaderPaddingBottom(e?.target?.value);
            }}
          />
        </div>
      </AsideContentItem>
    </>
  );
};
