import { BuilderJSON } from 'store';
import { AsideContentItem } from '../..';
import { BuilderColorPicker } from 'pages/storebuilder/components/forms/colorpicker';
import { BuilderUploadImage } from 'pages/storebuilder/components/forms/upload';
import { BuilderTextField } from 'pages/storebuilder/components/forms/input';

export const FeaturedStyle = ({ title }) => {
  const {
    pages,
    setFeaturedSectionBackgroundColor,
    setFeaturedSectionBackgroundImage,
    setFeaturedSectionForegroundColor,
    setFeaturedSectionMarginTop,
    setFeaturedSectionMarginBottom,
    setFeaturedSectionPaddingTop,
    setFeaturedSectionPaddingBottom
  } = BuilderJSON((state) => state);
  const { style } = pages?.landing?.featuredSections?.filter(
    (sec) => sec?.title === title
  )[0];

  return (
    <>
      <AsideContentItem label={`Background`}>
        <div className={`builder_items`}>
          <BuilderColorPicker
            title={title}
            label={`Color`}
            activeColor={style?.backgroundColor}
            handleActiveColor={setFeaturedSectionBackgroundColor}
          />
          <BuilderUploadImage
            title={title}
            label={`Image`}
            mediaRecommendations={`Recommended dimensions are 1600 x 750, types are jpg, jpeg, png, gif or svg`}
            defaultImage={style?.backgroundImage}
            setImage={setFeaturedSectionBackgroundImage}
          />
        </div>
      </AsideContentItem>
      <AsideContentItem label={`Foreground`}>
        <div className={`builder_items`}>
          <BuilderColorPicker
            title={title}
            label={`Color`}
            activeColor={style?.foregroundColor}
            handleActiveColor={setFeaturedSectionForegroundColor}
          />
        </div>
      </AsideContentItem>
      <AsideContentItem label={`Section spaces`}>
        <div className={`builder_items`}>
          <BuilderTextField
            type={`number`}
            label={`Margin top`}
            defaultValue={style?.marginTop}
            onChange={(e) => {
              setFeaturedSectionMarginTop(e?.target?.value, title);
            }}
          />
          <BuilderTextField
            type={`number`}
            label={`Margin bottom`}
            defaultValue={style?.marginBottom}
            onChange={(e) => {
              setFeaturedSectionMarginBottom(e?.target?.value, title);
            }}
          />
          <BuilderTextField
            type={`number`}
            label={`Padding top`}
            defaultValue={style?.paddingTop}
            onChange={(e) => {
              setFeaturedSectionPaddingTop(e?.target?.value, title);
            }}
          />
          <BuilderTextField
            type={`number`}
            label={`Padding bottom`}
            defaultValue={style?.paddingBottom}
            onChange={(e) => {
              setFeaturedSectionPaddingBottom(e?.target?.value, title);
            }}
          />
        </div>
      </AsideContentItem>
    </>
  );
};
