import { useEffect, useState } from 'react';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import { RenderIf } from 'utilities/helpers';
import { client } from 'utilities/db';
import ImageDropzone from 'components/imageDropzone/imageDropzone';
import { TextField } from 'components/input';
import { Button } from 'components/button';
import { Toasts } from 'components/toasts';
import styles from './index.module.css';
import { useFetchSingleBrand } from 'hooks/brands/useBrandHook';
import { US_STATES } from 'utilities/constants';
import { CustomSelectDropdown } from 'components/select/multi';
import { ImageCropUploader } from 'components/imagecropuploader';

export const PortfolioBranchesAddUpdatePage = () => {
  const navigate = useNavigate();
  let location = useLocation()?.pathname?.split('/');
  let action = location[3];
  const { brandId } = useParams();
  const branchToUpdate = useLocation()?.state?.branchToUpdate;

  const { data: brandData } = useFetchSingleBrand(brandId);

  const {
    handleSubmit,
    register,
    formState: { errors },
    control
  } = useForm({
    defaultValues: {
      branch_name:
        branchToUpdate?.branch_name || branchToUpdate?.kitchen_name || '',
      postal_code: branchToUpdate?.postal_code || '',
      city: branchToUpdate?.city || '',
      state: branchToUpdate?.state || '',
      tax: branchToUpdate?.tax || '',
      street_address_one: branchToUpdate?.address
        ? JSON.parse(branchToUpdate?.address || {})?.line1
        : '',
      street_address_two: branchToUpdate?.address
        ? JSON.parse(branchToUpdate?.address || {})?.line2
        : ''
    }
  });

  const [predictedPlace, setPredictedPlace] = useState(null);
  const [strAddressFocus, setStrAddressFocus] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [brandImage, setBrandImage] = useState(branchToUpdate?.image_url || '');

  const onSubmit = async (val) => {
    try {
      setSubmitting(true);
      if (action === 'add') {
        if (brandData?.brand_type === 'CLOUD') {
          await client.post('/cloudKitchen/add', {
            kitchen_name: val?.branch_name,
            brand_id: parseInt(brandId),
            address: {
              line1: val?.street_address_one,
              line2: val?.street_address_two
            },
            postal_code: val?.postal_code,
            city: val?.city,
            state: val?.state?.value,
            tax: val?.tax,
            is_default: false,
            geo_location: {
              key: 'some key',
              value: 'some value'
            },
            singlular: false,
            image_url: brandImage
          });
        } else {
          await client.post('/branch/add', {
            branch_name: val?.branch_name,
            brand_id: parseInt(brandId),
            address: {
              line1: val?.street_address_one,
              line2: val?.street_address_two
            },
            postal_code: val?.postal_code,
            city: val?.city,
            state: val?.state?.value,
            tax: val?.tax,
            is_default: false,
            geo_location: {
              key: 'some key',
              value: 'some value'
            },
            singlular: false,
            image_url: brandImage
          });
        }
        setSubmitting(false);
        Toasts.success('Branch added successfully');
        navigate(`/portfolio/brand/details/${brandId}`);
      }
      if (action === 'update') {
        if (brandData?.brand_type === 'CLOUD') {
          await client.post('/cloudKitchen/add', {
            kitchen_name: val?.branch_name,
            brand_id: parseInt(brandId),
            address: {
              line1: val?.street_address_one,
              line2: val?.street_address_two
            },
            postal_code: val?.postal_code,
            city: val?.city,
            state: val?.state?.value,
            tax: val?.tax,
            is_default: 0,
            geo_location: {
              key: 'some key',
              value: 'some value'
            },
            singlular: false,
            image_url: brandImage
          });
        } else {
          await client.post(`/branch/update/${branchToUpdate?.id}`, {
            branch_name: val?.branch_name,
            brand_id: parseInt(brandId),
            address: {
              line1: val?.street_address_one,
              line2: val?.street_address_two
            },
            postal_code: val?.postal_code,
            city: val?.city,
            state: val?.state?.value,
            tax: val?.tax,
            is_default: 0,
            geo_location: {
              key: 'some key',
              value: 'some value'
            },
            singlular: false,
            image_url: brandImage
          });
        }
        setSubmitting(false);
        Toasts.success('Branch added successfully');
        navigate(`/portfolio/brand/details/${brandId}`);
      }
    } catch (error) {
      setSubmitting(false);
      Toasts.error(error?.response?.data?.message);
    }
  };

  const {
    placesService,
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading
  } = usePlacesService({
    apiKey: 'AIzaSyBoZtTiuyPSY1sCdmxpuaSRhwEy4KenKcM'
  });

  useEffect(() => {
    // fetch place details for the first element in placePredictions array
    if (placePredictions.length)
      placesService.getDetails(
        { placeId: placePredictions[0].place_id },
        (place, status) => {
          if (status === 'OK') {
            setPredictedPlace(place?.formatted_address);
          }
        }
      );
  }, [placePredictions]);

  return (
    <>
      <div className="mt_40"></div>
      <div className={styles.box}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <h2>
            <span className="ff_semi">
              {brandData?.brand_type === 'CLOUD'
                ? 'Cloud Kitchen Locations Page'
                : 'Portfolio Branches Page'}
            </span>
          </h2>
          <div className="mt_16"></div>
          <TextField
            name="branch_name"
            label={
              brandData?.brand_type === 'CLOUD'
                ? 'Cloud Kitchen Name'
                : 'Branch Name'
            }
            placeholder={
              brandData?.brand_type === 'CLOUD'
                ? 'Cloud Kitchen Name'
                : 'Branch Name'
            }
            error={
              errors?.branch_name?.type === 'required'
                ? 'Branch Name is required'
                : ''
            }
            validation={{
              required: true
            }}
            register={register}
            required
          />
          <TextField
            name="tax"
            label="Tax"
            placeholder="Tax"
            error={errors?.tax?.type === 'required' ? 'Tax is required' : ''}
            validation={{
              required: true
            }}
            register={register}
            required
          />
          <div className="mt_16"></div>
          <div className="field_box">
            <TextField
              name="street_address_one"
              label={'Street Address 1'}
              placeholder={'Street Address 1'}
              type="text"
              register={register}
            />
          </div>
          <div className="mt_16"></div>
          <TextField
            name="street_address_two"
            label={'Street Address 2'}
            placeholder={'Street Address 2'}
            type="text"
            register={register}
          />
          <div className="grid grid_gap16">
            <div className="colxs6">
              <div className="mt_16"></div>
              <TextField
                name="postal_code"
                label={'Zipcode'}
                placeholder={'Zipcode'}
                type="text"
                error={
                  errors?.postal_code?.type === 'required'
                    ? 'Postal Code is required'
                    : ''
                }
                validation={{
                  required: true
                }}
                register={register}
                required
              />
            </div>
            <div className="colxs6">
              <div className="mt_16"></div>
              <TextField
                name="city"
                placeholder={'City'}
                label={'City'}
                type="text"
                error={
                  errors?.city?.type === 'required' ? 'City is required' : ''
                }
                validation={{
                  required: true
                }}
                register={register}
                required
              />
            </div>
            <div className="colxs6">
              <div className="mt_16"></div>
              <Controller
                name="state"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <CustomSelectDropdown
                    hookForm={true}
                    field={field}
                    label="State"
                    placeholder="Choose state"
                    options={US_STATES}
                    error={
                      errors?.country?.type === 'required'
                        ? 'State is required'
                        : ''
                    }
                  />
                )}
              />
            </div>
          </div>
          <div className="mt_16"></div>
          {/* <div>
            <label htmlFor="exampleFormControlInput1" className="label">
              Upload Logo
            </label>
            <ImageDropzone image={brandImage} setImage={setBrandImage} />
          </div> */}

          <ImageCropUploader
            imageSrc={brandImage}
            handleImage={setBrandImage}
          />

          <div className="mt_24"></div>
          <div className="onfooter">
            <div></div>
            <div className="flex item_vcenter">
              <Button
                type="submit"
                label={'Save'}
                color="bg-primary"
                disabled={submitting}
              />
            </div>
          </div>
        </form>
      </div>
    </>
  );
};
