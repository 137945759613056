import WebFont from 'webfontloader';
import { BuilderJSON } from 'store';
import { Header } from 'components/header';
import { AsideBuilder } from 'pages/storebuilder/aside';
import styles from './index.module.css';

export const StoreBuilder = ({ children }) => {
  const { pages } = BuilderJSON((state) => state);

  WebFont.load({
    google: {
      families: [
        `${pages?.global?.style?.headerFont}`,
        `${pages?.global?.style?.bodyFont}`,
        `${pages?.global?.style?.buttonFont}`
      ]
    }
  });

  return (
    <article className={styles.article}>
      <Header />
      <main
        className={styles.main}
        style={{
          marginLeft: 'var(--asideWidth)'
        }}
      >
        <AsideBuilder />
        <div>{children}</div>
      </main>
    </article>
  );
};
