import { Ikon } from 'assets/icons';
import { Button } from 'components/button';
import { TextField } from 'components/input';
import { Toasts } from 'components/toasts';
import React, { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { client } from 'utilities/db';
import { getBranchId, getLocationId } from 'utilities/helpers/auth';
import useForceUpdate from 'use-force-update';
import styles from './index.module.css';
import { BatchModal, DiscountModal, OverridePrediction } from './modal';
import { BatchBox, IngredientBox, RecipeMixture } from './box';

export default function PrepareToItemAccordion({
  image,
  name,
  stockAvailable,
  stockRequired,
  isOpen,
  onClick,
  foodWaste,
  setSelectedFoodWasteItem,
  foodWasteItemSelected,
  setNewBatchModal,
  unit,
  item,
  isMixture = false,
  refetch,
  expiringRecipe,
  foodWasteType
}) {
  const forceUpdate = useForceUpdate();
  const [multiplier, setMultiplier] = useState(30);
  const [servings, setServings] = useState('');
  const [expiryDate, setExpiryDate] = useState('');
  const [preparedDate, setPreparedDate] = useState('');
  const [applyDisount, setApplyDisount] = useState(false);
  const [discountType, setDiscountType] = useState(true);
  const [discountPercentage, setDiscountPercentage] = useState('');
  const [startDiscountPercentage, setStartDiscountPercentage] = useState('');
  const [endDiscountPercentage, setEndDiscountPercentage] = useState('');
  const [itemUnit, setItemUnit] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [foodWasteItem, setFoodWasteItem] = useState(null);
  const [applyDiscountModal, setApplyDiscountModal] = useState(false);
  const [overridePrediction, setOverridePrediction] = useState(false);

  const brandId = getLocationId();
  const branchId = getBranchId();

  const isExpiringDisabled =
    discountType && discountType !== 'false'
      ? discountPercentage.trim() === ''
      : startDiscountPercentage.trim() === '' ||
        endDiscountPercentage.trim() === '';
  const {
    mutate: mutateApplyDiscountonRecipe,
    isSuccess: recipeApplyDiscountSuccess,
    isError: recipeApplyDiscountError
  } = useMutation((userData) => {
    return client.post(`/discount/addRecipe`, userData);
  });
  const {
    mutate: mutateApplyDiscountonMixture,
    isSuccess: mixtureApplyDiscountSuccess,
    isError: mixtureApplyDiscountError
  } = useMutation((userData) => {
    return client.post(`/discount/addMixture`, userData);
  });
  const {
    mutate: mutateApplyDiscountonRaw,
    isSuccess: rawApplyDiscountSuccess,
    isError: rawApplyDiscountError
  } = useMutation((userData) => {
    return client.post(`/discount/addProduct`, userData);
  });
  const { mutate, isLoading, isSuccess, isError, error, data } = useMutation(
    (userData) => {
      return client.post(`/recipeInventories/add`, userData);
    }
  );
  const {
    mutate: mixtueMutate,
    isSuccess: mixtureISucces,
    isError: mixtureError
  } = useMutation((userData) => {
    return client.post(`/mixtureInventories/add`, userData);
  });

  const onApplyDiscountOnRecipe = () => {
    if (isExpiringDisabled) {
      Toasts.error('Kindly fill all fields');
      return;
    }
    const data = discountType
      ? {
          modifier_id: item?.RecipeModel?.id,
          batch_id: item?.id,
          flat: true,
          discount: 50
        }
      : {
          modifier_id: item?.RecipeModel?.id,
          batch_id: item?.id,
          flat: false,
          starting_discount: startDiscountPercentage,
          ending_discount: endDiscountPercentage
        };
    mutateApplyDiscountonRecipe(data);
  };

  const onApplyDiscountOthers = (modifierId) => {
    if (isExpiringDisabled) {
      Toasts.error('Kindly fill all fields');
      return;
    }
    const data =
      discountType && discountType !== 'false'
        ? {
            modifier_id: modifierId,
            batch_id: item?.id,
            flat: true,
            discount: discountPercentage
          }
        : {
            modifier_id: modifierId,
            batch_id: item?.id,
            flat: false,
            starting_discount: startDiscountPercentage,
            ending_discount: endDiscountPercentage
          };

    if (foodWasteType === 'mixture') {
      mutateApplyDiscountonMixture(data);
      return;
    }
    mutateApplyDiscountonRaw(data);
  };

  const onCloseApplyDiscountModal = () => {
    setApplyDiscountModal(false);
    onCloseNewBatchModal();
  };

  const onCloseNewBatchModal = () => {
    setOpenModal(false);
    setApplyDisount(false);
    setDiscountType(true);
    setServings('');
    setExpiryDate('');
    setDiscountPercentage('');
    setStartDiscountPercentage('');
    setEndDiscountPercentage('');
  };

  const onApplyOverride = () => {
    setOverridePrediction(false);
  };

  useEffect(() => {
    if (!isSuccess && !mixtureISucces) return;
    onCloseNewBatchModal();
    refetch();
    Toasts.success('Serving added successfully');
    forceUpdate();
  }, [isSuccess, mixtureISucces]);

  useEffect(() => {
    if (
      !recipeApplyDiscountSuccess &&
      !mixtureApplyDiscountSuccess &&
      !rawApplyDiscountSuccess
    )
      return;
    onCloseApplyDiscountModal();
    refetch();
    Toasts.success('discount applied successfully');
    forceUpdate();
    setFoodWasteItem({});
  }, [
    recipeApplyDiscountSuccess,
    mixtureApplyDiscountSuccess,
    rawApplyDiscountSuccess
  ]);

  useEffect(() => {
    if (
      !isError &&
      !mixtureError &&
      !recipeApplyDiscountError &&
      !mixtureApplyDiscountError &&
      !rawApplyDiscountError
    )
      return;
    Toasts.error('some error occoured, please try again later');
  }, [
    isError,
    mixtureError,
    recipeApplyDiscountError,
    mixtureApplyDiscountError,
    rawApplyDiscountError
  ]);

  return (
    <>
      <div className="border rounded mt-3">
        <div className="mt_20"></div>
        <div
          onClick={expiringRecipe ? () => null : () => onClick()}
          className={styles.row}
        >
          <div className={styles.row_name}>
            <img src={image} className={styles.row_img} />
            <h6 className={styles.stock_txt} style={{ marginLeft: 5 }}>
              {name || 'kitchen'}
            </h6>
          </div>

          <div
            className={styles.stock}
            style={{ width: expiringRecipe ? '40%' : '30%' }}
          >
            <div>
              <small className={styles.stock_t}>Available in stock</small>
              <p className={styles.stock_txt}>
                {stockAvailable} {unit}
              </p>
            </div>
            <div className="ml-3">
              <small className={styles.stock_t}>Required</small>
              <div className="p-1 rounded-full px-2 bg-[#EED7FF]">
                <p
                  className={styles.stock_txt}
                  style={{
                    backgroundColor: '#EED7FF',
                    width: 100,
                    borderRadius: 14,
                    height: 20,
                    alignItems: 'center'
                  }}
                >
                  30 servings
                  {/* {stockRequired} {unit} */}
                </p>
              </div>
            </div>
            {expiringRecipe ? (
              <Button
                style={{ width: '150px' }}
                onClick={() => setApplyDiscountModal(true)}
                type="button"
                label={'Apply Discount'}
                color={'bg-button'}
              />
            ) : (
              <div className="flex align-items-center justify-content-center ml-3">
                <Ikon downArrow />
              </div>
            )}
          </div>
        </div>

        {isOpen && foodWaste && !expiringRecipe && (
          <>
            <div className="mt_20"></div>
            <div className="p-3">
              <h5
                style={{
                  fontWeight: 600,
                  fontSize: '16px',
                  color: '#1E1E1E'
                }}
              >
                Recipes to Make with This Mixture
              </h5>

              <div className={`mt-4 flex flex-wrap gap-3 ${styles.serving}`}>
                {
                  // item?.recipes?
                  [1, 1, 1, 1, 1, 1, 1].map((item, index) => (
                    <RecipeMixture
                      onClick={() => {
                        console.log(foodWasteItem);
                        setFoodWasteItem(index);
                      }}
                      isSelected={index === foodWasteItem}
                      item={item}
                    />
                  ))
                }
              </div>

              {foodWasteItem?.id && (
                <div className="mt-3 border-top p-3 flex align-items-center justify-content-between">
                  <div>
                    <div className="flex">
                      <b>{foodWasteItem?.recipe_name || 'recipe name'}</b>
                      {foodWasteItem?.ModifierModel?.discount_type ===
                      'FLAT' ? (
                        <p className="ml-2">
                          -{' '}
                          {foodWasteItem?.ModifierModel?.discount_value
                            ?.discount || 'discount'}
                          %
                        </p>
                      ) : (
                        <p className="ml-2">
                          -{' '}
                          {foodWasteItem?.ModifierModel?.discount_value
                            ?.starting_discount || 'strting discpunt'}
                          % -{' '}
                          {foodWasteItem?.ModifierModel?.discount_value
                            ?.ending_discount || 'ending_discount'}
                          %
                        </p>
                      )}
                    </div>
                    <div className="mt-3">
                      <div className="flex gap-2 mr-2">
                        {foodWasteItem?.RecipeIngredientModel?.map(
                          (item, i) => (
                            <div key={i} className="p-3 border rounded">
                              <h4>
                                {item?.ProductModel?.product_name ||
                                  item?.MixtureModel?.mixture_name ||
                                  item?.ProductGroupModel?.product_group_name}
                                <span
                                  className={`ml-2 text-[10px] px-2 rounded p-1 ${
                                    item?.ingredient_type === 'RAW'
                                      ? 'text-green-500 bg-green-200'
                                      : 'text-orange-500 bg-orange-200'
                                  }`}
                                >
                                  {item?.ingredient_type}
                                </span>
                              </h4>
                              <br />
                              <p>quantity</p>
                              <p>
                                {item?.quantity *
                                  (multiplier > 1 ? multiplier : 1)}{' '}
                                {item?.unit}
                              </p>
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  </div>

                  <Button
                    onClick={() => {
                      setApplyDiscountModal(true);
                      if (
                        foodWasteItem?.ModifierModel?.discount_type === 'FLAT'
                      ) {
                        setDiscountPercentage(
                          `${foodWasteItem?.ModifierModel?.discount_value?.discount}` ||
                            ''
                        );
                        setDiscountType(true);
                      }
                      if (
                        foodWasteItem?.ModifierModel?.discount_type ===
                        'PROGRESSIVE'
                      ) {
                        setStartDiscountPercentage(
                          `${foodWasteItem?.ModifierModel?.discount_value?.starting_discount}` ||
                            ''
                        );
                        setEndDiscountPercentage(
                          `${foodWasteItem?.ModifierModel?.discount_value?.ending_discount}` ||
                            ''
                        );
                        setDiscountType(false);
                      }
                    }}
                    type="button"
                    label={
                      foodWasteItem?.ModifierModel?.discount_type
                        ? 'Edit Discount'
                        : 'Apply Discount'
                    }
                    color={'bg-button'}
                  />
                </div>
              )}
            </div>
          </>
        )}

        {isOpen && !foodWaste && (
          <>
            <div className="mt_30"></div>
            <div className="p-3">
              <h5 className={styles.stock_txt}>Predictions / Demand</h5>
              <div className="mt_20"></div>

              <div className={styles.serve_r}>
                <h3
                  className={styles.stock_txt}
                  style={{
                    marginLeft: 5
                  }}
                >
                  30 Servings Required
                </h3>

                <div>
                  <small>
                    This is 25% more than your usual demand because of the
                    following:
                  </small>
                  <ol>
                    <li>India Vs Pak Cricket Match</li>
                    <li>Rainy Weather</li>
                  </ol>
                </div>

                <Button
                  onClick={() => setOverridePrediction(true)}
                  type="button"
                  label={'Override the Prediction'}
                  color={'bg-button'}
                  style={{ borderRadius: 22, marginRight: 8 }}
                />
              </div>
              <div className="mt_20"></div>

              <div className="mt-4">
                <h5 className={styles.stock_txt}>Servings Available</h5>
                <div className="mt_20"></div>
                <div className={`flex gap-2  mt-2 align-items-center `}>
                  <div className={`flex ${styles.serving}`}>
                    {isMixture
                      ? //  item?.mixtureInventory?
                        [1, 1].map((item, i) => (
                          <BatchBox
                            index={i}
                            unit={item?.unit}
                            batch={item?.batch_number}
                            servings={item?.quantity}
                            expiry={item?.expired_at}
                          />
                        ))
                      : // item?.recipeInventory?
                        [1, 1, 1, 1, 1, 1].map((item, i) => (
                          <BatchBox
                            index={i}
                            unit={item?.unit}
                            batch={item?.batch_number}
                            servings={item?.quantity}
                            expiry={item?.expired_at}
                            isRecipe
                          />
                        ))}
                  </div>
                  <Button
                    style={{ marginTop: '10px', width: '220px' }}
                    onClick={() => setOpenModal(true)}
                    type="button"
                    label={'Prepare 30 Servings'}
                    color={'bg-button'}
                  />
                </div>
              </div>
              <div className="mt_20"></div>
              <div className="mt-8 border-top pt-4">
                <div className={styles.head}>
                  <h5 className={styles.stock_txt}>
                    Ingredients required to prepare 30 servings
                  </h5>
                  <TextField
                    name="multiplier"
                    id="multiplier"
                    type="number"
                    label={``}
                    placeholder=""
                    onChange={(val) => setMultiplier(val.target.value)}
                    value={multiplier}
                  />
                </div>

                <div className={styles.serving_div}>
                  <div className={`flex ${styles.serving}`}>
                    {isMixture
                      ? // item?.mixtureIngredients?
                        [1, 1, 1, 1, 1].map((item) => (
                          <IngredientBox item={item} multiplier={multiplier} />

                          // <div className="p-3 border rounded">
                          //   <h4>
                          //     {item?.ProductModel?.product_name ||
                          //       item?.MixtureModel?.mixture_name ||
                          //       item?.ProductGroupModel?.product_group_name}
                          //     <span
                          //       className={`ml-2 text-[10px] px-2 rounded p-1 ${
                          //         item?.ingredient_type === 'RAW'
                          //           ? 'text-green-500 bg-green-200'
                          //           : 'text-orange-500 bg-orange-200'
                          //       }`}
                          //     >
                          //       {item?.ingredient_type}
                          //     </span>
                          //   </h4>
                          //   <br />
                          //   <p>quantity</p>
                          //   <p>
                          //     {item?.quantity *
                          //       (multiplier > 1 ? multiplier : 1)}{' '}
                          //     {item?.unit}
                          //   </p>
                          // </div>
                        ))
                      : // item?.recipeIngredients?
                        [1, 1, 1, 1, 1].map((item) => (
                          <IngredientBox item={item} multiplier={multiplier} />
                        ))}
                  </div>
                  <Button
                    className={`button button_radius ${styles.savebtn}`}
                    onClick={() => null}
                    type="button"
                    label={'Save / Record Spillage'}
                    color={'bg-button'}
                    // disabled={isDisabled}
                  />
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      <OverridePrediction
        overridePrediction={overridePrediction}
        setOverridePrediction={setOverridePrediction}
        onApplyOverride={onApplyOverride}
      />
      <BatchModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        isMixture={isMixture}
        onCloseNewBatchModal={onCloseNewBatchModal}
        refetch={refetch}
        item={item}
      />
      <DiscountModal
        expiringRecipe={expiringRecipe}
        applyDiscountModal={applyDiscountModal}
        onCloseApplyDiscountModal={onCloseApplyDiscountModal}
        setDiscountType={setDiscountType}
        discountType={discountType}
        discountPercentage={discountPercentage}
        setDiscountPercentage={setDiscountPercentage}
        startDiscountPercentage={startDiscountPercentage}
        endDiscountPercentage={endDiscountPercentage}
        setEndDiscountPercentage={setEndDiscountPercentage}
        onApplyDiscountOnRecipe={onApplyDiscountOnRecipe}
        onApplyDiscountOthers={onApplyDiscountOthers}
        foodWasteItem={foodWasteItem}
      />
    </>
  );
}
