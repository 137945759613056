import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { TextField } from 'components/input';
import { TextArea } from 'components/textarea';
import { Button } from 'components/button';
import { Ikon } from 'assets/icons';
import ImageDropzone from 'components/imageDropzone/imageDropzone';
import { client } from 'utilities/db';
import { useMutation } from 'react-query';
import { CustomSelectDropdown } from 'components/select/multi';
import { SelectDropdown } from 'components/selectdropdown';
import { useFetchColors } from 'hooks/resourceHooks';
// import { Toasts } from 'components/toasts';

export const AddNewCategory = ({
  setMenuStep,
  locationId,
  parentId = null,
  isEdit,
  editableData,
  branchArr,
  branchesSameMenu
}) => {
  const { mutate, isLoading, isSuccess, isError, error, data } = useMutation(
    (userData) => {
      return client.post(
        isEdit ? `/category/update/${isEdit}` : `/category/add`,
        userData
      );
    }
  );
  const { data: colorsList } = useFetchColors();
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [categoryText, setCategoryText] = useState('');
  const [categoryImg, setCategoryImg] = useState(editableData?.image || '');
  const [imageLoading, setImageLoading] = useState(false);
  const [selectedTypes, setselectedTypes] = useState([]);
  const {
    handleSubmit,
    register,
    formState: { errors }
  } = useForm({
    defaultValues: {
      name: editableData?.name || '',
      description: editableData?.description || '',
      types: editableData?.types || ''
    }
  });

  useEffect(() => {
    const types = [...categoryOptions];
    types.push({
      name: categoryText,
      id: categoryText
    });
    setCategoryOptions(types);
  }, [categoryText, categoryOptions]);

  const onSubmitCategroy = (val) => {
    mutate({
      name: val?.name,
      brand_id: locationId,
      color_id: val?.color,
      image: categoryImg,
      description: val?.description,
      category_types: selectedTypes?.map((item) => ({
        name: item?.label,
        description: item?.label
      })),
      is_active: true,
      branch_ids: branchesSameMenu ? null : branchArr,
      category_level: branchesSameMenu ? 'BRAND' : 'BRANCH'
    });
  };

  useEffect(() => {
    if (!isError) return;
  }, [isError]);

  useEffect(() => {
    if (!isSuccess) return;
    setMenuStep(1);
  }, [isSuccess]);

  return (
    <article>
      <form onSubmit={handleSubmit(onSubmitCategroy)}>
        <div className="onheader1">
          <div>
            {!branchesSameMenu && (
              <button
                type="button"
                className="ikonlink"
                color="primary"
                onClick={() => setMenuStep(1)}
              >
                <span>
                  <Ikon backArrow />
                </span>
                <span>Back</span>
              </button>
            )}
          </div>
          <div className="flex item_vcenter">
            <Button
              onClick={() => setMenuStep(1)}
              label={'Cancel'}
              color="outline-lite"
            />
            <Button
              disabled={imageLoading}
              isLoading={isLoading}
              type="submit"
              label={'Save'}
              color="bg-button"
            />
          </div>
        </div>
        <div className="pt_10"></div>
        <div>
          <ImageDropzone
            setIsLoading={setImageLoading}
            image={categoryImg}
            setImage={setCategoryImg}
          />
          <div className="mt_15"></div>
          <TextField
            name="name"
            label={'Category Name'}
            type="text"
            error={
              errors?.name?.type === 'required'
                ? 'Category name is required'
                : ''
            }
            validation={{
              required: true
            }}
            register={register}
            required
          />
          <div className="mt_15"></div>
          <TextArea
            name="description"
            label={'Description'}
            rows="7"
            register={register}
            validation={{
              required: true
            }}
            error={
              errors?.description?.type === 'required'
                ? 'Description is required'
                : ''
            }
          />
          <div className="mt_15"></div>
          <CustomSelectDropdown
            name="link"
            placeholder="Add Category Types"
            options={[...categoryOptions]?.map((item) => ({
              label: item?.name,
              value: item?.id
            }))}
            inputValue={categoryText}
            onChange={setselectedTypes}
            value={selectedTypes}
            isMulti
            onInputChange={(e) => setCategoryText(e)}
          />
          <SelectDropdown
            label="Color"
            {...register('color', {
              required: true
            })}
            data={[
              { value: '', label: 'choose option' },
              ...colorsList.map((item) => ({
                value: item?.id,
                label: item?.color_name
              }))
            ]}
            error={
              errors?.color?.type === 'required' ? 'Color is required' : ''
            }
          />
        </div>
      </form>
      {error && (
        <div>
          <p style={{ color: 'red', fontWeight: '800' }}>
            {error?.response?.data?.message}
          </p>
        </div>
      )}
    </article>
  );
};
