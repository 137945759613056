import React from 'react';
import { BuilderJSON } from 'store';
import { AsideContentItem } from '../..';
import { BuilderLayoutButton } from 'pages/storebuilder/components/forms/buttons/layout';
import { BuilderTextareaField } from 'pages/storebuilder/components/forms/textarea';
import { BuilderUploadImage } from 'pages/storebuilder/components/forms/upload';
import { BuilderSwitchField } from 'pages/storebuilder/components/forms/switch';
import { Ikon } from 'assets/icons';
import { RenderIf } from 'utilities/helpers';

export const CarouselContent = ({ title }) => {
  const {
    pages,
    setCarouselSectionLayout,
    setCarouselSectionContentInContainer,
    setCarouselSectionContentOverlay,
    setCarouselSlideMedia,
    setCarouselSlideTitle,
    setCarouselSlideDescription,
    setAddCarouselSlide,
    setRemoveCarouselSlide,
    setRemoveCarouselSection,
    removeRenderOrderItem
  } = BuilderJSON((state) => state);
  const { content } = pages?.landing?.carouselSections?.filter(
    (sec) => sec?.title === title
  )[0];

  return (
    <>
      <AsideContentItem label={`Layout`}>
        <div className={`builder-layout-items`}>
          <div>
            <BuilderLayoutButton
              hasTextOnly={true}
              isActive={content?.layout === 1 ? true : false}
              handleOnClick={() => {
                setCarouselSectionLayout(1, title);
              }}
            >
              1
            </BuilderLayoutButton>
          </div>
          <div>
            {/* <BuilderLayoutButton 
              hasTextOnly={true}
              isActive={content?.layout === 2 ? true : false}
              handleOnClick={() => {
                setHeaderLayout(2, title);
              }}
            >
              2
            </BuilderLayoutButton> */}
          </div>
        </div>
      </AsideContentItem>
      <AsideContentItem label={`Container`}>
        <div className={`builder_items`}>
          <BuilderSwitchField
            id={`CAROUSEL_CONTENT_IN_CONTAINER`}
            label={`Content container`}
            defaultChecked={content?.contentInContainer}
            onChange={() => {
              setCarouselSectionContentInContainer(title);
            }}
          />
          <BuilderSwitchField
            id={`CAROUSEL_CONTENT_OVERLAY`}
            label={`Content overlay`}
            defaultChecked={content?.contentOverlay}
            onChange={() => {
              setCarouselSectionContentOverlay(title);
            }}
          />
        </div>
      </AsideContentItem>
      {content?.slides?.map((slide, i) => (
        <React.Fragment key={i}>
          <AsideContentItem label={`Slide ${i + 1}`}>
            <div className={`builder_items`}>
              <BuilderUploadImage
                title={title}
                slideIndex={i}
                label={`Image`}
                mediaRecommendations={`Recommended dimensions are 1600 x 750, types are jpg, jpeg, png, gif or svg`}
                defaultImage={slide?.media}
                setImage={setCarouselSlideMedia}
              />
              <BuilderTextareaField
                label={`Title`}
                defaultValue={slide?.title}
                onChange={(e) => {
                  setCarouselSlideTitle(e?.target?.value, title, i);
                }}
              />
              <BuilderTextareaField
                label={`Description`}
                defaultValue={slide?.description}
                onChange={(e) => {
                  setCarouselSlideDescription(e?.target?.value, title, i);
                }}
              />
              <RenderIf isTrue={content?.slides?.length > 1}>
                <div>
                  <button
                    type={`button`}
                    className={`builder-button-link`}
                    color={`gray`}
                    onClick={() => {
                      setRemoveCarouselSlide(title, i);
                    }}
                  >
                    <Ikon name={`close-x`} fillColor={`var(--grey)`} />
                    <span>remove this slide</span>
                  </button>
                </div>
              </RenderIf>
              <RenderIf isTrue={content?.slides?.length - 1 === i}>
                <div>
                  <button
                    type={`button`}
                    className={`builder-button-link`}
                    color={`primary`}
                    onClick={() => {
                      setAddCarouselSlide(title);
                    }}
                  >
                    <Ikon name={`builder-plus`} fillColor={`var(--primary)`} />
                    <span>add another slide</span>
                  </button>
                </div>
              </RenderIf>
            </div>
          </AsideContentItem>
        </React.Fragment>
      ))}
      <AsideContentItem label={`Remove section`}>
        <div className={`builder_items`}>
          <div>
            <button
              type={`button`}
              className={`builder-button-link`}
              color={`gray`}
              onClick={() => {
                setRemoveCarouselSection(title);
                removeRenderOrderItem(title);
              }}
            >
              <Ikon name={`close-x`} fillColor={`var(--grey)`} />
              <span>remove this section</span>
            </button>
          </div>
        </div>
      </AsideContentItem>
    </>
  );
};
