import React, { useEffect, useState } from 'react';
import {
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer,
  Legend,
  Tooltip
} from 'recharts';
import { RenderIf } from 'utilities/helpers';

// const data = [
//   { name: 'Group A', value: 400 },
//   { name: 'Group B', value: 300 },
//   { name: 'Group C', value: 300 },
//   { name: 'Group D', value: 200 }
// ];

const COLORS = ['#954CCB', '#4AAE8C', '#EF8751', '#E3606E'];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.4;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? 'start' : 'end'}
      dominantBaseline="central"
      fontSize={10}
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

export default ({ pieChart = [] }) => {
  const [noData, setNoData] = useState(true);

  const pieChartData = pieChart.map((item) => ({
    name: item?.platform,
    value: item?.count
  }));

  useEffect(() => {
    setNoData(true);
  }, []);

  return (
    <div className={'chart_box'}>
      <div style={{ width: `100%` }}>
        <p className="w_40">Order Volume</p>
        <RenderIf isTrue={!noData}>
          <p>
            <strong className="chart_title ">302</strong>
          </p>
          <ResponsiveContainer height={300}>
            <PieChart
              margin={{
                top: -10
              }}
            >
              <Pie
                data={pieChartData}
                cx="50%"
                cy="50%"
                labelLine={false}
                label={renderCustomizedLabel}
                outerRadius={120}
                fill="#8884d8"
                dataKey="value"
              >
                {pieChartData.map((entry, index) => (
                  <Cell key={index} fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>
              <Tooltip />
              <Legend height={15} align="center" verticalAlign="bottom" />
            </PieChart>
          </ResponsiveContainer>
        </RenderIf>
        <RenderIf isTrue={noData}>
          <div
            style={{
              width: `100%`,
              height: 250,
              display: `flex`,
              alignItems: `center`,
              justifyContent: `center`,
              backgroundColor: `#D9D9D9`,
              color: `#707070`,
              fontSize: `14px`,
              fontWeight: `600`,
              marginTop: 10,
              borderRadius: `0 0 8px 8px`
            }}
          >
            No data
          </div>
        </RenderIf>
      </div>
    </div>
  );
};
