import { useState } from 'react';
import { RenderIf } from 'utilities/helpers';
import { AddNewCategory } from '../addcategory';
import { CategoryList } from '../categorylist';
import { AddMenuItem } from '../menuitem';
import ProgressSteps from 'components/steps';
import { Button } from 'components/button';
import { AddBranchForm } from 'pages/branches/add';

export const NewBranch = ({
  onGoBack,
  branchEditableValues,
  clearEditableValues,
  locationId,
  step,
  singleBranch,
  onChangeStep
}) => {
  console.log('🚀 ~ file: index.js ~ line 19 ~ onGoBack', onGoBack);
  const [addMenu, setAddMenu] = useState(false);
  const [menuStep, setMenuStep] = useState(1);
  const [selectedCategory, setSelectedCategory] = useState('');

  return (
    <>
      <div className={'onbox'}>
        <div className="grid grid_gap60">
          <div className={addMenu ? 'collg6' : 'collg12'}>
            <h1>
              <strong>Add Branch</strong>
            </h1>
            <h5 className="mt_4">About Branch</h5>
            <div className="mt_16"></div>
            <div className="w_368 center_auto">
              <ProgressSteps active={4} />
            </div>
            <h5 className="mt_16">
              <b>Branch Address</b>
            </h5>
            <div className="mt_16">
              <AddBranchForm
                onboarding={true}
                setAddMenu={setAddMenu}
                locationId={locationId}
                step={step}
                branchEditableValues={branchEditableValues}
                clearEditableValues={clearEditableValues}
                onGoBack={onGoBack}
                singleBranch={singleBranch}
                onChangeStep={onChangeStep}
              />
            </div>
          </div>
          <RenderIf isTrue={addMenu}>
            <div className="collg6">
              {/* <RenderIf isTrue={menuStep === 1}>
                  <div>
                    <h6 className="ff_semi">Categories</h6>
                    <div className="mt_15"></div>
                    <button
                      className="add_button"
                      onClick={() => setMenuStep(2)}
                    >
                      <span>
                        <Ikon plus />
                      </span>
                      <span>Add Category</span>
                    </button>
                  </div>
                </RenderIf> */}
              <RenderIf isTrue={menuStep === 1}>
                <CategoryList
                  selectedCategory={selectedCategory}
                  setSelectedCategory={setSelectedCategory}
                  locationId={locationId}
                  menuStep={menuStep}
                  setMenuStep={setMenuStep}
                />
              </RenderIf>
              <RenderIf isTrue={menuStep === 2}>
                <AddNewCategory
                  locationId={locationId}
                  setMenuStep={setMenuStep}
                />
              </RenderIf>
              <RenderIf isTrue={menuStep === 3}>
                <AddMenuItem
                  selectedCategory={selectedCategory}
                  setMenuStep={setMenuStep}
                />
              </RenderIf>
            </div>
          </RenderIf>
        </div>
        {/* <div className="mt_25"></div>
          <div className="onfooter">
            <div className="flex item_vcenter">
              <Button
                onClick={() => {
                  onChangeStep(0);
                  clearEditableValues();
                }}
                type="button"
                label={'Back'}
                color="outline-lite"
              />
            </div>
          </div> */}
      </div>
    </>
  );
};
