import { useQuery } from 'react-query';
import { AXIOS_INSTANCE_ONBOARDING } from 'utilities/db';

export const useFetchFinixMarkup = (keyName, branch_id) => {
  const { status, data, error, isFetching, isLoading, refetch } = useQuery(
    [keyName],
    () => getData(branch_id),
    {
      enabled: !!branch_id
    }
  );

  return {
    finixMarkupStatus: status,
    finixMarkupData: data,
    finixMarkupError: error,
    finixMarkupFetching: isFetching,
    finixMarkupLoading: isLoading,
    finixMarkupRefetch: refetch
  };
};

const getData = async (branch_id) => {
  let { data } = await AXIOS_INSTANCE_ONBOARDING.get(
    `/branches/addPaymentLevel/${branch_id}`
  );
  return data;
};
