import styles from './index.module.css';

export const DeviceCard = ({
  name,
  description,
  modalNumber,
  serialNumber,
  status,
  statusTxt,
  buttonText = 'View details',
  onClick = () => {}
}) => {
  return (
    <div className={styles.card}>
      <header className={styles.header}>
        <div className={styles.status}>
          Status:
          <div
            className={styles.badge}
            style={{
              background: status ? 'var(--primary)' : 'rgb(var(--grey-rgb))'
            }}
          >
            {statusTxt}
          </div>
        </div>
        <div className={styles.serial}>
          <div>{serialNumber}</div>
        </div>
      </header>
      <h6>{name}</h6>
      <div className={styles.modal}>
        Modal no:
        <span>{modalNumber}</span>
      </div>
      <div></div>
      <p>{description}</p>
      <div></div>
      <footer className={styles.footer}>
        <div>
          <button
            className={styles.innerButton}
            onClick={() => {
              onClick();
            }}
          >
            {buttonText}
          </button>
        </div>
      </footer>
    </div>
  );
};
