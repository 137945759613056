import { useEffect, useState } from 'react';
import Aside from 'components/aside';
import { Header } from 'components/header';
import { useSearchParams } from 'react-router-dom';
import { useStore } from 'store';
import { useFetchOneBrandHook } from 'hooks/brands/useFetchOneBrandHook';
import { useFetchOneBranchHook } from 'hooks/branch/userFetchOneBranchHook';
import { RenderIf } from 'utilities/helpers';
import { Loading } from 'components/loading';
import styles from './index.module.css';

export const MainLayout = ({ children }) => {
  const {
    setBranch,
    setBrand,
    brand,
    branch,
    setUser,
    setBuisnessId,
    isWebMenu
  } = useStore((state) => state);

  const [searchParams, setSearchParams] = useSearchParams();
  const { oneBrandData, oneBrandLoading } = useFetchOneBrandHook(
    `brand/${searchParams.get('brandId') || brand?.id}`,
    searchParams.get('brandId') || brand?.id
  );
  const { oneBranchData, oneBranchLoading } = useFetchOneBranchHook(
    `branch/${searchParams.get('branchId') || branch?.id}`,
    searchParams.get('branchId') || branch?.id
  );

  useEffect(() => {
    if (oneBrandData && oneBranchData) {
      if (
        searchParams?.get('brandId') &&
        searchParams?.get('branchId') &&
        searchParams?.get('userId') &&
        searchParams?.get('buisnessId')
      ) {
        setBrand(oneBrandData);
        setBranch(oneBranchData);
        setUser({
          id: searchParams?.get('userId')
        });
        setSearchParams('');
        setBuisnessId(searchParams?.get('buisnessId'));
      }
    }
  }, [searchParams, oneBranchData, oneBrandData]);

  return (
    <>
      <RenderIf isTrue={oneBrandLoading || oneBranchLoading}>
        <div className={`loading_full`}>
          <Loading />
        </div>
      </RenderIf>
      <RenderIf isTrue={oneBranchData && oneBrandData}>
        <Header />
        <main className={styles.main}>
          <article
            className={
              !isWebMenu
                ? `${styles.article} ${styles.articleSmart}`
                : styles.article
            }
          >
            <div>
              <Aside userRole={`SUPER_ADMIN`} />
            </div>
            <div>
              <div className={`contain-bx`}>{children}</div>
            </div>
          </article>
        </main>
      </RenderIf>
    </>
  );
};
