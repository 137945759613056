import ProgressSteps from 'components/steps';
import { Button } from 'components/button';
import { useNavigate } from 'react-router-dom';

export const MultipleBranch = ({ onChangeStep }) => {
  const navigate = useNavigate();
  return (
    <>
      <div className="onbox">
        <h1>
          <strong>Add Branch</strong>
        </h1>
        <h5 className="mt_4">About Branch</h5>
        <div className="mt_16"></div>
        <div className="w_368 center_auto">
          <ProgressSteps active={4} />
        </div>
        <div className="mt_16"></div>
        <h4>
          <span className="ff_semi">
            Does this restaurant have multiple branches?
          </span>
        </h4>
        <div className="mt_35"></div>
        <div className="onfooter">
          <div></div>
          <div className="flex item_vcenter">
            <Button
              onClick={() => onChangeStep(3)}
              type="button"
              label={'No'}
              color="outline-lite"
            />
            <Button
              onClick={() => {
                window.localStorage.setItem('onBoardOnFinish', true);
                navigate('/brands');
              }}
              type="button"
              label={'Yes'}
              color="bg-button"
            />
          </div>
        </div>
      </div>
    </>
  );
};
