import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './assets/fonts/inter/Inter-Light.ttf';
import './assets/fonts/inter/Inter-Regular.ttf';
import './assets/fonts/inter/Inter-Medium.ttf';
import './assets/fonts/inter/Inter-SemiBold.ttf';
import './assets/fonts/inter/Inter-Bold.ttf';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-phone-number-input/style.css';
import './assets/styles/index.css';

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.Fragment>
    <QueryClientProvider client={queryClient}>
      <App />
    </QueryClientProvider>
    <ToastContainer />
  </React.Fragment>
);
