import { RenderIf } from 'utilities/helpers';
import ProgressSteps from 'components/steps';
import { CardBranchList } from 'components/cards/branchlist';
import { Button } from 'components/button';
import { Ikon } from 'assets/icons';
import { client } from 'utilities/db';
import { useMutation, useQuery } from 'react-query';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Loading } from 'components/loading';

export const AddBranch = ({
  onChangeStep,
  onGoBack,
  onEditClick,
  locationId,
  onGoToMenu
}) => {
  const navigate = useNavigate();
  const {
    refetch,
    isLoading: branchesLoading,
    error: branchesError,
    data: branchesData = [],
    isSuccess: branchesSuccess
  } = useQuery(['fetchBranches'], async () => {
    const data = await client.get(`/branch/findAll/${locationId}`);
    return data.data.branchDetails;
  });
  const {
    mutate,
    isLoading: deleteLoading,
    isError: isDeleteError,
    error: deleteError,
    isSuccess: deleteSuccess
  } = useMutation((id) => {
    return client.post(`/branch/delete/${id}`);
  });
  useEffect(() => {
    if (!branchesError) return;
    // alert(branchesError?.message)
  }, [branchesError]);

  useEffect(() => {
    if (!isDeleteError) return;
    // alert(deleteError?.message)
  }, [isDeleteError]);

  useEffect(() => {
    if (!deleteSuccess) return;
    refetch();
  }, [deleteSuccess]);

  const onDelete = (id) => {
    mutate(id);
  };

  return (
    <>
      <div className="onbox">
        <h2>
          <strong>Add Branch</strong>
        </h2>
        <h5>About Branch</h5>
        <ProgressSteps active={2} />
        <h5 className="mt_15">
          <b>Branches</b>
        </h5>
        <div className="pt_15"></div>
        <RenderIf isTrue={branchesLoading || deleteLoading}>
          <div className={`loading_full`}>
            <Loading />
          </div>
        </RenderIf>
        <RenderIf
          isTrue={branchesLoading || deleteLoading ? false : branchesSuccess}
        >
          {branchesData.map((item, i) => (
            <CardBranchList
              key={i}
              title={`${item?.address?.line1}`}
              street={item?.address?.line2}
              town={item?.state}
              city={item?.city}
              handleEdit={() => onEditClick(item)}
              handleDelete={() => onDelete(item?.id)}
            />
          ))}
        </RenderIf>
        <div className="flex item_hend mt_15">
          <button
            onClick={() => onChangeStep(2)}
            className="ikonlink"
            color="primary"
          >
            <span>
              <Ikon plus />
            </span>
            <span>Add a new branch</span>
          </button>
        </div>
        <div className="pt_40"></div>
        <div className="onfooter">
          {/* <Button
              type="button"
              label={`I'll do this later`}
              onClick={() => null}
              className="alink"
              color="dark"
            /> */}
          <div />
          <div className="flex item_vcenter">
            <Button
              onClick={() => onChangeStep(0)}
              type="button"
              label={'Back'}
              color="outline-lite"
            />
            <RenderIf isTrue={branchesData.length > 0}>
              <Button
                onClick={() => {
                  window.localStorage.setItem('onBoardOnFinish', true);
                  navigate('/dashboard');
                }}
                label={'Next'}
                color="bg-button"
              />
            </RenderIf>
          </div>
        </div>
      </div>
      {branchesError && (
        <div>
          <p style={{ color: 'red', fontWeight: '800' }}>
            {branchesError?.response?.data?.message}
          </p>
        </div>
      )}
      {deleteError && (
        <div>
          <p style={{ color: 'red', fontWeight: '800' }}>
            {deleteError?.response?.data?.message}
          </p>
        </div>
      )}
    </>
  );
};
