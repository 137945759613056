import { client } from 'utilities/db';
import { useQuery } from 'react-query';

export const useFetchVendorHook = (keyName, id) => {
  const { status, data, error, isFetching, isLoading, refetch } = useQuery(
    [keyName, id],
    () => getVendorData(id),
    {
      enabled: !!id,
      refetchInterval: 60000
    }
  );

  return {
    singleVendorStatus: status,
    singleVendorData: data,
    singleVendorError: error,
    singleVendorFetching: isFetching,
    singleVendorLoading: isLoading,
    singleVendorRefetch: refetch
  };
};

const getVendorData = async (id) => {
  let result = await client.get(`/vendor/findOne/${id}`);
  result = result.data;
  result = result?.vendor;

  return result;
};
