import { client } from 'utilities/db';
import { useQuery } from 'react-query';

export const useFetchBranchManagersHooks = (keyName, id, brandType) => {
  const { status, data, error, isFetching, isLoading, refetch } = useQuery(
    [keyName, id, brandType],
    () => getManagers(id, brandType),
    {
      enabled: !!id || !!brandType,
      refetchInterval: 60000
    }
  );

  return {
    branchManagerStatus: status,
    branchManagerData: data,
    branchManagerError: error,
    branchManagerFetching: isFetching,
    branchManagerLoading: isLoading,
    branchManagerRefetch: refetch
  };
};

const getManagers = async (id, brandType) => {
  let result = null;
  if (brandType === 'CLOUD') {
    result = await client.get(`/cloudKitchen/getManagers/${id}`);
    result = result.data;
    result = result?.cloudManagers;
  } else {
    result = await client.get(`/branch/getManagers/${id}`);
    result = result.data;
    result = result?.branchManagers;
  }

  return result;
};
