import { OrderAreaChart } from 'components/charts/orderareachart';
import { Ikon } from 'assets/icons';
import styles from "./index.module.css";
import { useState } from 'react';

const saleData = [
    {
      name: 'Page A',
      uv: 1000,
      pv: 2400,
      amt: 2400,
    },
    {
      name: 'Page B',
      uv: 1500,
      pv: 1398,
      amt: 2210,
    },
    {
      name: 'Page C',
      uv: 1000,
      pv: 9800,
      amt: 2290,
    },
    {
      name: 'Page D',
      uv: 1680,
      pv: 3908,
      amt: 2000,
    },
    {
      name: 'Page E',
      uv: 1390,
      pv: 4800,
      amt: 2181,
    },
    {
      name: 'Page F',
      uv: 1790,
      pv: 3800,
      amt: 2500,
    },
    {
      name: 'Page G',
      uv: 2490,
      pv: 4300,
      amt: 2100,
    },
];

export const OrderDetailPage = () => {
    const [lineChart, setLineChart] = useState(saleData);

  return (
    <>
        <article className={styles.article}>
            <header className={styles.header}>
                <div>
                    <h2>Order Detail Title</h2>
                    <h6>In-progress</h6>
                </div>
                {/* <div className={styles.filterBx}>
                    <button type={`button`} className={styles.active}>Today</button>
                    <button type={`button`}>Yesterday</button>
                    <button type={`button`}>Week</button>
                    <button type={`button`}>Month</button>
                </div> */}
            </header>
            <div className={styles.statsBx}>
              <div>
                <div className={styles.iconBx}>
                  <Ikon name={`net-sales`} />
                </div>
                <div className={styles.statsItemContent}>
                  <p>Net sales</p>
                  <h3>$350.4</h3>
                </div>
              </div>
              <div>
                <div className={styles.headItemContent}>
                  <p>Total orders</p>
                  <h3>104</h3>
                  <p><small><span>+23%</span> since last month</small></p>
                </div>
              </div>
              <div>
                <div className={styles.iconBx}>
                  <Ikon name={`amount-paid`} />
                </div>
                <div className={styles.headItemContent}>
                  <p>Amount paid</p>
                  <h3>$642.39</h3>
                </div>
              </div>
              <div>
                <div className={styles.iconBx}>
                  <Ikon name={`select-time`} />
                </div>
                <div className={styles.headItemContent}>
                  <p>Select time period</p>
                  <h3></h3>
                </div>
              </div>
            </div>
            <div className={styles.tableBx}>
                <OrderAreaChart data={lineChart} />
            </div>
        </article>
    </>
  );
}
