import React from 'react';
import { BuilderJSON } from 'store';
import { AsideContentItem } from '../..';
import { BuilderLayoutButton } from 'pages/storebuilder/components/forms/buttons/layout';
import { BuilderTextareaField } from 'pages/storebuilder/components/forms/textarea';
import { BuilderUploadImage } from 'pages/storebuilder/components/forms/upload';
import { BuilderSwitchField } from 'pages/storebuilder/components/forms/switch';
import { Ikon } from 'assets/icons';
import { RenderIf } from 'utilities/helpers';
import { BuilderTextField } from 'pages/storebuilder/components/forms/input';

export const ProductContent = ({ title }) => {
  const {
    pages,
    setProductSectionLayout,
    setProductSectionTitle,
    setProductSectionDescription,
    setProductSectionContentInContainer,
    setProductSlideMedia,
    setProductSlideTitle,
    setProductSlideDescription,
    setProductSlidePrice,
    setAddProductSlide,
    setRemoveProductSlide,
    setRemoveProductSection,
    removeRenderOrderItem
  } = BuilderJSON((state) => state);
  const { content } = pages?.landing?.productSections?.filter(
    (sec) => sec?.title === title
  )[0];

  return (
    <>
      <AsideContentItem label={`Layout`}>
        <div className={`builder-layout-items`}>
          <div>
            <BuilderLayoutButton
              hasTextOnly={true}
              isActive={content?.layout === 1 ? true : false}
              handleOnClick={() => {
                setProductSectionLayout(1, title);
              }}
            >
              1
            </BuilderLayoutButton>
          </div>
          <div>
            {/* <BuilderLayoutButton 
              hasTextOnly={true}
              isActive={content?.layout === 2 ? true : false}
              handleOnClick={() => {
                setProductSectionLayout(2, title);
              }}
            >
              2
            </BuilderLayoutButton> */}
          </div>
        </div>
      </AsideContentItem>
      <AsideContentItem label={`Container`}>
        <div className={`builder_items`}>
          <BuilderTextareaField
            label={`Title`}
            defaultValue={content?.title}
            onChange={(e) => {
              setProductSectionTitle(e?.target?.value, title);
            }}
          />
          <BuilderTextareaField
            label={`Description`}
            defaultValue={content?.description}
            onChange={(e) => {
              setProductSectionDescription(e?.target?.value, title);
            }}
          />
          <BuilderSwitchField
            id={`CAROUSEL_CONTENT_IN_CONTAINER`}
            label={`Content container`}
            defaultChecked={content?.contentInContainer}
            onChange={() => {
              setProductSectionContentInContainer(title);
            }}
          />
        </div>
      </AsideContentItem>
      {content?.products?.map((slide, i) => (
        <React.Fragment key={i}>
          <AsideContentItem label={`Product ${i + 1}`}>
            <div className={`builder_items`}>
              <BuilderUploadImage
                title={title}
                slideIndex={i}
                label={`Image`}
                mediaRecommendations={`Recommended dimensions are 250 x 250, types are jpg, jpeg, png, gif or svg`}
                defaultImage={slide?.media}
                setImage={setProductSlideMedia}
              />
              <BuilderTextareaField
                label={`Title`}
                defaultValue={slide?.title}
                onChange={(e) => {
                  setProductSlideTitle(e?.target?.value, title, i);
                }}
              />
              <BuilderTextareaField
                label={`Description`}
                defaultValue={slide?.description}
                onChange={(e) => {
                  setProductSlideDescription(e?.target?.value, title, i);
                }}
              />
              <BuilderTextField
                type={`number`}
                label={`Price`}
                defaultValue={slide?.price}
                onChange={(e) => {
                  setProductSlidePrice(e?.target?.value, title, i);
                }}
              />
              <RenderIf isTrue={content?.products?.length > 1}>
                <div>
                  <button
                    type={`button`}
                    className={`builder-button-link`}
                    color={`gray`}
                    onClick={() => {
                      setRemoveProductSlide(title, i);
                    }}
                  >
                    <Ikon name={`close-x`} fillColor={`var(--grey)`} />
                    <span>remove this product</span>
                  </button>
                </div>
              </RenderIf>
              <RenderIf isTrue={content?.products?.length - 1 === i}>
                <div>
                  <button
                    type={`button`}
                    className={`builder-button-link`}
                    color={`primary`}
                    onClick={() => {
                      setAddProductSlide(title);
                    }}
                  >
                    <Ikon name={`builder-plus`} fillColor={`var(--primary)`} />
                    <span>add another product</span>
                  </button>
                </div>
              </RenderIf>
            </div>
          </AsideContentItem>
        </React.Fragment>
      ))}
      <AsideContentItem label={`Remove section`}>
        <div className={`builder_items`}>
          <div>
            <button
              type={`button`}
              className={`builder-button-link`}
              color={`gray`}
              onClick={() => {
                setRemoveProductSection(title);
                removeRenderOrderItem(title);
              }}
            >
              <Ikon name={`close-x`} fillColor={`var(--grey)`} />
              <span>remove this section</span>
            </button>
          </div>
        </div>
      </AsideContentItem>
    </>
  );
};
