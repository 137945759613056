export const TEMPImgs = {
  ImgAuthLT: require('../assets/Images/auth_layout_left_top_img.png'),
  ImgAuthLB: require('../assets/Images/auth_layout_left_bottom_img.png'),
  ImgAuthR: require('../assets/Images/auth_layout_right_img.png'),
  ImgCategoryL1: require('../assets/Images/category_1.jpg'),

  ImgTheme1: require('../assets/Images/themes/01.jpg'),
  ImgTheme2: require('../assets/Images/themes/02.jpg'),
  ImgTheme3: require('../assets/Images/themes/03.jpg'),
  ImgTheme4: require('../assets/Images/themes/04.jpg'),
  ImgTheme5: require('../assets/Images/themes/05.jpg'),
  ImgTheme6: require('../assets/Images/themes/06.jpg'),
  ImgTheme7: require('../assets/Images/themes/07.jpg'),
  ImgTheme8: require('../assets/Images/themes/08.jpg'),
  ImgTheme9: require('../assets/Images/themes/09.jpg'),
  ImgTheme10: require('../assets/Images/themes/10.jpg'),
  ImgTheme11: require('../assets/Images/themes/11.jpg'),

  ImgAccount: require('../assets/Images/account.png'),
  ImgDelivery: require('../assets/Images/delivery.png'),
  ImgSigninEmoji: require('../assets/Images/signin_emoji.png'),
  ImgResetEmoji: require('../assets/Images/reset_emoji.png'),
  ImgOtpEmoji: require('../assets/Images/otp_emoji.png')
};
