import { useNavigate } from 'react-router-dom';
import { useFetchManagerHook } from 'hooks/managers/useFetchManagerHooks';
import { RenderIf } from 'utilities/helpers';
import { Button } from 'components/button';
import { Loading } from 'components/loading';

export const ManagerListingPage = ({ brandId }) => {
  const navigate = useNavigate();

  const { managerData, managerLoading } = useFetchManagerHook(
    `manager/${brandId}`,
    brandId
  );

  return (
    <>
      <div className="mt_20"></div>
      <div className="shadow_box1">
        <h2>
          <span className="ff_semi">Brand manager</span>
        </h2>
        <div className="mt_10"></div>
        <div className="flex item_hend">
          <Button
            label="Add a Manager"
            color="bg-titary"
            onClick={() => navigate(`/portfolio/brand/manager/add/${brandId}`)}
            disabled={managerData ? true : false}
          />
        </div>
        <RenderIf isTrue={managerLoading}>
          <div className={`loading_full`}>
            <Loading />
          </div>
        </RenderIf>
        <RenderIf isTrue={!managerLoading}>
          <div>
            <div className="mt_15"></div>
            <h4>
              <span className="ff_semi">Manager information</span>
            </h4>
            <div className="mt_15"></div>
            <dl>
              <dt>
                <b>Manager name:</b>
              </dt>
              <dd>
                <strong>
                  {managerData?.UserModel?.[0]?.EmployeeModel?.name}
                </strong>
              </dd>
              <dt>
                <b>Manager email:</b>
              </dt>
              <dd>
                <strong>{managerData?.UserModel?.[0]?.email}</strong>
              </dd>
            </dl>
          </div>
        </RenderIf>
      </div>
    </>
  );
};
