import { Ikon } from 'assets/icons';
import styles from './index.module.css';

export const RadioButton = (props) => {
  const {
    onChange = () => {},
    register = () => {},
    validation = {},
    info,
    infoChild,
    labels,
    required,
    wrapClass
  } = props;

  return (
    <>
      <div className={styles.fieldbox}>
        <div className={wrapClass ? wrapClass : styles.field}>
          {required ? <span className={styles.required}>*</span> : null}
          {labels?.map((label, i) => (
            <div className={styles.radioItem} key={i}>
              <input
                checked={label.checked}
                {...register(label?.name, validation)}
                type="radio"
                name={label?.name}
                className={styles.input}
                id={label?.id}
                value={label?.value}
                onChange={onChange}
              />
              <span className={styles.checks}></span>
              {label?.label ? (
                <label htmlFor={label?.id} className={styles.label}>
                  {label?.label}
                </label>
              ) : null}
            </div>
          ))}
        </div>
        <div>
          {info ? (
            <div className={styles.infobox}>
              <Ikon info />
              <div className={styles.infoChild}>
                <Ikon indicatorArrow />
                <div className={styles.infoContent}>{infoChild}</div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};
