import { Ikon } from 'assets/icons';
import styles from './index.module.css';

export const CardMenuList = (props) => {
  const { title, description, types, handleEdit, onClick } = props;

  return (
    <>
      <article onClick={onClick} className={styles.box}>
        <div>
          <h6>
            <b>{title}</b>
            {types.map((t, i) => (
              <span className={styles.badge} key={i}>
                {t}
              </span>
            ))}
          </h6>
          <div className="mt_10"></div>
          <p>{description}</p>
        </div>
        <div>
          <button className="alink" onClick={handleEdit}>
            <Ikon pencil />
          </button>
        </div>
      </article>
    </>
  );
};
