import { useFetchBrands } from 'hooks/resourceHooks';
import { MenuCategory } from 'pages/menu/category';
import { CategoryDetail } from 'pages/menu/category/detail';
import { MenuItem } from 'pages/menu/item';
import { MenuItemDetail } from 'pages/menu/item/detail';
import { MenuModifier } from 'pages/menu/modifier';
import { MenuModifierDetail } from 'pages/menu/modifier/detail';
import { useQuery } from 'react-query';
import { useStore } from 'store';
import { client } from 'utilities/db';
import { RenderIf } from 'utilities/helpers';
import { getLocationId } from 'utilities/helpers/auth';

export const MenuView = ({
  category,
  categoryDetail,
  item,
  itemDetail,
  modifier,
  modifierDetail
}) => {
  const { branch } = useStore((state) => state);

  const locationId = getLocationId();
  const { data = [] } = useFetchBrands();

  const { data: allCategories = [] } = useQuery(
    ['fetchCategoryForMenuItem', branch?.id],
    async () => {
      const data = await client.get(`/category/find-by-branch/${branch?.id}`);
      return data.data.categories;
    }
  );

  return (
    <>
      <RenderIf isTrue={category}>
        <MenuCategory />
      </RenderIf>
      <RenderIf isTrue={categoryDetail}>
        <CategoryDetail />
      </RenderIf>
      <RenderIf isTrue={item}>
        <MenuItem allCategories={allCategories} brands={data} />
      </RenderIf>
      <RenderIf isTrue={itemDetail}>
        <MenuItemDetail />
      </RenderIf>
      <RenderIf isTrue={modifier}>
        <MenuModifier allCategories={allCategories} brands={data} />
      </RenderIf>
      <RenderIf isTrue={modifierDetail}>
        <MenuModifierDetail />
      </RenderIf>
    </>
  );
};
