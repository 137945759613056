import { useQuery } from 'react-query';
import { client } from 'utilities/db';
import { getLocationId, getUser } from 'utilities/helpers/auth';
import { useStore } from 'store';

export const useFetchCountries = () => {
  const {
    isLoading,
    data = [],
    isSuccess,
    isError,
    refetch
  } = useQuery(['fetchCountries'], async () => {
    const data = await client.get(`/resouce/getCountries`);
    return data.data.countries;
  });

  return {
    data,
    isLoading,
    isSuccess,
    isError,
    refetch
  };
};

export const useFetchRoles = () => {
  const {
    isLoading,
    data = [],
    isSuccess,
    isError,
    refetch
  } = useQuery(['fetchRoles'], async () => {
    const data = await client.get(`/resouce/getExistingRoles`);
    return data.data.existingRoles;
  });

  return {
    data,
    isLoading,
    isSuccess,
    isError,
    refetch
  };
};

export const useFetchColors = () => {
  const {
    isLoading,
    data = [],
    isSuccess,
    isError,
    refetch
  } = useQuery(['fetchColors'], async () => {
    const data = await client.get(`/resouce/getColors`);
    return data.data.colors;
  });

  return {
    data,
    isLoading,
    isSuccess,
    isError,
    refetch
  };
};

export const useFetchCategories = (id) => {
  const locationId = id || getLocationId();
  const { branch } = useStore((state) => state);
  const {
    isLoading,
    data = [],
    isSuccess,
    isError,
    refetch
  } = useQuery(['fetchAllCats'], async () => {
    const data = await client.get(
      `/category/with-types-by-branch/${branch?.id}`
    );
    return data.data.data;
  });

  return {
    data,
    isLoading,
    isSuccess,
    isError,
    refetch
  };
};

export const useFetchCategoriesAll = () => {
  const { branch } = useStore((state) => state);
  const {
    isLoading,
    data = [],
    isSuccess,
    isError,
    refetch
  } = useQuery(['fetchAllCatsAll'], async () => {
    const data = await client.get(`/category/find-by-branch/${branch?.id}`);
    return data.data.data;
  });

  return {
    data,
    isLoading,
    isSuccess,
    isError,
    refetch
  };
};

export const useFetchMenusByBrand = (id) => {
  const locationId = id || getLocationId();
  const { branch } = useStore((state) => state);
  const {
    isLoading,
    data = [],
    isSuccess,
    isError,
    refetch
  } = useQuery(['fetchAllMenusByBrand'], async () => {
    const data = await client.get(`/menuItem/findAllByBranch/${branch?.id}`);
    return data.data.menuItems;
  });

  return {
    data,
    isLoading,
    isSuccess,
    isError,
    refetch
  };
};

export const useFetchProductsHook = (keyName) => {
  const { status, data, error, isFetching, isLoading, refetch } = useQuery(
    [keyName],
    () => getProducts(),
    {
      //enabled: !!id,
      refetchInterval: 60000
    }
  );

  return {
    productsStatus: status,
    productsData: data,
    productsError: error,
    productsFetching: isFetching,
    productsLoading: isLoading,
    productsRefetch: refetch
  };
};

export const useFetchBranches = (id) => {
  const locationId = id || getLocationId();
  let keyName = `branch/${locationId}`;

  const { status, data, error, isFetching, isLoading, refetch, isSuccess } =
    useQuery([keyName], () => getProducts(locationId), {
      enabled: !!id,
      refetchInterval: 60000
    });

  return {
    status,
    data,
    error,
    isFetching,
    isLoading,
    refetch,
    isSuccess
  };
};

const getProducts = async (locationId) => {
  const data = await client.get(`/branch/findAll/${locationId}`);
  return data?.data?.branchDetails;
};

export const useFetchBrands = (id) => {
  const user = id || getUser()?.id;
  let keyName = `fetchAllBrand/${user}`;

  const {
    status,
    data,
    error,
    isFetching,
    isLoading,
    refetch,
    isSuccess,
    isError
  } = useQuery([keyName], () => getBrandData(user), {
    enabled: !!id,
    refetchInterval: 60000
  });

  return {
    status,
    data,
    error,
    isFetching,
    isLoading,
    refetch,
    isSuccess,
    isError
  };
};

const getBrandData = async (user) => {
  const data = await client.get(`/brand/findAllByUser/${user}`);
  return data.data.user?.Brands;
};

export const days = [
  { label: 'MON', value: 'monday', id: 'monday' },
  { label: 'TUE', value: 'tuesday', id: 'tuesday' },
  { label: 'WED', value: 'wednesday', id: 'wednesday' },
  { label: 'THU', value: 'thursday', id: 'thursday' },
  { label: 'FRI', value: 'friday', id: 'friday' },
  { label: 'SAT', value: 'saturday', id: 'saturday' },
  { label: 'SUN', value: 'sunday', id: 'sunday' }
];
