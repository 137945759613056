import React from 'react'
import Kitchen from '../../pages/kitchen'

export const KitchenView = () => {
  return (
    <>
        <Kitchen />
    </>
  )
}
