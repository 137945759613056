import { Ikon } from 'assets/icons';
import { Button } from 'components/button';
import {
  useFetchRecipes,
  useFetchSingleMixture,
  useFetchSingleRecipe
} from 'hooks/recipes/useRecipesHook';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getBranchId, getLocationId } from 'utilities/helpers/auth';
import { TempAccordion } from '../update';
import styles from './index.module.css';
import { Loading } from 'components/loading';
import { Toasts } from 'components/toasts';
import { client } from 'utilities/db';
import moment from 'moment';

export const RecipeDetail = ({ onEdit, isMixture }) => {
  const { id } = useParams();
  const locationId = getLocationId();
  const branchId = getBranchId();

  const {
    data: recipesData = []
  } = useFetchRecipes(branchId, locationId);
  const getRecipeData = recipesData?.find((item) => Number(item?.RecipeModel?.id) === Number(id));

  const {
    data: fetchedRecipe = {},
    isLoading: fetchedRecipeLoading,
    refetch
  } = useFetchSingleRecipe(!isMixture ? id : false);

  const {
    data: fetchedMixture = {},
    isLoading: fetchedMixtureLoading,
    refetch: refetchMixture
  } = useFetchSingleMixture(isMixture ? id : false);
  // const {
  //   recipe = {},
  //   recipeIngredients = [],
  //   allergens,
  //   // this will be the chart data
  //   productHealthFactor = [],
  //   productNutrientsDetails = []
  // } = fetchedRecipe;

  const recipe = fetchedRecipe?.recipe || {};
  const recipeIngredients = fetchedRecipe?.recipeIngredients || [];
  const allergens = fetchedRecipe?.allergens || [];
  const productHealthFactor = fetchedRecipe?.productHealthFactor || [];
  const productNutrientsDetails = fetchedRecipe?.productNutrientsDetails || [];

  const { mixture_name = '', MixtureRecipeModel = [] } = fetchedMixture || {};

  const recipe_name = recipe?.recipe_name || '';
  const recipe_update_at = recipe?.updated_at || '';
  const cooking_time = recipe?.cooking_time || '00:00';
  const recipe_cost = recipe?.recipe_cost || 0;
  const no_of_ingredients = recipe?.no_of_ingredients || '';
  const total_servings = recipe?.total_servings || '';
  const steps = recipe?.steps ? JSON.parse(recipe?.steps) : [];

  useEffect(() => {
    if (id) {
      if (isMixture) {
        refetchMixture(id);
        return;
      }
      refetch(id);
    }
  }, [id]);

  const enableForMenu = async () => {
    try {
      let bool = fetchedRecipe?.recipe?.add_nutrition_to_menu;
      const response = await client.post(
        `recipe/addNutritionToMenu/${fetchedRecipe?.recipe?.id}`,
        {
          add_nutrition_to_menu: !bool
        }
      );
      refetch();
      Toasts.success(
        `${
          bool
            ? 'Nutrition facts removed from menu'
            : 'Nutrition facts added to menu'
        }`
      );
    } catch (error) {
      console.log('error', error);
      Toasts.error('Something went wrong');
    }
  };

  if (isMixture && !fetchedMixture?.id) {
    return (
      <div className={`loading_full`}>
        <Loading />
      </div>
    );
  }

  if (isMixture && fetchedMixture?.id) {
    return (
      <>
        <article className={styles.article}>
          <div className="flex item_space_btn">
            <div>
              <h1>
                <strong>Recipe engineering</strong>
              </h1>
              <h5 className="mt_4">Manage and create recipes here.</h5>
            </div>
            <div>
              <Button
                onClick={() => onEdit(fetchedMixture)}
                type="button"
                label={'Edit'}
                color={'bg-button'}
              />
            </div>
          </div>
          <h4><span className="ff_semi">{mixture_name}</span></h4>
          <div className={styles.ingredients}>
            <div className={styles.slider}>
              <div className={styles.sliderBr} style={{ width: `calc(175px * ${MixtureRecipeModel?.length})` }}>
                {MixtureRecipeModel?.map((item, index) => (
                  <div key={index} className={styles.ingCard}>
                    <div>
                      <div className={styles.ingCardIcon}>
                        {item?.ProductModel?.product_name?.split(' ')?.[0]?.charAt(0)}
                        {item?.ProductModel?.product_name?.split(' ')?.[1]?.charAt(0)}
                      </div>
                    </div>
                    <div>
                      <h5>{item?.ProductModel?.product_name}</h5>
                    </div>
                    <div
                      className={`${styles.badge} ${
                        item?.type === 'raw' ||
                        item?.ingredient_type?.toLowerCase() === 'raw'
                          ? styles.badge1
                          : styles.badge2
                      }`}
                    >
                      {item?.ingredient_type?.toLowerCase()}
                    </div>
                    <div className={styles.quantity}>
                      <div>Quantity</div>
                      <div className={styles.quantity_dot}></div>
                      <div>{item?.quantity}</div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </article>
      </>
    );
  }

  if (fetchedRecipeLoading || !recipe?.id) {
    return (
      <div className={`loading_full`}>
        <Loading />
      </div>
    );
  }

  return (
    <>
      <article className={styles.article}>
        <div className="flex item_space_btn">
          <div>
            <h1>
              <strong>Recipe engineering</strong>
            </h1>
            <h5 className="mt_4">Manage and create recipes here.</h5>
          </div>
          <div>
            <Button
              onClick={() => onEdit(fetchedRecipe)}
              type="button"
              label={'Edit'}
              color={'bg-button'}
            />
          </div>
        </div>
        <h4>
          <span className="ff_semi">{recipe_name}</span>
        </h4>
        <div className={styles.r1Bx}>
          <div>
            <div className={styles.profileIcon}>
              {getRecipeData?.MenuItemModel?.menu_item_name?.split(' ')?.[0]?.charAt(0)}
              {getRecipeData?.MenuItemModel?.menu_item_name?.split(' ')?.[1]?.charAt(0)}
            </div>
          </div>
          <div className={styles.column1}>
            <div>
              <div className={styles.r1Item1}>
                <label>Last use</label>
                <div className={styles.value}>{moment(recipe_update_at).format("DD MMM YYYY")}</div>
              </div>
            </div>
            <div className={styles.itemsFlex1}>
              <div className={styles.r1Item2}>
                <label>No of ingredients</label>
                <div className={styles.value}>{no_of_ingredients}</div>
              </div>
              <div className={styles.r1Item2}>
                <label>Cooking time</label>
                <div className={styles.value}>{cooking_time}</div>
              </div>
            </div>
          </div>
          <div className={styles.itemsFlex1}>
            <div className={styles.column1}>
              <div>
                <div className={styles.itemIcon}>
                  <Ikon name={`bag-cart`} />
                </div>
              </div>
              <div className={styles.r1Item2}>
                <label>Total cost</label>
                <div className={styles.value}>${recipe_cost}</div>
              </div>
            </div>
            <div className={styles.column1}>
              <div>
                <div className={styles.itemIcon}>
                  <Ikon name={`bag-cart`} />
                </div>
              </div>
              <div className={styles.r1Item2}>
                <label>Calories</label>
                <div className={styles.value}>{getRecipeData?.MenuItemModel?.calories}</div>
              </div>
            </div>
            <div className={styles.column1}>
              <div>
                <div className={styles.itemIcon}>
                  <Ikon name={`bag-cart`} />
                </div>
              </div>
              <div className={styles.r1Item2}>
                <label>Total servings</label>
                <div className={styles.value}>{total_servings}</div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="shadow_box1">
          <div className={styles.recipeDetailFlex}>
            <div className={styles.flexLayer1}>
              <div>
                <div className={styles.label1}>Allergens</div>
                <div className={styles.value1}>
                  {JSON.stringify(allergens)?.replace(/[\[\]'",]+/g, ' ')}
                </div>
              </div>
              <div>
                <div className={styles.label1}>
                  <span>Health score</span>
                  <span>
                    <Ikon name="suport" />
                  </span>
                  <span
                    onClick={enableForMenu}
                    style={{
                      cursor: 'pointer'
                    }}
                  >
                    {fetchedRecipe?.recipe?.add_nutrition_to_menu
                      ? 'Remove from Menu'
                      : 'Add to Menu'}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <div className={styles.ingredients}>
          <div className={styles.slider}>
            <div className={styles.sliderBr} style={{ width: `calc(175px * ${recipeIngredients?.length})` }}>
              {recipeIngredients?.map((item, index) => (
                <div key={index} className={styles.ingCard}>
                  <div>
                    <div className={styles.ingCardIcon}>
                      {item?.ProductModel?.product_name?.split(' ')?.[0]?.charAt(0) || 
                      item?.MixtureModel?.mixture_name?.split(' ')?.[0]?.charAt(0) || 
                      item?.ProductGroupModel?.group_name?.split(' ')?.[0]?.charAt(0)}
                      {item?.ProductModel?.product_name?.split(' ')?.[1]?.charAt(0) || 
                      item?.MixtureModel?.mixture_name?.split(' ')?.[1]?.charAt(0) || 
                      item?.ProductGroupModel?.group_name?.split(' ')?.[1]?.charAt(0)}
                    </div>
                  </div>
                  <div>
                    <h5>
                      {item?.ProductModel?.product_name ||
                        item?.MixtureModel?.mixture_name ||
                        item?.ProductGroupModel?.group_name}
                    </h5>
                  </div>
                  <div
                    className={`${styles.badge} ${
                      item.type === 'raw' ||
                      item?.ingredient_type?.toLowerCase() === 'raw'
                        ? styles.badge1
                        : styles.badge2
                    }`}
                  >
                    {item?.ingredient_type?.toLowerCase()}
                  </div>
                  <div className={styles.quantity}>
                    <div>
                      Quantity
                    </div>
                    <div className={styles.quantity_dot}></div>
                    <div>
                      {item?.quantity}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className={styles.grid}>
          <div>
            <h4>
              Steps for a recipe
            </h4>
            <div className={styles.stepList}>
              {steps?.map((item, index) => (
                <div className={styles.stepCard} key={index}>
                  <div></div>
                  <div>Step {index + 1}:</div>
                  <div>{item?.content}</div>
                </div>
              ))}
            </div>
            {/* {steps?.map((item) => (
              <div className={`${styles.accordionStep} mt_10`} key={item.step}>
                <TempAccordion title={`Step ${item?.step}`}>
                  {item?.content}
                </TempAccordion>
              </div>
            ))} */}
          </div>
          <div>
            <h4>
              Nutritional facts
            </h4>
            <div className={styles.nutList}>
              {productNutrientsDetails?.map((item, i) => (
                <div key={i} className={styles.nutCard}>
                  <div></div>
                  <div>
                    <div>{item?.nutrientName}</div>
                    <div>{item?.value} {item?.unitName}</div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </article>
    </>
  );
};
