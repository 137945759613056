import { useMemo, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { client } from 'utilities/db';
import { useFetchVendorsHook } from 'hooks/vendors/useFetchVendorsHook';
import { ModalComponent } from 'components/modal';
import { PhoneField, TextField } from 'components/input';
import { Button } from 'components/button';
import { Toasts } from 'components/toasts';
import { useStore } from 'store';
import { emailRegex, phoneRegex } from 'utilities/regex';

export const ManualVendorPage = ({
  setShowAddVendor,
  setAdditionType,
  showUpdateVendor,
  setShowUpdateVendor,
  updateData,
  setVendorId
}) => {
  const { brand } = useStore((state) => state);
  // const user = JSON.parse(localStorage.getItem('user'));
  const { vendorRefetch } = useFetchVendorsHook('vendors', brand?.id);

  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
    reset,
    watch
  } = useForm({
    defaultValues: {
      name: showUpdateVendor ? updateData?.vendor_information?.name : '',
      phone: showUpdateVendor ? updateData?.vendor_information?.phone : '',
      email: showUpdateVendor ? updateData?.vendor_information?.email : '',
      address: showUpdateVendor ? updateData?.vendor_information?.address : ''
    }
  });

  console.log('🚀 ~ file: index.jsx:28 ~ errors', watch());

  useMemo(() => {
    if (showUpdateVendor && updateData) {
      reset({
        name: updateData?.vendor_information?.name,
        phone: updateData?.vendor_information?.phone,
        email: updateData?.vendor_information?.email,
        address: updateData?.vendor_information?.address
      });
    } else {
      reset({
        name: '',
        phone: '',
        email: '',
        address: ''
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showUpdateVendor, updateData]);

  const { mutate, isLoading, isError, error, isSuccess } = useMutation(
    (vendorData) => {
      if (showUpdateVendor) {
        return client.post(`/vendor/update/${updateData?.id}`, vendorData);
      }
      return client.post('/vendor/add', vendorData);
    }
  );

  const handleSubmitData = (data) => {
    const { name, phone, email, address } = data;
    mutate({
      vendor_type: 'STREET_VENDOR',
      brand_id: brand?.id,
      vendor_information: {
        name,
        phone,
        email,
        address
      }
    });
  };

  const closeModal = () => {
    setShowAddVendor(false);
    setAdditionType('');
    setShowUpdateVendor(false);
    setVendorId('');
  };

  useMemo(() => {
    if (isSuccess) {
      Toasts.success('Vendor added successfully');
      vendorRefetch();
      closeModal();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  useEffect(() => {
    if (!isError) return;
    Toasts.error(error?.response?.data?.message);
  }, [isError, error]);

  return (
    <>
      <ModalComponent
        modal={closeModal}
        handleModal={closeModal}
        modalMiddle={false}
        rightModal={true}
      >
        <h4>
          <span className="ff_semi">
            {showUpdateVendor ? 'Update' : 'Add New'} Local Vendor
          </span>
        </h4>
        <div className="mt_40"></div>
        <div className="pt_10">
          <form onSubmit={handleSubmit(handleSubmitData)}>
            <TextField
              label="Name"
              placeholder="Name"
              name="name"
              error={
                errors?.name?.type === 'required' ? 'Name is required' : ''
              }
              register={register}
              validation={{
                required: true
              }}
              required={true}
            />
            <PhoneField
              label={`Phone number`}
              name={`phone`}
              control={control}
              rules={{ required: true, pattern: phoneRegex }}
              error={
                errors?.phone?.type === 'required'
                  ? 'Phone number is required'
                  : errors?.phone?.type === 'pattern'
                  ? 'Phone number is invalid'
                  : ''
              }
              required={true}
            />
            {/* <Controller
              name="phone"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <PhoneInput
                  {...field}
                  international
                  countryCallingCodeEditable={false}
                  defaultCountry="PK"
                  error={
                    errors?.phone?.type === 'required'
                      ? 'Phone number is required'
                      : errors?.phone?.type === 'pattern'
                      ? 'Phone number is invalid'
                      : ''
                  }
                />
              )}
            /> */}

            {/* <TextField
              label="Phone"
              placeholder="Phone"
              name="phone"
              register={register}
              error={
                errors?.phone?.type === 'required'
                  ? 'Phone is required'
                  : errors?.phone?.type === 'pattern'
                  ? 'Phone is invalid'
                  : ''
              }
              validation={{
                required: true,
                pattern: phoneRegex
              }}
              type="number"
              required={true}
            /> */}
            <TextField
              label="Email"
              placeholder="Email"
              name="email"
              error={
                errors?.email?.type === 'required'
                  ? 'Email is required'
                  : errors?.email?.type === 'pattern'
                  ? 'Email is invalid'
                  : ''
              }
              register={register}
              validation={{
                required: true,
                pattern: emailRegex
              }}
              required={true}
            />
            <TextField
              label="Address"
              placeholder="Address"
              name="address"
              error={
                errors?.address?.type === 'required'
                  ? 'Address is required'
                  : ''
              }
              register={register}
              validation={{
                required: true
              }}
              required={true}
            />
            <div className="mt_30"></div>
            <hr className="hr_modal_right" />
            <div className="flex item_hend mt_30">
              <Button
                onClick={closeModal}
                color="outline-lite"
                label={'Cancel'}
              />
              <Button
                label={showUpdateVendor ? 'Update' : 'Submit'}
                color="bg-primary"
                type="submit"
                disabled={isLoading}
              />
            </div>
          </form>
        </div>
      </ModalComponent>
    </>
  );
};
