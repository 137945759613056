import { BuilderJSON } from 'store';
import { useFetchGoogleWebfonts } from 'pages/storebuilder/hooks/googlefonts/fetchWebfonts';
import { AsideContentItem } from '../..';
import { BuilderSelectField } from 'pages/storebuilder/components/forms/select';
import { BuilderColorPicker } from 'pages/storebuilder/components/forms/colorpicker';
import { BuilderUploadImage } from 'pages/storebuilder/components/forms/upload';
import { BuilderTextField } from 'pages/storebuilder/components/forms/input';

export const GlobalStyle = () => {
  const {
    pages,
    setHeaderFont,
    setBodyFont,
    setButtonFont,
    setBackgroundColor,
    setBackgroundImage,
    setForegroundColor,
    setButtonPrimaryBackgroundColor,
    setButtonPrimaryTextColor,
    setButtonPrimaryRoundedCorners,
    setButtonSecondaryBackgroundColor,
    setButtonSecondaryTextColor,
    setButtonSecondaryRoundedCorners
  } = BuilderJSON((state) => state);
  const { style } = pages?.global;
  const { fontData } = useFetchGoogleWebfonts();

  return (
    <>
      <AsideContentItem label={`Typography`}>
        <div className={`builder_items`}>
          <BuilderSelectField
            label={`Header font`}
            options={fontData?.items}
            defaultValue={style?.headerFont}
            onChange={(e) => setHeaderFont(e.target.value)}
          />
          <BuilderSelectField
            label={`Body font`}
            options={fontData?.items}
            defaultValue={style?.bodyFont}
            onChange={(e) => setBodyFont(e.target.value)}
          />
          <BuilderSelectField
            label={`Button font`}
            options={fontData?.items}
            defaultValue={style?.buttonFont}
            onChange={(e) => setButtonFont(e.target.value)}
          />
        </div>
      </AsideContentItem>
      <AsideContentItem label={`Background`}>
        <div className={`builder_items`}>
          <BuilderColorPicker
            label={`Color`}
            activeColor={style?.backgroundColor}
            handleActiveColor={setBackgroundColor}
          />
          <BuilderUploadImage
            label={`Image`}
            mediaRecommendations={`Recommended dimensions are 1600 x 1080, types are jpg, jpeg, png, gif or svg`}
            defaultImage={style?.backgroundImage}
            setImage={setBackgroundImage}
          />
        </div>
      </AsideContentItem>
      <AsideContentItem label={`Foreground`}>
        <div className={`builder_items`}>
          <BuilderColorPicker
            label={`Color`}
            activeColor={style?.foregroundColor}
            handleActiveColor={setForegroundColor}
          />
        </div>
      </AsideContentItem>
      <AsideContentItem label={`Button Primary`}>
        <div className={`builder_items`}>
          <BuilderColorPicker
            label={`Background`}
            activeColor={style?.button?.primary?.backgroundColor}
            handleActiveColor={setButtonPrimaryBackgroundColor}
          />
          <BuilderColorPicker
            label={`Text`}
            activeColor={style?.button?.primary?.textColor}
            handleActiveColor={setButtonPrimaryTextColor}
          />
          <BuilderTextField
            type={`number`}
            label={`Corner radius`}
            defaultValue={style?.button?.primary?.roundedCorners}
            onChange={(e) => {
              setButtonPrimaryRoundedCorners(e?.target?.value);
            }}
          />
        </div>
      </AsideContentItem>
      <AsideContentItem label={`Button Secondary`}>
        <div className={`builder_items`}>
          <BuilderColorPicker
            label={`Background`}
            activeColor={style?.button?.secondary?.backgroundColor}
            handleActiveColor={setButtonSecondaryBackgroundColor}
          />
          <BuilderColorPicker
            label={`Text`}
            activeColor={style?.button?.secondary?.textColor}
            handleActiveColor={setButtonSecondaryTextColor}
          />
          <BuilderTextField
            type={`number`}
            label={`Corner radius`}
            defaultValue={style?.button?.secondary?.roundedCorners}
            onChange={(e) => {
              setButtonSecondaryRoundedCorners(e?.target?.value);
            }}
          />
        </div>
      </AsideContentItem>
    </>
  );
};
