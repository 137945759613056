import { PrimaryButton } from 'components/button';
import { Toasts } from 'components/toasts';
import { useFetchFinixMarkup } from 'hooks/finix-payments/useFetchFinixMarkup';
import { useState } from 'react';
import { Slider } from 'rsuite';
import { useStore } from 'store';
import { AXIOS_INSTANCE_ONBOARDING } from 'utilities/db';

const BrandPaymentContracts = () => {
  const { branch } = useStore();
  const { finixMarkupData, finixMarkupLoading } = useFetchFinixMarkup(
    'finixMarkup',
    branch?.id
  );
  console.log(
    '🚀 ~ BrandPaymentContracts ~ finixMarkupData:',
    Number(finixMarkupData?.markup)
  );

  const [markup, setMarkup] = useState(Number(finixMarkupData?.markup) || 0);

  const handleSliderChange = (value) => {
    console.log('🚀 ~ handleSliderChange ~ value:', value);
    setMarkup(value);
  };

  const handleSave = async () => {
    try {
      await AXIOS_INSTANCE_ONBOARDING.post(`/branches/addPaymentLevel`, {
        branch_id: branch?.id,
        markup: Number(markup),
        payment_level: 'BRANCH'
      });
    } catch (error) {
      console.log('🚀 ~ handleSave ~ error:', error);
      Toasts.error(error?.response?.data?.message);
    }
  };

  return (
    <div>
      <p>
        Please select how much markup you will pay to a brand on every
        transaction.
      </p>
      <div style={{ width: '50%', padding: '20px' }}>
        <Slider value={markup} onChange={handleSliderChange} />
        <PrimaryButton disabled={markup === 0} onClick={handleSave}>
          {Number(finixMarkupData?.markup) === 0 ? 'Save' : 'Update'}
        </PrimaryButton>
      </div>
    </div>
  );
};

export default BrandPaymentContracts;
