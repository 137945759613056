import { SetupDomain } from 'pages/setupdomain';
import { BuyDomain } from 'pages/setupdomain/buydomain';
import { ExistingDomain } from 'pages/setupdomain/existingdomain';
import { Domains } from 'pages/setupdomain/domains';

export const SetupDomainView = () => {
  return <SetupDomain />;
};

export { BuyDomain, ExistingDomain, Domains };
