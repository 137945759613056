import { useMemo, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { client } from 'utilities/db';
import { useFetchVendorsHook } from 'hooks/vendors/useFetchVendorsHook';
import { ModalComponent } from 'components/modal';
import { TextField } from 'components/input';
import { Button } from 'components/button';
import { Toasts } from 'components/toasts';
import { useStore } from 'store';
import { emailRegex } from 'utilities/regex';

export const RestDepoVendorPage = ({
  setShowAddVendor,
  setAdditionType,
  showUpdateVendor,
  setShowUpdateVendor,
  updateData,
  setVendorId
}) => {
  const { brand } = useStore((state) => state);

  const user = JSON.parse(localStorage.getItem('user'));
  const { vendorRefetch } = useFetchVendorsHook('vendors', brand?.id);

  const {
    handleSubmit,
    register,
    formState: { errors },
    watch,
    reset
  } = useForm({
    defaultValues: {
      email: showUpdateVendor ? updateData?.vendor_information?.email : '',
      password: showUpdateVendor
        ? updateData?.vendor_information?.password
        : '',
      confirmPassword: ''
    }
  });

  useMemo(() => {
    if (showUpdateVendor && updateData) {
      reset({
        email: updateData?.vendor_information?.email,
        password: updateData?.vendor_information?.password
      });
    } else {
      reset({
        email: '',
        password: ''
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showUpdateVendor, updateData]);

  const { mutate, isLoading, isError, error, isSuccess } = useMutation(
    (vendorData) => {
      if (showUpdateVendor) {
        return client.post(`/vendor/update/${updateData?.id}`, vendorData);
      }
      return client.post('/vendor/add', vendorData);
    }
  );

  const closeModal = () => {
    setVendorId('');
    setShowAddVendor(false);
    setAdditionType('');
    setShowUpdateVendor(false);
  };

  const handleSubmitData = (data) => {
    const { email, password } = data;
    mutate({
      vendor_type: 'RESTAURANT_DEPO',
      brand_id: brand?.id,
      vendor_information: {
        email,
        password
      }
    });
  };

  useMemo(() => {
    if (isSuccess) {
      Toasts.success('Vendor added successfully');
      vendorRefetch();
      closeModal();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  useEffect(() => {
    if (!isError) return;
    Toasts.error(error?.response?.data?.message);
  }, [isError]);

  return (
    <>
      <ModalComponent
        modal={closeModal}
        handleModal={closeModal}
        modalMiddle={false}
        rightModal={true}
      >
        <h4>
          <span className="ff_semi">
            {showUpdateVendor ? 'Update' : 'Add'} Restaurant Depo Vendor
          </span>
        </h4>
        <div className="mt_40"></div>
        <div className="pt_10">
          <form onSubmit={handleSubmit(handleSubmitData)}>
            <TextField
              label="Email"
              placeholder="Email"
              name="email"
              error={
                errors?.email?.type === 'required'
                  ? 'Email is required'
                  : errors?.email?.type === 'pattern'
                  ? 'Email is invalid'
                  : ''
              }
              register={register}
              validation={{
                required: true,
                pattern: emailRegex
              }}
              required={true}
            />
            <TextField
              label="Password"
              placeholder="Password"
              name="password"
              error={
                errors?.password?.type === 'required'
                  ? 'Password is required'
                  : ''
              }
              register={register}
              validation={{
                required: true
              }}
              required={true}
            />
            <TextField
              label="Confirm Password"
              placeholder="Confirm Password"
              name="confirmPassword"
              error={
                errors?.confirmPassword?.type === 'required'
                  ? 'Confirm Password is required'
                  : watch('password') !== '' &&
                    watch('confirmPassword') !== '' &&
                    watch('password') !== watch('confirmPassword')
                  ? 'Password does not match'
                  : ''
              }
              register={register}
              validation={{
                required: true
              }}
              required={true}
            />
            <div className="mt_30"></div>
            <hr className="hr_modal_right" />
            <div className="flex item_hend mt_30">
              <Button
                onClick={closeModal}
                color="outline-lite"
                label={'Cancel'}
              />
              <Button
                label={showUpdateVendor ? 'Update' : 'Submit'}
                color="bg-primary"
                type="submit"
                disabled={isLoading}
              />
            </div>
          </form>
        </div>
      </ModalComponent>
    </>
  );
};
