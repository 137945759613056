import { RenderIf } from 'utilities/helpers';
import styles from './index.module.css';

export const BuilderTextareaField = ({
  label,
  name,
  id,
  minLength,
  maxLength,
  rows,
  cols,
  placeholder,
  defaultValue,
  error,
  onChange
}) => {
  return (
    <article className={styles.article}>
      <RenderIf isTrue={label}>
        <label>{label}</label>
      </RenderIf>
      <div>
        <textarea
          name={name}
          id={id}
          minLength={minLength}
          maxLength={maxLength}
          rows={rows}
          cols={cols}
          placeholder={placeholder}
          defaultValue={defaultValue}
          onChange={onChange}
        />
      </div>
      <RenderIf isTrue={error}>
        <div className={styles.error}>{error}</div>
      </RenderIf>
    </article>
  );
};
