import { client } from 'utilities/db';
import { useQuery } from 'react-query';

export const useFetchInventoryHook = (keyName = 'inventory', id) => {
  const { status, data, error, isFetching, isLoading, refetch } = useQuery(
    [keyName],
    () => getInventoryData(id),
    {
      enabled: !!id,
      refetchInterval: 60000
    }
  );

  return {
    inventoryStatus: status,
    inventoryData: data,
    inventoryError: error,
    inventoryFetching: isFetching,
    inventoryLoading: isLoading,
    inventoryRefetch: refetch
  };
};

const getInventoryData = async (id) => {
  let result = await client.get(`/inventory/findAllByBranch/${id}`);
  result = result.data;
  result = result?.inventories;

  return result;
};
