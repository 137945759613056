import { Button } from 'components/button';
import { useFetchOneBrandHook } from 'hooks/brands/useFetchOneBrandHook';
import { useNavigate } from 'react-router-dom';
import { RenderIf } from 'utilities/helpers';
import { Loading } from 'components/loading';

export const CompanyInfoPage = ({ brandId }) => {
  const navigate = useNavigate();

  const { oneBrandData, oneBrandLoading } = useFetchOneBrandHook(
    `brand/${brandId}`,
    brandId
  );
  console.log(
    '🚀 ~ file: index.jsx ~ line 10 ~ CompanyInfoPage ~ brandData',
    oneBrandData
  );

  return (
    <>
      <div className="mt_20"></div>
      <div className="shadow_box1">
        <h2>
          <span className="ff_semi">Comapany information</span>
        </h2>
        <div className="mt_10"></div>
        <div className="flex item_hend">
          <Button
            disabled={
              oneBrandData?.business_information &&
              oneBrandData?.representative_information
            }
            label={'Add Company Info'}
            color="bg-titary"
            onClick={() => {
              navigate(`/portfolio/brand/company/add/${brandId}`, {
                state: { companyType: oneBrandData?.company_type }
              });
            }}
          />
        </div>
        <RenderIf isTrue={oneBrandLoading}>
          <div className={`loading_full`}>
            <Loading />
          </div>
        </RenderIf>
        <RenderIf
          isTrue={
            oneBrandData?.business_information &&
            oneBrandData?.representative_information
          }
        >
          <div className="grid grid_gap30">
            <div className="collg6">
              <div className="mt_15"></div>
              <h4>
                <span className="ff_semi">Business information</span>
              </h4>
              <div className="mt_15"></div>
              <dl>
                <dt>
                  <b>Business name:</b>
                </dt>
                <dd>
                  <strong>
                    {oneBrandData?.business_information?.buisness_name}
                  </strong>
                </dd>
                <dt>
                  <b>Business email:</b>
                </dt>
                <dd>
                  <strong>
                    {oneBrandData?.business_information?.buisness_email}
                  </strong>
                </dd>
                <dt>
                  <b>Business phone:</b>
                </dt>
                <dd>
                  <strong>
                    {oneBrandData?.business_information?.buisness_phone}
                  </strong>
                </dd>
                <dt>
                  <b>Business url:</b>
                </dt>
                <dd>
                  <strong>
                    {oneBrandData?.business_information?.buisness_url}
                  </strong>
                </dd>
                <dt>
                  <b>Company description:</b>
                </dt>
                <dd>
                  <strong>
                    {oneBrandData?.business_information?.buisness_description}
                  </strong>
                </dd>
              </dl>
            </div>
            <div className="collg6">
              <div className="mt_15"></div>
              <h4>
                <span className="ff_semi">Representative information</span>
              </h4>
              <div className="mt_15"></div>
              <dl>
                <dt>
                  <b>First name:</b>
                </dt>
                <dd>
                  <strong>
                    {oneBrandData?.representative_information?.first_name}
                  </strong>
                </dd>
                <dt>
                  <b>Last name:</b>
                </dt>
                <dd>
                  <strong>
                    {oneBrandData?.representative_information?.last_name}
                  </strong>
                </dd>
                <dt>
                  <b>Phone:</b>
                </dt>
                <dd>
                  <strong>
                    {oneBrandData?.representative_information?.phone}
                  </strong>
                </dd>
                <dt>
                  <b>Date of birth:</b>
                </dt>
                <dd>
                  <strong>
                    {oneBrandData?.representative_information?.dob}
                  </strong>
                </dd>
                <dt>
                  <b>SSN:</b>
                </dt>
                <dd>
                  <strong>
                    {oneBrandData?.representative_information?.ssn}
                  </strong>
                </dd>
                <dt>
                  <b>Country:</b>
                </dt>
                <dd>
                  <strong>
                    {oneBrandData?.representative_information?.country}
                  </strong>
                </dd>
                <dt>
                  <b>State:</b>
                </dt>
                <dd>
                  <strong>
                    {oneBrandData?.representative_information?.state}
                  </strong>
                </dd>
                <dt>
                  <b>City:</b>
                </dt>
                <dd>
                  <strong>
                    {oneBrandData?.representative_information?.city}
                  </strong>
                </dd>
                <dt>
                  <b>Zip code:</b>
                </dt>
                <dd>
                  <strong>
                    {oneBrandData?.representative_information?.zip}
                  </strong>
                </dd>
                <dt>
                  <b>Address:</b>
                </dt>
                <dd>
                  <strong>
                    {oneBrandData?.representative_information?.address}
                  </strong>
                </dd>
              </dl>
            </div>
          </div>
        </RenderIf>
      </div>
    </>
  );
};
