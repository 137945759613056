import { useNavigate } from 'react-router-dom';
import { useFetchBranchManagersHooks } from 'hooks/managers/useFetchBranchManagerHooks';
import { RenderIf } from 'utilities/helpers';
import { Button } from 'components/button';
import { useFetchSingleBrand } from 'hooks/brands/useBrandHook';
import { Loading } from 'components/loading';

export const ManagerListingPage = ({ brandId, branchId }) => {
  const navigate = useNavigate();

  const { data: brandData } = useFetchSingleBrand(brandId);

  const { branchManagerData, branchManagerLoading } =
    useFetchBranchManagersHooks(
      `branch_manager/${branchId}`,
      branchId,
      brandData?.brand_type
    );

  return (
    <>
      <div className="mt_20"></div>
      <div className="shadow_box1">
        <h2>
          <span className="ff_semi">Branch manager</span>
        </h2>
        <div className="mt_10"></div>
        <div className="flex item_hend">
          <Button
            label="Add a Manager"
            color="bg-titary"
            onClick={() =>
              navigate(`/portfolio/branch/manager/add/${brandId}/${branchId}`)
            }
            disabled={branchManagerData ? true : false}
          />
        </div>
        <RenderIf isTrue={branchManagerLoading}>
          <div className={`loading_full`}>
            <Loading />
          </div>
        </RenderIf>
        <RenderIf isTrue={branchManagerData}>
          <div>
            <div className="mt_15"></div>
            <h4>
              <span className="ff_semi">Manager information</span>
            </h4>
            <div className="mt_15"></div>
            <dl>
              <dt>
                <b>Manager name:</b>
              </dt>
              <dd>
                <strong>{branchManagerData?.name}</strong>
              </dd>
              <dt>
                <b>Manager email:</b>
              </dt>
              <dd>
                <strong>{branchManagerData?.UserModel?.[0]?.email}</strong>
              </dd>
            </dl>
          </div>
        </RenderIf>
      </div>
    </>
  );
};
