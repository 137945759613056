// import { useState } from 'react';
import Kitchenplan from 'components/demandplanning/kitchenplan';
import Preditedsales from 'components/demandplanning/preditedsales';
import Predictions from 'components/demandplanning/predictions';

export default function DemandPlanningPage() {
  // const [search, setSearch] = useState('');
  return (
    <>
      <div className="mt_40"></div>
      <h2>
        <span className="ff_semi">Demand Planning</span>
      </h2>
      <h5>
        <strong>We hope you're having a good day.</strong>
      </h5>
      <Kitchenplan />
      <Preditedsales />
      <Predictions />
    </>
  );
}
