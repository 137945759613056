import { client } from 'utilities/db';
import { useQuery } from 'react-query';

export const useFetchOnlineContentHook = (keyName = 'onlineContent', id) => {
  const { status, data, error, isFetching, isLoading, refetch } = useQuery(
    [keyName],
    () => getOnlineContentData(id),
    {
      enabled: !!id,
      refetchInterval: 60000
    }
  );

  return {
    onlineContentStatus: status,
    onlineContentData: data,
    onlineContentError: error,
    onlineContentFetching: isFetching,
    onlineContentLoading: isLoading,
    onlineContentRefetch: refetch
  };
};

const getOnlineContentData = async (id) => {
  let result = await client.get(`/zewstOnlineContent/findAllByBrand/${id}`);
  result = result.data;
  result = result?.zewstOnlineContents;
  return result;
};
