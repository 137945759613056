import moment from 'moment/moment';
import React from 'react';
import styles from './index.module.css';

export const BatchBox = ({ batch, servings, expiry, unit, index }) => {
  return (
    <div className={`shadow_box p10  border rounded p-2 ${styles.batch_box}`}>
      <p className={styles.batch_txt}>Batch {batch}</p>
      <div
        className={`flex align-items-center justify-content-between mt-2 `}
        style={{ justifyContent: 'space-between', marginTop: '8px' }}
      >
        <b>
          {servings} {unit || 'Servings'}
        </b>
        <div
          className="p-1 px-2 bg-red-400 ml-4 rounded-full text-white"
          style={{
            backgroundColor: index == 0 ? '#F16063' : '#66CB9F',
            width: 130,
            borderRadius: 14,
            height: 20,
            alignItems: 'center'
          }}
        >
          <p
            style={{
              textAlign: 'center',
              color: '#fff',
              fontSize: '10px',
              fontWeight: '600',
              marginTop: 2
            }}
          >
            Expiring in {moment(expiry).diff(moment(), 'days')} days
          </p>
        </div>
      </div>
    </div>
  );
};
export const IngredientBox = ({ item, multiplier }) => (
  <>
    <div className={`shadow_box p10 border rounded ${styles.invntryBox}`}>
      <h4 style={{ fontWeight: '700', fontSize: '15px' }}>
        {item?.ProductModel?.product_name ||
          item?.MixtureModel?.mixture_name ||
          item?.ProductGroupModel?.product_group_name}
        <span
          className={`ml-2 text-[10px] px-2 rounded p-1 ${
            item?.ingredient_type === 'RAW'
              ? 'text-green-500 bg-green-200'
              : 'text-orange-500 bg-orange-200'
          }`}
        >
          {item?.ingredient_type || 'Ingedient'}
        </span>
      </h4>
      <br />
      <p style={{ color: ' #425466', fontWeight: 500, fontSize: '14px' }}>
        Quantity
      </p>
      <p style={{ color: ' #16192C', fontWeight: 500, fontSize: '13px' }}>
        {item?.quantity * (multiplier > 1 ? multiplier : 1) || 5}{' '}
        {item?.unit || 'KG'}
      </p>
    </div>
  </>
);
export const RecipeMixture = ({ item, onClick, isSelected }) => (
  <>
    <div
      onClick={onClick}
      className={`shadow_box p10 border rounded ${
        isSelected ? styles.mixtureBoxs : styles.mixtureBox
      }`}
    >
      <img
        className={styles.mixImg}
        src={
          'https://cdn.pixabay.com/photo/2022/09/29/17/15/halloween-7487706_960_720.jpg'
        }
      />
      <b className="mt-2 text-center">{item?.recipe_name || 'recipe name'}</b>
    </div>
  </>
);
