import { Button } from 'components/button';
import { CustomTable } from 'components/datatable';
import { useFetchBranches, useFetchBrands } from 'hooks/resourceHooks';
import { useFetchRolesHook } from 'hooks/roles/useFetchRolesHook';
import { ManualVendorPage } from 'pages/vendors/manual';
import React, { useState } from 'react';
import { Table } from 'rsuite';
import { useStore } from 'store';
import { RenderIf } from 'utilities/helpers';
import { RoleModal } from '../rolemodal';

const Role = () => {
  const { Column, HeaderCell, Cell } = Table;
  const { user, brand } = useStore((state) => state);
  const { roleData: Roles, roleLoading } = useFetchRolesHook(
    'roles',
    brand?.id
  );
  const { data: branchesData, isLoading: branchesLoading } = useFetchBranches(
    brand?.id
  );
  const { data: brandsData, isLoading: brandsLoading } = useFetchBrands(
    user?.id
  );
  const [addRole, setAddRole] = useState(false);

  const RoleCell = ({ rowData, dataKey, ...props }) => (
    <Cell {...props}>
      <b>{rowData?.name}</b>
    </Cell>
  );

  const ActionsCell = ({ setVendorId, rowData, dataKey, ...props }) => {
    return (
      <Cell {...props}>
        <button
          className="button_sm button_radius"
          color="bg-primary"
          onClick={() => {}}
        >
          Update
        </button>
      </Cell>
    );
  };
  return (
    <>
      <div className="mt_40"></div>
      <div className="flex item_space_btn pt_10">
        <div>
          <h1>
            <strong>Roles</strong>
          </h1>
          <h5 className="mt_4">We hope you're having a good day.</h5>
        </div>
        <div>
          <Button
            label="Add Role"
            color="bg-titary"
            onClick={() => {
              setAddRole(true);
            }}
          />
        </div>
      </div>
      <div className="mt_40"></div>
      <RenderIf isTrue={Roles == undefined || Roles?.length == 0}>
        <div className={`no_data1`}>No data found.</div>
      </RenderIf>

      <div>
        <RenderIf isTrue={Roles?.length > 0}>
          <CustomTable
            rows={Roles?.map((role, index) => {
              return {
                id: index + 1,
                name: role?.name || '-',
                description: role?.description || '-',
                brand_name:
                  brandsData?.find((brand) => brand?.id === role?.brand_id)
                    ?.brand_name || '-',
                branch_name:
                  branchesData?.find((branch) => branch?.id === role?.branch_id)
                    ?.branch_name || '-'
              };
            })}
            // loading={vendorLoading}
            pageLimit={10}
            pagination={Roles?.length > 10 ? true : false}
            loading={roleLoading}
          >
            <Column width={50} align="center" fixed>
              <HeaderCell>Id</HeaderCell>
              <Cell dataKey="id" />
            </Column>
            <Column width={200} sortable>
              <HeaderCell>Name</HeaderCell>
              <RoleCell dataKey="name" />
            </Column>
            {/* <Column width={320}>
              <HeaderCell>Description</HeaderCell>
              <Cell dataKey="description" />
            </Column> */}

            <Column width={200} sortable>
              <HeaderCell>Brand</HeaderCell>
              <Cell dataKey="brand_name" />
            </Column>
            <Column flexGrow={1} width={200} sortable>
              <HeaderCell>Branch Id</HeaderCell>
              <Cell dataKey="branch_name" />
            </Column>

            {/* <Column width={120} align="center" fixed="right">
              <HeaderCell>Actions</HeaderCell>
              <ActionsCell setVendorId={'setVendorId'} dataKey="actions" />
            </Column> */}
          </CustomTable>
        </RenderIf>
      </div>
      <div>
        <RenderIf isTrue={addRole}>
          <RoleModal type="Add Role" setRole={setAddRole} Role={addRole} />
        </RenderIf>
      </div>
    </>
  );
};

export default Role;
