import { Button } from 'components/button';
import { Toasts } from 'components/toasts';
import { useFetchProductsResDepoHook } from 'hooks/products/useFetchProductsResDepoHook';
import { useFetchVendorsHook } from 'hooks/vendors/useFetchVendorsHook';
import { useState } from 'react';
import { useStore } from 'store';
import { client } from 'utilities/db';
import { RenderIf } from 'utilities/helpers';
import { Loading } from 'components/loading';

export const RestaurantDepoInventoryPage = ({ setRecieptData }) => {
  let user = JSON.parse(localStorage.getItem('user'));

  let dataRequestWasSent =
    localStorage.getItem('restaurantDepoDataHasStartedFetching') || 'false';

  const { brand } = useStore((state) => state);

  const { vendorData } = useFetchVendorsHook('vendors', brand?.id);
  const { productsResDepoData, productsResDepoLoading } =
    useFetchProductsResDepoHook(`restaurantDepo/${user?.id}`, user?.id);

  const [dataLoading, setDataLoading] = useState(false);

  const postRequestForData = async () => {
    try {
      setDataLoading(true);
      let vendorId = vendorData?.find(
        (vendor) => vendor?.vendor_type === 'RESTAURANT_DEPO'
      )?.id;
      const response = await client.post(
        `/inventoryReceipt/send-res-depo-request/${vendorId}`
      );
      Toasts.success('Data request for Restaurant Depo sent successfully');
      setDataLoading(false);
      localStorage.setItem('restaurantDepoDataHasStartedFetching', 'true');
    } catch {
      Toasts.error('Error sending data for Restaurant Depo request');
      localStorage.setItem('restaurantDepoDataHasStartedFetching', 'false');
      setDataLoading(false);
    }
  };

  const handleAddInventory = () => {
    setRecieptData(productsResDepoData);
  };

  return (
    <>
      <RenderIf
        isTrue={dataRequestWasSent === 'false' || dataRequestWasSent === null}
      >
        <h4>
          <span className="ff_semi">Restaurant Depo Inventory Page</span>
        </h4>
        <div className="mt_40"></div>
        <Button label="Fetch Data" onClick={postRequestForData} />
        <RenderIf isTrue={dataLoading}>
          <div className={`loading_full`}>
            <Loading />
          </div>
        </RenderIf>
      </RenderIf>
      <RenderIf isTrue={dataRequestWasSent === 'true'}>
        <h4>
          <span className="ff_semi">Restaurant Depo Inventory Page</span>
        </h4>
        <div className="pt_10">
          <p style={{ color: 'orange' }}>
            Fetching data from Restaurant Depo Request has already been sent.
          </p>
          <Button
            label="Continue Adding Inventory"
            onClick={handleAddInventory}
            disabled={!productsResDepoData || productsResDepoData?.length === 0}
          />
        </div>
      </RenderIf>
    </>
  );
};
