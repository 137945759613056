import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { FieldInput } from '../../components/input';
import { FieldCheckbox } from 'components/checkbox';
import { Button } from '../../components/button';
import { RenderIf } from 'utilities/helpers';
import { OtpPage } from './otp';
import { client } from 'utilities/db';
import { useMutation } from 'react-query';
import { useFetchCountries } from 'hooks/resourceHooks';
import { CustomSelectDropdown } from 'components/select/multi';
import { Toasts } from 'components/toasts';
import { emailRegex } from 'utilities/regex';
import { US_STATES } from 'utilities/constants';

export const SignupPage = () => {
  const navigate = useNavigate();
  const { data: countryList, isLoading: countryLoading } = useFetchCountries();
  const { mutate, isLoading, isSuccess, isError, error } = useMutation(
    (userData) => {
      return client.post(`/user/signup`, userData);
    }
  );
  const [userEmail, setUserEmail] = useState('');
  const [step, setStep] = useState(1);
  const {
    handleSubmit,
    register,
    formState: { errors },
    control,
    watch
  } = useForm();
  const onSignup = (val) => {
    const { first_name, last_name, email, password, country, state } = val;
    setUserEmail(email);
    mutate({
      email: email,
      password: password,
      country_id: country.value,
      state: state.value,
      name: first_name,
      last_name: last_name
    });
  };

  useEffect(() => {
    const user = window.localStorage.getItem('user');
    if (user) {
      navigate('/');
    }
    return;
  }, [navigate]);

  useEffect(() => {
    if (!isError) return;
    if (error?.response?.data?.message === 'Email exists but not verified') {
      setStep(2);
      return;
    }
    Toasts.error(error?.response?.data?.message);
  }, [isError, error]);

  useEffect(() => {
    if (!isSuccess) return;
    setStep(2);
  }, [isSuccess]);

  return (
    <article className="full">
      <div className="auth_box">
        <div className="mt_40"></div>
        <div className="pt_40"></div>
        <div className="mt_40"></div>
        <RenderIf isTrue={step === 1}>
          <form onSubmit={handleSubmit(onSignup)}>
            <h2 className="text_center">
              <span className="ff_semi">Create your account</span>
            </h2>
            <div className="mt_16"></div>
            <div className="auth_form">
              <div className={`grid grid_gap10`}>
                <div className={`colmd6`}>
                  <div className="mt_16"></div>
                  <FieldInput
                    name="first_name"
                    label={'First name'}
                    type="text"
                    error={
                      errors?.name?.type === 'required'
                        ? 'First name is required'
                        : ''
                    }
                    register={register}
                    validation={{
                      required: true
                    }}
                    required
                  />
                </div>
                <div className={`colmd6`}>
                  <div className="mt_16"></div>
                  <FieldInput
                    name="last_name"
                    label={'Last name'}
                    type="text"
                    error={
                      errors?.name?.type === 'required'
                        ? 'Last name is required'
                        : ''
                    }
                    register={register}
                    validation={{
                      required: true
                    }}
                    required
                  />
                </div>
              </div>
              <div className="mt_16"></div>
              <FieldInput
                name="email"
                label={'Email address'}
                type="text"
                error={
                  errors?.email?.type === 'required'
                    ? 'Email is required'
                    : errors?.email?.type === 'pattern'
                    ? 'Email is invalid'
                    : ''
                }
                register={register}
                validation={{
                  required: true,
                  pattern: emailRegex
                }}
                required
              />
              <div className={`grid grid_gap10`}>
                <div className={`colmd6`}>
                  <div className="mt_16"></div>
                  <FieldInput
                    name="password"
                    label={'Password'}
                    type="password"
                    error={
                      errors?.password?.type === 'required'
                        ? 'Password is required'
                        : errors?.password?.type === 'minLength'
                        ? 'Password must be at least 8 characters'
                        : errors?.password?.type === 'maxLength'
                        ? 'Password must be at most 20 characters'
                        : ''
                    }
                    register={register}
                    validation={{
                      required: true,
                      minLength: 8,
                      maxLength: 20
                    }}
                    required
                  />
                </div>
                <div className={`colmd6`}>
                  <div className="mt_16"></div>
                  <FieldInput
                    name="confirmPassword"
                    label={'Confirm Password'}
                    type="password"
                    error={
                      errors?.confirmPassword?.type === 'required'
                        ? 'Confirm Password is required'
                        : errors?.confirmPassword?.type === 'minLength'
                        ? 'Confirm Password must be at least 8 characters'
                        : errors?.confirmPassword?.type === 'maxLength'
                        ? 'Confirm Password must be at most 20 characters'
                        : errors?.confirmPassword?.type === 'validate'
                        ? 'Confirm Password must match Password'
                        : ''
                    }
                    register={register}
                    validation={{
                      required: true,
                      minLength: 8,
                      maxLength: 20,
                      validate: (value) => value === watch('password')
                    }}
                    required
                  />
                </div>
              </div>
              <div className={`grid grid_gap10`}>
                <div className={`colmd6`}>
                  <div className="mt_16"></div>
                  <Controller
                    name="country"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <CustomSelectDropdown
                        hookForm={true}
                        field={field}
                        label="Country"
                        placeholder="Choose country"
                        options={countryList?.map((country) => ({
                          value: country?.id,
                          label: country?.name
                        }))}
                        error={
                          errors?.country?.type === 'required'
                            ? 'Country is required'
                            : ''
                        }
                        isLoading={countryLoading}
                        value={{
                          value: countryList?.[0]?.id,
                          label: countryList?.[0]?.name
                        }}
                      />
                    )}
                  />
                </div>
                <div className={`colmd6`}>
                  <div className="mt_16"></div>
                  <Controller
                    name="state"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <CustomSelectDropdown
                        hookForm={true}
                        field={field}
                        label="State"
                        placeholder="Choose state"
                        options={US_STATES}
                        error={
                          errors?.country?.type === 'required'
                            ? 'State is required'
                            : ''
                        }
                      />
                    )}
                  />
                </div>
              </div>
              <div className="mt_30"></div>
              <FieldCheckbox
                name="privacyNTerms"
                id="privacyNTerms"
                register={register}
              >
                <>
                  I agree to ZEWST's&nbsp;
                  <Link to={`/`}>Terms</Link>,&nbsp;
                  <Link to={`/`}>Privacy Policy</Link>, and E-Sign Consent.
                </>
              </FieldCheckbox>
              <div className="mt_30"></div>
              <div className={`field`}>
                <Button
                  isLoading={isLoading}
                  disabled={!watch('privacyNTerms') || isLoading}
                  type="submit"
                  label={'Register'}
                  color="bg-primary"
                  full
                />
              </div>
              <div className="mt_20"></div>
              <div className={`flex item_hcenter`}>
                <label className="label">
                  Already a Member? <Link to="/login">Login</Link>
                </label>
              </div>
            </div>
            <div className="mt_40"></div>
          </form>
        </RenderIf>
        <RenderIf isTrue={step === 2}>
          <OtpPage setStep={setStep} email={userEmail} />
        </RenderIf>
      </div>
    </article>
  );
};
