import { Table } from 'rsuite';
import { RenderIf } from 'utilities/helpers';
import { CustomTable } from 'components/datatable';
import EmptyInventoryImg from './../../../assets/Images/Empty_inventory.png';
import { Ikon } from 'assets/icons';
import styles from './index.module.css';
import { useParams } from 'react-router-dom';
import { useFetchInventoryProductHook } from 'hooks/inventory/useFetchInventoryProductHook';

const { Column, HeaderCell, Cell } = Table;

export const ViewItemDetails = () => {
  const { id } = useParams();

  const { inventoryData, inventoryLoading } = useFetchInventoryProductHook(
    `inventory/findAllByProduct/${id}`,
    id
  );

  const filteredData = inventoryData?.inventories?.filter((item) => {
    return item != null;
  });

  const product = inventoryData?.product;

  return (
    <>
      <header className={styles.header}>
        <div>
          <h2>Inventory</h2>
          <h5>We hope you're having a good day.</h5>
        </div>
        
      </header>
      <div className={styles.content}>
        <RenderIf isTrue={filteredData?.length === 0}>
          <div className={styles.empty_state}>
            <h2>You currently have no inventory for selected item</h2>
            <picture>
              <source srcSet={EmptyInventoryImg} />
              <img src={EmptyInventoryImg} alt={`Empty Inventory`} />
            </picture>
            
          </div>
        </RenderIf>
        <RenderIf isTrue={filteredData?.length > 0}>
        <div className={styles.rowEqCol}>
            <div>
              <button
                className={`${styles.statBx} ${styles.activeStat}`}
                onClick={() => {}}
              >
                <div>
                  <div className={styles.statIcon}>
                    <Ikon name={`all-products`} fillColor={`var(--white)`} />
                  </div>
                </div>
                <div>
                  <div className={styles.statLabel}>Product Name:</div>
                  <div className={styles.statValue}>{product?.product_name}</div>
                </div>
              </button>
            </div>
          </div>
          <div style={{ marginTop: 24 }}></div>
          <div className={styles.contentBx}>
            <CustomTable
              rows={filteredData?.map((inv, index) => {
                return {
                  id: index + 1,
                  batch_number: inv?.batch_number || '-',
                  quantity: inv?.quantity || '-',
                  unit: inv?.unit || '-',
                  total_price: '$' + parseFloat(inv?.total_price)?.toFixed(4) || '-',
                  purchase_date: inv?.purchase_date || '-',
                  best_before: inv?.best_before || '-',
                  converted_quantity: inv?.converted_quantity || '-',
                  converted_unit: inv?.converted_unit || '-',
                  unit_price: '$' + parseFloat(inv?.unit_price)?.toFixed(4) || '-',
                };
              })}
              loading={inventoryLoading}
              rowHeight={45}
              pageLimit={10}
              pagination={filteredData?.length > 10 ? true : false}
            >
              <Column width={50} fixed>
                <HeaderCell>Id</HeaderCell>
                <Cell dataKey="id" />
              </Column>
              <Column flexGrow={1} sortable>
                <HeaderCell>Batch</HeaderCell>
                <Cell dataKey="batch_number" />
              </Column>
              <Column width={100} sortable>
                <HeaderCell>Quantity</HeaderCell>
                <Cell dataKey="quantity" />
              </Column>
              <Column width={120} sortable>
                <HeaderCell>Unit</HeaderCell>
                <Cell dataKey="unit" />
              </Column>
              <Column width={120} sortable>
                <HeaderCell>Total Price</HeaderCell>
                <Cell dataKey="total_price" />
              </Column>
              <Column width={100} sortable>
                <HeaderCell>Purchase Date</HeaderCell>
                <Cell dataKey="purchase_date" />
              </Column>
              <Column width={100} sortable>
                <HeaderCell>Best Before</HeaderCell>
                <Cell dataKey="best_before" />
              </Column>
              <Column width={160} sortable>
                <HeaderCell>Converted Quantity</HeaderCell>
                <Cell dataKey="converted_quantity" />
              </Column>
              <Column width={110} sortable>
                <HeaderCell>Converted Unit</HeaderCell>
                <Cell dataKey="converted_unit" />
              </Column>
              <Column width={110} sortable>
                <HeaderCell>Unit Price</HeaderCell>
                <Cell dataKey="unit_price" />
              </Column>
            </CustomTable>
          </div>
        </RenderIf>
      </div>
    </>
  );
};
