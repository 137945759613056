import { useEffect, useState } from 'react';
import { RenderIf } from 'utilities/helpers';
import { AddBrand } from 'pages/addbrand';
import { AddEmployeeInformation } from 'pages/addbrand/employee';
import { AddBankInformation } from 'pages/addbrand/bank';
import { useFetchBranches, useFetchBrands } from 'hooks/resourceHooks';
import { useFetchBankDetailsHook } from 'hooks/brands/useFetchBankDetailsHook';
import { useFetchManagerHook } from 'hooks/managers/useFetchManagerHooks';
import { useNavigate } from 'react-router-dom';
import { useStore } from 'store';

export const AddBrandsView = () => {
  const { setBranch, setBrand } = useStore((state) => state);

  const navigate = useNavigate();

  const { data, isLoading } = useFetchBrands();

  const { bankData, bankLoading } = useFetchBankDetailsHook(
    `bankDetail/${data?.[0]?.id}`,
    data?.[0]?.id
  );

  const { managerData } = useFetchManagerHook(
    `manager/${data?.[0]?.id}`,
    data?.[0]?.id
  );

  const { data: branchData } = useFetchBranches(data?.[0]?.id);

  const [step, setStep] = useState(1);

  const onChangeStep = (step) => {
    setStep(step);
  };

  const onGoBack = () => {
    if (step === 1) return;
    setStep(step - 1);
  };

  useEffect(() => {
    if (isLoading) return;
    if (data?.length > 0) {
      setBrand(data?.[0]);
      setStep(2);

      if (bankData?.id) {
        setStep(3);
      }

      if (branchData?.length > 0) {
        setBranch(branchData?.[0]);
        navigate('/dashboard');
      }
    }
  }, [isLoading, data, bankLoading, bankData, managerData, branchData]);

  return (
    <>
      <RenderIf isTrue={isLoading}>
        <h1>Loading</h1>
      </RenderIf>
      <RenderIf isTrue={step === 1}>
        <AddBrand onChangeStep={onChangeStep} />
      </RenderIf>
      <RenderIf isTrue={step === 2}>
        <AddBankInformation onChangeStep={onChangeStep} onGoBack={onGoBack} />
      </RenderIf>
      <RenderIf isTrue={step === 3}>
        <AddEmployeeInformation
          onChangeStep={onChangeStep}
          onGoBack={onGoBack}
        />
      </RenderIf>
    </>
  );
};
