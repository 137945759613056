import { useState } from 'react';
import { BuilderJSON } from 'store';
import styles from './index.module.css';
import { Ikon } from 'assets/icons';
import { ButtonThemePrimary } from 'pages/storebuilder/components/forms/buttons/themeprimary';
import { ButtonThemeSecondary } from 'pages/storebuilder/components/forms/buttons/themesecondary';
import { ButtonThemeTertiary } from 'pages/storebuilder/components/forms/buttons/themetertiary';
import { RenderIf } from 'utilities/helpers';

export const BuilderThemeHeader = () => {
  const { pages, screenView } = BuilderJSON((state) => state);
  const { content, style } = pages?.header;
  const [menuActive, setMenuActive] = useState(false);

  return (
    <>
      <header
        id={`Header`}
        className={
          screenView === `WEB_VIEW`
            ? styles.header
            : `${styles.header} ${styles.mobileView}`
        }
        style={{
          backgroundColor: style?.backgroundColor,
          backgroundImage: `url(${style?.backgroundImage})`,
          color: style?.foregroundColor,
          marginTop: `${style?.marginTop}px`,
          marginBottom: `${style?.marginBottom}px`,
          paddingTop: `${style?.paddingTop}px`,
          paddingBottom: `${style?.paddingBottom}px`,
          position: content?.sticky ? `sticky` : ``,
          top: content?.sticky ? `103px` : ``,
          zIndex: content?.sticky ? `var(--headerZIndex)` : ``,
          borderRadius: `${content?.roundedCorners}px`
        }}
      >
        <div
          className={content?.contentInContainer ? `contBx` : ``}
          style={{
            paddingLeft:
              screenView === `WEB_VIEW` && content?.contentInContainer
                ? `var(--builder-theme-horizontal-padding)`
                : screenView !== `WEB_VIEW` && content?.contentInContainer
                ? `16px`
                : ``,
            paddingRight:
              screenView === `WEB_VIEW` && content?.contentInContainer
                ? `var(--builder-theme-horizontal-padding)`
                : screenView !== `WEB_VIEW` && content?.contentInContainer
                ? `16px`
                : ``
          }}
        >
          <div
            className={content?.layout === 1 ? styles.layout1 : styles.layout1}
          >
            <div className={styles.logo}>
              <RenderIf isTrue={content?.showLogo}>
                <picture>
                  <source srcSet={pages?.global?.content?.logo} />
                  <img
                    src={pages?.global?.content?.logo}
                    alt={`Logo`}
                    style={{
                      width: !content?.logoSizeAsGlobal
                        ? `${content?.logoWidth}px`
                        : `${pages?.global?.content?.logoWidth}px`,
                      height: `auto`
                    }}
                  />
                </picture>
              </RenderIf>
            </div>

            <div className={styles.toggleMenu}>
              <ButtonThemeTertiary
                onClick={() => {
                  setMenuActive(!menuActive);
                }}
                iconOnly={true}
              >
                <Ikon
                  name={`builder-menu`}
                  fillColor={style?.foregroundColor}
                />
              </ButtonThemeTertiary>
            </div>

            <RenderIf isTrue={menuActive}>
              <div
                className={styles.overlay}
                onClick={() => {
                  setMenuActive(!menuActive);
                }}
              ></div>
            </RenderIf>

            <div
              className={
                menuActive
                  ? `${styles.layoutInner} ${styles.menu_active}`
                  : styles.layoutInner
              }
            >
              <ButtonThemeTertiary onClick={() => {}} iconOnly={true}>
                <Ikon name={`cart`} fillColor={style?.foregroundColor} />
              </ButtonThemeTertiary>
              <ButtonThemeTertiary onClick={() => {}}>
                Log in
              </ButtonThemeTertiary>
              <ButtonThemeSecondary onClick={() => {}}>
                REGISTER
              </ButtonThemeSecondary>
              <ButtonThemePrimary onClick={() => {}}>
                ORDER NOW
              </ButtonThemePrimary>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};
