import { BuilderJSON } from 'store';
import styles from './index.module.css';

export const ButtonThemePrimary = ({
  type = `button`,
  onClick = () => {},
  children
}) => {
  const { pages } = BuilderJSON((state) => state);
  const primary = pages?.global?.style?.button?.primary;

  return (
    <>
      <button
        type={type}
        onClick={onClick}
        className={styles.button}
        style={{
          backgroundColor: primary?.backgroundColor,
          color: primary?.textColor,
          borderRadius: `${primary?.roundedCorners}px`
        }}
      >
        {children}
      </button>
    </>
  );
};
