import { client } from 'utilities/db';
import { useQuery } from 'react-query';

export const useFetchReportsHook = (keyName, brandId, branchId) => {
  const { status, data, error, isFetching, isLoading, refetch } = useQuery(
    [keyName],
    () => getData(brandId, branchId),
    {
      enabled: !!brandId || !!branchId,
      refetchInterval: 60000
    }
  );

  return {
    reportStatus: status,
    reportData: data,
    reportError: error,
    reportFetching: isFetching,
    reportLoading: isLoading,
    reportRefetch: refetch
  };
};

const getData = async (brandId, branchId) => {
  let result = await client.post(`/reporting/overview`, {
    brand_ids: [brandId],
    branch_ids: [branchId]
  });
  result = result.data;

  return result;
};
