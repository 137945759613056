import { Ikon } from 'assets/icons';
import styles from './index.module.css';

export const CheckboxField = (props) => {
  const {
    name,
    id,
    value,
    onChange = () => {},
    register = () => {},
    validation = {},
    info,
    infoChild,
    children,
    required
  } = props;

  return (
    <>
      <div className={styles.fieldbox}>
        <div className={styles.field}>
          {required ? <span className={styles.required}>*</span> : null}
          <input
            {...register(name, validation)}
            type="checkbox"
            name={name}
            className={styles.input}
            id={id}
            defaultValue={value}
            onChange={onChange}
            checked={value}
          />
          <span className={styles.checks}></span>
          {children ? (
            <label htmlFor={id} className={styles.label}>
              {children}
            </label>
          ) : null}
        </div>
        <div>
          {info ? (
            <div className={styles.infobox}>
              <Ikon info />
              <div className={styles.infoChild}>
                <Ikon indicatorArrow />
                <div className={styles.infoContent}>{infoChild}</div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};

export const FieldCheckbox = (props) => {
  const {
    name,
    id,
    defaultValue,
    register = () => {},
    validation = {},
    info,
    infoChild,
    children,
    required
  } = props;

  return (
    <>
      <div className={styles.fieldbox}>
        <div className={styles.field}>
          {required ? <span className={styles.required}>*</span> : null}
          <input
            {...register(name, validation)}
            type="checkbox"
            name={name}
            className={styles.input}
            id={id}
            defaultValue={defaultValue}
          />
          <span className={styles.checks}></span>
          {children ? (
            <label htmlFor={id} className={styles.label}>
              {children}
            </label>
          ) : null}
        </div>
        <div>
          {info ? (
            <div className={styles.infobox}>
              <Ikon info />
              <div className={styles.infoChild}>
                <Ikon indicatorArrow />
                <div className={styles.infoContent}>{infoChild}</div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};

export const ColorRadioField = (props) => {
  const {
    name,
    id,
    value,
    register = () => {},
    validation = {},
    colorHex
  } = props;

  return (
    <div className={styles.colorRadio}>
      <input
        {...register(name, validation)}
        type="radio"
        name={name}
        className={styles.colorInput}
        id={id}
        value={value}
      />
      <label htmlFor={id} style={{ backgroundColor: colorHex }}></label>
    </div>
  );
};

export const StatusRadioField = (props) => {
  const {
    name,
    onChange = () => {},
    register = () => {},
    validation = {},
    defaultChecked = false
  } = props;
  return (
    <div className={styles.menuStatus}>
      <div className={styles.deactivate}>
        <input
          {...register(name, validation)}
          type="radio"
          name={name}
          className={styles.statusInput}
          id={`deactivate`}
          value={`DEACTIVATE`}
          onChange={onChange}
          defaultChecked
        />
        <label htmlFor={`deactivate`}>Deactivate</label>
      </div>
      <div className={styles.activate}>
        <input
          {...register(name, validation)}
          type="radio"
          name={name}
          className={styles.statusInput}
          id={`activate`}
          value={`ACTIVATE`}
          onChange={onChange}
        />
        <label htmlFor={`activate`}>Activate</label>
      </div>
    </div>
  );
};

export const IsRequiredRadioField = (props) => {
  const {
    name,
    onChange = () => {},
    register = () => {},
    validation = {}
  } = props;

  return (
    <div className={styles.menuStatus}>
      <div className={styles.deactivate}>
        <input
          {...register(name, validation)}
          type="radio"
          name={name}
          className={styles.statusInput}
          id={`optional`}
          value={`OPTIONAL`}
          onChange={onChange}
          defaultChecked
        />
        <label htmlFor={`optional`}>Optional</label>
      </div>
      <div className={styles.activate}>
        <input
          {...register(name, validation)}
          type="radio"
          name={name}
          className={styles.statusInput}
          id={`required`}
          value={`REQUIRED`}
          onChange={onChange}
        />
        <label htmlFor={`required`}>Required</label>
      </div>
    </div>
  );
};

export const BadgeDayCheckbox = (props) => {
  const {
    name,
    id,
    value,
    onChange = () => {},
    register = () => {},
    validation = {},
    label
  } = props;

  return (
    <>
      <label htmlFor={id} className={styles.bdLabel}>
        {label}
        <input
          {...register(name, validation)}
          type="checkbox"
          name={name}
          className={styles.bdInput}
          id={id}
          value={value}
          onChange={onChange}
        />
        <span className={styles.bdActive}></span>
      </label>
    </>
  );
};
