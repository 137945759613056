import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { RenderIf } from 'utilities/helpers';
import { useFetchCategoriesByBranch } from 'hooks/categories';
import { Button } from 'components/button';
import Frame from '../../../assets/Images/Frame.png';
import account from '../../../assets/Images/account.png';
import Cat from '../../../assets/Images/categry.png';
import { Ikon } from 'assets/icons';

function KDSCateoryPage() {
  const brand = useLocation().state?.brand;
  const branch = useLocation().state?.branch;

  const { brandCategoriesData, brandCategoriesLoading } =
    useFetchCategoriesByBranch(`brandCategories/${branch?.id}`, branch?.id);

  const navigate = useNavigate();

  const [categories, setcategories] = useState([]);

  return (
    <>
      <div className={`mt_40`}></div>
      <div className={`flex item_space_btn pt_10`}>
        <div>
          <h1>
            <strong>Selected Brand</strong>
          </h1>
          <h5 className={`mt_4`}>Lorem ipsum dolor ist meunda.</h5>
        </div>
      </div>
      <div className={`grid grid_gap30`}>
        <div className={`collg4`}>
          <button
            className={`card_item3 card_item3_md active mt_30`}
            onClick={() => {}}
          >
            <div>
              <picture>
                <RenderIf isTrue={!brand?.image_url}>
                  <div className={`placeholder`}>
                    <Ikon name={`imgplaceholder`} />
                  </div>
                </RenderIf>
                <RenderIf isTrue={brand?.image_url}>
                  <source srcSet={brand?.image_url} />
                  <img src={brand?.image_url} alt="" />
                </RenderIf>
              </picture>
              <div className={`mt_15`}></div>
              <h4>
                <strong>{brand?.brand_name}</strong>
              </h4>
            </div>
          </button>
        </div>
      </div>
      <div className={`mt_40`}></div>
      <div className={`pt_10`}></div>
      <div className={`flex item_space_btn pt_10`}>
        <div>
          <h1>
            <strong>Selected Branch</strong>
          </h1>
          <h5 className={`mt_4`}>Lorem ipsum dolor ist meunda.</h5>
        </div>
      </div>
      <div className={`grid grid_gap30`}>
        <div className={`collg4`}>
          <button
            className={`branch_card1 branch_card1_sm active mt_30`}
            onClick={() => {}}
          >
            <div
              className={'picture'}
              style={{
                backgroundImage: `url(${Frame})`
              }}
            ></div>
            <div className={`branch_content1`}>
              <p>{branch.heading}</p>
              <div className={`mt_10`}></div>
              <div className={`branch_manager`}>
                <picture>
                  <source srcSet={account} />
                  <img src={account} alt="" />
                </picture>
                <div>
                  <div>
                    <b>{branch?.branch_name}</b>
                  </div>
                </div>
              </div>
            </div>
          </button>
        </div>
      </div>
      <div className={`mt_40`}></div>
      <div className={`pt_10`}></div>
      <div className={`flex item_space_btn pt_10`}>
        <div>
          <h1>
            <strong>Select Categories</strong>
          </h1>
          <h5 className={`mt_4`}>Lorem ipsum dolor ist meunda.</h5>
        </div>
      </div>
      <RenderIf isTrue={!brandCategoriesLoading}>
        <div className={`grid grid_gap30`}>
          {brandCategoriesData
            ?.filter(
              (cat) =>
                cat?.brand_id === brand?.id && cat?.branch_id === branch?.id
            )
            ?.map((cat, index) => (
              <div key={index} className={`collg4`}>
                <button
                  onClick={() => {
                    const index = categories?.findIndex(
                      (c) => c?.id === cat?.id
                    );
                    if (index > -1) {
                      categories?.splice(index, 1);
                      setcategories([...categories]);
                      return;
                    } else {
                      setcategories([...categories, cat]);
                    }
                  }}
                  className={`card_item1 card_item1_sm mt_30 
                ${
                  categories?.length > 0 &&
                  categories?.find((c) => c?.id === cat?.id) &&
                  'active'
                }
              `}
                  style={{
                    borderLeft: `6px solid`,
                    borderLeftColor: `${
                      index === 0
                        ? '#8d23dd'
                        : index === 1
                        ? '#6361d8'
                        : index === 2
                        ? '#d8618c'
                        : ''
                    }`
                  }}
                >
                  <picture>
                    <RenderIf isTrue={!Cat}>
                      <div className={`placeholder`}>
                        <Ikon name={`imgplaceholder`} />
                      </div>
                    </RenderIf>
                    <RenderIf isTrue={Cat}>
                      <source srcSet={Cat} />
                      <img src={Cat} alt="" />
                    </RenderIf>
                  </picture>
                  <div className={`card_item1_box`}>
                    <h6>
                      <span className="ff_semi">{cat?.category_name}</span>
                    </h6>
                  </div>
                </button>
              </div>
            ))}
        </div>
        <div className={`mt_30`}></div>
        <div className={`flex item_hend`}>
          <Button
            onClick={() => {
              if (categories?.length > 0) {
                navigate('/KDSSelected', {
                  state: {
                    brand,
                    branch,
                    categories
                  }
                });
              }
            }}
            className={`button button_radius b_wid160`}
            color="bg-primary"
            label={`Next`}
            disabled={categories?.length === 0}
          />
        </div>
      </RenderIf>
    </>
  );
}

export default KDSCateoryPage;
