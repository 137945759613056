import { client } from 'utilities/db';
import { useQuery } from 'react-query';

export const useFetchZewstBrands = (keyName = 'zewstbrands') => {
  const { status, data, error, isFetching, isLoading, refetch } = useQuery(
    [keyName],
    () => getBrands(),
    {
      refetchInterval: 60000
    }
  );

  return {
    zewstBrandStatus: status,
    zewstBrandData: data,
    zewstBrandError: error,
    zewstBrandFetching: isFetching,
    zewstBrandLoading: isLoading,
    zewstBrandRefetch: refetch
  };
};

const getBrands = async () => {
  let result = await client.get(`/brand/findAllBrandBranches`);
  result = result.data;
  result = result?.brandBranches;
  return result;
};
