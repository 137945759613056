import { TEMPImgs } from '../../theme/images';
import { Ikon } from 'assets/icons';
import styles from './index.module.css';

export const AuthLayout = ({ children }) => {
  return (
    <>
      <main>
        <div className={styles.flex}>
          <div className={styles.box}>
            <header className={styles.header}>
              <div className={styles.hflex}>
                <div>
                  <div className={styles.logo}>
                    <a href="/" rel="noreferrer">
                      <Ikon logo />
                    </a>
                  </div>
                </div>
              </div>
            </header>
            <div className={styles.content}>{children}</div>
          </div>
          <div className={styles.imgbox}></div>
        </div>
      </main>
    </>
  );
};
