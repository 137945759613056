import { useEffect, useState } from 'react';
import { Table } from 'rsuite';
import { useStore } from 'store';
import { useFetchOrdersHook } from 'hooks/orders/useFetchOrdersHook';
import { RenderIf } from 'utilities/helpers';
import { CustomTable } from 'components/datatable';
import styles from "./index.module.css";
import moment from 'moment';

export const OrdersPage = () => {
    const { Column, HeaderCell, Cell } = Table;
    const { brand, branch } = useStore((state) => state);
    const [fromDate, setFromDate] = useState(moment(new Date())?.format("YYYY-MM-DD"));
    const [todayDate, setTodayDate] = useState(moment(new Date())?.format("YYYY-MM-DD"));
    const [active, setActive] = useState(`TODAY`);
    const {
        ordersData,
        ordersLoading,
        ordersRefetch
    } = useFetchOrdersHook('orders', brand?.id, branch?.id, fromDate, todayDate);
    const Orders = ordersData?.data;

    const OrderDateCell = ({ rowData, dataKey, ...props }) => {
        return (
          <Cell {...props}>
            {moment(rowData?.orderDate)?.format("MM/DD/YY")}
          </Cell>
        );
    };

    const OrderStatusCell = ({ rowData, dataKey, ...props }) => {
        return (
          <Cell {...props}>
            <div 
                style={{ 
                    width: 86, 
                    height: 22,
                    borderRadius: 3, 
                    display: `flex`,
                    alignItems: `center`,
                    justifyContent: `center`, 
                    fontSize: 13,
                    color: `rgba(112, 118, 140, 1)`, 
                    fontWeight: `500`,
                    background: rowData?.orderStatus === `PENDING` ? `#F4D9C6` 
                        : rowData?.orderStatus === `CANCELLED` ? `#EFD4D4` : `rgba(221, 243, 215, 1)`,
                }}
            >
                {rowData?.orderStatus === `PENDING` ? `In-progress` : rowData?.orderStatus === `CANCELLED` ? `Cancelled` : rowData?.orderStatus }
            </div>
          </Cell>
        );
    };

    const OrderTypeCell = ({ rowData, dataKey, ...props }) => {
        return (
          <Cell {...props}>
            <div 
                style={{ 
                    width: 86, 
                    height: 22,
                    borderRadius: 3, 
                    display: `flex`,
                    alignItems: `center`,
                    justifyContent: `center`, 
                    fontSize: 13,
                    color: `rgba(112, 118, 140, 1)`, 
                    fontWeight: `500`,
                    background: rowData?.orderType === `TAKE_AWAY` ? `#F3E3D7` 
                        : rowData?.orderType === `DELIVERY` ? `#DDF3D7` : `#E4D7F3`,
                }}
            >
                {rowData?.orderType === `TAKE_AWAY` ? `Take-Away` : rowData?.orderType === `DELIVERY` ? `Delivery` : `Dine in` }
            </div>
          </Cell>
        );
    };

    useEffect(() => {
        ordersRefetch();
    }, [fromDate, todayDate]);

    useEffect(() => {
        handleButtonClick(`MONTH`, 30);
    }, []);

    const handleButtonClick = (value, num) => {
        const d = new Date();
        setTodayDate(moment(d)?.format("YYYY-MM-DD"));
        setActive(value);
        const priorDate = new Date(new Date().setDate(d.getDate() - num));
        const formatPriorDate = moment(priorDate)?.format("YYYY-MM-DD");
        setFromDate(formatPriorDate);
    }

  return (
    <>
        <article className={styles.article}>
            <header className={styles.header}>
                <div>
                    <h2>Orders History</h2>
                    <h6>See all the details about your orders</h6>
                </div>
                <div className={styles.filterBx}>
                    <button type={`button`} 
                        className={active === `TODAY` ? styles.active : ``} 
                        onClick={() => {
                            handleButtonClick(`TODAY`, 0);
                        }}
                    >Today</button>
                    <button type={`button`} 
                        className={active === `YESTERDAY` ? styles.active : ``} 
                        onClick={() => {
                            handleButtonClick(`YESTERDAY`, 2);
                        }}
                    >Yesterday</button>
                    <button type={`button`} 
                        className={active === `WEEK` ? styles.active : ``} 
                        onClick={() => {
                            handleButtonClick(`WEEK`, 7);
                        }}
                    >Week</button>
                    <button type={`button`} 
                        className={active === `MONTH` ? styles.active : ``} 
                        onClick={() => {
                            handleButtonClick(`MONTH`, 30);
                        }}
                    >Month</button>
                </div>
            </header>
            <div className={styles.tableBx}>
                <RenderIf isTrue={Orders?.length > 0}>
                    <CustomTable 
                        rowHeight={52}
                        rows={Orders?.filter((order) => order != null)?.map(
                        (order, index) => {
                            return {
                            id: index + 1,
                            customerId: order?.customer,
                            orderTotal: order?.total,
                            orderId: order?._id,
                            orderType: order?.type,
                            orderDate: order?.orderedOn,
                            orderStatus: order?.payment,
                            };
                        }
                        )}
                        loading={ordersLoading}
                        pageLimit={10}
                        pagination={Orders?.length > 10 ? true : false}
                    >
                        <Column width={60} fixed>
                            <HeaderCell>Id</HeaderCell>
                            <Cell dataKey="id" />
                        </Column>
                        <Column flexGrow={1}>
                            <HeaderCell>Customer ID</HeaderCell>
                            <Cell dataKey="customerId" />
                        </Column>
                        <Column width={130} sortable>
                            <HeaderCell>Amount</HeaderCell>
                            <Cell dataKey="orderTotal" />
                        </Column>
                        <Column flexGrow={1}>
                            <HeaderCell>Order Id</HeaderCell>
                            <Cell dataKey="orderId" />
                        </Column>
                        <Column width={130} sortable>
                            <HeaderCell>Order Type</HeaderCell>
                            <OrderTypeCell dataKey="orderType" />
                        </Column>
                        <Column width={130} sortable>
                            <HeaderCell>Order Date</HeaderCell>
                            <OrderDateCell dataKey="orderDate" />
                        </Column>
                        <Column width={130} sortable>
                            <HeaderCell>Order Status</HeaderCell>
                            <OrderStatusCell dataKey="orderStatus" />
                        </Column>
                        {/* <Column width={80} fixed="right">
                            <HeaderCell>Options</HeaderCell>
                            <Cell style={{ padding: '6px' }}>
                                {rowData => (
                                    <Button appearance="link" onClick={() => alert(`id:${rowData.id}`)}>
                                    ...
                                    </Button>
                                )}
                            </Cell>
                        </Column> */}
                    </CustomTable>
                </RenderIf>
                <RenderIf isTrue={Orders?.length === 0}>
                    <div className={styles.noData}>No Orders data found</div>
                </RenderIf>
            </div>
        </article>
    </>
  );
}
