import { Button } from 'components/button';
import { CustomTable } from 'components/datatable';
import { useFetchRolesHook } from 'hooks/roles/useFetchRolesHook';
import { useFetchTeamHook } from 'hooks/team/useFetchTeamHook';
import React, { useState } from 'react';
import { Table } from 'rsuite';
import { useStore } from 'store';
import { RenderIf } from 'utilities/helpers';
import { RoleModal } from '../rolemodal';

const Employ = () => {
  const { Column, HeaderCell, Cell } = Table;
  const { brand } = useStore((state) => state);
  const {
    teamData: Employs,
    teamLoading,
    teamRefetch
  } = useFetchTeamHook('employ', brand?.id);
  const { roleData: Roles, roleLoading } = useFetchRolesHook(
    'roles',
    brand?.id
  );
  const [assignRole, setAssignRole] = useState(false);
  const EmployCell = ({ rowData, dataKey, ...props }) => {
    return (
      <Cell {...props}>
        <b>{rowData?.email}</b>
      </Cell>
    );
  };

  const ActionsCell = ({ setVendorId, rowData, dataKey, ...props }) => {
    return (
      <Cell {...props}>
        <button
          className="button_sm button_radius"
          color="bg-primary"
          onClick={() => {}}
        >
          Update
        </button>
      </Cell>
    );
  };
  return (
    <>
      <div className="mt_40"></div>
      <div className="flex item_space_btn pt_10">
        <div>
          <h1>
            <strong>Employ</strong>
          </h1>
          <h5 className="mt_4">We hope you're having a good day.</h5>
        </div>
        <div>
          <Button
            label="Asign Role"
            color="bg-titary"
            onClick={() => {
              setAssignRole(true);
            }}
          />
        </div>
      </div>
      <div className="mt_40"></div>
      <RenderIf isTrue={Employs?.length === 0}>
        <div className={`no_data1`}>No data found.</div>
      </RenderIf>

      <div>
        <RenderIf isTrue={Employs?.length > 0}>
          <CustomTable
            rows={Employs?.filter((item) => item != null)?.map(
              (employ, index) => {
                return {
                  id: index + 1,
                  email: employ?.email || '-',
                  number: employ?.contact_number || '-',
                  role_name:
                    Roles?.find((role) => role?.id === employ?.role_id)?.name ||
                    '-'
                };
              }
            )}
            loading={teamLoading}
            pageLimit={10}
            pagination={Employs?.length > 10 ? true : false}
          >
            <Column width={50} align="center" fixed>
              <HeaderCell>Id</HeaderCell>
              <Cell dataKey="id" />
            </Column>
            <Column width={350}>
              <HeaderCell>Email</HeaderCell>
              <EmployCell dataKey="email" />
            </Column>
            <Column width={320}>
              <HeaderCell>Number</HeaderCell>
              <Cell dataKey="number" />
            </Column>

            <Column width={200} sortable>
              <HeaderCell>Role</HeaderCell>
              <Cell dataKey="role_name" />
            </Column>

            {/* <Column width={120} align="center" fixed="right">
              <HeaderCell>Actions</HeaderCell>
              <ActionsCell setVendorId={'setVendorId'} dataKey="actions" />
            </Column> */}
          </CustomTable>
        </RenderIf>
      </div>
      <div>
        <RenderIf isTrue={assignRole}>
          <RoleModal
            type="Assign Role"
            setRole={setAssignRole}
            Role={assignRole}
          />
        </RenderIf>
      </div>
    </>
  );
};

export default Employ;
