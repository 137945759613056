import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { RenderIf } from 'utilities/helpers';
import { useFetchBranches } from 'hooks/resourceHooks';
import { Button } from 'components/button';
import Frame from '../../../assets/Images/Frame.png';
import account from '../../../assets/Images/account.png';
import { Ikon } from 'assets/icons';

function KDSBranch() {
  const brand = useLocation().state?.brand;

  const { data: branchesData, isLoading } = useFetchBranches(brand?.id);

  const navigate = useNavigate();
  const [selectedBranch, setSelectedBranch] = useState(null);

  return (
    <>
      <div className={`mt_40`}></div>
      <div className={`flex item_space_btn pt_10`}>
        <div>
          <h1>
            <strong>Selected Brand</strong>
          </h1>
          <h5 className={`mt_4`}>Lorem ipsum dolor ist meunda.</h5>
        </div>
      </div>
      <div className={`grid grid_gap30`}>
        <div className={`collg4`}>
          <button
            className={`card_item3 card_item3_md active mt_30`}
            onClick={() => {}}
          >
            <div>
              <picture>
                <RenderIf isTrue={!brand?.image_url}>
                  <div className={`placeholder`}>
                    <Ikon name={`imgplaceholder`} />
                  </div>
                </RenderIf>
                <RenderIf isTrue={brand?.image_url}>
                  <source srcSet={brand?.image_url} />
                  <img src={brand?.image_url} alt="" />
                </RenderIf>
              </picture>
              <div className={`mt_15`}></div>
              <h4>
                <strong>{brand?.brand_name}</strong>
              </h4>
            </div>
          </button>
        </div>
      </div>
      <div className={`mt_40`}></div>
      <div className={`pt_10`}></div>
      <div className={`flex item_space_btn pt_10`}>
        <div>
          <h1>
            <strong>Select Branch</strong>
          </h1>
          <h5 className={`mt_4`}>Lorem ipsum dolor ist meunda.</h5>
        </div>
      </div>
      <RenderIf isTrue={!isLoading}>
        <div className={`grid grid_gap30`}>
          {branchesData?.map((branch, index) => (
            <div key={index} className={`collg4`}>
              <button
                className={
                  selectedBranch?.id === branch?.id
                    ? `branch_card1 branch_card1_sm active mt_30`
                    : `branch_card1 branch_card1_sm mt_30`
                }
                onClick={() => {
                  setSelectedBranch(branch);
                }}
              >
                <div
                  className={'picture'}
                  style={{
                    backgroundImage: `url(${Frame})`
                  }}
                ></div>
                <div className={`branch_content1`}>
                  <p>{branch.heading}</p>
                  <div className={`mt_10`}></div>
                  <div className={`branch_manager`}>
                    <picture>
                      <source srcSet={account} />
                      <img src={account} alt="" />
                    </picture>
                    <div>
                      <div>
                        <b>{branch?.branch_name}</b>
                      </div>
                    </div>
                  </div>
                </div>
              </button>
            </div>
          ))}
        </div>
        <div className={`mt_30`}></div>
        <div className={`flex item_hend`}>
          <Button
            onClick={() => {
              if (selectedBranch) {
                navigate('/KDSCategory', {
                  state: { branch: selectedBranch, brand: brand }
                });
              }
            }}
            className={`button button_radius b_wid160`}
            color="bg-primary"
            label={`Next`}
            disabled={!selectedBranch}
          />
        </div>
      </RenderIf>
    </>
  );
}

export default KDSBranch;
