import ImageDropzone from 'components/imageDropzone/imageDropzone';
import { TextField } from 'components/input';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { client } from 'utilities/db';
import { useStore } from 'store';
import { Button } from 'components/button';
import { CustomSelectDropdown } from 'components/select/multi';
import { Toasts } from 'components/toasts';
import styles from './index.module.css';
import { emailRegex, phoneRegex } from 'utilities/regex';
import { RenderIf } from 'utilities/helpers';
import { useFetchOneBrandHook } from 'hooks/brands/useFetchOneBrandHook';
import { ImageCropUploader } from 'components/imagecropuploader';

export const AddUpdateBrandPage = () => {
  const navigate = useNavigate();
  let params = useLocation()?.pathname?.split('/');
  let action = params[params.length - 1];

  const brandToUpdate = useLocation()?.state?.brandToUpdate;

  const { user } = useStore((state) => state);
  const { oneBrandRefetch } = useFetchOneBrandHook(
    `brand/${brandToUpdate?.id}`,
    brandToUpdate?.id
  );

  const {
    handleSubmit,
    register,
    formState: { errors },
    control,
    watch
  } = useForm({
    defaultValues: {
      brand_name: brandToUpdate?.brand_name || '',
      owner_id: brandToUpdate?.owner_id || user?.id,
      phone_number: brandToUpdate?.phone_number || '',
      brand_email: brandToUpdate?.brand_email || '',
      ein: brandToUpdate?.ein || '',
      image_url: brandToUpdate?.image_url || '',
      brand_type: brandToUpdate?.brand_type || '',
      company_name: '',
      company_type: '',
      bank_account_number: '',
      account_holder_name: '',
      routing_number: '',
      email: '',
      name: '',
      phone: ''
    }
  });
  const [brandImage, setBrandImage] = useState(
    brandToUpdate?.image_url || null
  );
  const [submitting, setSubmitting] = useState(false);

  const handleBrandSubmit = async (data) => {
    try {
      if (action === 'add') {
        setSubmitting(true);
        let addBrandResponse = await client.post('/brand/add', {
          brand_name: data.brand_name,
          owner_id: user?.id,
          phone_number: data.phone_number,
          brand_email: data.brand_email,
          ein: data.ein,
          image_url: brandImage,
          brand_type: data?.brand_type?.value
        });
        addBrandResponse = addBrandResponse?.data;

        await client.post('/bankDetails/add', {
          brand_id: addBrandResponse?.brand?.id,
          company_name: data.company_name,
          company_type: data.company_type?.value,
          bank_account_number: data.bank_account_number,
          account_holder_name: data.account_holder_name,
          routing_number: data.routing_number
        });
        setSubmitting(false);
        Toasts.success('Brand added successfully');
        navigate('/portfolio');
      }
      if (action === 'update') {
        setSubmitting(true);
        let addBrandResponse = await client.post(
          `/brand/update/${brandToUpdate?.id}`,
          {
            brand_name: data.brand_name,
            owner_id: user?.id,
            phone_number: data.phone_number,
            brand_email: data.brand_email,
            ein: data.ein,
            image_url: brandImage,
            brand_type: data?.brand_type
          }
        );
        addBrandResponse = addBrandResponse?.data;
        oneBrandRefetch();
        setSubmitting(false);
        Toasts.success('Brand added successfully');
        navigate(`/portfolio/brand/details/${brandToUpdate?.id}`);
      }
    } catch (error) {
      Toasts.error(error?.response?.data?.message);
      setSubmitting(false);
    }
  };

  return (
    <>
      <div className="mt_40"></div>
      <div className={styles.box}>
        <form onSubmit={handleSubmit(handleBrandSubmit)}>
          <h2>
            <span className="ff_semi">About Brand</span>
          </h2>
          <div className="mt_16"></div>
          <TextField
            label="Brand Name"
            placeholder="Brand Name"
            name="brand_name"
            error={
              errors?.brand_name?.type === 'required'
                ? 'Brand Name is required'
                : ''
            }
            register={register}
            validation={{
              required: true
            }}
          />
          <RenderIf isTrue={action === 'add'}>
            <Controller
              name="brand_type"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <CustomSelectDropdown
                  hookForm={true}
                  isMulti={false}
                  field={field}
                  label="Brand Type"
                  placeholder="Choose Brand Type"
                  options={[
                    {
                      label: 'Brand',
                      value: 'STANDARD'
                    },
                    {
                      label: 'Cloud Kitchen',
                      value: 'CLOUD'
                    }
                  ]}
                  error={
                    errors?.brand_type?.type === 'required'
                      ? 'Brand type is required'
                      : ''
                  }
                />
              )}
            />
          </RenderIf>
          <TextField
            type="number"
            label="Phone Number"
            placeholder="13334445555"
            name="phone_number"
            register={register}
            error={
              errors?.phone_number?.type === 'required'
                ? 'Phone is required'
                : errors?.phone_number?.type === 'pattern'
                ? 'Phone is invalid'
                : ''
            }
            validation={{
              required: true,
              pattern: phoneRegex
            }}
          />
          <TextField
            label="Email"
            placeholder="Email"
            name="brand_email"
            register={register}
            error={
              errors?.brand_email?.type === 'required'
                ? 'Email is required'
                : errors?.brand_email?.type === 'pattern'
                ? 'Email is invalid'
                : ''
            }
            validation={{
              required: true,
              pattern: emailRegex
            }}
          />
          <TextField
            label="EIN"
            placeholder="123123123"
            name="ein"
            error={errors?.ein?.type === 'required' ? 'Tax is required' : ''}
            register={register}
            validation={{
              required: true
            }}
          />
          <div className="mt_16"></div>

          {/* <div>
            <div className="label_box">
              <label htmlFor="exampleFormControlInput1" className="label">
                Upload Logo
              </label>
            </div>
            <ImageDropzone image={brandImage} setImage={setBrandImage} />
          </div> */}

          <ImageCropUploader
            imageSrc={brandImage}
            handleImage={setBrandImage}
          />

          <RenderIf isTrue={action === 'add'}>
            <div className="mt_16"></div>
            <h2>
              <span className="ff_semi">Company's Information</span>
            </h2>
            <div className="mt_16"></div>
            <TextField
              label="Company Name"
              placeholder="Company Name"
              name="company_name"
              error={
                errors?.company_name?.type === 'required'
                  ? 'Company Name is required'
                  : ''
              }
              register={register}
              validation={{
                required: true
              }}
            />
            <div className="mt_16"></div>
            <Controller
              name="company_type"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <CustomSelectDropdown
                  hookForm={true}
                  field={field}
                  label="Company Type"
                  placeholder="Choose options"
                  options={[
                    { label: 'INDIVIDUAL', value: 'INDIVIDUAL' },
                    { label: 'BUSINESS', value: 'BUSINESS' }
                  ]}
                  error={
                    errors?.company_type?.type === 'required'
                      ? 'Company Name is required'
                      : ''
                  }
                />
              )}
            />
            <div className="mt_16"></div>
            <TextField
              label="Bank Account Number"
              placeholder="Bank Account Number"
              name="bank_account_number"
              error={
                errors?.bank_account_number?.type === 'required'
                  ? 'Bank Account Number is required'
                  : ''
              }
              register={register}
              validation={{
                required: true
              }}
            />
            <TextField
              label="Account Holder Name"
              placeholder="Account Holder Name"
              name="account_holder_name"
              error={
                errors?.account_holder_name?.type === 'required'
                  ? 'Account Holder Name is required'
                  : ''
              }
              register={register}
              validation={{
                required: true
              }}
            />
            <TextField
              label="Routing Number"
              placeholder="Routing Number"
              name="routing_number"
              error={
                errors?.routing_number?.type === 'required'
                  ? 'Routing Number is required'
                  : ''
              }
              register={register}
              validation={{
                required: true
              }}
            />
          </RenderIf>
          <div className="flex item_hend mt_30">
            <Button label="Cancel" color="outline-lite" type="button" />
            <Button
              label="Save"
              color="bg-titary"
              type="submit"
              disabled={submitting}
            />
          </div>
        </form>
      </div>
    </>
  );
};
