import axios from 'axios';
import { useQuery } from 'react-query';

export const useFetchOrders = (keyName, id) => {
  const { status, data, error, isFetching, isLoading, refetch } = useQuery(
    [keyName, id],
    () => getData(id),
    {
      enabled: !!id,
      refetchInterval: 60000
    }
  );

  return {
    orderStatus: status,
    orderData: data,
    orderError: error,
    orderFetching: isFetching,
    orderLoading: isLoading,
    orderRefetch: refetch
  };
};

const getData = async (id) => {
  let result = await axios.get(
    `https://zewst-customer-service-prod.herokuapp.com/orders/brand/${id}`
  );
  result = result.data;
  result = result?.data;

  return result;
};
