import { useForm } from 'react-hook-form';
import { TextField } from 'components/input';
import { CheckboxField } from 'components/checkbox';
import { Button } from 'components/button';
import { useState } from 'react';
import { client } from 'utilities/db';
import { useMutation, useQuery } from 'react-query';
import { useFetchOneDbaHook } from 'hooks/brands/useFetchOneBrandHook';
import { useEffect } from 'react';
import { Toasts } from 'components/toasts';

export const FileADba = ({ brandId }) => {
  const { data, refetch } = useFetchOneDbaHook('DBA', brandId);
  const { mutate, isSuccess } = useMutation((userData) => {
    return client.post(
      data?.DBADetails?.id ? `/DBA/update/${brandId}` : `/DBA/add`,
      userData
    );
  });

  console.log('🚀 ~ file: index.jsx:14 ~ FileADba ~ isSuccess', isSuccess);

  const [corporationAct, setCorporationAct] = useState(false);
  const [liabilityCompany, setLiabilityCompany] = useState(false);
  const [nonProfitCorporation, setNonProfitCorporation] = useState(false);
  const [limitedPartnership, setLimitedPartnership] = useState(false);
  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue
  } = useForm({
    defaultValues: {
      corporation_name: ''
    }
  });

  useEffect(() => {
    const item = data?.DBADetails || {};
    if (item?.id) {
      setValue('corporation_name', item?.businses_name);
      setValue('alternate_name', item?.alternate_business_name);
      setValue('njId', item?.nj_id_number);
      setValue('forth_state', item?.forth_state_original);
      setValue('date_formation', item?.Incorporation_date);
      setValue('date_authorization', item?.authorization_date);
      setValue('state_purpose', item?.alternate_name_purpose_of_activity);
      setValue('not_use_state', item?.alternate_name_voilation_purpose);
      setCorporationAct(item?.njbca14a221 ? true : false);
      setLiabilityCompany(item?.njbca15da223 ? true : false);
      setNonProfitCorporation(item?.llc422b4 ? true : false);
      setLimitedPartnership(item?.lpca422a6 ? true : false);
    }
  }, [data]);

  const onSubmit = async (val) => {
    mutate({
      brand_id: brandId,
      njbca14a221: corporationAct ? 1 : 0,
      njbca15da223: liabilityCompany ? 1 : 0,
      llc422b4: nonProfitCorporation ? 1 : 0,
      lpca422a6: limitedPartnership ? 1 : 0,
      businses_name: val?.corporation_name,
      alternate_business_name: val?.alternate_name,
      nj_id_number: val?.njId,
      forth_state_original: val?.forth_state,
      Incorporation_date: val?.date_formation,
      authorization_date: val?.date_authorization,
      alternate_name_purpose_of_activity: val?.state_purpose,
      alternate_name_voilation_purpose: val?.not_use_state
    });
  };

  useEffect(() => {
    if (isSuccess) {
      Toasts.success('DBA Details Updated Successfully');
      refetch();
    }
  }, [isSuccess]);

  return (
    <>
      <div className="mt_20"></div>
      <div className="shadow_box1">
        <form onSubmit={handleSubmit(onSubmit)}>
          <h2>
            <span className="ff_semi">File DBA</span>
          </h2>
          <div className="mt_16"></div>
          <p>
            You may lose the info you entered so far. Are you sure you want to
            leave changes unsaved?
          </p>
          <div className="mt_16"></div>
          <CheckboxField
            value={corporationAct}
            onChange={(e) => setCorporationAct(!corporationAct)}
            name="corporationAct"
          >
            Title 14A:2-2.1 (2) New Jersey Business Corporation Act
          </CheckboxField>
          <div className="mt_16"></div>
          <CheckboxField
            value={liabilityCompany}
            onChange={(e) => setLiabilityCompany(!liabilityCompany)}
            name="liabilityCompany"
          >
            Title 42:2B-4 Limited Liability Company
          </CheckboxField>
          <div className="mt_16"></div>
          <CheckboxField
            value={nonProfitCorporation}
            onChange={(e) => setNonProfitCorporation(!nonProfitCorporation)}
            name="nonProfitCorporation"
          >
            Title 15A:2-2-3 (b) New Jersey Nonprofit Corporation Act
          </CheckboxField>
          <div className="mt_16"></div>
          <CheckboxField
            value={limitedPartnership}
            onChange={(e) => setLimitedPartnership(!limitedPartnership)}
            name="corporationAct"
          >
            Title 42:2A-6 Limited Partnership
          </CheckboxField>
          <div className="mt_16"></div>
          <p>
            Pursuant to the provisions of the appropriate statue, checked above,
            of the New Jersey Statutes, the undersigned corporation/business
            entity hereby applies for the registration of an Alternate Name in
            New Jersey for a period of five (5) years, and for that purpose
            submits the following application.
          </p>
          <div className="mt_16"></div>
          <TextField
            name="corporation_name"
            label="Name of Corporation/Business"
            placeholder="placeholder"
            type="text"
            error={
              errors?.corporation_name?.type === 'required'
                ? 'Corporation Name is required'
                : ''
            }
            validation={{
              required: true
            }}
            register={register}
            required
          />
          <TextField
            name="alternate_name"
            label="Alternate Name to be used"
            placeholder="placeholder"
            type="text"
            error={
              errors?.alternate_name?.type === 'required'
                ? 'This field is required'
                : ''
            }
            validation={{
              required: true
            }}
            register={register}
            required
          />
          <TextField
            name="njId"
            label="NJ 10-Digit ID Number"
            placeholder="placeholder"
            type="text"
            error={
              errors?.njId?.type === 'required' ? 'This field is required' : ''
            }
            validation={{
              required: true
            }}
            register={register}
            required
          />
          <TextField
            name="state_purpose"
            label="State the purpose of activity to be conducted using the Alternate Name"
            placeholder="placeholder"
            type="text"
            error={
              errors?.state_purpose?.type === 'required'
                ? 'This field is required'
                : ''
            }
            validation={{
              required: true
            }}
            register={register}
            required
          />
          <TextField
            name="forth_state"
            label="Set forth state of Original Incorporation/Formation"
            placeholder="placeholder"
            type="text"
            error={
              errors?.forth_state?.type === 'required'
                ? 'This field is required'
                : ''
            }
            validation={{
              required: true
            }}
            register={register}
            required
          />
          <TextField
            name="not_use_state"
            label="The business has not previously used the Alternate Name in this State in Violation of this Statute, or if it has, the month and year in which it commenced such us is"
            placeholder="placeholder"
            type="text"
            error={
              errors?.not_use_state?.type === 'required'
                ? 'This field is required'
                : ''
            }
            validation={{
              required: true
            }}
            register={register}
            required
          />
          <TextField
            name="date_formation"
            label="Date of Incorporation/Formation"
            placeholder="placeholder"
            type="date"
            error={
              errors?.date_formation?.type === 'required'
                ? 'This field is required'
                : ''
            }
            validation={{
              required: true
            }}
            register={register}
            required
          />
          <TextField
            name="date_authorization"
            label="Date of Authorization (Foreign)"
            placeholder="placeholder"
            type="date"
            error={
              errors?.date_authorization?.type === 'required'
                ? 'This field is required'
                : ''
            }
            validation={{
              required: true
            }}
            register={register}
            required
          />
          <div className="mt_24"></div>
          <div className="onfooter">
            <div></div>
            <div className="flex item_vcenter">
              <Button type="button" label="Cancel" color="outline-lite" />
              <Button type="submit" label="Save" color="bg-button" />
            </div>
          </div>
        </form>
      </div>
    </>
  );
};
