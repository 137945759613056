import { toast } from 'react-toastify';

export const Toasts = {
  success: (message) => {
    const data =
      typeof message === 'string'
        ? message
        : message?.[0]?.message || 'some error occoured';
    return toast.success(data, {
      position: 'top-right',
      autoClose: 5000,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      theme: 'light'
    });
  },

  error: (message) => {
    const data =
      typeof message === 'string'
        ? message
        : message?.[0]?.message || 'some error occoured';
    return toast.error(data, {
      position: 'top-right',
      autoClose: 5000,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      theme: 'light'
    });
  },

  warn: (message) => {
    const data =
      typeof message === 'string'
        ? message
        : message?.[0]?.message || 'some error occoured';
    return toast.warn(data, {
      position: 'top-right',
      autoClose: 5000,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      theme: 'light'
    });
  },

  info: (message) => {
    const data =
      typeof message === 'string'
        ? message
        : message?.[0]?.message || 'some error occoured';
    return toast.info(data, {
      position: 'top-right',
      autoClose: 5000,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      theme: 'light'
    });
  }
};
