import { useEffect, useState } from 'react';
import { RenderIf } from 'utilities/helpers';
import ProgressSteps from 'components/steps';
import { AddNewCategory } from 'pages/addbranch/addcategory';
import { CardImgMenuList } from 'components/cards/imgmenu';
import { CardMenuList } from 'components/cards/menulist';
import { AddMenuItem } from 'pages/addbranch/menuitem';
import { Button } from 'components/button';
import { Ikon } from 'assets/icons';
import { useQuery } from 'react-query';
import { client } from 'utilities/db';
import { useNavigate } from 'react-router-dom';
import { useStore } from 'store';
import { Loading } from 'components/loading';

export const MainCategoryList = ({
  onChangeStep,
  onGoBack,
  tempUpload = false,
  locationId,
  branchArr
}) => {
  const { branch } = useStore((state) => state);

  const navigate = useNavigate();
  const { branchesSameMenu } = useStore((state) => state);
  const [menuStep, setMenuStep] = useState(1);
  const [selectedCategory, setselectedCategory] = useState({});
  const [selcetedMenu, setSelcetedMenu] = useState({});
  const [isEdit, setIsEdit] = useState('');
  const {
    refetch,
    isLoading,
    // error,
    data = []
    // isSuccess
  } = useQuery(['fetchCategoriesOfBranch', branch?.id], async () => {
    if (branchesSameMenu) {
      const data = await client.get(`/category/find-by-branch/${branch?.id}`);
      return data.data.data;
    } else {
      const branchData = async (id) => {
        return await client.get(`/category/find-by-branch/${id}`);
      };
      const allBranchData = async () => {
        return await Promise.all(branchArr.map(branchData));
      };
      const data = await allBranchData();
      return data.data.data;
    }
  });
  const {
    refetch: refetchMenu,
    isLoading: menuIsLoading,
    // error: menuError,
    data: menuData = []
    // isSuccess: menuIsSuccess
  } = useQuery(['fetchMenuOfCategoriesByBrands'], async () => {
    const data = await client.get(`/menuItem/findAllByBranch/${branch?.id}`);
    return data.data.menuItems;
  });
  useEffect(() => {
    if (tempUpload) {
      setMenuStep(5);
    }
  }, [tempUpload]);

  useEffect(() => {
    if (menuStep === 1) {
      refetch();
      setIsEdit('');
      setselectedCategory({});
      setSelcetedMenu({});
    }
  }, [menuStep, refetch]);

  useEffect(() => {
    if (!selectedCategory?.id) return;
    refetchMenu();
  }, [selectedCategory?.id, refetchMenu]);

  return (
    <>
      <div style={{ minHeight: window.innerHeight }} className={`onflexlay`}>
        <div
          className={
            menuStep === 2 || menuStep === 4 ? 'onbox onbox2' : 'onbox'
          }
        >
          <div className="grid grid_gap60">
            <div
              className={
                menuStep === 2 || menuStep === 4 ? 'collg6' : 'collg12'
              }
            >
              <h2>
                <strong>Add Menu</strong>
              </h2>
              <h5>Create new category</h5>
              <ProgressSteps active={3} />
              <h5 className="mt_30">
                {menuStep === 2 || menuStep === 1 ? (
                  <b>Categories</b>
                ) : (
                  <b>Menus</b>
                )}
              </h5>
              <RenderIf isTrue={isLoading}>
                <div className={`loading_full`}>
                  <Loading />
                </div>
              </RenderIf>
              <RenderIf isTrue={menuStep === 2}>
                <div className="flex flex_wrap">
                  {data?.map((item, i) => (
                    <CardImgMenuList
                      key={i}
                      media={item?.image}
                      title={item?.category_name}
                    />
                  ))}
                </div>
              </RenderIf>
              <RenderIf isTrue={menuStep === 4}>
                <div className="flex flex_wrap">
                  {menuData?.map((item, i) => (
                    <CardImgMenuList
                      key={i}
                      media={item?.image}
                      title={item?.menu_item_name}
                    />
                  ))}
                </div>
              </RenderIf>
              <RenderIf isTrue={menuStep === 1}>
                <div className="flex flex_wrap">
                  {data?.map((item, i) => (
                    <CardImgMenuList
                      onClick={() => setselectedCategory(item)}
                      key={i}
                      media={item?.image}
                      title={item?.category_name}
                    />
                  ))}
                  <button className="add_button" onClick={() => setMenuStep(2)}>
                    <span>
                      <Ikon plus />
                    </span>
                    <span>Add Category</span>
                  </button>
                </div>

                <RenderIf isTrue={selectedCategory?.id}>
                  <hr className="mt_40" />
                  <div className="pt_30"></div>

                  <CardMenuList
                    title={selectedCategory?.name}
                    description={selectedCategory?.description}
                    types={selectedCategory?.types || []}
                    onClick={() => {
                      setIsEdit(selectedCategory?.id);
                      setMenuStep(2);
                    }}
                  />
                  <div className="mt_30"></div>
                  <RenderIf isTrue={menuIsLoading}>
                    <div className={`loading_full`}>
                      <Loading />
                    </div>
                  </RenderIf>
                  <div className="flex flex_wrap">
                    {menuData?.map((item, i) => (
                      <CardImgMenuList
                        onClick={() => setSelcetedMenu(item)}
                        key={i}
                        media={item?.image}
                        title={item?.menu_item_name}
                      />
                    ))}
                    <button
                      className="add_button"
                      onClick={() => setMenuStep(4)}
                    >
                      <span>
                        <Ikon plus />
                      </span>
                      <span>Add Item</span>
                    </button>
                  </div>
                  <div className="mt_30"></div>
                  <RenderIf isTrue={selcetedMenu?.id}>
                    <CardMenuList
                      onClick={() => {
                        setIsEdit(selcetedMenu?.id);
                        setMenuStep(4);
                      }}
                      title={selcetedMenu?.item}
                      description={selcetedMenu?.description}
                      types={[`$${selcetedMenu?.price}`]}
                    />
                  </RenderIf>
                </RenderIf>
              </RenderIf>
            </div>
            <RenderIf isTrue={menuStep === 2}>
              <div className="collg6">
                <AddNewCategory
                  isEdit={isEdit}
                  editableData={selectedCategory}
                  locationId={locationId}
                  setMenuStep={setMenuStep}
                  branchArr={branchArr}
                  branchesSameMenu={branchesSameMenu}
                />
              </div>
            </RenderIf>
            <RenderIf isTrue={menuStep === 4}>
              <div className="collg6">
                <AddMenuItem
                  menusRefetch={refetchMenu}
                  locationId={locationId}
                  editableId={isEdit}
                  selectedMenu={selcetedMenu}
                  selectedCategory={selectedCategory}
                  setMenuStep={setMenuStep}
                  branchArr={branchArr}
                  branchesSameMenu={branchesSameMenu}
                />
              </div>
            </RenderIf>
          </div>
          <div className="mt_35"></div>
          <div className="onfooter">
            <Button
              type="button"
              label={`I'll do this later`}
              onClick={() => navigate('/dashboard')}
              className="alink"
              color="dark"
            />
            <div className="flex item_vcenter">
              <Button
                onClick={() => onChangeStep(2)}
                type="button"
                label={'Back'}
                color="outline-lite"
              />
              {(menuStep === 1 || menuStep === 4) && (
                <Button
                  onClick={() => {
                    if (menuStep === 4) {
                      navigate('/dashboard');
                      return;
                    }
                    setMenuStep(4);
                  }}
                  type="submit"
                  label={'Next'}
                  color="bg-button"
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
