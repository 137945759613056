import { BuilderJSON } from 'store';
import styles from './index.module.css';

export const ButtonThemeSecondary = ({
  type = `button`,
  onClick = () => {},
  children
}) => {
  const { pages } = BuilderJSON((state) => state);
  const secondary = pages?.global?.style?.button?.secondary;

  return (
    <>
      <button
        type={type}
        onClick={onClick}
        className={styles.button}
        style={{
          backgroundColor: secondary?.backgroundColor,
          color: secondary?.textColor,
          borderRadius: `${secondary?.roundedCorners}px`
        }}
      >
        {children}
      </button>
    </>
  );
};
