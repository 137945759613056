import React from 'react';
import MixturePlanning from '../../../assets/Images/mixturePlanning.svg';
import IngridientPlanning from '../../../assets/Images/ingredientPlanning.svg';
import ItemsExpiring from '../../../assets/Images/itemsExpiring.svg';
import { Button } from 'components/button';
import { useNavigate } from 'react-router-dom';
import { ButtonDropdown } from 'components/select/button';
import styles from './index.module.css';

export default () => {
  const navigate = useNavigate();

  return (
    <>
      <div className="grid grid_gap30">
        <div className="collg4">
          <div className="mt_30"></div>
          <div className="shadow_box p16 min_height_171">
            <div className="flex item_vcenter item_space_btn">
              <p>
                <span className="ff_semi">Forecasted Revenue</span>
              </p>
              <ButtonDropdown
                style={{ backgroundColor: '#fff' }}
                titleChildren={`Next 7 Days`}
                positionX="rzero"
                isSimple
              >
                <div className={`shadow_box ${styles.dropdown}`}>
                  <p>Next 7 Days</p>
                  <p>Next 7 Days</p>
                </div>
              </ButtonDropdown>
            </div>
            <h1 className="mt_30 text_center">
              <b>2000</b>
            </h1>
            <div className="mt_30"></div>
          </div>
        </div>
        <div className="collg4">
          <div className="mt_30"></div>
          <div className="shadow_box p16 min_height_171">
            <div className="flex item_vcenter item_space_btn">
              <p>
                <span className="ff_semi">Food Waste</span>
              </p>
            </div>
            <div className="grid grid_gap30">
              <div className="collg4">
                <div className="text_center mt_30">
                  <img src={MixturePlanning} alt="" />
                  <div className="f11 mt_8" color="primary">
                    5
                  </div>
                  <p className="f11">Mixtures Expiring</p>
                </div>
              </div>
              <div className="collg4">
                <div className="text_center mt_30">
                  <img src={IngridientPlanning} alt="" />
                  <div className="f11 mt_8" color="primary">
                    5
                  </div>
                  <p className="f11">Ingredients Expiring</p>
                </div>
              </div>
              <div className="collg4">
                <div className="text_center mt_30">
                  <img src={ItemsExpiring} alt="" />
                  <div className="f11 mt_8" color="danger">
                    5
                  </div>
                  <p className="f11">Items Expiring</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="collg4">
          <div className="mt_30"></div>
          <div className="shadow_box p16 min_height_171">
            <div className="flex item_vcenter item_space_btn">
              <p>
                <span className="ff_semi">Kitchen Planning</span>
              </p>
            </div>
            <p className="f11 mt_5">
              Lörem ipsum prerat kippavandring. Lånat fyst yre pår. Timellare
              hobäl och mibäst nyktig rev. Supraren televing, bin Ladin-rabatt.
            </p>
            <br />
            <Button
              onClick={() => navigate('/kitchenplanning')}
              label={'Kitchen Planning'}
              color="outline-primary"
            />
          </div>
        </div>
      </div>
    </>
  );
};
