import SaleOverview from 'components/charts/overviewcharts/salesoverview';
import TotalProfit from 'components/charts/overviewcharts/totalprofit';
import Barchart from 'components/charts/revenuecharts/barchart';
import Lineshadowchartp from 'components/charts/revenuecharts/lineshadowchartp';
import Scatterchart from 'components/charts/revenuecharts/scatterchart';
import Linechart from 'components/charts/staticscharts/linechart';
import Lineshadowchart from 'components/charts/staticscharts/lineshadowchart';
import Needlepiechart from 'components/charts/staticscharts/needlepiechart';
import Piechart from 'components/charts/staticscharts/piechart';
import { useStore } from 'store';
import styles from './index.module.css';
import { useEffect, useMemo, useState } from 'react';
import { RenderIf } from 'utilities/helpers';
import CategoryReport from 'components/charts/datatable/categoryreport';
import MenuReport from 'components/charts/datatable/menuitemreport';
import { DashboardAreaChartBig } from 'components/charts/dashboardareachartbig';
import { DashboardLineChart } from 'components/charts/dashboardlinechart';
import { useFetchReportsHook } from 'hooks/overview/useFetchReports';
import { Loading } from 'components/loading';
import { Ikon } from 'assets/icons';
import { DashboardPiechart } from 'components/charts/dashboardpiechart';
import { DashboardOrderType } from 'components/charts/dashboardordertype';
import { DashboardCategoryPiechart } from 'components/charts/dashcategorypiechart';
import { useFetchDashboardHook } from 'hooks/dashboard/useFetchDashboard';
import ReportingJson from '../../reporting.json';

const saleData = [
  {
    name: 'Page A',
    uv: 1000,
    pv: 2400,
    amt: 2400,
    key: 'uv'
  },
  {
    name: 'Page B',
    uv: 1500,
    pv: 1398,
    amt: 2210,
    key: 'uv'
  },
  {
    name: 'Page C',
    uv: 1000,
    pv: 9800,
    amt: 2290,
    key: 'uv'
  },
  {
    name: 'Page D',
    uv: 1680,
    pv: 3908,
    amt: 2000,
    key: 'uv'
  },
  {
    name: 'Page E',
    uv: 1390,
    pv: 4800,
    amt: 2181,
    key: 'uv'
  },
  {
    name: 'Page F',
    uv: 1790,
    pv: 3800,
    amt: 2500,
    key: 'uv'
  },
  {
    name: 'Page G',
    uv: 2490,
    pv: 4300,
    amt: 2100,
    key: 'uv'
  }
];

const averageBasketData = [
  {
    name: '01',
    uv: 40,
    pv: 91,
    amt: 77
  },
  {
    name: '02',
    uv: 80,
    pv: 99,
    amt: 81
  },
  {
    name: '03',
    uv: 45,
    pv: 100,
    amt: 90
  },
  {
    name: '04',
    uv: 85,
    pv: 90,
    amt: 70
  },
  {
    name: '05',
    uv: 39,
    pv: 96,
    amt: 81
  },
  {
    name: '06',
    uv: 79,
    pv: 57,
    amt: 50
  },
  {
    name: '07',
    uv: 49,
    pv: 77,
    amt: 67
  },
  {
    name: '08',
    uv: 89,
    pv: 91,
    amt: 80
  },
  {
    name: '09',
    uv: 39,
    pv: 80,
    amt: 77
  },
  {
    name: '10',
    uv: 79,
    pv: 100,
    amt: 91
  }
];

const Dashboard = () => {
  const [lineChart, setLineChart] = useState(saleData);
  const [averageBasketLineChart, setAverageBasketLineChart] =
    useState(averageBasketData);
  const [pieChart, setPieChart] = useState([]);
  const [barChart, setBarChart] = useState([]);
  const { user, brand, branch } = useStore((state) => state);

  const { reportData, reportLoading } = useFetchReportsHook(
    'overview',
    brand?.id,
    branch?.id
  );

  const { dashboardData, dashboardLoading, dashboardRefetch } =
    useFetchDashboardHook(`dasboard-${branch?.id}`, branch?.id);
  useMemo(() => {
    if (reportData && !reportLoading) {
      // setLineChart(reportData?.ordersReport?.ordersCount);
      setLineChart(saleData);
      setAverageBasketLineChart(averageBasketData);
      setPieChart(reportData?.ordersReport?.ordersPlatform);
      setBarChart(reportData?.ordersReport?.ordersSales);
    }
  }, [reportData, reportLoading]);

  const dataForDashbaord = ReportingJson.data; //dashboardData?.data || ReportingJson.data;

  const salesAreaChartData =
    dataForDashbaord?.orderEachDay?.map((item) => {
      return {
        date: item?.date,
        total: item?.total,
        keyName: 'total'
      };
    }) || [];

  const averageBasketChartData =
    dataForDashbaord?.orderEachDay?.map((item) => {
      return {
        date: item?.date,
        average: item?.averageOrderValue,
        keyName: 'average'
      };
    }) || [];

  const averageBasketChartItems =
    dataForDashbaord?.orderEachDay
      ?.map((item) => item?.topMenuItems)
      ?.flat()
      ?.slice(0, 3) || [];

  console.log('🚀 ~ ReportingJson:', dataForDashbaord);

  const allCustomersChartData =
    dataForDashbaord?.repeatedCustomer > 0
      ? [
          ({
            name: 'Repeated Customers',
            value: dataForDashbaord?.repeatedCustomer
          },
          {
            name: 'Total Customers',
            value:
              dataForDashbaord?.totalCustomers -
              dataForDashbaord?.repeatedCustomer
          })
        ]
      : [];

  const topCategoriesChartData =
    dataForDashbaord?.topCategories?.map((item) => {
      return {
        categoryName: item?.categoryName,
        value: item?.count
      };
    }) || [];

  return (
    <>
      <RenderIf isTrue={reportLoading}>
        <div className={`loading_full`}>
          <Loading />
        </div>
      </RenderIf>
      <>
        <RenderIf isTrue={!reportLoading}>
          <div className={styles.layout}>
            <header className={styles.header}>
              <div>
                <div className={styles.iconBx}>
                  <Ikon name={`net-sales`} />
                </div>
                <div className={styles.headItemContent}>
                  <p>Net sales</p>
                  <h3>${dataForDashbaord?.totalNetSales || 0}</h3>
                </div>
              </div>
              <div>
                <div className={styles.headItemContent}>
                  <p>Total orders</p>
                  <h3>${dataForDashbaord?.orderVolume || 0}</h3>
                  <p>
                    <small>
                      <span>+23%</span> since last month
                    </small>
                  </p>
                </div>
              </div>
              <div>
                <div className={styles.iconBx}>
                  <Ikon name={`amount-paid`} />
                </div>
                <div className={styles.headItemContent}>
                  <p>Total sales</p>
                  <h3>${dataForDashbaord?.totalSales || 0}</h3>
                </div>
              </div>
              <div>
                <div className={styles.iconBx}>
                  <Ikon name={`select-time`} />
                </div>
                <div className={styles.headItemContent}>
                  <p>Select time period</p>
                  <h3></h3>
                </div>
              </div>
            </header>
            <div className={styles.flex_1}>
              <div>
                <DashboardAreaChartBig
                  data={salesAreaChartData}
                  keyName={salesAreaChartData?.[0]?.keyName}
                />
              </div>
              <div>
                <DashboardLineChart
                  data={averageBasketChartData || averageBasketLineChart}
                  keyName={averageBasketChartData?.[0]?.keyName}
                  itemsData={averageBasketChartItems}
                />
              </div>
            </div>
            <div className={styles.flex_2}>
              <div>
                <DashboardPiechart
                  chartData={allCustomersChartData}
                  repeatedCustomer={dataForDashbaord?.repeatedCustomer}
                  newCustomers={
                    dataForDashbaord?.totalCustomers -
                    dataForDashbaord?.repeatedCustomer
                  }
                />
              </div>
              <div>
                <DashboardOrderType data={dataForDashbaord?.orderType} />
              </div>
              <div>
                <DashboardCategoryPiechart chartData={topCategoriesChartData} />
              </div>
            </div>
          </div>
        </RenderIf>
      </>
    </>
  );
};

export default Dashboard;
