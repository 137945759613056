import React, { useEffect, useRef } from 'react';
import dragAndDrop from 'assets/Images/dragAndDrop.svg';
import { useMutation } from 'react-query';
import { client } from 'utilities/db';
import { Toasts } from 'components/toasts';
import styles from './index.module.css';

function ImageDropzone({
  image,
  setImage,
  setIsLoading = () => {},
  customComponent,
  handleImageUpload,
  large = false
}) {
  const { mutate, isLoading, isSuccess, isError, error, data } = useMutation(
    (userData) => {
      return client.post(`/upload`, userData);
    }
  );
  const imageRef = useRef(null);
  const openImage = () => imageRef.current.click();
  const onImagePick = (file) => {
    const formData = new FormData();
    // const path = window.URL.createObjectURL(file.target.files[0]);
    formData.append('image_url', file.target.files[0]);
    formData.append('type', 'image');
    formData.append('filename', 'demo');
    mutate(formData);
  };
  const onImageDrag = (file) => {
    const formData = new FormData();
    file.preventDefault();
    formData.append('image_url', file.dataTransfer.files[0]);
    formData.append('type', 'image');
    formData.append('filename', 'demo');
    mutate(formData);
  };

  useEffect(() => {
    if (!isError) return;
    if (isError) {
      Toasts.error(`File format not supported`);
    }
  }, [isError]);

  useEffect(() => {
    if (!isSuccess) return;
    setImage(data?.data?.data?.[0]?.image_url);
  }, [isSuccess]);

  useEffect(() => {
    setIsLoading(isLoading);
  }, [isLoading]);

  return (
    <>
      <article className={large ? `input_browse lg` : styles.browse}>
        {isLoading ? (
          <div className="upload1">
            <h3>Loading</h3>
          </div>
        ) : image ? (
          <div
            onDrop={onImageDrag}
            onDragOver={(e) => e.preventDefault()}
            onClick={openImage}
            className="upload1"
          >
            <img className="upload_img1" src={image} alt="" />
          </div>
        ) : customComponent ? (
          <div
            onDrop={onImageDrag}
            onDragOver={(e) => e.preventDefault()}
            onClick={openImage}
          >
            <div className={styles.bx}>{customComponent}</div>
          </div>
        ) : (
          <div
            onDrop={onImageDrag}
            onDragOver={(e) => e.preventDefault()}
            onClick={openImage}
            className="upload1"
          >
            <div className={`upload_content1`}>
              <img src={dragAndDrop} alt="" />
              <p>
                Supported formates: JPEG, PNG, GIF, MP4, PDF, PSD, AI, Word, PPT
              </p>
            </div>
          </div>
        )}
        <input
          onChange={handleImageUpload ? handleImageUpload : onImagePick}
          ref={imageRef}
          type="file"
          className="hide"
        />
      </article>
    </>
  );
}

export default ImageDropzone;
