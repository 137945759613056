import React, { useEffect, useState } from 'react';

import styles from './index.module.css';
import { Ikon } from 'assets/icons';
import { RenderIf } from 'utilities/helpers';

export const CarouselItem = ({ children, width, opacity }) => {
  return (
    <>
      <article
        className={styles.carouselItem}
        style={{ width: width, opacity: opacity }}
      >
        {children}
      </article>
    </>
  );
};

const Carousel = ({
  children,
  slideItemWidth,
  showIndicators = true,
  showPageLabels = true,
  indicateHorizontalStart = false,
  indicateHorizontalCenter = false,
  indicateHorizontalEnd = false,
  indicateVerticalStart = false,
  indicateVerticalCenter = false,
  indicateVerticalEnd = false,
  pageLHorizontalStart = false,
  pageLHorizontalCenter = false,
  pageLHorizontalEnd = false,
  pageLVerticalStart = false,
  pageLVerticalCenter = false,
  pageLVerticalEnd = false,
  indicateTop,
  indicateRight,
  indicateBottom,
  indicateLeft,
  pageLTop,
  pageLRight,
  pageLBottom,
  pageLLeft,
  indicateColor,
  pageLColor
}) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [paused, setPaused] = useState(false);

  const updateIndex = (newIndex) => {
    if (newIndex < 0) {
      newIndex = React?.Children?.count(children) - 1;
    } else if (newIndex >= React?.Children?.count(children)) {
      newIndex = 0;
    }

    setActiveIndex(newIndex);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (!paused) {
        updateIndex(activeIndex + 1);
      }
    }, 3000);

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  });

  return (
    <>
      <article
        className={styles.carousel}
        onMouseEnter={() => setPaused(true)}
        onMouseLeave={() => setPaused(false)}
      >
        <div
          className={styles.carouselInner}
          style={{ transform: `translateX(-${activeIndex * 100}%)` }}
        >
          {React?.Children?.map(children, (child, index) => {
            return React?.cloneElement(child, {
              width: `100%`
              // opacity: index === activeIndex ? 1 : 0
            });
          })}
        </div>
        <RenderIf
          isTrue={showIndicators && React?.Children?.count(children) > 1}
        >
          <div
            className={
              indicateHorizontalStart
                ? `${styles.indicators} ${styles.horizontalStart}`
                : indicateHorizontalCenter
                ? `${styles.indicators} ${styles.horizontalCenter}`
                : indicateHorizontalEnd
                ? `${styles.indicators} ${styles.horizontalEnd}`
                : indicateVerticalStart
                ? `${styles.indicators} ${styles.verticalStart}`
                : indicateVerticalCenter
                ? `${styles.indicators} ${styles.verticalCenter}`
                : indicateVerticalEnd
                ? `${styles.indicators} ${styles.verticalEnd}`
                : `${styles.indicators}`
            }
            style={{
              top: indicateTop ? indicateTop : `initial`,
              right: indicateRight ? indicateRight : `initial`,
              bottom: indicateBottom ? indicateBottom : `initial`,
              left: indicateLeft ? indicateLeft : `initial`
            }}
          >
            <button
              type={`button`}
              onClick={() => {
                updateIndex(activeIndex - 1);
              }}
              style={{
                border: `${indicateColor ? indicateColor : `#ffffff`} 1px solid`
              }}
            >
              <Ikon
                name={`left-cert`}
                fillColor={indicateColor ? indicateColor : `#FFFFFF`}
              />
            </button>
            <button
              type={`button`}
              onClick={() => {
                updateIndex(activeIndex + 1);
              }}
              style={{
                border: `${indicateColor ? indicateColor : `#ffffff`} 1px solid`
              }}
            >
              <Ikon
                name={`right-cert`}
                fillColor={indicateColor ? indicateColor : `#FFFFFF`}
              />
            </button>
          </div>
        </RenderIf>
        <RenderIf
          isTrue={showPageLabels && React?.Children?.count(children) > 1}
        >
          <div
            className={
              pageLHorizontalStart
                ? `${styles.pageLabels} ${styles.horizontalStart}`
                : pageLHorizontalCenter
                ? `${styles.pageLabels} ${styles.horizontalCenter}`
                : pageLHorizontalEnd
                ? `${styles.pageLabels} ${styles.horizontalEnd}`
                : pageLVerticalStart
                ? `${styles.pageLabels} ${styles.verticalStart}`
                : pageLVerticalCenter
                ? `${styles.pageLabels} ${styles.verticalCenter}`
                : pageLVerticalEnd
                ? `${styles.pageLabels} ${styles.verticalEnd}`
                : styles.pageLabels
            }
            style={{
              top: pageLTop ? pageLTop : `initial`,
              right: pageLRight ? pageLRight : `initial`,
              bottom: pageLBottom ? pageLBottom : `initial`,
              left: pageLLeft ? pageLLeft : `initial`
            }}
          >
            {React?.Children?.map(children, (child, index) => {
              return (
                <button
                  type={`button`}
                  onClick={() => {
                    updateIndex(index);
                  }}
                  style={{
                    background: pageLColor
                      ? pageLColor
                      : `rgba(255, 255, 255, 1)`,
                    opacity: index === activeIndex ? 1 : 0.4
                  }}
                ></button>
              );
            })}
          </div>
        </RenderIf>
      </article>
    </>
  );
};

export default Carousel;
