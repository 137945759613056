import { RenderIf } from 'utilities/helpers';
import styles from './index.module.css';

export const BuilderSelectField = ({
  label,
  name,
  id,
  options = [],
  defaultValue,
  error,
  onChange = () => {}
}) => {
  return (
    <article className={styles.article}>
      <RenderIf isTrue={label}>
        <label>{label}</label>
      </RenderIf>
      <div>
        <select name={name} id={id} onChange={onChange}>
          <RenderIf isTrue={options?.length > 0}>
            {options?.map((opt, index) => {
              return (
                <option
                  key={`${index}`}
                  value={opt?.family}
                  selected={opt?.family === defaultValue}
                >
                  {opt?.family}
                </option>
              );
            })}
          </RenderIf>
        </select>
      </div>
      <RenderIf isTrue={error}>
        <div className={styles.error}>{error}</div>
      </RenderIf>
    </article>
  );
};
