import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { RenderIf } from 'utilities/helpers';
import { Button } from 'components/button';
import { ModalComponent } from 'components/modal';
import { AddBrandInformation } from './add';
import { AddBrandEmployee } from './add/employee';
import { AddBankInformation } from 'pages/brands/add/bank';
import { TextField } from 'components/input';
import { ButtonDropdown } from 'components/select/button';
import { Ikon } from 'assets/icons';
import styles from './index.module.css';
import { useFetchBrands } from 'hooks/resourceHooks';
import { getUser } from 'utilities/helpers/auth';
import { Loading } from 'components/loading';

export const CardEmptyBrand = ({
  title,
  buttonLabel,
  buttonClick = () => {},
  ikonName
}) => {
  return (
    <>
      <div className={styles.emptyBox}>
        <div>
          <h2>{title}</h2>
          <div className="mt_40"></div>
          <Button
            color={`outline-primary`}
            label={buttonLabel}
            onClick={buttonClick}
          />
        </div>
        <div>
          <Ikon name={ikonName} />
        </div>
      </div>
    </>
  );
};

export const BrandsPage = () => {
  const user = getUser();
  const [step, setStep] = useState(1);
  const [data, setData] = useState([]);
  const [search, setSearch] = useState('');
  const [isOpenModal, setIsOpenModal] = useState(false);
  const {
    isLoading: brandsLoading,
    data: brandsList = [],
    isFetching: brandsFetching,
    refetch: refetchBrands
  } = useFetchBrands(user?.id);
  const isLoading = brandsLoading || brandsFetching;
  const navigate = useNavigate();

  const onChangeStep = (step) => {
    refetchBrands();
    setStep(step);
  };

  const handleModal = () => {
    setStep(1);
    setIsOpenModal(!isOpenModal);
  };

  useEffect(() => {
    if (brandsList?.length > 0) {
      setData(brandsList?.filter((item) => item?.brand_name.includes(search)));
    }
  }, [search]);

  useEffect(() => {
    if (brandsList?.length > 0) {
      setData(brandsList);
    }
  }, [brandsList]);

  return (
    <>
      <article className={styles.article}>
        <div className="mt_25"></div>
        <div className="flex item_vcenter item_space_btn">
          <h2>
            <strong>Brands</strong>
          </h2>
          <Button
            type={`button`}
            label={`Add New Brand`}
            color={`bg-primary`}
            onClick={handleModal}
          />
        </div>
        <p>Lorem ipsum dolor ist meunda.</p>
        <div className="mt_30"></div>
        <RenderIf isTrue={isLoading}>
          <div className={`loading_full`}>
            <Loading />
          </div>
        </RenderIf>
        <RenderIf isTrue={brandsList?.length === 0 && !isLoading}>
          <CardEmptyBrand
            title={`No Brands Found Yet! Add a Brand First`}
            buttonLabel={`Add New Brand`}
            buttonClick={handleModal}
            ikonName={`nobrand`}
          />
        </RenderIf>
        <RenderIf isTrue={brandsList?.length > 0}>
          <div className="filter">
            <div className="grid grid_gap20">
              <div className="collg4">
                <TextField
                  name="search"
                  id="search"
                  type="text"
                  label={`Search`}
                  placeholder="search"
                  onChange={(e) => setSearch(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="mt_25"></div>
          <div className="brands">
            <table cellPadding={0} cellSpacing={0} className="table">
              <thead>
                <tr>
                  <th>Brand</th>
                  <th>Brand Manager</th>
                  <th>Phone</th>
                  <th>Email</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {data?.map((item) => (
                  <tr>
                    <td>
                      <div className="flex item_vcenter">
                        <div className="brand_profile">
                          {item?.image_url ? (
                            <picture>
                              <source srcSet="" />
                              <img src={item?.image_url} alt="" />
                            </picture>
                          ) : (
                            <div className="imgplace">
                              <Ikon name="imgplaceholder" />
                            </div>
                          )}
                        </div>
                        <h6>
                          <b>{item?.brand_name}</b>
                        </h6>
                      </div>
                    </td>
                    <td>{item?.brandManager?.name || ''}</td>
                    <td>{item?.phone_number}</td>
                    <td>{item?.brand_email}</td>
                    <td>
                      <ButtonDropdown>
                        <div className="bdd1 width_216">
                          <button
                            type="button"
                            className="bdd_item"
                            onClick={(e) =>
                              navigate(`/brand/detail/${item?.id}`)
                            }
                          >
                            View Details
                          </button>
                          {/* <button type="button" className="bdd_item">
                          Delete
                        </button> */}
                        </div>
                      </ButtonDropdown>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </RenderIf>
      </article>
      <RenderIf isTrue={isOpenModal}>
        <ModalComponent modal={isOpenModal} handleModal={handleModal}>
          <RenderIf isTrue={step === 1}>
            <AddBrandInformation onChangeStep={onChangeStep} />
          </RenderIf>
          <RenderIf isTrue={step === 2}>
            <AddBrandEmployee onChangeStep={onChangeStep} />
          </RenderIf>
          <RenderIf isTrue={step === 3}>
            <AddBankInformation
              onChangeStep={onChangeStep}
              handleModal={handleModal}
              setData={setData}
            />
          </RenderIf>
        </ModalComponent>
      </RenderIf>
    </>
  );
};
