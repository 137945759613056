import ProgressSteps from 'components/steps';
import { Toasts } from 'components/toasts';
import { useFetchManagerHook } from 'hooks/managers/useFetchManagerHooks';
import { AddBrandEmployeeForm } from 'pages/brands/add/employee';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getLocationId } from 'utilities/helpers/auth';

export const AddEmployeeInformation = ({ onChangeStep }) => {
  const navigate = useNavigate();
  const locationId = getLocationId();
  const { managerData, managerError } = useFetchManagerHook(
    'managers',
    locationId
  );

  useEffect(() => {
    if (!managerData) return;
    if (managerError) {
      Toasts.error(managerError?.response?.data?.message);
      return;
    }
    if (managerData) {
      navigate('/');
    }
  }, [managerData, managerError]);
  return (
    <>
      <div className="onbox">
        <h1>
          <strong>Add Brand</strong>
        </h1>
        <h5 className="mt_4">Manager's Information</h5>
        <div className="mt_16"></div>
        <div className="w_368 center_auto">
          <ProgressSteps active={3} />
        </div>
        {/* <h5 className="mt_26">Do you want to add manager?</h5> */}
        <div className="mt_26"></div>
        <AddBrandEmployeeForm onChangeStep={onChangeStep} onboarding={true} />
      </div>
    </>
  );
};
