import { useEffect, useState } from 'react';
import { RenderIf } from 'utilities/helpers';
import { AddBranch } from 'pages/addbranch';
import { NewBranch } from 'pages/addbranch/addnew';
import {
  getLocationData,
  getLocationId,
  getUser
} from 'utilities/helpers/auth';
import { useNavigate } from 'react-router-dom';
import { MultipleBranch } from 'pages/addbranch/multiplebranch';
import { useMutation } from 'react-query';
import { client } from 'utilities/db';
import { useFetchBranches, useFetchBrands } from 'hooks/resourceHooks';
import { useStore } from 'store';

export const AddBranchView = () => {
  const { setBranch } = useStore((state) => state);

  const { data: brandData, isLoading: brandLoading } = useFetchBrands();

  const locationId = getLocationId();

  const { data, isLoading: branchLoading } = useFetchBranches(
    brandData?.[0]?.id
  );

  console.log('🚀 ~ file: index.js ~ line 22 ~ AddBranchView ~ data', data);

  const navigate = useNavigate();
  const [step, setStep] = useState(0);
  const [branchEditableValues, setBranchEditableValues] = useState({});

  const { mutate, isLoading, isSuccess } = useMutation((data) => {
    return client.post(`/branch/add`, data);
  });
  const onChangeStep = (step) => {
    setStep(step);
  };
  const onGoBack = () => {
    if (step === 1) return;
    setStep(step - 1);
  };

  const onGoToMenu = () => {
    navigate('/add-menu');
  };

  const onEditClick = (val) => {
    setBranchEditableValues(val);
    onChangeStep(2);
  };

  const clearEditableValues = () => {
    setBranchEditableValues({});
  };

  useEffect(() => {
    if (branchLoading) return;
    if (data.length > 0) {
      setBranch(data?.[0]);
      window.localStorage.setItem('onBoardOnFinish', true);
      navigate('/dashboard');
      return;
    }
  }, [data, brandLoading, branchLoading]);

  if (branchLoading) {
    return <h1>Loading</h1>;
  }

  return (
    <>
      <RenderIf isTrue={step === 0}>
        <MultipleBranch onChangeStep={onChangeStep} />
      </RenderIf>
      <RenderIf isTrue={step === 1}>
        <AddBranch
          onGoToMenu={onGoToMenu}
          locationId={locationId}
          onEditClick={onEditClick}
          onChangeStep={onChangeStep}
          onGoBack={onGoBack}
        />
      </RenderIf>
      <RenderIf isTrue={step === 2}>
        <NewBranch
          onGoBack={() => setStep(1)}
          locationId={locationId}
          setBranchEditableValues={setBranchEditableValues}
          clearEditableValues={clearEditableValues}
          branchEditableValues={branchEditableValues}
          onChangeStep={onChangeStep}
        />
      </RenderIf>
      <RenderIf isTrue={step === 3}>
        <NewBranch
          singleBranch
          onGoBack={() => setStep(1)}
          locationId={locationId}
          setBranchEditableValues={setBranchEditableValues}
          clearEditableValues={clearEditableValues}
          branchEditableValues={branchEditableValues}
          onChangeStep={onChangeStep}
        />
      </RenderIf>
    </>
  );
};
