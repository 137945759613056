import { Table } from 'rsuite';
import { PrimaryButton } from 'components/button';
import { TextField } from 'components/input';
import { Toasts } from 'components/toasts';
import { useFetchDeploymentStatusHook } from 'hooks/domain/useFetchDeploymentStatusHook';
import { useFetchDomainSettingsHook } from 'hooks/domain/useFetchDomainSettingsHook';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { DeploymentClient } from 'utilities/db';
import { RenderIf } from 'utilities/helpers';
import styles from "./index.module.css";

const { Column, HeaderCell, Cell } = Table;

export const ExistingDomain = () => {
  const navigate = useNavigate();
  const brand = useLocation().state?.brand;

  const [submittingCustomDomain, setSubmittingCustomDomain] = useState(false);

  const { deploymentData, deploymentRefetch } = useFetchDeploymentStatusHook(
    `deploymentStatus/${brand?.id}`,
    brand?.id,
    brand?.brand_name,
    'PRODUCTION'
  );

  const { domainSettingData, domainSettingRefetch } =
    useFetchDomainSettingsHook(
      `domainSettings/${brand?.id}`,
      brand?.id,
      brand?.brand_name,
      'PRODUCTION'
    );

  const {
    handleSubmit,
    register,
    formState: { errors }
  } = useForm({
    defaultValues: {
      domainName: ''
    }
  });

  const handleSubmitCustomDomain = async (data) => {
    setSubmittingCustomDomain(true);
    try {
      const response = await DeploymentClient.post(
        `/deployment/addCustomDomain`,
        {
          brandId: `${brand?.id}`,
          brandName: brand?.brand_name,
          enviorment: 'PRODUCTION',
          domainName: data.domainName?.trim()?.toLowerCase()
        }
      );
      setSubmittingCustomDomain(false);
      deploymentRefetch();
      domainSettingRefetch();
      Toasts.success('Custom domain added successfully');
    } catch (error) {
      setSubmittingCustomDomain(false);
      Toasts.error(error?.response?.data?.error || error?.message || 'Error');
    }
  };

  const handleVerifyCustomDomain = async () => {
    try {
      const response = (
        await DeploymentClient.post(`/deployment/checkCustomDomainStatus`, {
          brandId: `${brand?.id}`,
          brandName: brand?.brand_name,
          enviorment: 'PRODUCTION',
          domainName: domainSettingData?.existingDeploymentRecord?.customDomain
        })
      )?.data;

      if (response?.configured) {
        domainSettingRefetch();
        Toasts.success('verified successfully, redirecting in 2 seconds');
        setTimeout(() => {
          navigate('/online-store/domains');
        }, 2000);
      } else {
        Toasts.error(
          'Not verified, please check your DNS settings and try again'
        );
      }
    } catch (error) {
      Toasts.error(error?.response?.data?.error || error?.message || 'Error');
    }
  };

  const deploymentRecordData = domainSettingData?.existingDeploymentRecord;

  const handleSubmitSearchDomain = async (data) => {};

  return (
    <div className={styles.layout}>
      <header className={styles.header}>
        <div>
          <h2> Connect your existing domain</h2>
          <h6>Manage, connect and transfer domains.</h6>
        </div>
        <RenderIf
        isTrue={
          deploymentRecordData?.customDomainConfig &&
          !deploymentRecordData?.customDomainVerified
        }
      >
        <div>
          <PrimaryButton type="button" onClick={handleVerifyCustomDomain}>
            Verify
          </PrimaryButton>
        </div>
      </RenderIf>
      </header>

      <RenderIf isTrue={!deploymentRecordData?.customDomainConfig}>
        <form onSubmit={handleSubmit(handleSubmitCustomDomain)}>
          <div className={styles.pageBx}>
            <div>
              <input
                type={`text`}
                name={`domainName`}
                className={styles.input}
                placeholder={`Add your custom domain`}
                {...register(`domainName`, { required: true })}
                // error={
                //   errors?.domainName?.type === 'required'
                //     ? 'This field is required'
                //     : ''
                // }
              />
              <PrimaryButton type="submit" isLoading={submittingCustomDomain} inlineStyle={{ whiteSpace: `nowrap` }}>
                Get configration
              </PrimaryButton>
            </div>
          </div>
        </form>
      </RenderIf>
      <RenderIf
        isTrue={
          deploymentRecordData?.customDomainConfig &&
          !deploymentRecordData?.customDomainVerified
        }
      >
        <div className={styles.pageBx_1}>
          <h4>To setup custom domain <b>{deploymentRecordData?.customDomain}</b>{' '}
          Please add following records in your DNS settings</h4>
          <div className={styles.tableBx}>
            <table className={styles.table_1} cellPadding={0} cellSpacing={0}>
              <thead>
                <tr>
                  <th>Type</th>
                  <th>Name</th>
                  <th>Value</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    {deploymentRecordData?.customDomainConfig?.setting1?.type}
                  </td>
                  <td>
                    {deploymentRecordData?.customDomainConfig?.setting1?.name}
                  </td>
                  <td>
                    {deploymentRecordData?.customDomainConfig?.setting1?.value}
                  </td>
                </tr>
                <tr>
                  <td>
                    {deploymentRecordData?.customDomainConfig?.setting2?.type}
                  </td>
                  <td>
                    {deploymentRecordData?.customDomainConfig?.setting2?.name}
                  </td>
                  <td>
                    {deploymentRecordData?.customDomainConfig?.setting2?.value}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </RenderIf>
    </div>
  );
};
