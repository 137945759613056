import { useState } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useFetchRoles } from 'hooks/resourceHooks';
import { client } from 'utilities/db';
import { TextField } from 'components/input';
import { Button } from 'components/button';
import { Toasts } from 'components/toasts';
import styles from './index.module.css';
import { useFetchSingleBrand } from 'hooks/brands/useBrandHook';
import { emailRegex, phoneRegex } from 'utilities/regex';

export const PortfolioAddBranchManagerPage = () => {
  const navigate = useNavigate();
  const { brandId, branchId } = useParams();
  const action = useLocation()?.pathname?.split('/')[4];

  const { data: rolesData } = useFetchRoles();

  const [submitting, setSubmitting] = useState(false);

  const { data: brandData } = useFetchSingleBrand(brandId);

  const {
    handleSubmit,
    register,
    formState: { errors }
  } = useForm({
    defaultValues: {
      email: '',
      name: '',
      phone: ''
    }
  });

  const onManagerSubmit = async (val) => {
    try {
      setSubmitting(true);
      if (brandData?.brand_type === 'CLOUD') {
        if (action === 'add') {
          await client.post(`/team/add`, {
            email: val?.email,
            name: val?.name,
            phone: val?.phone,
            role_id: 4,
            brand_id: parseInt(brandId),
            branch_id: null,
            existing_user_id: null,
            cloud_id: parseInt(branchId),
            team_level: 'cloud_manager'
          });
          setSubmitting(false);
          navigate(`/portfolio/branch/details/${brandId}/${branchId}`);
          Toasts.success('Branch Manager added successfully');
        }
        if (action === 'update') {
          const response = await client.put(`/team/update`, {
            email: val?.email,
            name: val?.name,
            phone: val?.phone,
            role_id: 4,
            brand_id: parseInt(brandId),
            branch_id: null,
            existing_user_id: null,
            cloud_id: parseInt(branchId),
            team_level: 'cloud_manager'
          });
        }
      } else {
        if (action === 'add') {
          await client.post(`/team/add`, {
            email: val?.email,
            //user_type_id: 1,
            name: val?.name,
            phone: val?.phone,
            role_id: rolesData?.find(
              (role) => role?.role_name === 'Branch Manager'
            )?.id,
            brand_id: parseInt(brandId),
            branch_id: parseInt(branchId),
            existing_user_id: null,
            team_level: 'branch_manager'
          });
          setSubmitting(false);
          navigate(`/portfolio/branch/details/${brandId}/${branchId}`);
          Toasts.success('Branch Manager added successfully');
        }
        if (action === 'update') {
          const response = await client.put(`/team/update`, {
            email: val?.email,
            user_type_id: 1,
            name: val?.name,
            phone: val?.phone,
            role_id: 2,
            brand_id: brandId,
            branch_id: branchId,
            existing_user_id: null,
            team_level: 'branch_manager'
          });
        }
      }
    } catch (error) {
      Toasts.error(error?.response?.data?.message);
      setSubmitting(false);
    }
  };

  return (
    <>
      <div className="mt_40"></div>
      <div className={styles.box}>
        <form onSubmit={handleSubmit(onManagerSubmit)}>
          <h2>
            <span className="ff_semi">
              {brandData?.brand_type === 'CLOUD'
                ? 'Add Cloud Location Manager Page'
                : 'Add Branch Manager Page'}
            </span>
          </h2>
          <div className="mt_16"></div>
          <TextField
            name="name"
            label={`Manager's Name`}
            type="text"
            error={
              errors?.name?.type === 'required'
                ? 'Manager name is required'
                : ''
            }
            placeholder={'John Doe'}
            validation={{
              required: true
            }}
            register={register}
            required
          />
          <TextField
            placeholder={'kfc@kfc.com'}
            name="email"
            label={`Manager's Email`}
            type="text"
            error={
              errors?.email?.type === 'required'
                ? 'Email is required'
                : errors?.email?.type === 'pattern'
                ? 'Email is invalid'
                : ''
            }
            validation={{
              required: true,
              pattern: emailRegex
            }}
            register={register}
            required
          />
          <TextField
            placeholder={'456787654'}
            name="phone"
            label={`Manager's Phone number`}
            type="number"
            error={
              errors?.phone?.type === 'required' ? 'Phone is required' : errors?.phone?.type === 'pattern'
              ? 'Phone is invalid'
              : ''
            }
            validation={{
              required: true,
              pattern: phoneRegex
            }}
            register={register}
            required
          />
          <div className="mt_24"></div>
          <div className="onfooter">
            <div></div>
            <div className="flex item_vcenter">
              <Button
                type="submit"
                label="Save"
                color="bg-button"
                disabled={submitting}
              />
            </div>
          </div>
        </form>
      </div>
    </>
  );
};
