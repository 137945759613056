import { client } from 'utilities/db';
import { useQuery } from 'react-query';

export const useFetchProductsResDepoHook = (keyName, id) => {
  const { status, data, error, isFetching, isLoading, refetch } = useQuery(
    [keyName],
    () => getProducts(id),
    {
      enabled: !!id,
      refetchInterval: 60000
    }
  );

  return {
    productsResDepoStatus: status,
    productsResDepoData: data,
    productsResDepoError: error,
    productsResDepoFetching: isFetching,
    productsResDepoLoading: isLoading,
    productsResDepoRefetch: refetch
  };
};

const getProducts = async (id) => {
  let result = await client.get(
    `/inventoryReceipt/getRecieptItemsResDepo/${id}`
  );
  result = result.data;
  result = result?.recieptItems;

  return result;
};
