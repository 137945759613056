import { client } from 'utilities/db';
import { useQuery } from 'react-query';

export const useFetchRequestsAgainstUser = (keyName, id) => {
  const { status, data, error, isFetching, isLoading, refetch } = useQuery(
    [keyName, id],
    () => getData(id),
    {
      enabled: !!id,
      refetchInterval: 60000
    }
  );

  return {
    brandRequestStatus: status,
    brandRequestData: data,
    brandRequestError: error,
    brandRequestFetching: isFetching,
    brandRequestLoading: isLoading,
    brandRequestRefetch: refetch
  };
};

const getData = async (id) => {
  let result = await client.get(
    `/cloudKitchen/getCloudKitchenByBrandOwnerId/${id}`
  );
  result = result.data;
  result = result?.cloudKitchenRestaurants;
  return result;
};
