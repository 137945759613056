import { useQuery } from 'react-query';
import { client } from 'utilities/db';
import { useStore } from 'store';

export const useFetchSingleCategory = (id) => {
  const { brand } = useStore((state) => state);
  const {
    isLoading,
    data = [],
    isSuccess,
    isError,
    refetch
  } = useQuery(
    ['fetchSingleCategory'],
    async () => {
      const data = await client.get(
        `/category/with-types-by-brand-and-category/${brand?.id}/${id}`
      );
      return data.data;
    },
    {
      enable: !!id
    }
  );

  return {
    data,
    isLoading,
    isSuccess,
    isError,
    refetch
  };
};

export const useFetchSingleMenuItem = (keyName, brandId, menuId) => {
  const { isLoading, data, isSuccess, isError, refetch } = useQuery(
    [keyName, brandId, menuId],
    async () => {
      const response = await client.get(
        `/menuItem/findAllByBrandAndMenuItem/${brandId}/${menuId}`
      );
      return response?.data?.menuItem;
    },
    {
      enable: !!menuId || !!brandId
    }
  );

  return {
    data,
    isLoading,
    isSuccess,
    isError,
    refetch
  };
};

export const useFetchSingleModifierGroup = (id) => {
  const { brand } = useStore((state) => state);
  const {
    isLoading,
    data = [],
    isSuccess,
    isError,
    refetch
  } = useQuery(
    ['fetchSingleModifierGroup'],
    async () => {
      const data = await client.get(
        `/modifierGroup/findAllByBrandAndModifier/${brand?.id}/${id}`
      );
      return data.data;
    },
    {
      enable: !!id
    }
  );

  return {
    data,
    isLoading,
    isSuccess,
    isError,
    refetch
  };
};
