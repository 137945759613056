import { useState } from 'react';
import { useForm, useFieldArray } from 'react-hook-form';
import { RenderIf } from 'utilities/helpers';
import { Button } from 'components/button';
import { TextField } from 'components/input';
import { TextArea } from 'components/textarea';
import { CustomSelectDropdown } from 'components/select/multi';
import { BadgeCheckbox } from 'components/badgecheckbox';
import { Ikon } from 'assets/icons';
import styles from './index.module.css';

const temporaryProducts = [
  {
    groupName: 'Avocado',
    products: [
      {
        name: 'Green',
        id: '1',
        quantity: '',
        unit: ''
      },
      {
        name: 'Red',
        id: '2',
        quantity: '',
        unit: ''
      },
      {
        name: 'Blue',
        id: '3',
        quantity: '',
        unit: ''
      },
      {
        name: 'Orange',
        id: '4',
        quantity: '',
        unit: ''
      }
    ]
  },
  {
    groupName: 'Basil',
    products: [
      {
        name: 'Basil 1',
        id: '5',
        quantity: '',
        unit: ''
      },
      {
        name: 'Basil 2',
        id: '6',
        quantity: '',
        unit: ''
      },
      {
        name: 'Basil 3',
        id: '7',
        quantity: '',
        unit: ''
      }
    ]
  },
  {
    groupName: 'Meat',
    products: [
      {
        name: 'Meat 1',
        id: '8',
        quantity: '',
        unit: ''
      },
      {
        name: 'Meat 2',
        id: '9',
        quantity: '',
        unit: ''
      },
      {
        name: 'Meat 3',
        id: '10',
        quantity: '',
        unit: ''
      }
    ]
  },
  {
    groupName: 'Egg',
    products: [
      {
        name: 'Egg 1',
        id: '11',
        quantity: '',
        unit: ''
      },
      {
        name: 'Egg 2',
        id: '12',
        quantity: '',
        unit: ''
      }
    ]
  }
];

export const AddUpdateRecipePage = () => {
  // const location = useLocation()?.pathname?.split('/');
  // const action = location?.[2];
  // const type = location?.[3];
  const [option, setOption] = useState('Recipe');
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [addStep, setAddStep] = useState(false);
  const [stepData, setStepData] = useState([
    'Quisque rutrum. Aenean imperdi. Etiam ultricies nisi vel augue. Curabitur ullamcorper ultricies nisi. Nam eget dui. Etiam rhoncus. Maecenas tempus, tellus eget.',
    'Quisque rutrum. Aenean imperdi. Etiam ultricies nisi vel augue. Curabitur ullamcorper ultricies nisi.',
    'Etiam ultricies nisi vel augue. Curabitur ullamcorper ultricies nisi. Nam eget dui. Etiam rhoncus. Maecenas tempus, tellus eget.',
    'Quisque rutrum. Aenean imperdi. Etiam ultricies nisi vel augue.'
  ]);

  const {
    handleSubmit,
    register,
    formState: { errors },
    control
    // watch
  } = useForm({
    defaultValues: {
      recipe_name: '',
      serving: '',
      cooking_time: '',
      category: '',
      allergens: [{ allergen: '' }],
      unit: '',
      quantity: '',
      expire_time: '',
      alert_time: '',
      steps: []
    }
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'allergens'
  });

  const {
    fields: stepsFields,
    append: stepsAppend,
    remove: stepsRemove
  } = useFieldArray({
    control,
    name: 'steps'
  });

  const handleProductsSelected = (product) => {
    const check = selectedProducts?.filter(
      (item) =>
        item?.group === product?.group &&
        item?.product?.id === product?.product?.id
    );
    if (check?.length > 0) {
      return;
    } else {
      setSelectedProducts([...selectedProducts, product]);
    }
  };

  const handleSubmitRecipe = (formData) => {};

  const handleSaveIngredients = () => {
    // console.log('selectedProducts', selectedProducts);
  };

  return (
    <>
      <article className={styles.article}>
        <div className="flex item_space_btn">
          <div>
            <h2>
              <strong>Recipe engineering</strong>
            </h2>
            <p>Manage and create recipes here.</p>
          </div>
          <div></div>
        </div>
        <div className="mt_30"></div>
        <div className="flex item_vcenter">
          <Button
            className={option === 'Recipe' ? `tab active` : `tab`}
            label="Recipe"
            onClick={() => setOption('Recipe')}
          />
          <Button
            label="Ingredients"
            className={option === 'Ingredients' ? `tab active` : `tab`}
            onClick={() => setOption('Ingredients')}
          />
        </div>
        <RenderIf isTrue={option === 'Recipe'}>
          <form onSubmit={handleSubmit(handleSubmitRecipe)}>
            <div className="grid grid_gap30">
              <div className="collg4">
                <div className="mt_20"></div>
                <div className="shadow_box1">
                  <h4>
                    <strong>Recipe details</strong>
                  </h4>
                  <div className="mt_15"></div>
                  <div>
                    <CustomSelectDropdown
                      label="Recipe name"
                      {...register('recipe_name', {
                        required: true
                      })}
                      options={[
                        { value: 1, label: 'option 1' },
                        { value: 2, label: 'option 2' }
                      ]}
                      error={
                        errors?.recipe_name?.type === 'required'
                          ? 'Recipe name is required'
                          : ''
                      }
                      placeholder="Choose recipe"
                    />
                    <div className="mt_10"></div>
                    <TextField
                      label="Serving"
                      placeholder="Serving"
                      name="serving"
                      error={
                        errors?.serving?.type === 'required'
                          ? 'Serving is required'
                          : ''
                      }
                      type="number"
                      register={register}
                      validation={{
                        required: true
                      }}
                    />
                    <div className="mt_10"></div>
                    <TextField
                      label="Cooking time (mins)"
                      placeholder="Cooking time (mins)"
                      name="cooking_time"
                      error={
                        errors?.cooking_time?.type === 'required'
                          ? 'Cooking time (mins) is required'
                          : ''
                      }
                      register={register}
                      validation={{
                        required: true
                      }}
                    />
                    <div className="mt_10"></div>
                    <CustomSelectDropdown
                      label="Category"
                      {...register('category', {
                        required: true
                      })}
                      options={[{ value: 1, label: 'option 1' }]}
                      error={
                        errors?.category?.type === 'required'
                          ? 'Category is required'
                          : ''
                      }
                      placeholder="Choose category"
                    />
                    <div className="mt_10"></div>
                    <div>
                      <label className="label">Allergen</label>
                    </div>
                    <div>
                      {fields?.map((field, index) => {
                        return (
                          <div
                            className="relative add_remove_btns"
                            key={field.id}
                          >
                            <TextField
                              name={`allergens${index}`}
                              placeholder="Allergen"
                              register={register}
                              error={
                                errors?.allergens?.[index]?.allergen?.type ===
                                'required'
                                  ? 'Allergen is required'
                                  : ''
                              }
                              // {...register(`allergens.${index}.allergen`, {
                              //   required: true
                              // })}
                            />
                            {index === 0 ? (
                              <button
                                type="button"
                                className="add_field"
                                onClick={() => append({ allergen: '' })}
                              >
                                <Ikon plus />
                              </button>
                            ) : (
                              <>
                                <button
                                  type="button"
                                  className="add_field"
                                  onClick={() => append({ allergen: '' })}
                                >
                                  <Ikon plus />
                                </button>
                                <button
                                  type="button"
                                  className="remove_field"
                                  onClick={() => remove(index)}
                                >
                                  <Ikon minus />
                                </button>
                              </>
                            )}
                            {errors?.allergens?.[index]?.allergen?.type ===
                            'required' ? (
                              <div className="error"></div>
                            ) : (
                              ''
                            )}
                          </div>
                        );
                      })}
                    </div>
                    <div className="mt_10"></div>
                    <TextField
                      label="Unit"
                      placeholder="Unit"
                      name="unit"
                      error={
                        errors?.unit?.type === 'required'
                          ? 'Unit is required'
                          : ''
                      }
                      register={register}
                      validation={{
                        required: true
                      }}
                    />
                    <div className="mt_10"></div>
                    <TextField
                      label="Quantity"
                      placeholder="Quantity"
                      name="quantity"
                      error={
                        errors?.quantity?.type === 'required'
                          ? 'Quantity is required'
                          : ''
                      }
                      type="number"
                      register={register}
                      validation={{
                        required: true
                      }}
                    />
                    <div className="mt_10"></div>
                    <TextField
                      label="Expiry time"
                      placeholder="Expiry time"
                      name="expire_time"
                      error={
                        errors?.expire_time?.type === 'required'
                          ? 'Expiry time is required'
                          : ''
                      }
                      register={register}
                      validation={{
                        required: true
                      }}
                    />
                    <div className="mt_10"></div>
                    <TextField
                      label="Alert time"
                      placeholder="Alert time"
                      name="alert_time"
                      error={
                        errors?.alert_time?.type === 'required'
                          ? 'Alert time is required'
                          : ''
                      }
                      register={register}
                      validation={{
                        required: true
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="collg8">
                <div className="mt_20"></div>
                <div className="shadow_box1">
                  <div className="flex item_vcenter item_space_btn">
                    <h4>
                      <strong>Recipe steps</strong>
                    </h4>
                    <div>
                      <button
                        type="button"
                        className="button button_radius ikonlink"
                        color="outline-primary"
                        onClick={() => {
                          stepsAppend({ step: '' });
                          setAddStep(true);
                        }}
                      >
                        <span>
                          <Ikon plus />
                        </span>
                        <span>Add step</span>
                      </button>
                    </div>
                  </div>
                  <div className="mt_15"></div>
                  <div>
                    <RenderIf isTrue={!addStep}>
                      {stepData &&
                        stepData?.length &&
                        stepData?.map((stp, index) => (
                          <div key={index}>
                            <TempAccordion title={`Step ${index + 1}`}>
                              {stp}
                            </TempAccordion>
                          </div>
                        ))}
                    </RenderIf>
                    <RenderIf isTrue={addStep}>
                      {stepsFields?.map((field, index) => {
                        return (
                          <div
                            className="relative add_remove_btns"
                            key={field.id}
                          >
                            <TextArea
                              name={`steps${index}`}
                              placeholder="Step description"
                              rows={10}
                              register={register}
                              error={
                                errors?.steps?.[index]?.step?.type === 'required'
                                  ? 'Step description is required'
                                  : ''
                              }
                            />
                            {index === 0 ? null : (
                              <>
                                <button
                                  type="button"
                                  className="del_field"
                                  onClick={() => stepsRemove(index)}
                                >
                                  <Ikon del />
                                </button>
                              </>
                            )}
                          </div>
                        );
                      })}
                    </RenderIf>
                  </div>
                </div>
              </div>
            </div>
            <div className="pt_10"></div>
            <div className="grid grid_gap30">
              <div className="collg4">
                <div className="mt_20"></div>
                <div className="shadow_box1">
                  <h4>
                    <strong>Pricing</strong>
                  </h4>
                  <div className="mt_15"></div>
                  <div>
                    <div className="fixfield_right">
                      <p className="label_left">
                        <label>Waste (%)</label>
                      </p>
                      <TextField
                        placeholder="10%"
                        name="waste"
                        error={
                          errors?.waste?.type === 'required'
                            ? 'Waste is required'
                            : ''
                        }
                        register={register}
                        validation={{
                          required: true
                        }}
                      />
                    </div>
                    <div className="mt_10"></div>
                    <div className="fixfield_right">
                      <p className="label_left">
                        <label>Total cost: ${0}</label>
                      </p>
                      <TextField
                        placeholder="7.51$"
                        name="food_cost"
                        error={
                          errors?.food_cost?.type === 'required'
                            ? 'Target food cost is required'
                            : ''
                        }
                        type="number"
                        register={register}
                        validation={{
                          required: true
                        }}
                      />
                    </div>
                    <div className="mt_10"></div>
                    <div className="fixfield_right">
                      <p className="label_left">
                        <label>Target food cost (%)</label>
                      </p>
                      <TextField
                        placeholder="15%"
                        name="target_food_cost"
                        error={
                          errors?.target_food_cost?.type === 'required'
                            ? 'Target food cost is required'
                            : ''
                        }
                        type="number"
                        register={register}
                        validation={{
                          required: true
                        }}
                      />
                    </div>
                    <div className="mt_10"></div>
                    <div className="fixfield_right">
                      <p className="label_left">
                        <label>Suggested pricing</label>
                      </p>
                      <TextField
                        placeholder="50.06$"
                        name="suggested_pricing"
                        error={
                          errors?.suggested_pricing?.type === 'required'
                            ? 'Suggested pricing is required'
                            : ''
                        }
                        register={register}
                        validation={{
                          required: true
                        }}
                      />
                    </div>
                    <div className="mt_10"></div>
                    <div className="fixfield_right">
                      <p className="label_left">
                        <label>Sales price tax inc</label>
                      </p>
                      <TextField
                        placeholder="29.09$"
                        name="sales_price_tax_inc"
                        error={
                          errors?.sales_price_tax_inc?.type === 'required'
                            ? 'Sales price tax inc is required'
                            : ''
                        }
                        register={register}
                        validation={{
                          required: true
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="collg8">
                <div className="mt_20"></div>
                <div className="shadow_box1">
                  <h4>
                    <strong>Recipe variations</strong>
                  </h4>
                  <div className="mt_25"></div>
                  <ModifiersList
                    modifier={{ name: 'Small' }}
                    handleModifier={() => setOption('Ingredients')}
                  />
                </div>
              </div>
            </div>
            <div className="mt_20"></div>
            <Button label="Save" color="bg-primary" type="submit" />
          </form>
        </RenderIf>
        <RenderIf isTrue={option === 'Ingredients'}>
          <div className="grid grid_gap30">
            <div className="collg4">
              <div className="mt_20"></div>
              <div className="shadow_box1">
                <h4>
                  <strong>Groups</strong>
                </h4>
                <div className="mt_25"></div>
                <TextField
                  placeholder="Search"
                  name="search"
                  onChange={(e) => console.log(e.target.value)}
                />
                <div className="mt_15"></div>
                {temporaryProducts?.map((product) => {
                  return (
                    <CustomAccordion
                      group={product.groupName}
                      products={product?.products}
                      handleClick={handleProductsSelected}
                    />
                  );
                })}
              </div>
            </div>
            <div className="collg8">
              <div className="mt_20"></div>
              <div className="shadow_box1">
                <h4>
                  <strong>Ingredients</strong>
                </h4>
                <div className="mt_25"></div>
                <div className="flex item_vcenter">
                  <BadgeCheckbox
                    labels={[
                      { name: 'small', label: 'Small', id: 'small' },
                      { name: 'medium', label: 'Medium', id: 'medium' },
                      { name: 'large', label: 'Large', id: 'large' }
                    ]}
                  />
                </div>
                <div className="mt_15"></div>
                <div>
                  {selectedProducts?.map((product, index) => {
                    return (
                      <div className="shadow_box ingred_item" key={index}>
                        <h5>
                          <b>
                            {product?.group} ({product?.product?.name})
                          </b>
                        </h5>
                        <div className="flex item_vcenter">
                          <div className="ingred_field">
                            <TextField
                              label="Quantity"
                              placeholder="Quantity"
                              type="number"
                              onChange={(e) => {
                                console.log(e.target.value);
                                const newSelectedProducts = selectedProducts.map(
                                  (selectedProduct) => {
                                    if (
                                      selectedProduct?.product?.id ===
                                      product?.product?.id
                                    ) {
                                      return {
                                        ...selectedProduct,
                                        quantity: e.target.value
                                      };
                                    }
                                    return selectedProduct;
                                  }
                                );
                                setSelectedProducts(newSelectedProducts);
                              }}
                            />
                          </div>
                          <div className="ingred_field">
                            <TextField
                              label="Unit"
                              placeholder="Unit"
                              onChange={(e) => {
                                console.log(e.target.value);
                                const newSelectedProducts = selectedProducts.map(
                                  (selectedProduct) => {
                                    if (
                                      selectedProduct?.product?.id ===
                                      product?.product?.id
                                    ) {
                                      return {
                                        ...selectedProduct,
                                        unit: e.target.value
                                      };
                                    }
                                    return selectedProduct;
                                  }
                                );
                                setSelectedProducts(newSelectedProducts);
                              }}
                            />
                          </div>
                          <button
                            type="button"
                            className="alink"
                            onClick={() => {
                              const filtered = selectedProducts?.filter(
                                (item) =>
                                  item?.product?.id !== product?.product?.id
                              );
                              setSelectedProducts(filtered);
                            }}
                          >
                            <Ikon closex />
                          </button>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
          <div className="mt_30"></div>
          <Button
            type="button"
            label="Save"
            color="bg-primary"
            onClick={handleSaveIngredients}
          />
        </RenderIf>
      </article>
    </>
  );
};

const ModifiersList = ({ modifier, handleModifier }) => {
  return (
    <div className="modifi_item">
      <div>
        <h5>
          <b>{modifier.name}</b>
        </h5>
      </div>
      <div>
        <button className="alink" onClick={() => handleModifier(modifier)}>
          <Ikon name="edit" />
        </button>
      </div>
    </div>
  );
};

export const CustomAccordion = ({
  group,
  products,
  handleClick,
  onClickHeading,
  isMixture,
  productGroup
}) => {
  const [open, setOpen] = useState(false);
  return (
    <div className={styles.accItem}>
      <button
        type="button"
        className={styles.accordion}
        onClick={isMixture ? () => onClickHeading() : () => setOpen(!open)}
      >
        <div>
          <h6>{group}</h6>
          <p>({products?.length})</p>
        </div>
        <div>
          <RenderIf isTrue={!open}>
            <Ikon downArrow />
          </RenderIf>
          <RenderIf isTrue={open}>
            <Ikon upArrow />
          </RenderIf>
        </div>
      </button>
      <RenderIf isTrue={open}>
        <div className={styles.accContent}>
          <RenderIf isTrue={productGroup?.id}>
            <div className={styles.accordContentItem}>
              <Button
                className="alink full"
                color="primary"
                label="Add"
                onClick={() =>
                  handleClick(
                    { ...productGroup, quantity: '', type: 'PRODUCT_GROUP' },
                    true
                  )
                }
              />
            </div>
          </RenderIf>
          {products?.map((product, index) => {
            return (
              <div key={index}>
                <button
                  type="button"
                  className={styles.accordContentItem}
                  onClick={() => handleClick(product)}
                >
                  <h6>{product.name}</h6>
                  <div>
                    <Ikon name="linkArrow1" />
                  </div>
                </button>
              </div>
            );
          })}
        </div>
      </RenderIf>
    </div>
  );
};

export const TempAccordion = ({ title, children }) => {
  const [open, setOpen] = useState(title === 'Step 1' ? true : false);

  return (
    <>
      <button
        type="button"
        className="accordion"
        onClick={() => setOpen(!open)}
      >
        <h5>
          <b>{title}</b>
        </h5>
        <div>
          <RenderIf isTrue={!open}>
            <Ikon downArrow />
          </RenderIf>
          <RenderIf isTrue={open}>
            <Ikon upArrow />
          </RenderIf>
        </div>
      </button>
      <RenderIf isTrue={open}>
        <div className="accordion_content">{children}</div>
      </RenderIf>
    </>
  );
};
