import React, { useState } from 'react';
import { PieChart, Pie, Cell } from 'recharts';
import { RenderIf } from 'utilities/helpers';

const RADIAN = Math.PI / 180;
const data = [
  { name: 'A', value: 80, color: '#954CCB' },
  { name: 'B', value: 45, color: '#4AAE8C' },
  { name: 'C', value: 25, color: '#E3606E' }
];
const cx = 100;
const cy = 120;
const iR = 60;
const oR = 85;
const value = 50;

const needle = (value, data, cx, cy, iR, oR, color) => {
  let total = 0;
  data.forEach((v) => {
    total += v.value;
  });
  const ang = 180.0 * (1 - value / total);
  const length = (iR + 2 * oR) / 4;
  const sin = Math.sin(-RADIAN * ang);
  const cos = Math.cos(-RADIAN * ang);
  const r = 5;
  const x0 = cx + 5;
  const y0 = cy + 5;
  const xba = x0 + r * sin;
  const yba = y0 - r * cos;
  const xbb = x0 - r * sin;
  const ybb = y0 + r * cos;
  const xp = x0 + length * cos;
  const yp = y0 + length * sin;

  return [
    <circle cx={x0} cy={y0} r={r} fill={color} stroke="none" />,
    <path
      d={`M${xba},${yba}L${xbb},${ybb},L${xp},${yp},L${xba},${yba}`}
      stroke="#none"
      fill={color}
    />
  ];
};

export default () => {
  const [noData, setNoData] = useState(true);

  return (
    <div className="chart_box">
      <div className="ov_top">
        <div className="txt_div">
          <p>Order Volume</p>
          <RenderIf isTrue={!noData}>
            <p className="num_per">80%</p>
          </RenderIf>
        </div>
        <RenderIf isTrue={!noData}>
          <p>
            <strong className="chart_title">786</strong>
          </p>
        </RenderIf>
      </div>
      <RenderIf isTrue={!noData}>
        <PieChart
          margin={{
            left: 30,
            top: 10
          }}
          width={250}
          height={130}
        >
          <Pie
            dataKey="value"
            startAngle={180}
            endAngle={0}
            data={data}
            cx={cx}
            cy={cy}
            innerRadius={iR}
            outerRadius={oR}
            fill="#8884d8"
            stroke="none"
          >
            {data.map((entry, index) => (
              <Cell key={index} fill={entry.color} />
            ))}
          </Pie>
          {needle(value, data, cx, cy, iR, oR, '#1D1921')}
        </PieChart>
      </RenderIf>
      <RenderIf isTrue={noData}>
        <div
          style={{
            width: `100%`,
            height: 98,
            display: `flex`,
            alignItems: `center`,
            justifyContent: `center`,
            backgroundColor: `#D9D9D9`,
            color: `#707070`,
            fontSize: `14px`,
            fontWeight: `600`,
            marginTop: 30,
            borderRadius: `0 0 8px 8px`
          }}
        >
          No data
        </div>
      </RenderIf>
    </div>
  );
};
