import React from 'react';
import { BarChart, Bar, XAxis, Tooltip, ResponsiveContainer } from 'recharts';
import styles from './index.module.css';

let data = [
  {
    name: 'Page A',
    p1: 4000,
    p2: 2400,
    p3: 2400,
    p4: 2600,
    p5: 2700,
    pz: 200,
    date: 'Sep 1, 2023'
  },
  {
    name: 'Page B',
    p1: 3000,
    p2: 1398,
    p3: 2210,
    p4: 2800,
    p5: 2900,
    pz: 200,
    date: 'Sep 2, 2023'
  },
  {
    name: 'Page C',
    p1: 2000,
    p2: 9800,
    p3: 2290,
    p4: 2800,
    p5: 2900,
    pz: 200,
    date: 'Sep 3, 2023'
  },
  {
    name: 'Page D',
    p1: 2780,
    p2: 3908,
    p3: 2000,
    p4: 2800,
    p5: 2900,
    p0: 5,
    date: 'Sep 4, 2023'
  },
  {
    name: 'Page E',
    p1: 1890,
    p2: 4800,
    p3: 2181,
    p4: 2800,
    p5: 2900,
    pz: 200,
    date: 'Sep 5, 2023'
  },
  {
    name: 'Page F',
    p1: 2390,
    p2: 3800,
    p3: 2500,
    p4: 2800,
    p5: 2900,
    pz: 200,
    date: 'Sep 6, 2023'
  },
  {
    name: 'Page G',
    p1: 3490,
    p2: 4300,
    p3: 2100,
    p4: 2800,
    p5: 2900,
    pz: 200,
    date: 'Sep 7, 2023'
  }
];

const CustomizedAxisTick = (props) => {
  const { x, y, payload } = props;

  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={15}
        y={10}
        dy={15}
        textAnchor="end"
        fill="#999999"
        fontSize={10}
        fontWeight={`600`}
      >
        {payload.value?.split(',')[0]}
      </text>
    </g>
  );
};

export const BarchartComponent = ({ chartData, paddHeightInPercent }) => {
  return (
    <div className={styles.resBx} style={{ paddingTop: paddHeightInPercent }}>
      <ResponsiveContainer>
        <BarChart
          width={500}
          height={300}
          data={chartData || data}
          margin={{
            top: 20,
            right: 20,
            left: 20,
            bottom: 20
          }}
          //barCategoryGap={'40%'}
          barSize={13}
          background={{ fill: `#eee` }}
        >
          <XAxis
            dataKey="date"
            domain={['auto', 'auto']}
            axisLine={false}
            tickLine={false}
            tick={<CustomizedAxisTick />}
            interval={0}
          />
          <Tooltip cursor={{ fill: '#F6F4FE' }} />
          <Bar
            dataKey="p1"
            stackId="a"
            fill="#E4D7F3"
            radius={[50, 50, 50, 50]}
          />

          <Bar
            dataKey="p2"
            stackId="a"
            fill="#D4BAEC"
            radius={[50, 50, 50, 50]}
          />

          <Bar
            dataKey="p3"
            stackId="a"
            fill="#C59CE6"
            radius={[50, 50, 50, 50]}
          />

          <Bar
            dataKey="p4"
            stackId="a"
            fill="#B57FDF"
            radius={[50, 50, 50, 50]}
          />

          <Bar
            dataKey="p5"
            stackId="a"
            fill="#A561D8"
            radius={[50, 50, 50, 50]}
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};
