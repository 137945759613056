import { Button } from 'components/button';
import { TextField } from 'components/input';
import ProgressSteps from 'components/steps';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { client } from 'utilities/db';
import { urlRegex } from 'utilities/regex';

export const LinkExternalAccount = ({ onChangeStep, onGoBack, locationId }) => {
  const navigate = useNavigate();
  const {
    handleSubmit,
    register,
    formState: { errors }
  } = useForm();

  const { mutate, isLoading, isSuccess, isError, error, data } = useMutation(
    (userData) => {
      return client.post(`/restaurant/addSecret/${locationId}`, userData);
    }
  );

  const onSubmitLogin = (val) => {
    mutate({
      secret_keys: {
        external: val?.link
      }
    });
  };

  useEffect(() => {
    if (!isError) return;
    // alert(error);
  }, [isError]);

  useEffect(() => {
    if (!isSuccess) return;
    // alert(data?.data?.message);
  }, [isSuccess]);

  return (
    <div style={{ minHeight: window.innerHeight }} className={`onflexlay`}>
      <div className="onbox">
        <h2>
          <strong>Add Menu</strong>
        </h2>
        <h5>Connect external to download</h5>
        <ProgressSteps active={3} />
        <div className="mt_15"></div>
        <form onSubmit={handleSubmit(onSubmitLogin)}>
          <div>
            <TextField
              label={'Enter URL to connect'}
              name={'url'}
              type="text"
              error={errors?.url?.type === 'required' ? 'URL is required' : ''}
              validation={{
                required: true,
                pattern: urlRegex
              }}
              placeholder={'3456789876543'}
              register={register}
              required
            />
          </div>
          <div className="mt_40"></div>
          <div className="pt_40"></div>
          <div className="mt_40"></div>
          <div className="onfooter">
            <Button
              type="button"
              label={`I'll do this later`}
              onClick={() => navigate('/dashboard')}
              className="alink"
              color="dark"
            />
            <div className="flex item_vcenter">
              <Button
                onClick={() => onChangeStep(3)}
                type="button"
                label={'Back'}
                color="outline-lite"
              />
              <Button
                isLoading={isLoading}
                type="submit"
                label={'Save'}
                color="bg-button"
              />
            </div>
          </div>
        </form>
      </div>
      {(error) && (
        <div>
          <p style={{ color: 'red', fontWeight: '800' }}>
            {error?.response?.data?.message}
          </p>
        </div>
      )}
    </div>
  );
};
