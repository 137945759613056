import { DeploymentClient } from 'utilities/db';
import { useQuery } from 'react-query';

export const useFetchDeploymentStatusHook = (
  keyName,
  brandId,
  brandName,
  enviorment
) => {
  const { status, data, error, isFetching, isLoading, refetch } = useQuery(
    [keyName, brandId, brandName, enviorment],
    () => getData(brandId, brandName, enviorment),
    {
      enabled: !!brandId && !!brandName && !!enviorment,
      refetchInterval: 30000
    }
  );

  return {
    deploymentStatus: status,
    deploymentData: data,
    deploymentError: error,
    deploymentFetching: isFetching,
    deploymentLoading: isLoading,
    deploymentRefetch: refetch
  };
};

const getData = async (brandId, brandName, enviorment) => {
  let result = await DeploymentClient.post(`/deployment/checkStatus`, {
    brandId: `${brandId}`,
    brandName,
    enviorment
  });
  result = result.data;
  return result;
};
