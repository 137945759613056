import { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useFetchOneBranchHook } from 'hooks/branch/userFetchOneBranchHook';
import { RenderIf } from 'utilities/helpers';
import { ThemeTabs } from 'components/tabs';
import { ManagerListingPage } from './managerlisting';
import { Button } from 'components/button';
import { Ikon } from 'assets/icons';
import styles from './index.module.css';
import { useFetchSingleBrand } from 'hooks/brands/useBrandHook';
import { ModalComponent } from 'components/modal';
// import { RequestConnections } from './requestconnection';
import { ViewConnections } from './viewconnection';
import { Loading } from 'components/loading';

export const PortfolioBranchDetailsPage = () => {
  const { brandId, branchId } = useParams();
  const navigate = useNavigate();

  const { data: brandData } = useFetchSingleBrand(brandId);

  const { oneBranchData, oneBranchLoading } = useFetchOneBranchHook(
    `branch/${branchId}`,
    branchId,
    brandData?.brand_type
  );

  //const [makeRequestModal, setMakeRequestModal] = useState(false);
  const [viewRequestModal, setViewRequestModal] = useState(false);

  return (
    <>
      <div className="mt_40"></div>
      <h2>
        <span className="ff_semi">
          {brandData?.brand_type === 'CLOUD'
            ? 'Cloud Kitchen Location Details Page'
            : 'Branch Details Page'}
        </span>
      </h2>
      <div className="mt_20"></div>
      <RenderIf isTrue={oneBranchLoading}>
        <div className={`loading_full`}>
          <Loading />
        </div>
      </RenderIf>
      <RenderIf isTrue={!oneBranchLoading}>
        <div className="shadow_box1">
          <div className={styles.header_bd1}>
            <div className={styles.header_bd_left}>
              <picture className={styles.picture1}>
                <RenderIf isTrue={!oneBranchData?.image_url}>
                  <div className="placeholder">
                    <Ikon name="imgplaceholder" />
                  </div>
                </RenderIf>
                <RenderIf isTrue={oneBranchData?.image_url}>
                  <source srcSet={oneBranchData?.image_url} />
                  <img src={oneBranchData?.image_url} alt="brand logo" />
                </RenderIf>
              </picture>
              <div>
                <h1>
                  <b>
                    {oneBranchData?.branch_name || oneBranchData?.kitchen_name}
                  </b>
                </h1>
                <div className={styles.flex_row1}>
                  <div className="spans">
                    <span>
                      <Ikon name="email" />
                    </span>
                    <span>{oneBranchData?.city}</span>
                  </div>
                  <div className="spans">
                    <span>
                      <Ikon name="email" />
                    </span>
                    <span>{oneBranchData?.postal_code}</span>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="flex item_hend">
                <Button
                  label="Update Branch"
                  color="bg-titary"
                  onClick={() =>
                    navigate(`/portfolio/branch/update/${brandId}`, {
                      state: { branchToUpdate: oneBranchData }
                    })
                  }
                />
                <Button
                  label="Add a Menu"
                  color="bg-titary"
                  onClick={() => navigate(`/menu-engineering/categories`)}
                />
              </div>
              <RenderIf isTrue={brandData?.brand_type === 'CLOUD'}>
                <div className="flex mt_16">
                  <Button
                    label="View Requests"
                    color="outline-grey"
                    className="button button_radius button_sm"
                    onClick={() => setViewRequestModal(true)}
                  />
                  <Button
                    label="Request Connections"
                    color="outline-titary"
                    className="button button_radius button_sm"
                    onClick={() => {
                      //setMakeRequestModal(true)
                      navigate(`/portfolio/cloudbranch/${branchId}`);
                    }}
                  />
                </div>
              </RenderIf>
            </div>
          </div>
        </div>
        <div className="mt_40"></div>
        <ThemeTabs
          tabs={[{ label: 'Payments' }, { label: 'Managers' }]}
          panels={[
            { content: <BranchPaymentPage /> },
            {
              content: (
                <ManagerListingPage brandId={brandId} branchId={branchId} />
              )
            }
          ]}
          isSearch={<div></div>}
        />
      </RenderIf>

      {/* <ModalComponent
        modal={makeRequestModal}
        handleModal={() => setMakeRequestModal(!makeRequestModal)}
        modalMiddle={false}
      >
        <RequestConnections
          branchId={branchId}
          setMakeRequestModal={setMakeRequestModal}
        />
      </ModalComponent> */}

      <ModalComponent
        modal={viewRequestModal}
        handleModal={() => setViewRequestModal(!viewRequestModal)}
        modalMiddle={false}
      >
        <ViewConnections
          branchId={branchId}
          setViewRequestModal={setViewRequestModal}
        />
      </ModalComponent>
    </>
  );
};

export const BranchPaymentPage = () => {
  return (
    <>
      <div className={`shadow_box1`}>
        <h2 className={`ff_semi`}>Payments</h2>
      </div>
    </>
  );
};
