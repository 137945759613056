import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { RenderIf } from 'utilities/helpers';
import { Button } from '../../../components/button';
import { TextField } from '../../../components/input';
import { useMutation } from 'react-query';
import { client } from 'utilities/db';
import { Toasts } from 'components/toasts';

export const OtpPage = ({ email, setStep }) => {
  const navigate = useNavigate();

  const [counter, setCounter] = useState(60);

  const { mutate, isSuccess, isError, error } = useMutation((userData) => {
    return client.post(`/user/verifyAccount`, userData);
  });
  const {
    handleSubmit,
    register,
    formState: { errors },
    watch
  } = useForm();

  const onSubmitOTP = (val) => {
    mutate({
      email,
      otp_code: val?.otp
    });
  };

  useEffect(() => {
    if (!isError) return;
  }, [isError]);

  useEffect(() => {
    if (!isSuccess) return;
    navigate('/login');
  }, [isSuccess, navigate]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (counter === 0) {
        //setCounter(60);
        return;
      }
      if (counter > 0) {
        setCounter((prevState) => prevState - 1);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [counter]);

  const resendOtp = async () => {
    try {
      await client.post(`user/resendOTP`, { email });
      Toasts.success('OTP sent successfully');
    } catch (error) {
      console.log(error);
      Toasts.error('Unable to resend OTP');
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmitOTP)}>
        {/* <div className="otp_emoji pt_30">
          <picture>
            <source srcSet={TEMPImgs?.ImgOtpEmoji} />
            <img src={TEMPImgs?.ImgOtpEmoji} alt="OTP Emoji" width={36} />
          </picture>
        </div> */}
        <h2 className="text_center">
          <span className="ff_semi">Enter OTP</span>
        </h2>
        <h6 className="text_center mt_12">
          One time password is sent to your email.
        </h6>
        <div className="mt_46"></div>
        <div className="auth_form">
          <TextField
            name="otp"
            label={'Enter 6-digit Code'}
            error={
              errors?.otp?.type === 'required'
                ? 'OTP is required'
                : errors?.otp?.type === 'validate'
                ? 'OTP is invalid'
                : ''
            }
            type="number"
            register={register}
            validation={{
              required: true,
              validate: (value) => value === watch('otp')
            }}
            required
          />
          <div className="mt_30"></div>
          <div className={`field`}>
            <Button type="submit" label={'Verify'} color="bg-primary" full />
          </div>
          <div className="mt_20"></div>
          <div className={`flex item_hcenter`}>
            <label className="label">
              Didn't receive the code?{' '}
              <span
                style={{
                  color: counter === 0 ? '#a561d8' : '#a5a5a5',
                  fontWeight: '800',
                  cursor: 'pointer'
                }}
                onClick={() => {
                  if (counter === 0) {
                    setCounter(60);
                    resendOtp();
                  }
                }}
              >
                Resend OTP
              </span>{' '}
              <RenderIf isTrue={counter !== 0}>in {counter}sec.</RenderIf>
            </label>
          </div>
        </div>
        <RenderIf isTrue={isError}>
          <div className="mt_10">
            <div className="error">
              {error?.response?.data?.message?.[1]?.msg ||
                error?.response?.data?.message}
            </div>
          </div>
        </RenderIf>
      </form>
    </>
  );
};
