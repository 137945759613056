import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { useMutation } from 'react-query';
import { RenderIf } from 'utilities/helpers';
import { getUser } from 'utilities/helpers/auth';
import { client } from 'utilities/db';
import { TextField } from 'components/input';
import { Button } from 'components/button';
import ImageDropzone from 'components/imageDropzone/imageDropzone';
import { Toasts } from 'components/toasts';
import { useFetchBrands } from 'hooks/resourceHooks';
import { useFetchBankDetailsHook } from 'hooks/brands/useFetchBankDetailsHook';
import { useFetchManagerHook } from 'hooks/managers/useFetchManagerHooks';
import { CustomSelectDropdown } from 'components/select/multi';
import { emailRegex, phoneRegex } from 'utilities/regex';
import { ImageCropUploader } from 'components/imagecropuploader';

export const AddBrandForm = ({ onChangeStep, onboarding = false }) => {
  const navigate = useNavigate();

  const user = getUser();

  const { mutate, isLoading, isSuccess, isError, error } = useMutation(
    (userData) => {
      return client.post(`/brand/add`, userData);
    }
  );

  const {
    data: brandData,
    isLoading: brandLoading,
    refetch: brandRefecth
  } = useFetchBrands();

  const { bankData, bankLoading } = useFetchBankDetailsHook(
    `bankDetail/${brandData?.[0]?.id}`,
    brandData?.[0]?.id
  );

  const { managerData, managerLoading } = useFetchManagerHook(
    `manager/${brandData?.[0]?.id}`,
    brandData?.[0]?.id
  );

  const {
    handleSubmit,
    register,
    formState: { errors },
    control,
    watch
  } = useForm();
  const [resturantImage, setResturantImage] = useState('');
  const [imageLoading, setImageLoading] = useState(false);

  console.log('watch', watch('brand_type')?.value);

  const onSubmit = (val, event) => {
    event.preventDefault();
    mutate({
      brand_name: val?.brandName,
      owner_id: user?.id,
      phone_number: val?.phone,
      brand_email: val?.email,
      ein: val?.ein,
      image_url: resturantImage,
      brand_type: val?.brand_type?.value
    });
  };

  useEffect(() => {
    if (!isError) return;
    Toasts.error(error?.response?.data?.message);
  }, [isError]);

  useEffect(() => {
    if (!isSuccess) return;
    brandRefecth();
  }, [isSuccess]);

  useEffect(() => {
    if (brandLoading || bankLoading) return;

    if (brandData?.length > 0 && onboarding) {
      window.localStorage.setItem('onboardLocationId', brandData?.[0]?.id);
      window.localStorage.setItem(
        'onboardLocationData',
        JSON.stringify(brandData?.[0])
      );

      onChangeStep(2);
      if (bankData?.id) {
        console.log(
          '🚀 ~ file: index.jsx ~ line 87 ~ useEffect ~ bankData',
          bankData
        );
        onChangeStep(3);
      }
      // if (managerData) {
      //   navigate('/add-branch');
      // }
    }
  }, [brandData, brandLoading, bankLoading, bankData, managerData]);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div>
          <TextField
            placeholder={'KFC'}
            name="brandName"
            label={'Brand Name'}
            type="text"
            error={
              errors?.brandName?.type === 'required'
                ? 'Brand name is required'
                : ''
            }
            validation={{
              required: true
            }}
            register={register}
            required
          />
          <Controller
            name="brand_type"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <CustomSelectDropdown
                hookForm={true}
                isMulti={false}
                field={field}
                label="Brand Type"
                placeholder="Choose Brand Type"
                options={[
                  {
                    label: 'Brand',
                    value: 'STANDARD'
                  },
                  {
                    label: 'Cloud Kitchen',
                    value: 'CLOUD'
                  }
                ]}
                error={
                  errors?.brand_type?.type === 'required'
                    ? 'Brand type is required'
                    : ''
                }
              />
            )}
          />
          <TextField
            placeholder={'13334445555'}
            name="phone"
            label={'Phone Number'}
            type="number"
            error={
              errors?.phone?.type === 'required'
                ? 'Phone is required'
                : errors?.phone?.type === 'pattern'
                ? 'Phone is invalid'
                : ''
            }
            validation={{
              required: true,
              pattern: phoneRegex
            }}
            register={register}
            required
          />
          <TextField
            placeholder={'kfc@kfc.com'}
            name="email"
            label={'Email address'}
            type="email"
            error={
              errors?.email?.type === 'required'
                ? 'Email is required'
                : errors?.email?.type === 'pattern'
                ? 'Email is invalid'
                : ''
            }
            validation={{
              required: true,
              pattern: emailRegex
            }}
            register={register}
            required
          />
          <TextField
            placeholder={'456787654'}
            name="ein"
            label={'EIN'}
            type="number"
            error={
              errors?.ein?.type === 'required' ? 'Tax number is required' : ''
            }
            register={register}
            validation={{
              required: true
            }}
            required
          />
          <div className="mt_20"></div>
          <div>
            <label htmlFor="exampleFormControlInput1" className="label">
              Upload Restaurant image/ Logo
            </label>

            <ImageCropUploader
              imageSrc={resturantImage}
              handleImage={setResturantImage}
            />

            {/* <ImageDropzone
              setIsLoading={setImageLoading}
              image={resturantImage}
              setImage={setResturantImage}
            /> */}
          </div>
          <div className="mt_26"></div>
          <div className="onfooter">
            <div></div>
            <div className="flex item_vcenter">
              <Button
                type="submit"
                label={'Next'}
                color="bg-button"
                disabled={imageLoading}
                isLoading={isLoading}
              />
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export const AddBrandInformation = ({ onChangeStep }) => {
  return (
    <>
      <div className="box1">
        <h2>
          <strong>Add Brand</strong>
        </h2>
        <h5>About Brand</h5>
        <div className="mt_15"></div>
        <AddBrandForm onChangeStep={onChangeStep} />
      </div>
    </>
  );
};
