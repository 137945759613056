import styles from "./index.module.css";

export const DomainsLayout = ({ children }) => {
    return (
        <>
            <div className={styles.layout}>
                <div>{children}</div>
                <footer className={styles.footer}>
                    <div>
                        <h2>What type of domain can I connect?</h2>
                        <h4>You can use any domain (e.g., mydomain.com) or subdomain (e.g., shop.mydomain.com) with any ending you want. If you buy a domain from us, you can use it for any site you own.</h4>
                    </div>
                    <div>
                        <h2>Can I connect a domain by pointing?</h2>
                        <h4>Yes. Enter the domain you want to connect at the top of the page and we'll guide you step-by-step. While we recommend connecting by nameservers, you can choose to see instructions for connecting using the pointing method instead.</h4>
                    </div>
                </footer>
            </div>
        </>
    );
}