import { Ikon } from 'assets/icons';
import styles from './index.module.css';

export const Loading = () => {
  return (
    <>
      <div className={styles.loading}>
        <Ikon name={`loadZicon`} />
        <div className={styles.spinning}></div>
      </div>
    </>
  );
};
