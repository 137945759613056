import ProgressSteps from 'components/steps';
import { Button } from 'components/button';
import { useNavigate } from 'react-router-dom';
import { useStore } from 'store';

export const AddMenu = ({ onChangeStep, onGoBack }) => {
  const navigate = useNavigate();
  const { handleBranchesSameMenu } = useStore((state) => state);
  return (
    <div style={{ minHeight: window.innerHeight }} className={`onflexlay`}>
      <div className="onbox">
        <h2>
          <strong>Add Menu</strong>
        </h2>
        <h5>About Menu</h5>
        <ProgressSteps active={3} />
        <h4 className="ff_semi mt_15">All branches have same menu?</h4>
        <div className="mt_5"></div>
        <h6>
          Lörem ipsum kontran telesofi juräv fan. Hivis trer ego lohet. Sadebelt
          öse, bena.
        </h6>
        <div className="pt_40"></div>
        <div className="onfooter">
          <Button
            type="button"
            label={`I'll do this later`}
            onClick={() => navigate('/dashboard')}
            className="alink"
            color="dark"
          />
          <div className="flex item_vcenter">
            <Button
              onClick={() => {
                onChangeStep(8);
                handleBranchesSameMenu();
              }}
              type="button"
              label={'No'}
              color="outline-lite"
            />
            <Button
              onClick={() => onChangeStep(2)}
              type="button"
              label={'Yes'}
              color="bg-button"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
