export const ONLINE_SITE_DATA = {
  ids: {
    user_id: 1,
    restaurant_id: 1
  },
  theme: {
    color: '',
    template: null
  },
  header: {
    image: ''
  },
  homeCarousel: [
    {
      image:
        'https://zewst-backend-media-bucket.s3.amazonaws.com/ovbiuyf6oucn3282jxh4ia1130235.jpg',
      textClass: '',
      title: 'happy times!',
      description:
        '1/2 off draft beers, bottled wines and mules!! Check with your local Bareburger for details. Chef-crafted, signature burgers. Prepared fresh with the best ingredients and made just for you!'
    }
  ],
  homeFeatured: {
    homeFeaturedItems: [
      {
        image:
          'https://bareburger.kinsta.cloud/wp-content/uploads/2021/10/Website_NoTags-04.png',
        title: 'The Beef',
        description:
          'Our beef is organic, grass-fed, antibiotic free, growth-hormone free and ranched raised. Made with our custom, proprietary full muscle blend of organic brisket, chuck amp; short rib, it’s no wonder it steals the show!',
        category: 'BURGERS'
      }
    ],
    homeFeaturedSection: {
      heading: 'ALL-NATURAL, SUSTAINABLE BURGERS, GREENS amp; MORE',
      title: 'feel good,eat better',
      description:
        'Bareburger is where you can have it all: the best ethically sourced ingredients, an allergy-friendly kitchen (yes, we have dedicated fryers), big flavor and big fun.'
    }
  },
  homeFooterSection: {
    image:
      'https://bareburger.kinsta.cloud/wp-content/uploads/2021/12/01_footer.jpg',
    textClass: '',
    title: 'stop scrolling, start eating'
  },
  footer: {
    socials: {
      facebook: 'https://www.facebook.com/bareburger',
      instagram: 'https://www.instagram.com/bareburger',
      twitter: 'https://www.twitter.com/bareburger',
      email: ''
    },
    copyright: 'Kulchay'
  },
  aboutCarousel: [
    {
      image:
        'https://bareburger.kinsta.cloud/wp-content/uploads/2021/11/Menu-Footer-optimized.jpg',
      title: 'About us',
      textClass: 'white',
      description: ''
    }
  ],
  aboutSections: [
    {
      image:
        'https://bareburger.kinsta.cloud/wp-content/uploads/2021/12/BW7A0983.jpg',
      title: '',
      description: ''
    },
    {
      image:
        'https://bareburger.kinsta.cloud/wp-content/uploads/2021/12/BW7A5642_02.jpg',
      title: '',
      description: ''
    },
    {
      image:
        'https://bareburger.kinsta.cloud/wp-content/uploads/2021/12/BW7A4204.jpg',
      title: '',
      description: ''
    }
  ],
  aboutFooterSection: {
    image: 'https://bareburger.kinsta.cloud/wp-content/uploads/2021/12/03.jpg',
    title: 'stop scrolling, start eating',
    textClass: 'white'
  },
  contactCarousel: [
    {
      image:
        'https://bareburger.kinsta.cloud/wp-content/uploads/2021/12/02.jpg',
      title: 'Contact Us',
      textClass: 'white',
      description: ''
    }
  ],
  contactFooterSection: {
    image: 'https://bareburger.kinsta.cloud/wp-content/uploads/2021/12/03.jpg',
    title: 'stop scrolling, start eating',
    textClass: 'white'
  }
};

export const US_STATES = [
  { label: 'Alaska', value: 'Alaska' },
  { label: 'Alabama', value: 'Alabama' },
  { label: 'Arkansas', value: 'Arkansas' },
  { label: 'Arizona', value: 'Arizona' },
  { label: 'California', value: 'California' },
  { label: 'Colorado', value: 'Colorado' },
  { label: 'Connecticut', value: 'Connecticut' },
  { label: 'District of Columbia', value: 'District of Columbia' },
  { label: 'Delaware', value: 'Delaware' },
  { label: 'Florida', value: 'Florida' },
  { label: 'Georgia', value: 'Georgia' },
  { label: 'Hawaii', value: 'Hawaii' },
  { label: 'Iowa', value: 'Iowa' },
  { label: 'Idaho', value: 'Idaho' },
  { label: 'IL', value: 'Illinois' },
  { label: 'Illinois', value: 'Indiana' },
  { label: 'Kansas', value: 'Kansas' },
  { label: 'Kentucky', value: 'Kentucky' },
  { label: 'Louisiana', value: 'Louisiana' },
  { label: 'Massachusetts', value: 'Massachusetts' },
  { label: 'Maryland', value: 'Maryland' },
  { label: 'Maine', value: 'Maine' },
  { label: 'Michigan', value: 'Michigan' },
  { label: 'Minnesota', value: 'Minnesota' },
  { label: 'Missouri', value: 'Missouri' },
  { label: 'Mississippi', value: 'Mississippi' },
  { label: 'Montana', value: 'Montana' },
  { label: 'North Carolina', value: 'North Carolina' },
  { label: 'North Dakota', value: 'North Dakota' },
  { label: 'Nebraska', value: 'Nebraska' },
  { label: 'New Hampshire', value: 'New Hampshire' },
  { label: 'New Jersey', value: 'New Jersey' },
  { label: 'New Mexico', value: 'New Mexico' },
  { label: 'Nevada', value: 'Nevada' },
  { label: 'New York', value: 'NewYork' },
  { label: 'Ohio', value: 'Ohio' },
  { label: 'Oklahoma', value: 'Oklahoma' },
  { label: 'Oregon', value: 'Oregon' },
  { label: 'Pennsylvania', value: 'Pennsylvania' },
  { label: 'Rhode Island', value: 'Rhode Island' },
  { label: 'South Carolina', value: 'South Carolina' },
  { label: 'South Dakota', value: 'South Dakota' },
  { label: 'Tennessee', value: 'Tennessee' },
  { label: 'Texas', value: 'Texas' },
  { label: 'Utah', value: 'Utah' },
  { label: 'Virginia', value: 'Virginia' },
  { label: 'Vermont', value: 'Vermont' },
  { label: 'Washington', value: 'Washington' },
  { label: 'Wisconsin', value: 'Wisconsin' },
  { label: 'West Virginia', value: 'West Virginia' },
  { label: 'Wyoming', value: 'Wyoming' }
];

export const BASIC_MODE_ONLINE_SITE_DATA = {
  projectName: 'Zewst Online Store',
  pageName: 'Landing Page',
  global: {
    content: {
      title: 'Ranchers - Store',
      favicon: '',
      logo: 'https://zewst-backend-media-bucket-1.s3.amazonaws.com/lftabkd2fwrlski64va8rThe_Table_(restaurant)_logo.png',
      logoWidth: '100',
      social: {
        twitter: 'https://www.twitter.com',
        discord: 'https://www.discord.com',
        instagram: 'https://www.instagram.com',
        facebook: '',
        snapchat: '',
        tiktok: ''
      }
    },
    style: {
      colors: [
        {
          code: ''
        },
        {
          code: 'rgba(46, 39, 42, 1)'
        },
        {
          code: 'rgba(0, 0, 0, 1)'
        },
        {
          code: 'rgba(12, 163, 79, 1)'
        },
        {
          code: 'rgba(240, 97, 110, 1)'
        },
        {
          code: 'rgba(7, 113, 132, 1)'
        },
        {
          code: 'rgba(255,255,255,1)'
        },
        {
          code: 'rgba(249,175,35,1)'
        },
        {
          code: 'rgba(102,17,17,1)'
        },
        {
          code: 'rgba(246,246,246,1)'
        },
        {
          code: 'rgba(246,246,246,1)'
        }
      ],
      headerFont: 'Anton',
      bodyFont: 'Roboto',
      buttonFont: 'Oswald',
      backgroundColor: 'rgba(246,246,246,1)',
      backgroundImage: '',
      foregroundColor: 'rgba(0, 0, 0, 1)',
      button: {
        primary: {
          backgroundColor: 'rgba(240, 97, 110, 1)',
          textColor: 'rgba(255,255,255,1)',
          roundedCorners: '32'
        },
        secondary: {
          backgroundColor: 'rgba(46, 39, 42, 1)',
          textColor: 'rgba(255,255,255,1)',
          roundedCorners: '32'
        }
      }
    }
  },
  header: {
    content: {
      layout: 1,
      nav: [
        {
          label: '',
          url: ''
        }
      ],
      ctaButtons: [
        {
          label: '',
          url: ''
        }
      ],
      showLogo: true,
      logoSizeAsGlobal: true,
      logoWidth: 140,
      sticky: false,
      roundedCorners: 0,
      contentInContainer: true,
      socials: false
    },
    style: {
      backgroundColor: '',
      backgroundGradient: '',
      backgroundImage: '',
      foregroundColor: 'rgba(0, 0, 0, 1)',
      navColor: '',
      marginTop: 0,
      marginBottom: 0,
      paddingTop: '16',
      paddingBottom: '16'
    }
  },
  footer: {
    content: {
      layout: 1,
      nav: [
        {
          label: 'Privacy Policy',
          url: ''
        },
        {
          label: 'Terms of services',
          url: ''
        }
      ],
      copyright: '© 2024 ZEWST. All rights reserved.',
      email: 'store@zewst.com',
      showLogo: true,
      logoWidth: '99',
      roundedCorners: '0',
      contentInContainer: true,
      socials: true
    },
    style: {
      backgroundColor: '',
      backgroundGradient: '',
      backgroundImage: '',
      foregroundColor: 'rgba(46, 39, 42, 1)',
      navColor: '',
      marginTop: 0,
      marginBottom: 0,
      paddingTop: '32',
      paddingBottom: '32'
    }
  },
  landing: {
    renderOrder: [
      {
        id: '0',
        title: 'Carousel Section'
      },
      {
        id: '1',
        title: 'Featured Section'
      },
      {
        id: '2',
        title: 'Figure Section'
      }
    ],
    productSections: [],
    carouselSections: [
      {
        title: 'Carousel Section',
        content: {
          layout: 1,
          title: '',
          description: '',
          contentInContainer: false,
          contentOverlay: true,
          slides: [
            {
              media:
                'https://zewst-backend-media-bucket-1.s3.amazonaws.com/26bw0dxx33ws4qu2ihjm8eorder-banner.jpg',
              title: 'WELCOME TO \n[YOUR RESTAURANT NAME]',
              description:
                'At [Your Restaurant Name], we believe in crafting more than just meals; we create experiences. Nestled in the heart of [Your City], our restaurant invites you to embark on a culinary journey that tantalizes your taste buds and satisfies your soul. With a passion for flavors and a commitment to quality, we proudly present a menu that showcases the best of local and international cuisines.'
            }
          ]
        },
        style: {
          backgroundColor: '',
          backgroundGradient: '',
          backgroundImage: '',
          foregroundColor: 'rgba(255,255,255,1)',
          overlayBackgroundColor: 'rgba(0, 0, 0, 1)',
          overlayOpacity: '16',
          marginTop: 0,
          marginBottom: 0,
          paddingTop: 0,
          paddingBottom: 0
        }
      }
    ],
    featuredSections: [
      {
        title: 'Featured Section',
        content: {
          layout: 1,
          title: 'DINING EXPERIENCE',
          subTitle: 'OUR MENU',
          description:
            "Whether you're celebrating a special occasion, hosting a business meeting, or enjoying a casual meal with friends and family, our restaurant provides a warm and inviting atmosphere. Our attentive staff is committed to providing exceptional service, making your dining experience memorable and enjoyable.",
          contentInContainer: true,
          slides: [
            {
              category: 'Appetizers',
              title: 'Crispy Calamari',
              description:
                'Tender calamari rings coated in a light, crispy batter, served with zesty marinara sauce.',
              media:
                'https://zewst-backend-media-bucket-1.s3.amazonaws.com/mb4y8lvet8spcpaat4o4nisl19_fb_jun03.jpg'
            }
          ]
        },
        style: {
          backgroundColor: '',
          backgroundGradient: '',
          backgroundImage: '',
          foregroundColor: '',
          marginTop: 0,
          marginBottom: 0,
          paddingTop: '111',
          paddingBottom: '111'
        }
      }
    ],
    figureSections: [
      {
        title: 'Figure Section',
        content: {
          layout: 1,
          title: 'CATERING AND EVENTS',
          description:
            'Planning an event? Let us cater to your culinary needs. Our catering services are tailored to suit various occasions, from weddings and corporate events to private parties. Contact us to discuss your requirements, and let us create a customized menu that will impress your guests.',
          media:
            'https://zewst-backend-media-bucket-1.s3.amazonaws.com/qhmnupspd2r3yt1czv7irfGroup-5560.png',
          contentInContainer: false,
          contentOverlay: false
        },
        style: {
          backgroundColor: '',
          backgroundGradient: '',
          backgroundImage: '',
          foregroundColor: 'rgba(255,255,255,1)',
          overlayBackgroundColor: 'rgba(0, 0, 0, 1)',
          overlayOpacity: '16',
          marginTop: 0,
          marginBottom: 0,
          paddingTop: 0,
          paddingBottom: 0
        }
      }
    ]
  }
};

export const ADVANCED_MODE_ONLINE_SITE_DATA = {
  projectName: 'Zewst Online Store',
  pageName: 'Landing Page',
  global: {
    content: {
      title: `ZEWST - Store`,
      favicon: `https://placehold.co/32x32.png`,
      logo: `https://placehold.co/140x32.png`,
      logoWidth: 140,
      social: {
        twitter: `https://www.twitter.com`,
        discord: `https://www.discord.com`,
        instagram: `https://www.instagram.com`,
        facebook: ``,
        snapchat: `https://www.snapchat.com`,
        tiktok: ``
      }
    },
    style: {
      colors: [
        { code: `` },
        { code: `rgba(46, 39, 42, 1)` },
        { code: `rgba(0, 0, 0, 1)` },
        { code: `rgba(12, 163, 79, 1)` },
        { code: `rgba(240, 97, 110, 1)` },
        { code: `rgba(7, 113, 132, 1)` }
      ],
      headerFont: ``,
      bodyFont: ``,
      buttonFont: ``,
      backgroundColor: `rgba(254, 251, 249, 1)`,
      backgroundImage: ``,
      foregroundColor: `rgba(0, 0, 0, 1)`,
      button: {
        primary: {
          backgroundColor: `rgba(20, 20, 20, 1)`,
          textColor: `rgba(255, 255, 255, 1)`,
          roundedCorners: 10
        },
        secondary: {
          backgroundColor: `rgba(217, 217, 217, 1)`,
          textColor: `rgba(26, 26, 26, 1)`,
          roundedCorners: 10
        }
      }
    }
  },
  header: {
    content: {
      layout: 1,
      nav: [{ label: ``, url: `` }],
      ctaButtons: [{ label: ``, url: `` }],
      showLogo: true,
      logoSizeAsGlobal: true,
      logoWidth: 140,
      sticky: false,
      roundedCorners: 0,
      contentInContainer: true,
      socials: false
    },
    style: {
      backgroundColor: ``,
      backgroundGradient: ``,
      backgroundImage: ``,
      foregroundColor: ``,
      navColor: ``,
      marginTop: 0,
      marginBottom: 0,
      paddingTop: 10,
      paddingBottom: 10
    }
  },
  footer: {
    content: {
      layout: 1,
      nav: [
        { label: `Privacy Policy`, url: `` },
        { label: `Terms of services`, url: `` }
      ],
      copyright: `© 2022 ZEWST. All rights reserved.`,
      email: `store@zewst.xyz`,
      showLogo: true,
      logoWidth: 100,
      roundedCorners: 0,
      contentInContainer: true,
      socials: true
    },
    style: {
      backgroundColor: ``,
      backgroundGradient: ``,
      backgroundImage: ``,
      foregroundColor: ``,
      navColor: ``,
      marginTop: 0,
      marginBottom: 0,
      paddingTop: 24,
      paddingBottom: 24
    }
  },
  landing: {
    renderOrder: [
      { id: '0', title: 'Carousel Section' },
      { id: '1', title: 'Product Section' },
      { id: '2', title: 'Featured Section' },
      { id: '3', title: 'Figure Section' }
    ],
    productSections: [
      {
        title: 'Product Section',
        content: {
          layout: 1,
          title: ``,
          description: ``,
          contentInContainer: false,
          products: [
            {
              media: `https://placehold.co/250x250.png`,
              title: `Carousel Section slide 1 main heading`,
              description: `Thanks for reading my blog! Happy coding!!!`,
              price: 10
            },
            {
              media: `https://placehold.co/250x250.png`,
              title: `Carousel Section slide 2 main heading`,
              description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.`,
              price: 10
            },
            {
              media: `https://placehold.co/250x250.png`,
              title: `Carousel Section slide 3 main heading`,
              description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.`,
              price: 10
            },
            {
              media: `https://placehold.co/250x250.png`,
              title: `Carousel Section slide 4 main heading`,
              description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.`,
              price: 10
            },
            {
              media: `https://placehold.co/250x250.png`,
              title: `Carousel Section slide 5 main heading`,
              description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.`,
              price: 10
            }
          ]
        },
        style: {
          backgroundColor: ``,
          backgroundGradient: ``,
          backgroundImage: ``,
          foregroundColor: ``,
          marginTop: 0,
          marginBottom: 0,
          paddingTop: 16,
          paddingBottom: 16
        }
      }
    ],
    carouselSections: [
      {
        title: 'Carousel Section',
        content: {
          layout: 1,
          title: ``,
          description: ``,
          contentInContainer: false,
          contentOverlay: false,
          slides: [
            {
              media: `https://placehold.co/1600x750.png`,
              title: `Carousel Section slide 1 main heading`,
              description: `Thanks for reading my blog! Happy coding!!!`
            },
            {
              media: `https://placehold.co/1600x750.png`,
              title: `Carousel Section slide 2 main heading`,
              description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.`
            }
          ]
        },
        style: {
          backgroundColor: ``,
          backgroundGradient: ``,
          backgroundImage: ``,
          foregroundColor: ``,
          overlayBackgroundColor: `rgba(0, 0, 0, 1)`,
          overlayOpacity: 50,
          marginTop: 0,
          marginBottom: 0,
          paddingTop: 0,
          paddingBottom: 0
        }
      }
    ],
    featuredSections: [
      {
        title: 'Featured Section',
        content: {
          layout: 1,
          title: `Featured section main heading`,
          subTitle: `Featured section sub heading`,
          description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.`,
          contentInContainer: true,
          slides: [
            {
              category: `Enter Category`,
              title: `Featured section slide title`,
              description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.`,
              media: `https://placehold.co/400x400.png`
            }
          ]
        },
        style: {
          backgroundColor: ``,
          backgroundGradient: ``,
          backgroundImage: ``,
          foregroundColor: ``,
          marginTop: 0,
          marginBottom: 0,
          paddingTop: 71,
          paddingBottom: 71
        }
      }
    ],
    figureSections: [
      {
        title: 'Figure Section',
        content: {
          layout: 1,
          title: `Figure section main heading`,
          description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.`,
          media: `https://placehold.co/1600x750.png`,
          contentInContainer: false,
          contentOverlay: false
        },
        style: {
          backgroundColor: ``,
          backgroundGradient: ``,
          backgroundImage: ``,
          foregroundColor: ``,
          overlayBackgroundColor: `rgba(0, 0, 0, 1)`,
          overlayOpacity: 50,
          marginTop: 0,
          marginBottom: 0,
          paddingTop: 0,
          paddingBottom: 0
        }
      }
    ]
  }
};
