import React, { useEffect } from 'react';
import { BuilderJSON } from 'store';
import { BuilderThemeHeader } from './header';
import { BuilderThemeHomeCarousel } from './home/carousel';
import { BuilderThemeHomeFeatured } from './home/featured';
import { BuilderThemeHomeFigure } from './home/figure';
import { BuilderThemeFooter } from './footer';
import styles from './index.module.css';
import { BuilderThemeHomeProduct } from './home/product';
import { useStore } from 'store';
import { useFetchOnlineContentHook } from 'hooks/builder/useFetchOnlineContentHook';
import { RenderIf } from 'utilities/helpers';
import {
  ADVANCED_MODE_ONLINE_SITE_DATA,
  BASIC_MODE_ONLINE_SITE_DATA
} from 'utilities/constants';

export const ThemeCustomization = () => {
  const { brand, selectedBuilderMode } = useStore((state) => state);

  const { pages, screenView, setPages } = BuilderJSON((state) => state);
  const renderOrder = pages?.landing?.renderOrder;
  const bodyFont = pages?.global?.style?.bodyFont;
  const backgroundColor = pages?.global?.style?.backgroundColor;
  const backgroundImage = pages?.global?.style?.backgroundImage;
  const foregroundColor = pages?.global?.style?.foregroundColor;

  const { onlineContentData, onlineContentLoading } = useFetchOnlineContentHook(
    `onlineContent/${brand?.id}`,
    brand?.id
  );

  useEffect(() => {
    document.documentElement.style.setProperty(
      '--header-font-family',
      pages?.global?.style?.headerFont
    );
    document.documentElement.style.setProperty(
      '--body-font-family',
      pages?.global?.style?.bodyFont
    );
    document.documentElement.style.setProperty(
      '--button-font-family',
      pages?.global?.style?.buttonFont
        ? pages?.global?.style?.buttonFont
        : `var(--body-font-family)`
    );
  }, [
    pages?.global?.style?.headerFont,
    pages?.global?.style?.bodyFont,
    pages?.global?.style?.buttonFont
  ]);

  useEffect(() => {
    if (onlineContentData?.[0]?.value) {
      setPages(onlineContentData?.[0]?.value);
    }

    if (onlineContentData?.length === 0 && selectedBuilderMode === 'basic') {
      setPages(BASIC_MODE_ONLINE_SITE_DATA);
    }
    if (onlineContentData?.length === 0 && selectedBuilderMode === 'advanced') {
      setPages(ADVANCED_MODE_ONLINE_SITE_DATA);
    }
  }, [onlineContentData, selectedBuilderMode]);

  return (
    <>
      <RenderIf isTrue={onlineContentLoading}>
        <p>loading...</p>
      </RenderIf>
      <RenderIf isTrue={!onlineContentLoading}>
        <div
          className={
            screenView === `WEB_VIEW`
              ? styles.previewBox
              : `${styles.previewBox} mobile_view`
          }
          style={{
            position: `relative`,
            width: screenView === `WEB_VIEW` ? `100%` : 390,
            margin: `0 auto`,
            fontFamily: bodyFont,
            backgroundColor: backgroundColor,
            backgroundImage: `url(${backgroundImage})`,
            backgroundRepeat: `no-repeat`,
            backgroundPosition: `0 0`,
            backgroundSize: `100% auto`,
            color: foregroundColor
          }}
        >
          <div style={{ width: `100%`, height: 1, margin: `-1px 0` }}></div>
          <BuilderThemeHeader />
          {renderOrder?.map((ord, index) => (
            <React.Fragment key={index}>
              <RenderIf isTrue={selectedBuilderMode === `advanced`}>
                {pages?.landing?.productSections
                  ?.filter((item) => item?.title === ord?.title)
                  ?.map((sec, i) => (
                    <BuilderThemeHomeProduct title={sec?.title} key={i} />
                  ))}
              </RenderIf>
              {pages?.landing?.carouselSections
                ?.filter((item) => item?.title === ord?.title)
                ?.map((sec, i) => (
                  <BuilderThemeHomeCarousel title={sec?.title} key={i} />
                ))}
              {pages?.landing?.featuredSections
                ?.filter((item) => item?.title === ord?.title)
                ?.map((sec, i) => (
                  <BuilderThemeHomeFeatured title={sec?.title} key={i} />
                ))}
              {pages?.landing?.figureSections
                ?.filter((item) => item?.title === ord?.title)
                ?.map((sec, i) => (
                  <BuilderThemeHomeFigure title={sec?.title} key={i} />
                ))}
            </React.Fragment>
          ))}
          <BuilderThemeFooter />
        </div>
      </RenderIf>
    </>
  );
};
