import ProgressSteps from 'components/steps';
import { AddBankInformationForm } from 'pages/brands/add/bank';

export const AddBankInformation = ({ onChangeStep, onGoToMenu }) => {
  return (
    <>
      <div className="onbox">
        <h1>
          <strong>Add Brand</strong>
        </h1>
        <h5 className="mt_4">Bank's Information</h5>
        <div className="mt_16"></div>
        <div className="w_368 center_auto">
          <ProgressSteps active={2} />
        </div>
        <div className="mt_16"></div>
        <AddBankInformationForm
          onboarding={true}
          onGoToMenu={onGoToMenu}
          onChangeStep={onChangeStep}
        />
      </div>
    </>
  );
};
