import { forwardRef } from 'react';
import styles from './index.module.css';

export const SelectDropdown = forwardRef(
  (
    { onChange, onBlur, name, label, data, error, value, fieldSm = false },
    ref
  ) => (
    <>
      {label && <label className={styles.label}>{label}</label>}
      <div className={styles.box}>
        <select
          className={
            fieldSm ? `${styles.select} ${styles.fieldSm}` : styles.select
          }
          defaultValue={value}
          name={name}
          ref={ref}
          onChange={onChange}
          onBlur={onBlur}
        >
          {data.map(({ value, label }) => (
            <option value={value}>{label}</option>
          ))}
        </select>
      </div>
      {error && <p style={{ color: 'red' }}>{error}</p>}
    </>
  )
);
