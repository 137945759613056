import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useStore } from 'store';
import { RenderIf } from 'utilities/helpers';
import { Ikon } from 'assets/icons';
import styles from './index.module.css';

const asideNav = [
  {
    label: 'Dashboard',
    svgIcon: 'overview',
    route: '/dashboard',
    children: []
  },
  // {
  //   label: 'Demand Planning',
  //   svgIcon: 'demand-planning',
  //   route: '/demand-planning',
  //   children: []
  // },
  // {
  //   label: 'Online Store',
  //   svgIcon: 'online-store',
  //   route: '',
  //   children: [
  //     {
  //       label: 'Online Store Settings',
  //       svgIcon: 'online-store-settings',
  //       route: '/online-store/builder'
  //     },
  //     {
  //       label: 'Domains',
  //       svgIcon: 'online-store-domains',
  //       route: '/online-store/domains'
  //     }
  //   ]
  // },
  {
    label: 'Inventory',
    svgIcon: 'inventory',
    route: '',
    children: [
      {
        label: 'Overview',
        svgIcon: 'inventory-overview',
        route: '/inventory/overview'
      },
      {
        label: 'Inventory',
        svgIcon: 'inventory-inventory',
        route: '/inventory/listing'
      },
      // {
      //   label: 'Vendors',
      //   svgIcon: 'inventory-vendors',
      //   route: '/inventory/vendors'
      // },
      {
        label: 'Products',
        svgIcon: 'inventory-products',
        route: '/inventory/products'
      }
    ]
  },
  {
    label: 'Menu Engineering',
    svgIcon: 'menu-engineering',
    route: '',
    children: [
      {
        label: 'Categories',
        svgIcon: 'menu-categories',
        route: '/menu-engineering/categories'
      },
      {
        label: 'Items',
        svgIcon: 'menu-items',
        route: '/menu-engineering/items'
      },
      {
        label: 'Modifiers',
        svgIcon: 'menu-addons',
        route: '/menu-engineering/modifiers'
      }
    ]
  },
  {
    label: 'Recipe Engineering',
    svgIcon: 'recipe-engineering',
    route: '/recipe-engineering',
    children: []
  },
  {
    label: 'SDS',
    svgIcon: 'sds',
    route: '/sds',
    children: []
  },
  {
    label: 'Orders History',
    svgIcon: 'sds',
    route: '/orders/history',
    children: []
  },
  {
    label: 'Delivery Settings',
    svgIcon: 'sds',
    route: '/delivery-settings',
    children: []
  },
  {
    label: 'Payment Settings',
    svgIcon: 'sds',
    route: '',
    children: [
      {
        label: 'Payment Settings',
        svgIcon: 'payment-settings',
        route: '/payment-settings'
      },
      {
        label: 'Device Settings',
        svgIcon: 'device-settings',
        route: '/device-settings'
      },
      {
        label: 'Brand Payment Contracts',
        svgIcon: 'device-settings',
        route: '/brand-payment-contracts'
      }
    ]
  }
];

export const NavItem = ({ item }) => {
  const navigate = useNavigate();
  const pathname = useLocation().pathname;
  const { isWebMenu } = useStore((state) => state);
  const [active, setActive] = useState(false);

  return (
    <>
      <button
        type={`button`}
        className={
          item?.route === pathname && item?.children?.length === 0
            ? `${styles.navItem} ${styles.active}`
            : item?.children?.length !== 0 && !active
            ? `${styles.navItem} ${styles.ddItem}`
            : item?.children?.length !== 0 && active
            ? `${styles.navItem} ${styles.ddItem} ${styles.ddActive}`
            : styles.navItem
        }
        onClick={() => {
          if (item?.children?.length === 0) {
            navigate(item?.route);
          }
          if (item?.children?.length > 0) {
            setActive(!active);
          }
        }}
        style={{ width: isWebMenu ? `100%` : 50 }}
      >
        <div>
          <div className={styles.icon}>
            <RenderIf isTrue={item?.svgIcon}>
              <Ikon name={item?.svgIcon} />
            </RenderIf>
          </div>
          <div
            style={{
              width: !isWebMenu ? 0 : ``,
              transition: `width 0.35s ease`,
              overflow: `hidden`
            }}
          >
            {item?.label}
          </div>
        </div>
        <div className={!isWebMenu ? styles.itemSmtArrow : styles.itemWebArrow}>
          <RenderIf isTrue={item?.children?.length > 0}>
            <RenderIf isTrue={!active}>
              <Ikon name={`down-cert`} />
            </RenderIf>
            <RenderIf isTrue={active}>
              <Ikon name={`up-cert`} />
            </RenderIf>
          </RenderIf>
        </div>
        <RenderIf isTrue={!isWebMenu}>
          <div className={styles.tooltip}>{item?.label}</div>
        </RenderIf>
      </button>
      <RenderIf isTrue={item?.children?.length !== 0 && active}>
        {item?.children.map((citem, i) => (
          <div
            key={i}
            className={
              isWebMenu
                ? styles.subMenu
                : `${styles.subMenu} ${styles.smartSubMenu}`
            }
          >
            <button
              type={`button`}
              className={
                citem?.route === pathname
                  ? `${styles.navItem} ${styles.active}`
                  : styles.navItem
              }
              onClick={() => {
                navigate(citem?.route);
              }}
              style={{ width: isWebMenu ? `100%` : 50 }}
            >
              <div>
                <div className={styles.icon}>
                  <RenderIf isTrue={citem?.svgIcon}>
                    <Ikon name={citem?.svgIcon} />
                  </RenderIf>
                </div>
                <div
                  style={{
                    width: !isWebMenu ? 0 : ``,
                    transition: `width 0.35s ease`,
                    overflow: `hidden`
                  }}
                >
                  {citem?.label}
                </div>
              </div>
              <RenderIf isTrue={!isWebMenu}>
                <div className={styles.tooltip}>{item?.label}</div>
              </RenderIf>
            </button>
          </div>
        ))}
      </RenderIf>
    </>
  );
};

export default function Aside({ userRole }) {
  const { brand, isWebMenu } = useStore((state) => state);

  return (
    <aside
      className={styles.aside}
      style={{ overflowY: isWebMenu ? `auto` : `` }}
    >
      <RenderIf isTrue={userRole === 'SUPER_ADMIN'}>
        <RenderIf isTrue={brand?.brand_type === 'CLOUD'}>
          {asideNav?.length > 0 &&
            asideNav
              ?.filter(
                (nav) =>
                  nav.label === 'Overview' ||
                  nav.label === 'Portfolio' ||
                  nav.label === 'Online Store' ||
                  nav.label === 'SDS' ||
                  nav.label === 'KDS' ||
                  nav.label === 'Demand Planning'
              )
              .map((item, index) => {
                return (
                  <div key={index}>
                    <NavItem item={item} />
                  </div>
                );
              })}
        </RenderIf>
        <RenderIf isTrue={brand?.brand_type !== 'CLOUD'}>
          {asideNav?.length > 0 &&
            asideNav.map((item, index) => (
              <div key={index}>
                <NavItem item={item} />
              </div>
            ))}
        </RenderIf>
      </RenderIf>
    </aside>
  );
}
