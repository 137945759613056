import { CustomSelectDropdown } from 'components/select/multi';
import React, { useState } from 'react';
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer
} from 'recharts';
import { RenderIf } from 'utilities/helpers';

const data = [
  {
    name: '12am',
    uv: 4000,
    pv: 2400,
    amt: 2400
  },
  {
    name: '01pm',
    uv: 3000,
    pv: 1398,
    amt: 2210
  },
  {
    name: '02pm',
    uv: 2000,
    pv: 9800,
    amt: 2290
  },
  {
    name: '03pm',
    uv: 2780,
    pv: 3908,
    amt: 2000
  },
  {
    name: '02am',
    uv: 1890,
    pv: 4800,
    amt: 2181
  },
  {
    name: '04pm',
    uv: 2390,
    pv: 3800,
    amt: 2500
  },
  {
    name: '05pm',
    uv: 3490,
    pv: 4300,
    amt: 2100
  }
];
function TotalProfit() {
  const [noData, setNoData] = useState(true);

  return (
    <div className="chart_box_1">
      <div className="top_fd">
        <p>
          <strong className="chart_title fs_18">Total Profit</strong>
        </p>
        <RenderIf isTrue={!noData}>
          <div style={{ width: '150px' }}>
            <CustomSelectDropdown
              name="chart"
              placeholder="Select"
              options={[1]?.map((item) => ({
                label: 'Today',
                value: 'Today'
              }))}
              // onChange={}
              // value={}
            />
          </div>
        </RenderIf>
      </div>
      <RenderIf isTrue={!noData}>
        <div className="mt_40"></div>
        <div className="detail">
          <div className="txt">
            <p className="tp_txt">Income</p>
            <h3 className="chart_title fs_18">$55566</h3>
          </div>
          <div className="txt">
            <p className="tp_txt">Expense</p>
            <h3 className="chart_title fs_18">$55566</h3>
          </div>

          <div className="txt">
            <p className="tp_txt">Profit</p>
            <h3 className="chart_title fs_18">$55566</h3>
          </div>
        </div>
        <div className="mt_40"></div>
      </RenderIf>
      <RenderIf isTrue={!noData}>
        <ResponsiveContainer height={300}>
          <AreaChart
            data={data}
            margin={{
              top: 10,
              right: 30,
              left: 0,
              bottom: 0
            }}
          >
            {/* <CartesianGrid strokeDasharray="3" /> */}
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Area
              type="monotone"
              dataKey="uv"
              stroke="#954CCB"
              fill="#954CCB"
              opacity={'45%'}
            />
            <Area
              type="monotone"
              dataKey="pv"
              stroke="#954CCB"
              fill="#954CCB"
              opacity={'45%'}
            />
          </AreaChart>
        </ResponsiveContainer>
      </RenderIf>
      <RenderIf isTrue={noData}>
        <div
          style={{
            width: `100%`,
            height: 440,
            display: `flex`,
            alignItems: `center`,
            justifyContent: `center`,
            backgroundColor: `#D9D9D9`,
            color: `#707070`,
            fontSize: `14px`,
            fontWeight: `600`,
            marginTop: 10,
            borderRadius: `0 0 8px 8px`
          }}
        >
          No data
        </div>
      </RenderIf>
    </div>
  );
}

export default TotalProfit;
