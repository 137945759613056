import { useParams } from 'react-router-dom';
import { useFetchSingleMenuItem } from 'hooks/menu/useMenuHook';
import { RenderIf } from 'utilities/helpers';
import { useStore } from 'store';
import { Button } from 'components/button';
import { AddMenuItem } from '../add';
import { useState } from 'react';
import { useFetchBranches, useFetchCategories } from 'hooks/resourceHooks';
import { Toasts } from 'components/toasts';
import { client } from 'utilities/db';
import { Loading } from 'components/loading';

export const MenuItemDetail = () => {
  const { id } = useParams();
  const { brand } = useStore((state) => state);
  const {
    data: fetchMenuItem = {},
    isLoading: fetchedMenuItemLoading,
    refetch
  } = useFetchSingleMenuItem(`menuItem/${id}`, brand?.id, id);

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [uploadImage, setUploadImage] = useState('');
  const [uploadImageLoading, setUploadImageLoading] = useState(false);
  const { data: allCategories } = useFetchCategories();
  const { data: branchData, isLoading: branchLoading } = useFetchBranches(
    brand?.id
  );

  const updateMenu = async (val) => {
    // if (category.length === 0) {
    //   alert('Menu Item should be linked with atleast one category');
    //   return;
    // }

    try {
      await client.post(`/menuItem/update`, {
        brand_id: brand?.id,
        image: uploadImage || fetchMenuItem?.image,
        old_menu_item_name: fetchMenuItem?.menu_item_name,
        menu_item_level: val?.branch_ids?.length > 0 ? 'BRANCH' : 'BRAND',
        menu_item_name: val?.menu_item_name,
        menu_item_description: val?.menu_item_description,
        base_price: val?.base_price,
        taxable: val?.taxable === true ? '1' : '0',
        quantity_threshold: val?.quantity_threshold,
        quantity_options: {
          force_quantity: val?.force_quantity,
          lock_quantity: val?.lock_quantity,
          hide_quantity: val?.hide_quantity
        },
        tags:
          val?.tags?.length && val?.tags?.length > 0
            ? val?.tags?.map((tag) => tag.value)
            : [],
        menu_status: val?.menu_status,
        category: val?.category?.value,
        category_types: val?.category_types?.length
          ? val?.category_types?.map((type) => type?.value)
          : [],
        branch_ids:
          val?.branch_ids?.length && val?.branch_ids?.length > 0
            ? val?.branch_ids?.map((item) => item?.value)
            : null,
        available_days: {
          monday: val?.monday,
          tuesday: val?.tuesday,
          wednesday: val?.wednesday,
          thursday: val?.thursday,
          friday: val?.friday,
          saturday: val?.saturday,
          sunday: val?.sunday
        },
        available_time: {
          start_time: val?.start_time,
          end_time: val?.end_time
        },
        sale_channels: {
          pos: val?.pos,
          online_store: val?.online_store,
          others: val?.others
        }
      });
      setIsOpenModal(false);
      refetch();
      Toasts.success('Menu Item updated successfully');
    } catch (error) {
      Toasts.error(error?.response?.data?.message || 'Something went wrong');
    }
  };

  return (
    <>
      <article>
        <RenderIf isTrue={fetchedMenuItemLoading}>
          <div className={`loading_full`}>
            <Loading />
          </div>
        </RenderIf>
        <RenderIf isTrue={!fetchedMenuItemLoading}>
          <div className="grid grid_gap30">
            <div className="collg5">
              <div className="mt_40"></div>
              <RenderIf isTrue={fetchMenuItem?.image}>
                <picture>
                  <source srcSet={fetchMenuItem?.image} />
                  <img src={fetchMenuItem?.image} alt="" />
                </picture>
                <div className="mt_40"></div>
              </RenderIf>
              <hr />
              <div className="mt_20"></div>
              <h6>
                <strong>Item available on stores:</strong>
              </h6>
              <RenderIf isTrue={fetchMenuItem?.sale_channels?.online_store}>
                <div className="mt_15"></div>
                <h6>
                  <strong>Online store</strong>
                </h6>
              </RenderIf>
              <RenderIf isTrue={fetchMenuItem?.sale_channels?.pos}>
                <div className="mt_15"></div>
                <h6>
                  <strong>POS</strong>
                </h6>
              </RenderIf>
              <RenderIf isTrue={fetchMenuItem?.sale_channels?.others}>
                <div className="mt_15"></div>
                <h6>
                  <strong>Others</strong>
                </h6>
              </RenderIf>
            </div>

            <div className="collg7">
              <div className="mt_40"></div>
              <Button
                color="bg-titary"
                label={'Update menu item'}
                onClick={() => setIsOpenModal(!isOpenModal)}
              />
              <p>
                <small>
                  Created at:{' '}
                  <b>{fetchMenuItem?.created_at?.split('T')?.[0]}</b>
                </small>
              </p>
              <div className="mt_10"></div>
              <div className="flex item_space_btn">
                <h1>
                  <b>{fetchMenuItem?.menu_item_name}</b>
                </h1>
                <div>
                  <h2>
                    <span className="ff_semi">$ {fetchMenuItem?.price}</span>
                  </h2>
                </div>
              </div>
              <RenderIf isTrue={fetchMenuItem?.taxable === 1}>
                <div className="mt_20"></div>
                <p>
                  <strong>This item is taxable</strong>
                </p>
              </RenderIf>
              <div className="mt_10"></div>
              <hr />
              <div className="flex item_vcenter item_space_btn mt_10">
                <div>
                  <p>Menu item level:</p>
                  <h6 className="mt_4">
                    <b>
                      {fetchMenuItem?.menu_item_level === 'BRAND'
                        ? 'Brand'
                        : 'Branch'}
                    </b>
                  </h6>
                </div>
                <div>
                  <p>Menu item status:</p>
                  <h6 className="mt_4">
                    <b>
                      {fetchMenuItem?.menu_status === true
                        ? 'Activated'
                        : 'Deactivated'}
                    </b>
                  </h6>
                </div>
              </div>
              <div className="mt_10"></div>
              <hr />
              <div className="mt_30"></div>
              <h6>
                <strong>Description</strong>
              </h6>
              <div className="mt_10"></div>
              <p>{fetchMenuItem?.menu_item_description}</p>
              <div className="mt_40"></div>
              <h6>
                <strong>Linked category:</strong>{' '}
                <b>{fetchMenuItem?.category?.category_name}</b>
              </h6>
              <RenderIf isTrue={fetchMenuItem?.categoryTypes?.length > 0}>
                <div className="mt_20"></div>
                <h6>
                  <strong>Linked category types:</strong>
                </h6>
                <div className="pt_15"></div>
                {fetchMenuItem?.categoryTypes?.map((type, index) => (
                  <p key={index} className="mt_5">
                    <span className="ff_semi">{type?.category_type_name}</span>
                  </p>
                ))}
              </RenderIf>
              <RenderIf isTrue={fetchMenuItem?.tags?.length > 0}>
                <div className="mt_20"></div>
                <hr />
                <div className="mt_10"></div>
                <h6>
                  <strong>Tags:</strong>
                </h6>
                <div className="pt_5"></div>
                <div className="flex item_vcenter">
                  {fetchMenuItem?.tags?.map((tag, i) => (
                    <div key={i} className="badge mt_5">
                      <span className="ff_semi">{tag}</span>
                    </div>
                  ))}
                </div>
                <div className="mt_10"></div>
                <hr />
                <div className="mt_20"></div>
              </RenderIf>
              <div className="mt_15"></div>
              <div className="flex item_space_btn">
                <h6>
                  <strong>
                    Default quantity: {fetchMenuItem?.quantity_threshold}
                  </strong>
                </h6>
                <div>
                  <RenderIf
                    isTrue={
                      fetchMenuItem?.quantity_options?.force_quantity === true
                    }
                  >
                    <h6>Force quantity</h6>
                  </RenderIf>
                  <RenderIf
                    isTrue={
                      fetchMenuItem?.quantity_options?.hide_quantity === true
                    }
                  >
                    <h6>Hide quantity</h6>
                  </RenderIf>
                  <RenderIf
                    isTrue={
                      fetchMenuItem?.quantity_options?.lock_quantity === true
                    }
                  >
                    <h6>Lock quantity</h6>
                  </RenderIf>
                </div>
              </div>
              <div className="mt_15"></div>
              <hr />
              <div className="mt_15"></div>
              <h6>
                <strong>Available days:</strong>
              </h6>
              <div className="flex item_vcenter">
                <RenderIf
                  isTrue={fetchMenuItem?.availability_days?.monday === true}
                >
                  <div className="badge mt_5">
                    <span className="ff_semi">Monday</span>
                  </div>
                </RenderIf>
                <RenderIf
                  isTrue={fetchMenuItem?.availability_days?.tuesday === true}
                >
                  <div className="badge mt_5">
                    <span className="ff_semi">Tuesday</span>
                  </div>
                </RenderIf>
                <RenderIf
                  isTrue={fetchMenuItem?.availability_days?.wednesday === true}
                >
                  <div className="badge mt_5">
                    <span className="ff_semi">Wednesday</span>
                  </div>
                </RenderIf>
                <RenderIf
                  isTrue={fetchMenuItem?.availability_days?.thursday === true}
                >
                  <div className="badge mt_5">
                    <span className="ff_semi">Thursday</span>
                  </div>
                </RenderIf>
                <RenderIf
                  isTrue={fetchMenuItem?.availability_days?.friday === true}
                >
                  <div className="badge mt_5">
                    <span className="ff_semi">Friday</span>
                  </div>
                </RenderIf>
                <RenderIf
                  isTrue={fetchMenuItem?.availability_days?.saturday === true}
                >
                  <div className="badge mt_5">
                    <span className="ff_semi">Saturday</span>
                  </div>
                </RenderIf>
                <RenderIf
                  isTrue={fetchMenuItem?.availability_days?.sunday === true}
                >
                  <div className="badge mt_5">
                    <span className="ff_semi">Sunday</span>
                  </div>
                </RenderIf>
              </div>
              <RenderIf isTrue={fetchMenuItem?.availability_time?.start_time}>
                <div className="flex item_vcenter item_space_btn mt_15">
                  <div>
                    <p>
                      Start time:{' '}
                      <b>{fetchMenuItem?.availability_time?.start_time}</b>
                    </p>
                  </div>
                  <div>
                    <p>
                      End time:{' '}
                      <b>{fetchMenuItem?.availability_time?.end_time}</b>
                    </p>
                  </div>
                </div>
              </RenderIf>
              <div className="mt_15"></div>
            </div>
          </div>
        </RenderIf>
        {isOpenModal && (
          <AddMenuItem
            allCategories={allCategories}
            isLoading={uploadImageLoading}
            addMenu={updateMenu}
            additionType=""
            uploadImage={uploadImage}
            setUploadImageLoading={setUploadImageLoading}
            setUploadImage={setUploadImage}
            onClose={() => setIsOpenModal(false)}
            branchData={branchData}
            branchLoading={branchLoading}
            isOpenModal={isOpenModal}
            existingMenu={fetchMenuItem}
            action="update"
          />
        )}
      </article>
    </>
  );
};
