import { useEffect, useState } from 'react';
import { DatePicker, InputNumber, Toggle } from 'rsuite';
import { converTimeToDate, getTimeFromDate, RenderIf } from 'utilities/helpers';
import { FaClock } from 'react-icons/fa';
import { PrimaryButton } from 'components/button';
import { client } from 'utilities/db';
import { Toasts } from 'components/toasts';
import { useStore } from 'store';

import styles from './index.module.css';

export const DeliveryListing = () => {
  const { branch } = useStore((state) => state);

  const [deliveryEnabled, setDeliveryEnabled] = useState(false);
  const [startTime, setStartTime] = useState(new Date());
  const [endTime, setEndTime] = useState(new Date());
  const [deliveryRange, setDeliveryRange] = useState(10);
  const [deliveryTime, setDeliveryTime] = useState(10);
  const [submitLoading, setSubmitLoading] = useState(false);

  const getDoordashData = async (branch) => {
    try {
      const data = (await client.get(`/branch/getDoorDash/${branch?.id}`))
        ?.data;

      const doorDashSettings = data?.doorDashSettings;

      if (doorDashSettings?.active === 1) {
        setDeliveryEnabled(doorDashSettings?.active === 1 ? true : false);
        setStartTime(converTimeToDate(doorDashSettings?.start_time));
        setEndTime(converTimeToDate(doorDashSettings?.end_time));
        setDeliveryRange(doorDashSettings?.delivery_range);
        setDeliveryTime(doorDashSettings?.pickup_after);
      } else {
        setDeliveryEnabled(false);
        setStartTime(new Date());
        setEndTime(new Date());
        setDeliveryRange(10);
        setDeliveryTime(10);
      }
    } catch (error) {
      Toasts.error(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    if (!branch?.id) return;
    getDoordashData(branch);
  }, [branch?.id]);

  const handleSaveUpdateSettings = async () => {
    try {
      setSubmitLoading(true);
      const start_time = getTimeFromDate(startTime);
      const end_time = getTimeFromDate(endTime);

      await client.post(`/branch/addDoorDash`, {
        branch_id: branch?.id,
        start_time,
        end_time,
        delivery_range: +deliveryRange,
        pickup_after: +deliveryTime,
        active: deliveryEnabled
      });
      Toasts.success('Delivery settings updated successfully');
      setSubmitLoading(false);
    } catch (error) {
      setSubmitLoading(false);
      Toasts.error(error?.response?.data?.message);
    }
  };

  return (
    <article className={styles.article}>
      <header className={styles.header}>
        <div>
          <h2>Delivery Settings</h2>
        </div>
      </header>
      <div>
        <div className={styles.enableRw}>
          <h6>Enabled</h6>
          <div>
            <Toggle
              color="green"
              onChange={(bool) => {
                setDeliveryEnabled(bool);
              }}
              defaultChecked={deliveryEnabled}
              checked={deliveryEnabled}
            ></Toggle>
          </div>
        </div>
        <RenderIf isTrue={deliveryEnabled}>
          <div className={styles.listBx}>
            <div className={styles.listItem}>
              <label>Start time</label>
              <div>
                <DatePicker
                  format="HH:mm:ss"
                  caretAs={FaClock}
                  size="sm"
                  onChange={(date) => {
                    setStartTime(date);
                  }}
                  value={startTime}
                />
              </div>
            </div>
            <div className={styles.listItem}>
              <label>End time</label>
              <div>
                <DatePicker
                  format="HH:mm:ss"
                  caretAs={FaClock}
                  size="sm"
                  onChange={(date) => {
                    setEndTime(date);
                  }}
                  value={endTime}
                />
              </div>
            </div>
            <div className={styles.listItem}>
              <label>Pickup after (in minutes)</label>
              <div>
                <InputNumber
                  size="sm"
                  min={0}
                  max={200}
                  defaultValue={10}
                  value={deliveryTime}
                  onChange={(val) => setDeliveryTime(val)}
                />
              </div>
            </div>
            <div className={styles.listItem}>
              <label>Max miles for delivery</label>
              <div>
                <InputNumber
                  size="sm"
                  min={0}
                  max={15}
                  defaultValue={10}
                  value={deliveryRange}
                  onChange={(val) => setDeliveryRange(val)}
                />
              </div>
            </div>
          </div>
        </RenderIf>
      </div>
      <footer className={styles.footer}>
        <PrimaryButton
          onClick={handleSaveUpdateSettings}
          disabled={submitLoading}
          isLoading={submitLoading}
        >
          Save settings
        </PrimaryButton>
      </footer>
    </article>
  );
};
