import { useParams } from 'react-router-dom';
import { useFetchSingleCategory } from 'hooks/menu/useMenuHook';
import { RenderIf } from 'utilities/helpers';
import { AddMenuCatgory } from '../add';
import { useState } from 'react';
import { useFetchBranches, useFetchColors } from 'hooks/resourceHooks';
import { useStore } from 'store';
import { Button } from 'components/button';
import { Toasts } from 'components/toasts';
import { client } from 'utilities/db';
import { Loading } from 'components/loading';

export const CategoryDetail = () => {
  const { brand } = useStore((state) => state);
  const { id } = useParams();
  const {
    data: fetchCategory = {},
    isLoading: fetchedCategoryLoading,
    refetch
  } = useFetchSingleCategory(id);

  const { data: colorsList } = useFetchColors();
  const { data: branchData, isLoading: branchLoading } = useFetchBranches(
    brand?.id
  );

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [uploadImage, setUploadImage] = useState('');
  const [uploadImageLoading, setUploadImageLoading] = useState(false);
  const [additionType, setAdditionType] = useState('');

  const addMenu = async (val) => {

    try {
      // let typesArr = [{ name: '' }];
      // typesArr =
      //   val?.category_types?.length &&
      //   val?.category_types?.map((cat) => {
      //     return {
      //       name: cat?.label
      //     };
      //   });

      await client.post(`/category/update`, {
        image: uploadImage || fetchCategory?.image,
        old_name: fetchCategory?.category?.category_name,
        name: val?.name,
        description: val?.description,
        //category_types: typesArr,
        is_active: val?.is_active,
        color_id: val?.color_id?.value,
        //is_deleted: false,
        brand_id: fetchCategory?.category?.brand_id
        //branch_ids: val?.branch_ids
        //category_level: val?.branch_ids?.length > 0 ? 'BRANCH' : 'BRAND'
      });
      refetch();
      Toasts.success('Category updated successfully');
      setIsOpenModal(false);
    } catch (error) {
      Toasts.error(error?.response?.data?.message || 'Something went wrong');
    }
  };

  return (
    <>
      <article>
        <RenderIf isTrue={fetchedCategoryLoading}>
          <div className={`loading_full`}>
            <Loading />
          </div>
        </RenderIf>
        <RenderIf isTrue={!fetchedCategoryLoading}>
          <div className="grid grid_gap30">
            <RenderIf isTrue={fetchCategory?.category?.image}>
              <div className="collg5">
                <div className="mt_40"></div>
                <picture>
                  <source srcSet={fetchCategory?.category?.image} />
                  <img src={fetchCategory?.category?.image} alt="" />
                </picture>
              </div>
            </RenderIf>
            <div className="collg7">
              <div className="mt_40"></div>
              <p>
                <small>
                  Created at:{' '}
                  <b>{fetchCategory?.category?.created_at?.split('T')?.[0]}</b>
                </small>
              </p>
              <div className="mt_10"></div>
              <h1>
                <b>{fetchCategory?.category?.category_name}</b>
              </h1>
              <Button
                color="bg-titary"
                label={'Update menu catgery'}
                onClick={() => setIsOpenModal(!isOpenModal)}
              />
              <div className="mt_30"></div>
              <hr />
              <div className="flex item_vcenter item_space_btn mt_10">
                <div>
                  <p>Category level:</p>
                  <h6 className="mt_4">
                    <b>
                      {fetchCategory?.category?.category_level === 'BRAND'
                        ? 'Brand'
                        : 'Branch'}
                    </b>
                  </h6>
                </div>
                <div>
                  <p>Category status:</p>
                  <h6 className="mt_4">
                    <b>
                      {fetchCategory?.category?.is_active === 1
                        ? 'Activated'
                        : 'Deactivated'}
                    </b>
                  </h6>
                </div>
              </div>
              <div className="mt_10"></div>
              <hr />
              <div className="mt_30"></div>
              <h6>
                <strong>Description</strong>
              </h6>
              <div className="mt_10"></div>
              <p>{fetchCategory?.category?.category_description}</p>
              <RenderIf isTrue={fetchCategory?.categoryType?.length > 0}>
                <div className="mt_40"></div>
                <h6>
                  <strong>Category types</strong>
                </h6>
                <div className="pt_15"></div>
                {fetchCategory?.categoryType?.map((type, index) => (
                  <p key={index} className="mt_5">
                    <span className="ff_semi">{type?.category_type_name}</span>
                  </p>
                ))}
              </RenderIf>
            </div>
          </div>
        </RenderIf>

        {isOpenModal && (
          <AddMenuCatgory
            branchData={branchData}
            branchLoading={branchLoading}
            isLoading={uploadImageLoading}
            addMenu={addMenu}
            additionType={additionType}
            uploadImage={uploadImage}
            setUploadImageLoading={setUploadImageLoading}
            setUploadImage={setUploadImage}
            isOpenModal={isOpenModal}
            onClose={() => setIsOpenModal(false)}
            colorsList={colorsList}
            existingCategory={fetchCategory}
            action="update"
          />
        )}
      </article>
    </>
  );
};
