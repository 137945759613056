import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { QueryCache } from 'react-query';
import { BuilderJSON, useStore } from 'store';
import { AXIOS_INSTANCE_ZEWARDS, client } from 'utilities/db';
import { RenderIf } from 'utilities/helpers';
import { ButtonDropdown } from 'components/select/button';
import { Button } from 'components/button';
import { Ikon } from 'assets/icons';
import styles from './index.module.css';
import { Toasts } from 'components/toasts';

export const Header = () => {
  const {
    screenView,
    setScreenView,
    websiteBuilder,
    pages,
    setWebsiteBuilder,
    setPages
  } = BuilderJSON((state) => state);
  console.log('🚀 ~ Header ~ websiteBuilder:', websiteBuilder);
  const {
    user,
    setLoyaltyId,
    buisnessId,
    brand,
    branch,
    selectedBuilderMode,
    isWebMenu,
    setIsWebMenu,
    loyaltyId
  } = useStore((state) => state);

  const [publishLoading, setPublishLoading] = useState(false);

  const queryCache = new QueryCache({
    onError: (error) => {
      console.log(error);
    },
    onSuccess: (data) => {
      console.log(data);
    }
  });

  const handleLoyalties = async (id) => {
    const existingLoyalties = (
      await AXIOS_INSTANCE_ZEWARDS.get(
        `/loyalty/findAllByClientId/${buisnessId}?page=1&limit=10`
      )
    )?.data;

    console.log('existingLoyalties?.loyalties?.docs?.[0]', loyaltyId);

    if (existingLoyalties?.loyalties?.docs?.length > 0) {
      setLoyaltyId(existingLoyalties?.loyalties?.docs?.[0]?._id);
    }
  };

  useEffect(() => {
    if (user?.id) {
      handleLoyalties(user?.id);
    }
  }, [user]);

  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.clear();
    queryCache.clear();
    navigate('/login');
    window.location.reload();
  };

  const findExistingOnlineSite = async () => {
    let existingZewstOnline = await client.get(
      `/zewstOnline/findAllByBrand/${brand?.id}`
    );
    existingZewstOnline = existingZewstOnline?.data;
    existingZewstOnline = existingZewstOnline?.zewstOnlines;

    return existingZewstOnline;
  };

  const findExistingOnlineSiteContent = async () => {
    let existingZewstOnlineContent = await client.get(
      `/zewstOnlineContent/findAllByBrand/${brand?.id}`
    );
    existingZewstOnlineContent = existingZewstOnlineContent?.data;
    existingZewstOnlineContent =
      existingZewstOnlineContent?.zewstOnlineContents;

    return existingZewstOnlineContent;
  };

  const handlePublish = async () => {
    try {
      setPublishLoading(true);

      let existingZewstOnline = await findExistingOnlineSite();

      if (existingZewstOnline?.length > 0) {
        await client.post(
          `/zewstOnline/update/${existingZewstOnline?.[0]?.id}`,
          {
            brand_id: existingZewstOnline?.[0]?.brand_id,
            template_id: existingZewstOnline?.[0]?.template_id
          }
        );
      } else {
        await client.post('/zewstOnline/add', {
          brand_id: brand?.id,
          template_id: 1
        });
      }

      existingZewstOnline = await findExistingOnlineSite();
      let existingZewstOnlineContent = await findExistingOnlineSiteContent();
      let alteredPages = { ...pages };

      if (selectedBuilderMode === 'basic') {
        alteredPages = {
          ...pages,
          landing: {
            ...pages.landing,
            productSections: []
          },
          buisnessId: buisnessId,
          loyaltyId,
          brandId: brand?.id,
          branchId: branch?.id
        };
      } else {
        alteredPages = {
          ...pages,
          buisnessId: buisnessId,
          loyaltyId,
          brandId: brand?.id,
          branchId: branch?.id
        };
      }

      if (existingZewstOnlineContent?.length > 0) {
        await client.post(
          `/zewstOnlineContent/update/${existingZewstOnlineContent?.[0]?.id}`,
          {
            zewst_online_id: existingZewstOnline?.[0]?.id,
            meta: 'preview',
            value: alteredPages
          }
        );
      } else {
        await client.post(`/zewstOnlineContent/add`, {
          zewst_online_id: existingZewstOnline?.[0]?.id,
          meta: 'preview',
          value: alteredPages
        });
      }

      Toasts.success('Published successfully');
      setWebsiteBuilder(false);
      setPublishLoading(false);
      navigate('/online-store/domains');
    } catch (error) {
      Toasts.error('Something went wrong, please try again later');
      setPublishLoading(false);
    }
  };

  return (
    <>
      <header className={styles.header}>
        <div className={`contain-bx`}>
          <div className={styles.flex}>
            <div>
              <div className={styles.logo}>
                <Link to={`/dashboard`}>
                  <Ikon logo />
                </Link>
                <RenderIf isTrue={user?.id}>
                  <div className={styles.portfolio}>
                    <Ikon name={`portal`} />
                  </div>
                  <RenderIf isTrue={!websiteBuilder}>
                    <button
                      type={`button`}
                      className={styles.buttonMenu}
                      onClick={() => {
                        setIsWebMenu(!isWebMenu);
                      }}
                    >
                      <Ikon name={`menu`} />
                    </button>
                  </RenderIf>
                  <div className={styles.flexRowG7}>
                    <button
                      type={`button`}
                      className={styles.buttonBack}
                      onClick={() => {
                        if(websiteBuilder) {
                          setWebsiteBuilder(false);
                          navigate('/dashboard');
                        } else {
                          window?.open(
                            `https://portfolio.zewst.com/particularbuisness/${buisnessId}/brands/${brand?.id}/details`,
                            "_blank"
                          );
                        }
                      }}
                    >
                      <Ikon name={`back`} />
                    </button>
                    <div className={styles.flexRowGM5}>
                      <div className={`${styles.wayItem} ${styles.brandItem}`}>
                        <picture>
                          <source srcSet={brand?.banner_image || ''} />
                          <img
                            src={brand?.banner_image || ''}
                            alt={brand?.brand_name}
                          />
                        </picture>
                        <div className={styles.wayTip}>{brand?.brand_name}</div>
                      </div>
                      {/* <div className={`${styles.wayItem} ${styles.brandItem}`}>
                        <picture>
                          <source srcSet={branch?.image || ''} />
                          <img
                            src={branch?.image || ''}
                            alt={branch?.branch_name}
                          />
                        </picture>
                        <div className={styles.wayTip}>
                          {branch?.branch_name}
                        </div>
                      </div> */}
                      <div
                        className={`${styles.wayItem} ${styles.businessItem}`}
                      >
                        {branch?.branch_name?.charAt(0)}
                        <div className={styles.wayTip}>
                          {branch?.branch_name}
                        </div>
                      </div>
                    </div>
                  </div>
                </RenderIf>
                {/* <RenderIf isTrue={websiteBuilder}>
                  <div className={styles.backButton}>
                    <Button
                      label="Back"
                      color="grey"
                      onClick={() => {
                        setWebsiteBuilder(false);
                        navigate('/dashboard');
                      }}
                    />
                  </div>
                </RenderIf> */}
              </div>
            </div>
            <div>
              <div className={styles.headerRight}>
                <RenderIf isTrue={websiteBuilder}>
                  <div className={styles.screen_views}>
                    <button
                      type={`button`}
                      className={styles.mobile_view_button}
                      onClick={() => {
                        setScreenView(`MOBILE_VIEW`);
                      }}
                    >
                      <Ikon
                        name={`mobile-view`}
                        fillColor={
                          screenView === `MOBILE_VIEW`
                            ? `var(--primary)`
                            : `rgba(var(--black-rgb), 0.76)`
                        }
                      />
                    </button>
                    <button
                      type={`button`}
                      className={styles.web_view_button}
                      onClick={() => {
                        setScreenView(`WEB_VIEW`);
                      }}
                    >
                      <Ikon
                        name={`web-view`}
                        fillColor={
                          screenView === `WEB_VIEW`
                            ? `var(--primary)`
                            : `rgba(var(--black-rgb), 0.76)`
                        }
                      />
                    </button>
                  </div>
                  {/* <Button label="Preview" color="grey" onClick={() => {}} /> */}
                  <Button
                    loading={publishLoading}
                    disabled={publishLoading}
                    label="Publish"
                    color="bg-primary"
                    onClick={handlePublish}
                  />
                </RenderIf>
                <div className={styles.buttons}>
                  {/* <button className="ikonlink">
                    <Ikon name="plus2" />
                  </button>
                  <button className="ikonlink">
                    <Ikon name="search" />
                  </button>
                  <button className="ikonlink">
                    <Ikon name="chat" />
                  </button>
                  <button className="ikonlink">
                    <Ikon name="bell" />
                  </button> */}
                  <ButtonDropdown
                    titleChildren={user?.email?.charAt(0)}
                    positionX="rzero"
                  >
                    <button
                      className={styles.logout}
                      onClick={() => handleLogout()}
                    >
                      <span>
                        <Ikon name="logout" />
                      </span>
                      <span>Logout</span>
                    </button>
                  </ButtonDropdown>
                  {/* <button className={`imglink ${styles.account}`}>
                    <picture>
                      <source srcSet={TEMPImgs?.ImgAccount} />
                      <img src={TEMPImgs?.ImgAccount} alt="Account" />
                    </picture>
                  </button> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};
