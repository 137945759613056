import { useFetchDomainSettingsHook } from 'hooks/domain/useFetchDomainSettingsHook';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Elements,
  useStripe,
  useElements,
  CardElement,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { DeploymentClient } from 'utilities/db';
import React, { useEffect, useState } from 'react';
import { RenderIf } from 'utilities/helpers';
import { useForm } from 'react-hook-form';
import { PrimaryButton } from 'components/button';
import { Toasts } from 'components/toasts';
import styles from './index.module.css';

const stripePromise = loadStripe(
  'pk_live_51MJepOBeXyj6EbhuGVmz3wCUC50jOSosXfhLUalay2GkfG3TlSjDi8GOxEZKL6P0mAi1Y8tdvSIzLEWhhpOo1zXp00owFiLyZj'
); // REPLACE WITH YOUR STRIPE PUBLIC KEY

export const BuyDomain = () => {
  const brand = useLocation().state?.brand;

  const [submittingSearchDomain, setSubmittingSearchDomain] = useState(false);
  const [searchedDomain, setSearchedDomain] = useState(null);
  const [selectedDomain, setSelectedDomain] = useState(null);
  const [paidForThisYear, setPaidForThisYear] = useState(false);
  const [checkingUserPaymentForThisYear, setCheckingUserPaymentForThisYear] =
    useState(false);
  const [purchasingDomain, setPurchasingDomain] = useState(false);

  const {
    handleSubmit,
    register,
    formState: { errors }
  } = useForm({
    defaultValues: {
      domainName: ''
    }
  });

  const { domainSettingData, domainSettingRefetch } =
    useFetchDomainSettingsHook(
      `domainSettings/${brand?.id}`,
      brand?.id,
      brand?.brand_name,
      'PRODUCTION'
    );

  const deploymentRecordData = domainSettingData?.existingDeploymentRecord;

  const handleSubmitSearchDomain = async (data) => {
    try {
      setSubmittingSearchDomain(true);
      const response = (
        await DeploymentClient.post(`/deployment/checkDomainAvailability`, {
          domainName: data.domainName?.trim()?.toLowerCase()
        })
      )?.data;
      setSearchedDomain(response);
      setSubmittingSearchDomain(false);
    } catch (error) {
      setSubmittingSearchDomain(false);
      Toasts.error('Error checking domain availability');
    }
  };

  const checkForExistingPayment = async () => {
    setCheckingUserPaymentForThisYear(true);
    const response = (
      await DeploymentClient.get(
        `/deployment/getExistingPurchases?brandId=${brand?.id}&brandName=${brand?.brand_name}&enviorment=PRODUCTION`
      )
    )?.data;
    setPaidForThisYear(response?.payedForThisYear);
    setCheckingUserPaymentForThisYear(false);
  };

  useEffect(() => {
    checkForExistingPayment();
  }, []);

  const handlePurchaseDomain = async (domain) => {
    setPurchasingDomain(true);

    try {
      const purchaseResponse = (
        await DeploymentClient.post(`/deployment/purchaseDomain`, {
          brandId: `${brand?.id}`,
          brandName: brand?.brand_name,
          enviorment: 'PRODUCTION',
          domainName: domain
        })
      )?.data;
      console.log('🚀 ~ handlePay ~ purchaseResponse:', purchaseResponse);
      Toasts.success('Domain purchased successfully');
      setPurchasingDomain(false);
      return true;
    } catch (error) {
      setPurchasingDomain(false);
      Toasts.error(error?.response?.data?.error || error?.message || 'Error');
      return true;
    }
  };

  return (
    <div className={styles.layout}>
      <RenderIf isTrue={!selectedDomain}>
        <header className={styles.header}>
          <div>
            <h2>Buy Domain</h2>
            <h6>Manage, connect and transfer domains.</h6>
          </div>
        </header>
      </RenderIf>

      <RenderIf isTrue={checkingUserPaymentForThisYear}>
        <div>Checking user payment for this year...</div>
      </RenderIf>

      <RenderIf isTrue={!checkingUserPaymentForThisYear && !paidForThisYear}>
        <RenderIf isTrue={!selectedDomain}>
          <form onSubmit={handleSubmit(handleSubmitSearchDomain)}>
            <div className={styles.pageBx}>
              <div className={styles.inputBx}>
                <input
                  type={`text`}
                  name={`domainName`}
                  className={styles.input}
                  placeholder={`Search for a domain`}
                  {...register(`domainName`, { required: true })}
                  // error={
                  //   errors?.domainName?.type === 'required'
                  //     ? 'This field is required'
                  //     : ''
                  // }
                />
                <PrimaryButton
                  type="submit"
                  isLoading={submittingSearchDomain}
                  disabled={submittingSearchDomain}
                  inlineStyle={{ whiteSpace: `nowrap` }}
                >
                  Search now
                </PrimaryButton>
              </div>
              <RenderIf isTrue={!selectedDomain}>
                <RenderIf isTrue={searchedDomain}>
                  <div className={styles.inputBx}>
                    <div>
                      <h6>
                        <b>{searchedDomain?.checkDomainAvailability?.domain}</b>{' '}
                        is{' '}
                        {searchedDomain?.checkDomainAvailability?.available
                          ? 'available'
                          : 'not available'}
                      </h6>
                      <RenderIf
                        isTrue={
                          searchedDomain?.checkDomainAvailability?.available
                        }
                      >
                        <h6>
                          <b>
                            $ {searchedDomain?.checkDomainAvailability?.price}
                          </b>{' '}
                          / {searchedDomain?.checkDomainAvailability?.validFor}
                        </h6>
                      </RenderIf>
                    </div>
                    <div>
                      <RenderIf
                        isTrue={
                          searchedDomain?.checkDomainAvailability?.available
                        }
                      >
                        <PrimaryButton
                          onClick={() => {
                            setSelectedDomain({
                              domain:
                                searchedDomain?.checkDomainAvailability?.domain,
                              price:
                                searchedDomain?.checkDomainAvailability?.price,
                              validFor:
                                searchedDomain?.checkDomainAvailability
                                  ?.validFor
                            });
                          }}
                        >
                          Buy now
                        </PrimaryButton>
                      </RenderIf>
                    </div>
                  </div>
                </RenderIf>

                <RenderIf isTrue={searchedDomain?.suggestedDomains?.length}>
                  <div>
                    {searchedDomain?.suggestedDomains?.map(
                      (suggestedDomain, index) => {
                        return (
                          <React.Fragment key={index}>
                            <RenderIf isTrue={suggestedDomain?.available}>
                              <div className={styles.domainItem}>
                                <h6>{suggestedDomain?.domain}</h6>
                                <div>
                                  <RenderIf isTrue={suggestedDomain?.available}>
                                    <h6>
                                      <b>$ {suggestedDomain?.price}</b> /{' '}
                                      {suggestedDomain?.validFor}
                                    </h6>
                                    <PrimaryButton
                                      onClick={() => {
                                        setSelectedDomain({
                                          domain: suggestedDomain?.domain,
                                          price: suggestedDomain?.price,
                                          validFor: suggestedDomain?.validFor
                                        });
                                      }}
                                      inlineStyle={{
                                        height: 24,
                                        width: 71,
                                        background: `rgba(214, 184, 237, 0.25)`,
                                        border: `#A561D8 1px solid`,
                                        borderRadius: `7.5px`,
                                        color: `#4F1F73`
                                      }}
                                    >
                                      Buy
                                    </PrimaryButton>
                                  </RenderIf>
                                </div>
                              </div>
                            </RenderIf>
                          </React.Fragment>
                        );
                      }
                    )}
                  </div>
                </RenderIf>
              </RenderIf>
            </div>
          </form>
        </RenderIf>

        <RenderIf isTrue={selectedDomain}>
          <div className={styles.checkoutBx}>
            <div>
              <h2>
                <b>Order details</b>
              </h2>
              <div style={{ marginTop: 24 }}></div>
              <div className={styles.card_1}>
                <div className={styles.cardHead}>
                  <div className={styles.wwwBx}>www.</div>
                  <div>
                    <h4>{selectedDomain?.domain}</h4>
                    <div>
                      <h4>$ {selectedDomain?.price}</h4>
                      <h5>/ {selectedDomain?.validFor}</h5>
                    </div>
                  </div>
                </div>
                <div className={styles.cardFoot}>
                  <PrimaryButton
                    onClick={() => {
                      setSelectedDomain(null);
                    }}
                  >
                    Change
                  </PrimaryButton>
                </div>
              </div>
            </div>
            <div></div>
            <div>
              <h4>
                <b>Payment details</b>
              </h4>
              <div style={{ marginTop: 24 }}></div>
              <Elements stripe={stripePromise}>
                <BuyDomainForm
                  brand={brand}
                  selectedDomain={selectedDomain}
                  handlePurchaseDomain={handlePurchaseDomain}
                />
              </Elements>
            </div>
          </div>
        </RenderIf>
      </RenderIf>

      <RenderIf isTrue={paidForThisYear}>
        <div className={styles.pageBx_1}>
          <h4>
            Sorry for the inconvenience, we couldnt purchase your domain
            earlier, please see below
          </h4>
          <div>
            <div>
              <b>{deploymentRecordData?.selectedDomainToPurchase}</b> has
              already been paid for, please click below to make the purchase.
            </div>
            <PrimaryButton
              isLoading={purchasingDomain}
              disabled={purchasingDomain}
              onClick={() => {
                handlePurchaseDomain(
                  deploymentRecordData?.selectedDomainToPurchase
                );
              }}
            >
              You have already paid for this year
            </PrimaryButton>
          </div>
        </div>
      </RenderIf>
    </div>
  );
};

export const BuyDomainForm = ({
  brand,
  selectedDomain,
  handlePurchaseDomain
}) => {
  const navigate = useNavigate();

  const stripe = useStripe();
  const elements = useElements();

  const [submittingStripePayment, setSubmittingStripePayment] = useState(false);

  // console.log('🚀 ~ BuyDomainStripeWrapper ~ brand:', brand, selectedDomain);
  const [loadingStripe, setLoadingStripe] = useState(false);
  const [stripeToken, setStripeToken] = useState(null);

  const getStripeClientSecret = async () => {
    try {
      setLoadingStripe(true);
      const response = (
        await DeploymentClient.post(`/deployment/createStripePaymentIntent`, {
          brandId: `${brand?.id}`,
          brandName: brand?.brand_name,
          enviorment: 'PRODUCTION',
          domainName: selectedDomain?.domain
        })
      )?.data;
      console.log('🚀 ~ getStripeClientSecret ~ response:', response);
      setStripeToken(response?.clientSecret);
      setLoadingStripe(false);
    } catch (error) {
      setLoadingStripe(false);
      console.log('🚀 ~ error', error);
    }
  };

  useEffect(() => {
    getStripeClientSecret();
  }, []);

  const handlePay = async () => {
    setSubmittingStripePayment(true);
    if (!stripe || !elements) {
      return;
    }

    const cardNumberElement = elements?.getElement(CardNumberElement);

    try {
      const { paymentIntent, error } = await stripe.confirmCardPayment(
        stripeToken,
        {
          payment_method: {
            card: cardNumberElement,
            billing_details: {
              name: brand?.brand_name
            }
          }
        }
      );
      if (paymentIntent) {
        await handlePurchaseDomain(selectedDomain?.domain);
        navigate('/online-store/domains');
        setSubmittingStripePayment(false);
      } else if (error) {
        setSubmittingStripePayment(false);
        Toasts.error(error.message);
      }
    } catch (error) {
      setSubmittingStripePayment(false);
      console.log('🚀 ~ error', error);
    }
  };

  if (loadingStripe) {
    return <div>Loading...</div>;
  }

  const inputStyle = {
    // iconColor: '#c4f0ff',
    color: `var(--black)`,
    fontWeight: `500`,
    fontFamily: `'Inter', sans-serif`,
    fontSize: `16px`,
    fontSmoothing: `antialiased`,
    ':-webkit-autofill': {
      color: `var(--black)`
    },
    '::placeholder': {
      color: `var(--grey)`
    }
  };

  return (
    <div className={styles.cardEleBx}>
      {/* <CardElement /> */}
      <div className={styles.cardItem}>
        <CardNumberElement
          options={{
            style: {
              base: inputStyle
            }
          }}
        />
      </div>
      <div className={styles.cardItem}>
        <CardExpiryElement
          options={{
            style: {
              base: inputStyle
            }
          }}
        />
      </div>
      <div className={styles.cardItem}>
        <CardCvcElement
          options={{
            style: {
              base: inputStyle
            }
          }}
        />
      </div>
      <div>
        <PrimaryButton
          disabled={!stripe || submittingStripePayment}
          onClick={handlePay}
        >
          Pay now
        </PrimaryButton>
      </div>
    </div>
  );
};
