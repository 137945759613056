import { BuilderJSON } from 'store';
import { RenderIf } from 'utilities/helpers';
import useWindowDimensions from 'pages/storebuilder/hooks/windowdimensions/fetchWindowDimensions';
import HorizontalSlider, {
  SliderItem
} from 'pages/storebuilder/components/horizontalslider';
import styles from './index.module.css';

export const BuilderThemeHomeProduct = ({ title }) => {
  const { pages, screenView } = BuilderJSON((state) => state);
  const { content, style } = pages?.landing?.productSections?.filter(
    (sec) => sec?.title === title
  )[0];
  const { width } = useWindowDimensions();

  return (
    <>
      <article
        id={title?.split(` `)?.join(`_`)}
        className={styles.article}
        style={{
          backgroundColor: style?.backgroundColor,
          backgroundImage: `url(${style?.backgroundImage})`,
          color: style?.foregroundColor,
          marginTop: `${style?.marginTop}px`,
          marginBottom: `${style?.marginBottom}px`,
          paddingTop: `${style?.paddingTop}px`,
          paddingBottom: `${style?.paddingBottom}px`
        }}
      >
        <div
          className={content?.contentInContainer ? `contBx` : ``}
          style={{
            paddingLeft:
              screenView === `WEB_VIEW` && content?.contentInContainer
                ? `var(--builder-theme-horizontal-padding)`
                : screenView !== `WEB_VIEW` && content?.contentInContainer
                ? `16px`
                : ``,
            paddingRight:
              screenView === `WEB_VIEW` && content?.contentInContainer
                ? `var(--builder-theme-horizontal-padding)`
                : screenView !== `WEB_VIEW` && content?.contentInContainer
                ? `16px`
                : ``
          }}
        >
          <div
            className={content?.layout === 1 ? styles.layout1 : styles.layout1}
          >
            <div
              className={!content?.contentInContainer ? `contBx` : ``}
              style={{
                paddingLeft:
                  screenView === `WEB_VIEW` && !content?.contentInContainer
                    ? `var(--builder-theme-horizontal-padding)`
                    : screenView !== `WEB_VIEW` && !content?.contentInContainer
                    ? `16px`
                    : ``,
                paddingRight:
                  screenView === `WEB_VIEW` && !content?.contentInContainer
                    ? `var(--builder-theme-horizontal-padding)`
                    : screenView !== `WEB_VIEW` && !content?.contentInContainer
                    ? `16px`
                    : ``
              }}
            >
              <RenderIf isTrue={content?.title}>
                <h1 style={{ whiteSpace: `pre-line` }}>{content?.title}</h1>
              </RenderIf>
              <RenderIf isTrue={content?.description}>
                <div style={{ marginTop: 10 }}></div>
                <p style={{ whiteSpace: `pre-line` }}>{content?.description}</p>
              </RenderIf>
            </div>
            <HorizontalSlider
              spaceBetween={16}
              showItems={
                width < 560 || screenView === `MOBILE_VIEW`
                  ? 1
                  : width > 559 && width < 768
                  ? 2
                  : width > 767 && width < 1060
                  ? 3
                  : 4
              }
              itemWidth={
                width < 560 || screenView === `MOBILE_VIEW` ? 324 : 250
              }
            >
              {content?.products?.map((product, index) => (
                <SliderItem key={index}>
                  <picture>
                    <source srcSet={product?.media} />
                    <img src={product?.media} alt={product?.title} />
                  </picture>
                  <h5 style={{ color: style?.foregroundColor }}>
                    {product?.title}
                  </h5>
                  <div className={styles.price}>
                    ${` `}
                    {product?.price}
                  </div>
                  <p>{product?.description}</p>
                </SliderItem>
              ))}
            </HorizontalSlider>
          </div>
        </div>
      </article>
    </>
  );
};
