import { BuilderJSON } from 'store';
import { RenderIf } from 'utilities/helpers';
import useWindowDimensions from 'pages/storebuilder/hooks/windowdimensions/fetchWindowDimensions';
import Carousel, { CarouselItem } from 'pages/storebuilder/components/carousel';
import styles from './index.module.css';

export const BuilderThemeHomeCarousel = ({ title }) => {
  const { pages, screenView } = BuilderJSON((state) => state);
  const { content, style } = pages?.landing?.carouselSections?.filter(
    (sec) => sec?.title === title
  )[0];
  const { width } = useWindowDimensions();

  return (
    <>
      <article
        id={title?.split(` `)?.join(`_`)}
        className={styles.article}
        style={{
          backgroundColor: style?.backgroundColor,
          backgroundImage: `url(${style?.backgroundImage})`,
          color: style?.foregroundColor,
          marginTop: `${style?.marginTop}px`,
          marginBottom: `${style?.marginBottom}px`,
          paddingTop: `${style?.paddingTop}px`,
          paddingBottom: `${style?.paddingBottom}px`
        }}
      >
        <div
          className={content?.contentInContainer ? `contBx` : ``}
          style={{
            paddingLeft:
              screenView === `WEB_VIEW` && content?.contentInContainer
                ? `var(--builder-theme-horizontal-padding)`
                : screenView !== `WEB_VIEW` && content?.contentInContainer
                ? `16px`
                : ``,
            paddingRight:
              screenView === `WEB_VIEW` && content?.contentInContainer
                ? `var(--builder-theme-horizontal-padding)`
                : screenView !== `WEB_VIEW` && content?.contentInContainer
                ? `16px`
                : ``
          }}
        >
          <div
            className={content?.layout === 1 ? styles.layout1 : styles.layout1}
          >
            <Carousel
              showIndicators={
                width > 991 && screenView === `WEB_VIEW` ? true : false
              }
              showPageLabels={
                width > 991 && screenView === `WEB_VIEW` ? true : false
              }
              indicateHorizontalCenter={true}
              pageLVerticalCenter={true}
              indicateRight={`0`}
              indicateBottom={`24px`}
              indicateLeft={`0`}
              pageLTop={`0`}
              pageLRight={`var(--builder-theme-horizontal-padding)`}
              pageLBottom={`0`}
            >
              {content?.slides?.map((item, index) => (
                <CarouselItem key={index}>
                  <div className={styles.slide}>
                    <RenderIf isTrue={content?.contentOverlay}>
                      <div
                        className={styles.overlay}
                        style={{
                          background: style?.overlayBackgroundColor,
                          opacity: style?.overlayOpacity / 100
                        }}
                      ></div>
                    </RenderIf>
                    <picture>
                      <source srcSet={item?.media} />
                      <img src={item?.media} alt={item?.title} />
                    </picture>
                    <div className={styles.content}>
                      <div
                        className={!content?.contentInContainer ? `contBx` : ``}
                        style={{
                          paddingLeft:
                            screenView === `WEB_VIEW` &&
                            !content?.contentInContainer
                              ? `var(--builder-theme-horizontal-padding)`
                              : screenView !== `WEB_VIEW` &&
                                !content?.contentInContainer
                              ? `16px`
                              : ``,
                          paddingRight:
                            screenView === `WEB_VIEW` &&
                            !content?.contentInContainer
                              ? `var(--builder-theme-horizontal-padding)`
                              : screenView !== `WEB_VIEW` &&
                                !content?.contentInContainer
                              ? `16px`
                              : ``
                        }}
                      >
                        <div
                          className={styles.contentInner}
                          style={{ color: style?.foregroundColor }}
                        >
                          <RenderIf isTrue={item?.title}>
                            <h1 style={{ whiteSpace: `pre-line` }}>
                              {item?.title}
                            </h1>
                          </RenderIf>
                          <RenderIf isTrue={item?.description}>
                            <div style={{ whiteSpace: `pre-line` }}>
                              {item?.description}
                            </div>
                          </RenderIf>
                        </div>
                      </div>
                    </div>
                  </div>
                </CarouselItem>
              ))}
            </Carousel>
          </div>
        </div>
      </article>
    </>
  );
};
