import { BuilderJSON } from 'store';
import { RenderIf } from 'utilities/helpers';
import { AsideContentItem } from '../..';
import { BuilderLayoutButton } from 'pages/storebuilder/components/forms/buttons/layout';
import { BuilderSwitchField } from 'pages/storebuilder/components/forms/switch';
import { BuilderTextField } from 'pages/storebuilder/components/forms/input';

export const HeaderContent = () => {
  const {
    pages,
    setHeaderLayout,
    setHeaderShowLogo,
    setHeaderLogoSizeAsGlobal,
    setHeaderLogoWidth,
    setHeaderSticky,
    setHeaderContentInContainer,
    setHeaderRoundedCorners
    // setHeaderSocials
  } = BuilderJSON((state) => state);
  const { content } = pages?.header;

  return (
    <>
      <AsideContentItem label={`Layout`}>
        <div className={`builder-layout-items`}>
          <div>
            <BuilderLayoutButton
              hasTextOnly={true}
              isActive={content?.layout === 1 ? true : false}
              handleOnClick={() => {
                setHeaderLayout(1);
              }}
            >
              1
            </BuilderLayoutButton>
          </div>
          <div>
            {/* <BuilderLayoutButton 
              hasTextOnly={true}
              isActive={content?.layout === 2 ? true : false}
              handleOnClick={() => {
                setHeaderLayout(2);
              }}
            >
              2
            </BuilderLayoutButton> */}
          </div>
        </div>
      </AsideContentItem>
      <AsideContentItem label={`Logo`}>
        <div className={`builder_items`}>
          <BuilderSwitchField
            id={`LOGO_VISIBILITY`}
            label={`Show logo`}
            defaultChecked={content?.showLogo}
            onChange={() => {
              setHeaderShowLogo();
            }}
          />
          <BuilderSwitchField
            id={`LOGO_SIZE_AS_GLOBAL`}
            label={`Logo size as global`}
            defaultChecked={content?.logoSizeAsGlobal}
            onChange={() => {
              setHeaderLogoSizeAsGlobal();
            }}
          />
          <RenderIf isTrue={content?.showLogo && !content?.logoSizeAsGlobal}>
            <BuilderTextField
              type={`number`}
              label={`Logo width`}
              defaultValue={content?.logoWidth}
              onChange={(e) => {
                setHeaderLogoWidth(e?.target?.value);
              }}
            />
          </RenderIf>
        </div>
      </AsideContentItem>
      <AsideContentItem label={`Container`}>
        <div className={`builder_items`}>
          <BuilderSwitchField
            id={`STICKY`}
            label={`Position sticky`}
            defaultChecked={content?.sticky}
            onChange={() => {
              setHeaderSticky();
            }}
          />
          <BuilderTextField
            type={`number`}
            label={`Cornors radius`}
            defaultValue={content?.roundedCorners}
            onChange={(e) => {
              setHeaderRoundedCorners(e?.target?.value);
            }}
          />
          <BuilderSwitchField
            id={`CONTENT_IN_CONTAINER`}
            label={`Content container`}
            defaultChecked={content?.contentInContainer}
            onChange={() => {
              setHeaderContentInContainer();
            }}
          />
        </div>
      </AsideContentItem>
      {/* <AsideContentItem label={`Social Links`}>
        <div className={`builder_items`}>
          <BuilderSwitchField
            id={`SOCIALS_VISIBILITY`}
            label={`Show socials`}
            defaultChecked={content?.socials}
            onChange={() => {
              setHeaderSocials();
            }}
          />
        </div>
      </AsideContentItem> */}
    </>
  );
};
