import { client } from 'utilities/db';
import { useQuery } from 'react-query';

export const useFetchProductHook = (keyName, id) => {
  const { status, data, error, isFetching, isLoading, refetch } = useQuery(
    [keyName],
    () => getProduct(id),
    {
      enabled: !!id,
      refetchInterval: 60000
    }
  );

  return {
    productStatus: status,
    productData: data,
    productError: error,
    productFetching: isFetching,
    productLoading: isLoading,
    productRefetch: refetch
  };
};

const getProduct = async (id) => {
  let result = await client.get(`/product/findOne/${id}`);
  result = result.data;
  result = result?.product;

  return result;
};
