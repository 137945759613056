import { Ikon } from 'assets/icons';
import { Button } from 'components/button';
import { Toasts } from 'components/toasts';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { client } from 'utilities/db';
import { RenderIf } from 'utilities/helpers';
import { AddUpdateSdsScreen } from '../addupdate';

export const SdsDetailScreen = () => {
  const { sds } = useLocation().state;
  const [modal, setModal] = useState(false);
  const [createdDate, setCreatedDate] = useState(null);

  const navigate = useNavigate();

  const updateSdsCode = async (sds) => {
    try {
      await client.post(`/sdsscreen/updateCode/${sds?.id}`);
      Toasts.success('Code updated successfully');
      navigate('/sds');
    } catch (error) {
      Toasts.error(error?.response?.data?.message || 'Something went wrong');
    }
  };

  const getCreatedDateFormat = () => {
    const ndate = new Date(sds?.created_at);
    let yyyy = ndate?.getFullYear();
    let mm = ndate?.getMonth() + 1;
    let dd = ndate?.getDate();
    if (dd < 10) dd = '0' + dd;
    if (mm < 10) mm = '0' + mm;
    const cDate = mm + `/` + dd + `/` + yyyy;
    setCreatedDate(cDate);
  };

  useEffect(() => {
    getCreatedDateFormat();
  }, [sds]);

  return (
    <>
      <article>
        <div className="grid grid_gap30">
          <RenderIf isTrue={sds?.image_url}>
            <div className="collg5">
              <div className="mt_40"></div>
              <picture>
                <source srcSet={sds?.image_url} />
                <img src={sds?.image_url} alt="" />
              </picture>
            </div>
          </RenderIf>
          <div className="collg7">
            <div className="mt_40"></div>
            <p>
              <small>
                Created at: <b>{createdDate}</b>
              </small>
            </p>
            <div className="mt_10"></div>
            <h1>
              <b>{sds?.screen_name}</b>
            </h1>
            <div className="mt_20"></div>
            <hr />
            <div className="mt_30"></div>
            <h1>
              Passcode: <b>{sds?.code}</b>
            </h1>

            <div
              style={{
                marginTop: '20px',
                display: 'flex',
                alignItems: 'center'
              }}
            >
              <Button
                label="Update Code"
                color="bg-titary"
                onClick={() => updateSdsCode(sds)}
              />
              <div style={{ marginLeft: '10px' }}>
                <Button
                  label="Update Details"
                  color="bg-titary"
                  onClick={() => setModal(true)}
                />
              </div>
            </div>
          </div>
        </div>
      </article>
      <AddUpdateSdsScreen
        modal={modal}
        setModal={setModal}
        action="update"
        sds={sds}
      />
    </>
  );
};
