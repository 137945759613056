import { BuilderJSON } from 'store';
import { AsideContentItem } from '../..';
import { BuilderTextField } from 'pages/storebuilder/components/forms/input';
import { BuilderTextareaField } from 'pages/storebuilder/components/forms/textarea';
import { BuilderUploadImage } from 'pages/storebuilder/components/forms/upload';

export const GlobalContent = () => {
  const {
    pages,
    setTitle,
    setDescription,
    setFavicon,
    setLogo,
    setLogoWidth,
    setFacebook,
    setInstagram,
    setTwitter,
    setTiktok,
    setDiscord,
    setSnapchat
  } = BuilderJSON((state) => state);
  const { content } = pages?.global;

  return (
    <>
      <AsideContentItem label={`Page Title`}>
        <div className={`builder_items`}>
          <BuilderTextField
            label={`Describe your store title`}
            defaultValue={content?.title}
            onChange={(e) => {
              setTitle(e?.target?.value);
            }}
          />
        </div>
      </AsideContentItem>
      <AsideContentItem label={`Page Description`}>
        <div className={`builder_items`}>
          <BuilderTextareaField
            label={`Describe your store more`}
            defaultValue={content?.description}
            onChange={(e) => {
              setDescription(e?.target?.value);
            }}
          />
        </div>
      </AsideContentItem>
      <AsideContentItem label={`Favicon`}>
        <div className={`builder_items`}>
          <BuilderUploadImage
            label={`Upload store favicon`}
            mediaRecommendations={`Recommended dimensions are 32 x 32, types are jpg, jpeg, png, gif or svg`}
            defaultImage={content?.favicon}
            setImage={setFavicon}
          />
        </div>
      </AsideContentItem>
      <AsideContentItem label={`Logo`}>
        <div className={`builder_items`}>
          <BuilderUploadImage
            label={`Upload store logo`}
            mediaRecommendations={`Recommended dimensions are 200 x 48, types are jpg, jpeg, png, gif or svg`}
            defaultImage={content?.logo}
            setImage={setLogo}
          />
          <BuilderTextField
            label={`Logo width size`}
            type={`number`}
            defaultValue={content?.logoWidth}
            onChange={(e) => {
              setLogoWidth(e?.target?.value);
            }}
          />
        </div>
      </AsideContentItem>
      <AsideContentItem label={`Social Links`}>
        <div className={`builder_items`}>
          <BuilderTextField
            label={`Twitter`}
            placeholder={`https://www.twitter.com/zewst`}
            defaultValue={content?.social?.twitter}
            onChange={(e) => {
              setTwitter(e?.target?.value);
            }}
          />
          <BuilderTextField
            label={`Discord`}
            placeholder={`https://www.discord.com/zewst`}
            defaultValue={content?.social?.discord}
            onChange={(e) => {
              setDiscord(e?.target?.value);
            }}
          />
          <BuilderTextField
            label={`Instagram`}
            placeholder={`https://www.instagram.com/zewst`}
            defaultValue={content?.social?.instagram}
            onChange={(e) => {
              setInstagram(e?.target?.value);
            }}
          />
          <BuilderTextField
            label={`Facebook`}
            placeholder={`https://www.facebook.com/zewst`}
            defaultValue={content?.social?.facebook}
            onChange={(e) => {
              setFacebook(e?.target?.value);
            }}
          />
          <BuilderTextField
            label={`Snapchat`}
            placeholder={`https://www.snapchat.com/zewst`}
            defaultValue={content?.social?.snapchat}
            onChange={(e) => {
              setSnapchat(e?.target?.value);
            }}
          />
          <BuilderTextField
            label={`TikTok`}
            placeholder={`https://www.tiktok.com/zewst`}
            defaultValue={content?.social?.tiktok}
            onChange={(e) => {
              setTiktok(e?.target?.value);
            }}
          />
        </div>
      </AsideContentItem>
    </>
  );
};
