import { client } from 'utilities/db';
import { useQuery } from 'react-query';

export const useFetchVendorsHook = (keyName, brandId) => {
  const { status, data, error, isFetching, isLoading, refetch } = useQuery(
    [keyName, brandId],
    () => getVendorsData(brandId),
    {
      enabled: !!brandId,
      refetchInterval: 60000
    }
  );

  return {
    vendorStatus: status,
    vendorData: data,
    vendorError: error,
    vendorFetching: isFetching,
    vendorLoading: isLoading,
    vendorRefetch: refetch
  };
};

const getVendorsData = async (brandId) => {
  let result = await client.get(`/vendor/findAllByBrandId/${brandId}`);
  result = result.data;
  result = result?.vendor;

  return result;
};
