import { Button } from 'components/button';
import React, { useEffect, useState } from 'react';
import PrepareToItemAccordion from '../PrepareToItemAccordion';
import styles from './index.module.css';
import Img from '../../../assets/Images/Img.png';
export default ({
  expiringMixtures = [],
  expiringProducts = [],
  expiringRecipes = [],
  setNewBatchModal,
  refetch,
  applyDiscountModal = false,
  setApplyDiscountModal
}) => {
  const [foodWasteType, setFoodWasteType] = useState('mixture');
  const [foodWasteSelectedItem, setFoodWasteSelectedItem] = useState('');
  const [selectedFoodWasteItem, setSelectedFoodWasteItem] = useState('');

  return (
    <>
      <div className="mt_30"></div>

      <div className={styles.block}>
        <div className={styles.head}>
          <h5>Food Waste</h5>

          <div className={styles.btn_con}>
            <Button
              className={styles.btn}
              onClick={() => {
                setFoodWasteType('mixture');
                setFoodWasteSelectedItem('');
              }}
              type="button"
              label={'Mixtures'}
              color={foodWasteType === 'mixture' ? 'bg-button' : 'outline-lite'}
            />

            <Button
              className={styles.btn}
              onClick={() => {
                setFoodWasteType('Raw');
                setFoodWasteSelectedItem('');
              }}
              type="button"
              label={'Raw'}
              color={foodWasteType === 'Raw' ? 'bg-button' : 'outline-lite'}
            />
            <Button
              className={styles.btn}
              onClick={() => {
                setFoodWasteType('Recipes');
                setFoodWasteSelectedItem('');
              }}
              type="button"
              label={'Recipes'}
              color={foodWasteType === 'Recipes' ? 'bg-button' : 'outline-lite'}
            />
          </div>
        </div>

        <div>
          {foodWasteType === 'mixture'
            ? //  expiringMixtures?
              [1, 1, 1].map((item, i) => (
                <PrepareToItemAccordion
                  onClick={() => {
                    if (foodWasteSelectedItem === i) {
                      setFoodWasteSelectedItem('');
                      return;
                    }
                    setFoodWasteSelectedItem(i);
                  }}
                  isOpen={foodWasteSelectedItem === i}
                  image={Img}
                  name={item?.MixtureModel?.mixture_name}
                  stockAvailable={item?.quantity}
                  stockRequired={'3'}
                  foodWaste
                  item={item}
                  unit={item?.unit}
                  setSelectedFoodWasteItem={setSelectedFoodWasteItem}
                  foodWasteItemSelected={selectedFoodWasteItem}
                  setApplyDiscountModal={setApplyDiscountModal}
                  setNewBatchModal={setNewBatchModal}
                  refetch={refetch}
                  foodWasteType={'mixture'}
                />
              ))
            : foodWasteType === 'Raw'
            ? // expiringProducts?
              [1, 1, 1].map((item, i) => (
                <PrepareToItemAccordion
                  onClick={() => {
                    if (foodWasteSelectedItem === i) {
                      setFoodWasteSelectedItem('');
                      return;
                    }
                    setFoodWasteSelectedItem(i);
                  }}
                  isOpen={foodWasteSelectedItem === i}
                  image={Img}
                  name={item?.ProductModel?.product_name}
                  stockAvailable={item?.quantity}
                  stockRequired={'3'}
                  unit={item?.unit}
                  item={item}
                  foodWaste
                  setSelectedFoodWasteItem={setSelectedFoodWasteItem}
                  foodWasteItemSelected={selectedFoodWasteItem}
                  setApplyDiscountModal={setApplyDiscountModal}
                  setNewBatchModal={setNewBatchModal}
                  refetch={refetch}
                  foodWasteType={'raw'}
                />
              ))
            : // expiringRecipes?
              [1, 1, 1, 1].map((item, i) => (
                <PrepareToItemAccordion
                  onClick={() => {
                    if (foodWasteSelectedItem === i) {
                      setFoodWasteSelectedItem('');
                      return;
                    }
                    setFoodWasteSelectedItem(i);
                  }}
                  isOpen={foodWasteSelectedItem === i}
                  image={Img}
                  name={item?.RecipeModel?.recipe_name}
                  stockAvailable={item?.quantity}
                  stockRequired={'3'}
                  foodWaste
                  item={item}
                  unit={item?.unit}
                  setSelectedFoodWasteItem={setSelectedFoodWasteItem}
                  foodWasteItemSelected={selectedFoodWasteItem}
                  setApplyDiscountModal={setApplyDiscountModal}
                  setNewBatchModal={setNewBatchModal}
                  refetch={refetch}
                  expiringRecipe
                  foodWasteType={'recipe'}
                />
              ))}
        </div>
      </div>
    </>
  );
};
