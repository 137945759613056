import React from 'react';
import { BuilderJSON } from 'store';
import { RenderIf } from 'utilities/helpers';
import { AsideContentItem } from '../..';
import { BuilderLayoutButton } from 'pages/storebuilder/components/forms/buttons/layout';
import { BuilderTextField } from 'pages/storebuilder/components/forms/input';
import { BuilderTextareaField } from 'pages/storebuilder/components/forms/textarea';
import { BuilderSwitchField } from 'pages/storebuilder/components/forms/switch';
import { BuilderUploadImage } from 'pages/storebuilder/components/forms/upload';
import { Ikon } from 'assets/icons';

export const FeaturedContent = ({ title }) => {
  const {
    pages,
    setFeaturedSectionLayout,
    setFeaturedSectionTitle,
    setFeaturedSectionSubTitle,
    setFeaturedSectionDescription,
    setFeaturedSectionContentInContainer,
    setAddFeaturedSlide,
    setRemoveFeaturedSlide,
    setFeaturedSlideCategory,
    setFeaturedSlideTitle,
    setFeaturedSlideDescription,
    setFeaturedSlideMedia,
    setRemoveFeaturedSection,
    removeRenderOrderItem
  } = BuilderJSON((state) => state);
  const { content } = pages?.landing?.featuredSections?.filter(
    (sec) => sec?.title === title
  )[0];

  return (
    <>
      <AsideContentItem label={`Layout`}>
        <div className={`builder-layout-items`}>
          <div>
            <BuilderLayoutButton
              hasTextOnly={true}
              isActive={content?.layout === 1 ? true : false}
              handleOnClick={() => {
                setFeaturedSectionLayout(1, title);
              }}
            >
              1
            </BuilderLayoutButton>
          </div>
          <div>
            <BuilderLayoutButton
              hasTextOnly={true}
              isActive={content?.layout === 2 ? true : false}
              handleOnClick={() => {
                setFeaturedSectionLayout(2, title);
              }}
            >
              2
            </BuilderLayoutButton>
          </div>
        </div>
      </AsideContentItem>
      <AsideContentItem label={`Container`}>
        <div className={`builder_items`}>
          <BuilderTextareaField
            label={`Title`}
            defaultValue={content?.title}
            onChange={(e) => {
              setFeaturedSectionTitle(e?.target?.value, title);
            }}
          />
          <BuilderTextareaField
            label={`Subtitle`}
            defaultValue={content?.subTitle}
            onChange={(e) => {
              setFeaturedSectionSubTitle(e?.target?.value, title);
            }}
          />
          <BuilderTextareaField
            label={`Description`}
            defaultValue={content?.description}
            onChange={(e) => {
              setFeaturedSectionDescription(e?.target?.value, title);
            }}
          />
          <BuilderSwitchField
            id={`FEATURED_CONTENT_IN_CONTAINER`}
            label={`Content container`}
            defaultChecked={content?.contentInContainer}
            onChange={() => {
              setFeaturedSectionContentInContainer(title);
            }}
          />
        </div>
      </AsideContentItem>
      {content?.slides?.map((slide, i) => (
        <React.Fragment key={i}>
          <AsideContentItem label={`Slide ${i + 1}`}>
            <div className={`builder_items`}>
              <BuilderTextField
                label={`Category`}
                defaultValue={slide?.category}
                onChange={(e) => {
                  setFeaturedSlideCategory(e?.target?.value, title, i);
                }}
              />
              <BuilderTextareaField
                label={`Title`}
                defaultValue={slide?.title}
                onChange={(e) => {
                  setFeaturedSlideTitle(e?.target?.value, title, i);
                }}
              />
              <BuilderTextareaField
                label={`Description`}
                defaultValue={slide?.description}
                onChange={(e) => {
                  setFeaturedSlideDescription(e?.target?.value, title, i);
                }}
              />
              <BuilderUploadImage
                title={title}
                slideIndex={i}
                label={`Image`}
                mediaRecommendations={`Recommended dimensions are 500 x 500, types are jpg, jpeg, png, gif or svg`}
                defaultImage={slide?.media}
                setImage={setFeaturedSlideMedia}
              />
              <RenderIf isTrue={content?.slides?.length > 1}>
                <div>
                  <button
                    type={`button`}
                    className={`builder-button-link`}
                    color={`gray`}
                    onClick={() => {
                      setRemoveFeaturedSlide(title, i);
                    }}
                  >
                    <Ikon name={`close-x`} fillColor={`var(--grey)`} />
                    <span>remove this slide</span>
                  </button>
                </div>
              </RenderIf>
              <RenderIf isTrue={content?.slides?.length - 1 === i}>
                <div>
                  <button
                    type={`button`}
                    className={`builder-button-link`}
                    color={`primary`}
                    onClick={() => {
                      setAddFeaturedSlide(title);
                    }}
                  >
                    <Ikon name={`builder-plus`} fillColor={`var(--primary)`} />
                    <span>add another slide</span>
                  </button>
                </div>
              </RenderIf>
            </div>
          </AsideContentItem>
        </React.Fragment>
      ))}
      <AsideContentItem label={`Remove section`}>
        <div className={`builder_items`}>
          <div>
            <button
              type={`button`}
              className={`builder-button-link`}
              color={`gray`}
              onClick={() => {
                setRemoveFeaturedSection(title);
                removeRenderOrderItem(title);
              }}
            >
              <Ikon name={`close-x`} fillColor={`var(--grey)`} />
              <span>remove this section</span>
            </button>
          </div>
        </div>
      </AsideContentItem>
    </>
  );
};
