export const RenderIf = ({ children, isTrue }) => {
  return isTrue ? children : null;
};

export const getTimeFromDate = (date) => {
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');
  const seconds = date.getSeconds().toString().padStart(2, '0');
  const time = `${hours}:${minutes}:${seconds}`;
  return time;
};

export const converTimeToDate = (time) => {
  const [hours, minutes, seconds] = time.split(':');
  const date = new Date();
  date.setHours(hours);
  date.setMinutes(minutes);
  date.setSeconds(seconds);
  return date;
};
