import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { client } from 'utilities/db';
import { RenderIf } from 'utilities/helpers';
import { Toasts } from 'components/toasts';
import { TextField } from 'components/input';
import { Button } from 'components/button';
import Frame from '../../../assets/Images/Frame.png';
import account from '../../../assets/Images/account.png';
import Cat from '../../../assets/Images/categry.png';
import { Ikon } from 'assets/icons';

function SelectedKDSPage() {
  const brand = useLocation().state?.brand;
  const branch = useLocation().state?.branch;
  const categories = useLocation().state?.categories;

  const navigate = useNavigate();

  const [kdsName, setKdsName] = React.useState('');

  const submitData = async () => {
    try {
      const response = (
        await client.post(`/kdsscreen/add`, {
          name: kdsName,
          device_info: {
            value: 'uwsnuqwna'
          },
          status: 'ONLINE',
          filters: [
            {
              brand_id: brand?.id,
              branches: [
                {
                  branch_id: branch?.id,
                  branch_type: brand?.brand_type,
                  categories: categories?.map((item) => item.id)
                }
              ]
            }
          ]
        })
      )?.data;
      Toasts.success(response?.message || 'Successfully added KDS Screen');
      navigate('/kds-listing');
    } catch (error) {
      Toasts.error(error?.response?.data?.message);
    }
  };

  return (
    <>
      <div className={`mt_40`}></div>
      <div className={`flex item_space_btn pt_10`}>
        <div>
          <h1>
            <strong>Your Selection</strong>
          </h1>
          <h5 className={`mt_4`}>Lorem ipsum dolor ist meunda.</h5>
        </div>
      </div>
      <div className={`grid grid_gap30`}>
        <div className={`collg4`}>
          <div className={`mt_20`}></div>
          <h3>
            <strong>Brand</strong>
          </h3>
          <div className={`mt_10`}></div>
          <button
            className={`card_item3 card_item3_md active`}
            onClick={() => {}}
          >
            <div>
              <picture>
                <RenderIf isTrue={!brand?.image_url}>
                  <div className={`placeholder`}>
                    <Ikon name={`imgplaceholder`} />
                  </div>
                </RenderIf>
                <RenderIf isTrue={brand?.image_url}>
                  <source srcSet={brand?.image_url} />
                  <img src={brand?.image_url} alt="" />
                </RenderIf>
              </picture>
              <div className={`mt_15`}></div>
              <h4>
                <strong>{brand?.brand_name}</strong>
              </h4>
            </div>
          </button>
        </div>
        <div className={`collg4`}>
          <div className={`mt_20`}></div>
          <h3>
            <strong>Branch</strong>
          </h3>
          <div className={`mt_10`}></div>
          <button className={`branch_card1 active`} onClick={() => {}}>
            <div
              className={'picture'}
              style={{
                backgroundImage: `url(${Frame})`
              }}
            ></div>
            <div className={`branch_content1`}>
              <p>{branch.heading}</p>
              <div className={`mt_10`}></div>
              <div className={`branch_manager`}>
                <picture>
                  <source srcSet={account} />
                  <img src={account} alt="" />
                </picture>
                <div>
                  <div>
                    <b>{branch?.branch_name}</b>
                  </div>
                </div>
              </div>
            </div>
          </button>
        </div>
        <div className={`collg4`}>
          <div className={`mt_20`}></div>
          <h3>
            <strong>Categories</strong>
          </h3>
          {categories?.map((cat, index) => (
            <div key={index} className={`mt_10`}>
              <button
                className={`card_item1 card_item1_md  
                ${
                  categories?.length > 0 &&
                  categories?.find((c) => c?.id === cat?.id) &&
                  'active'
                }
              `}
                style={{
                  borderLeft: `6px solid`,
                  borderLeftColor: `${
                    index === 0
                      ? '#8d23dd'
                      : index === 1
                      ? '#6361d8'
                      : index === 2
                      ? '#d8618c'
                      : ''
                  }`
                }}
              >
                <picture>
                  <RenderIf isTrue={!Cat}>
                    <div className={`placeholder`}>
                      <Ikon name={`imgplaceholder`} />
                    </div>
                  </RenderIf>
                  <RenderIf isTrue={Cat}>
                    <source srcSet={Cat} />
                    <img src={Cat} alt="" />
                  </RenderIf>
                </picture>
                <div className={`card_item1_box`}>
                  <h6>
                    <span className={`ff_semi`}>{cat?.category_name}</span>
                  </h6>
                </div>
              </button>
            </div>
          ))}
        </div>
      </div>
      <div className={`mt_40`}></div>
      <div className={`pt_10`}></div>
      <h4>
        <span className={`ff_semi`}>Confirmation</span>
      </h4>
      <div className={`mt_20`}></div>
      <div className={`kds_footer1`}>
        <div>
          <TextField
            name="kds"
            placeholder="KDS Name"
            onChange={(e) => setKdsName(e.target.value)}
            value={kdsName}
          />
        </div>
        <Button
          label="Confirm and Continue"
          color="bg-titary"
          onClick={submitData}
          disabled={kdsName.length < 3}
        />
      </div>
      <div className="mt_25"></div>
    </>
  );
}

export default SelectedKDSPage;
