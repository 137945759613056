import { PortfolioPage } from 'pages/portfolio';
import { AddUpdateBrandPage } from 'pages/portfolio/brand/addupdate';
import { PortfolioBrandDetailsPage } from 'pages/portfolio/brand/details';
import { PortfolioBranchesAddUpdatePage } from 'pages/portfolio/branches/addupdate';
import { PortfolioBranchDetailsPage } from 'pages/portfolio/branches/details';
import { PortfolioAddManagerPage } from 'pages/portfolio/brand/managers';
import { PortfolioAddBranchManagerPage } from 'pages/portfolio/branches/managers';
import { PortfolioAddCompanyPage } from 'pages/portfolio/brand/company';

export const PortfolioView = () => {
  return <PortfolioPage />;
};

export {
  AddUpdateBrandPage,
  PortfolioBrandDetailsPage,
  PortfolioBranchesAddUpdatePage,
  PortfolioBranchDetailsPage,
  PortfolioAddManagerPage,
  PortfolioAddBranchManagerPage,
  PortfolioAddCompanyPage
};
