import { Button } from 'components/button';
import { ImageCropUploader } from 'components/imagecropuploader';
import ImageDropzone from 'components/imageDropzone/imageDropzone';
import { TextField } from 'components/input';
import { ModalComponent } from 'components/modal';
import { Toasts } from 'components/toasts';
import { useFetchSds } from 'hooks/sds/useFetchSds';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStore } from 'store';
import { client } from 'utilities/db';

export const AddUpdateSdsScreen = ({ modal, setModal, action, sds }) => {
  const navigate = useNavigate();

  const [uploadImage, setUploadImage] = useState('');
  const [uploadImageLoading, setUploadImageLoading] = useState(false);
  const [screenName, setScreenName] = useState(sds?.screen_name || '');
  const { brand, branch } = useStore((state) => state);

  const { sdsRefetch } = useFetchSds(`sds/${branch?.id}`, branch?.id);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'screenName') {
      setScreenName(value);
    }
  };

  const handleSave = async () => {
    if (!screenName || !uploadImage) {
      Toasts.error('Please add screen name and image');
      return;
    }
    try {
      await client.post('/sdsscreen/add', {
        brand_id: brand?.id,
        branch_id: branch?.id,
        image_url: uploadImage,
        screen_name: screenName
      });
      sdsRefetch();
      Toasts.success('Screen added successfully');
      setModal(false);
      navigate('/sds');
    } catch (error) {
      Toasts.error(error?.response?.data?.message || 'Something went wrong');
    }
  };

  const handleUpdate = async () => {
    if (!screenName || !uploadImage) {
      Toasts.error('Please add screen name and image');
      return;
    }
    try {
      await client.post(`/sdsscreen/update/${sds?.id}`, {
        brand_id: sds?.brand_id,
        branch_id: sds?.branch_id,
        image_url: uploadImage || sds?.image_url,
        screen_name: screenName
      });
      sdsRefetch();
      Toasts.success('Screen updated successfully');
      setModal(false);
      navigate('/sds');
    } catch (error) {
      Toasts.error(error?.response?.data?.message || 'Something went wrong');
    }
  };

  return (
    <ModalComponent
      modal={modal}
      handleModal={() => setModal(false)}
      modalMiddle={false}
      rightModal={true}
    >
      <div className="mt_40"></div>
      {/* <ImageDropzone
        customComponent={
          <div className="flex item_hcenter text_center">
            <div>
              <h4 color="greylite">Drag &amp; Drop to Upload Screen Image</h4>
              <h4 color="greylite" className="mt_16">
                OR
              </h4>
              <Button
                type="button"
                className="button_browse mt_16"
                label={'Browse File'}
              />
            </div>
          </div>
        }
        setIsLoading={setUploadImageLoading}
        image={uploadImage}
        setImage={setUploadImage}
        large={true}
      /> */}

      <ImageCropUploader imageSrc={uploadImage} handleImage={setUploadImage} />

      <div className="mt_40"></div>
      <TextField
        name="screenName"
        label={'Screen Name *'}
        placeholder="Screen 1"
        type="text"
        value={screenName}
        onChange={handleChange}
      />
      <Button
        type="button"
        className="button_browse mt_16"
        label={action === 'update' ? 'Update' : 'Save'}
        onClick={action === 'update' ? handleUpdate : handleSave}
      />
    </ModalComponent>
  );
};
