import { useEffect, useState } from 'react';
import { RenderIf } from 'utilities/helpers';
import { AddRecipePage } from './add';
import { RecipeDetail } from './detail';
import { EngineeringRecipe } from 'pages/engineeringrecipe';

export const RecipeEngineering = ({ steps, isMixture }) => {
  const [currentStep, setSteps] = useState();
  const [selectedModifiers, setSelectedModifiers] = useState({});
  const [selectedMixture, setSelectedMixture] = useState({});
  const [selectedRecipe, setSelectedRecipe] = useState({});
  const onChangeModifiers = (modifiers) => setSelectedModifiers(modifiers);
  const onChangeStep = (step) => setSteps(step);
  useEffect(() => {
    setSteps(steps);
  }, [steps]);
  const onEdit = (item) => {
    if (isMixture) {
      setSelectedMixture(item);
      setSteps(4);
      return;
    }
    setSteps(2);
    setSelectedRecipe(item);
  };

  if (currentStep === '') return null;

  return (
    <>
      <RenderIf isTrue={currentStep === 1}>
        <EngineeringRecipe
          onChangeStep={onChangeStep}
          onChangeModifiers={onChangeModifiers}
        />
      </RenderIf>
      <RenderIf isTrue={currentStep === 2}>
        <AddRecipePage
          selectedData={selectedRecipe}
          onChangeStep={onChangeStep}
          modifier={selectedModifiers}
        />
      </RenderIf>
      <RenderIf isTrue={currentStep === 4}>
        <AddRecipePage
          selectedData={selectedMixture}
          onChangeStep={onChangeStep}
          isMixture
        />
      </RenderIf>
      <RenderIf isTrue={currentStep === 3 && selectedModifiers}>
        <RecipeDetail
          onEdit={onEdit}
          onChangeStep={onChangeStep}
          selectedModifiers={selectedModifiers}
        />
      </RenderIf>
      <RenderIf isTrue={currentStep === 5}>
        <RecipeDetail
          onEdit={onEdit}
          isMixture
          onChangeStep={onChangeStep}
          selectedModifiers={selectedMixture}
        />
      </RenderIf>
    </>
  );
};

// const TableComponent = ({ columns, rows }) => {
//   return (
//     <table cellPadding={0} cellSpacing={0} className="table">
//       <thead>
//         <tr>
//           {columns?.map((column, index) => (
//             <th style={{ margin: '10px' }} key={index}>
//               {column}
//             </th>
//           ))}
//         </tr>
//       </thead>
//       <tbody>
//         {rows?.map((row, index) => (
//           <tr key={index}>
//             <td key={index}>{row?.name}</td>
//             <td key={index}>{row?.noIngredients}</td>
//             <td key={index}>{row?.time}</td>
//           </tr>
//         ))}
//       </tbody>
//     </table>
//   );
// };
