import { useState } from 'react';
import { RenderIf } from 'utilities/helpers';
import { AddMenu } from 'pages/addmenu';
import { AddMenuMethods } from 'pages/addmenu/methods';
import { ImportMenuMethods } from 'pages/addmenu/import';
import { LinkSquareAccount } from 'pages/addmenu/linksqaure';
import { LinkExternalAccount } from 'pages/addmenu/linkexternal';
import { MainCategoryList } from 'pages/addmenu/category';
import { UploadMenuTemplate } from 'pages/addmenu/upload';
import { getLocationId } from 'utilities/helpers/auth';
import { ChooseBranchsForMenu } from 'pages/addbranch/choose';

export const AddMenuView = () => {
  const [branchArr, setBranchArr] = useState([]);
  const locationId = getLocationId();
  const [step, setStep] = useState(1);
  const onChangeStep = (step) => {
    setStep(step);
  };
  const onGoBack = () => {
    if (step === 1) return;
    setStep(step - 1);
  };
  return (
    <>
      <RenderIf isTrue={step === 1}>
        <AddMenu onChangeStep={onChangeStep} onGoBack={onGoBack} />
      </RenderIf>
      <RenderIf isTrue={step === 2}>
        <AddMenuMethods onGoBack={onGoBack} onChangeStep={onChangeStep} />
      </RenderIf>
      <RenderIf isTrue={step === 3}>
        <ImportMenuMethods onGoBack={onGoBack} onChangeStep={onChangeStep} />
      </RenderIf>
      <RenderIf isTrue={step === 4}>
        <LinkSquareAccount
          locationId={locationId}
          onGoBack={onGoBack}
          onChangeStep={onChangeStep}
        />
      </RenderIf>
      <RenderIf isTrue={step === 5}>
        <LinkExternalAccount
          locationId={locationId}
          onGoBack={onGoBack}
          onChangeStep={onChangeStep}
        />
      </RenderIf>
      <RenderIf isTrue={step === 6}>
        <MainCategoryList
          locationId={locationId}
          onGoBack={onGoBack}
          onChangeStep={onChangeStep}
          branchArr={branchArr}
        />
      </RenderIf>
      <RenderIf isTrue={step === 7}>
        <UploadMenuTemplate onGoBack={onGoBack} onChangeStep={onChangeStep} />
      </RenderIf>
      <RenderIf isTrue={step === 8}>
        <ChooseBranchsForMenu
          onChangeStep={onChangeStep}
          locationId={locationId}
          branchArr={branchArr}
          setBranchArr={setBranchArr}
        />
      </RenderIf>
    </>
  );
};
