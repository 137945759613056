import { Button } from 'components/button';
import { TextField } from 'components/input';
import { ModalComponent } from 'components/modal';
import { RadioButton } from 'components/radiobutton';
import { SelectDropdown } from 'components/selectdropdown';
import { Toasts } from 'components/toasts';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import useForceUpdate from 'use-force-update';
import { client } from 'utilities/db';
import { allUnits, getBranchId, getLocationId } from 'utilities/helpers/auth';
import styles from './index.module.css';

export const OverridePrediction = ({
  overridePrediction = false,
  setOverridePrediction = () => {},
  onApplyOverride = () => {}
}) => {
  const [predictedList, setPredictedList] = useState([
    {
      forecast: 233,
      date: '23 Aug, 2022',
      quantity: ''
    },
    {
      forecast: 877,
      date: '23 Aug, 2022',
      quantity: ''
    },
    {
      forecast: 133,
      date: '23 Aug, 2022',
      quantity: ''
    }
  ]);
  const onChangePredictionQuantity = (quantity, i) => {
    const pred = [...predictedList];

    pred[i] = { ...pred[i], quantity };

    setPredictedList(pred);
  };
  return (
    <ModalComponent
      modal={overridePrediction}
      handleModal={() => setOverridePrediction(!overridePrediction)}
      chooseOption
    >
      <h1>Chicken Pizza</h1>
      <h6>Override prediction.</h6>
      <div className="mt_20"></div>
      <div className="mt-4 p-3">
        <div style={{ display: 'flex' }}>
          <div style={{}}>Forecasted</div>
          <div style={{ marginLeft: 22 }}>Date</div>
          <div style={{ marginLeft: 100 }}>Quantity</div>
        </div>
        {predictedList.map((item, i) => (
          <div key={i} className={styles.head} style={{ marginTop: 10 }}>
            <div className="w-[150px]">{item?.forecast}</div>
            <div className="w-[150px]" style={{ marginLeft: 30 }}>
              {item?.date}
            </div>
            <div className="w-[150px]">
              <TextField
                name="quantity"
                id="quantity"
                type="number"
                placeholder="Quantity"
                onChange={(val) =>
                  onChangePredictionQuantity(val.target.value, i)
                }
                value={item.quantity}
                fieldSm
              />
            </div>
          </div>
        ))}
        <br />
        <div className={styles.btn_div}>
          <Button
            onClick={() => setOverridePrediction(!overridePrediction)}
            label={'Cancel'}
            color="outline-lite"
          />
          <Button
            onClick={onApplyOverride}
            type="submit"
            label={'Override'}
            color="bg-button"
          />
        </div>
      </div>
    </ModalComponent>
  );
};

export const BatchModal = ({
  openModal = false,
  setOpenModal = () => {},
  isMixture,
  onCloseNewBatchModal = () => {},
  refetch,
  item
}) => {
  const brandId = getLocationId();
  const branchId = getBranchId();
  const forceUpdate = useForceUpdate();

  const [servings, setServings] = useState('');
  const [itemUnit, setItemUnit] = useState('');
  const [preparedDate, setPreparedDate] = useState('');
  const [expiryDate, setExpiryDate] = useState('');
  const isDisabled = isMixture
    ? servings.trim() === '' ||
      itemUnit.trim() === '' ||
      preparedDate.trim() === '' ||
      expiryDate.trim() === ''
    : servings.trim() === '' ||
      preparedDate.trim() === '' ||
      expiryDate.trim() === '';
  const onApplyNewBatch = () => {
    if (isDisabled) {
      Toasts.error('Kindly fill all fields');
      return;
    }
    if (isMixture) {
      const data = {
        brand_id: brandId,
        branch_id: branchId,
        mixture_id: item?.id,
        quantity: servings,
        unit: 'kg',
        prepared_at: moment(preparedDate).format('YYYY-MM-DD'),
        expired_at: moment(expiryDate).format('YYYY-MM-DD'),
        best_before: moment(expiryDate).format('YYYY-MM-DD')
      };
      mixtueMutate(data);
      return;
    }
    const data = {
      brand_id: brandId,
      branch_id: branchId,
      recipe_id: item?.id,
      quantity: servings,
      prepared_at: moment(preparedDate).format('YYYY-MM-DD'),
      expired_at: moment(expiryDate).format('YYYY-MM-DD'),
      best_before: moment(expiryDate).format('YYYY-MM-DD')
    };
    mixtueMutate(data);
  };
  const {
    mutate: mixtueMutate,
    isSuccess: mixtureISucces,
    isError: mixtureError
  } = useMutation((userData) => {
    return client.post(`/mixtureInventories/add`, userData);
  });
  useEffect(() => {
    if (!mixtureISucces) return;
    onCloseNewBatchModal();
    refetch();
    Toasts.success('Serving added successfully');
    forceUpdate();
  }, [mixtureISucces]);
  useEffect(() => {
    if (!mixtureError) return;
    Toasts.error('some error occoured, please try again later');
  }, [mixtureError]);
  return (
    <ModalComponent
      modal={openModal}
      handleModal={onCloseNewBatchModal}
      chooseOption
    >
      <h1>New Batch</h1>
      <h6>This is a supportive text.</h6>
      {/* <br />*/}
      {/* <SelectDropdown
        label="Food Item Name"
        data={[
          { value: '', label: 'choose option' },
          ...item?.recipeIngredients.map((item) => ({
            value: item?.ProductModel?.product_name?.toUpperCase(),
            label: item?.ProductModel?.id
          }))
        ]}
        onChange={(e) => setSelectedFoodItem(e.target.value)}
        value={selectedFoodItem}
      /> */}
      <br />
      <TextField
        name="numberOfServings"
        id="numberOfServings"
        type="number"
        label={`Enter Number of Servings`}
        placeholder="100"
        onChange={(val) => setServings(val.target.value)}
        value={servings}
      />
      {isMixture && (
        <>
          <br />
          <SelectDropdown
            label="Unit"
            data={[
              { value: '', label: 'choose option' },
              ...allUnits.map((item) => ({
                value: item,
                label: item
              }))
            ]}
            onChange={(e) => setItemUnit(e.target.value)}
            value={itemUnit}
          />
        </>
      )}
      <br />
      <TextField
        name="preparedDate"
        id="preparedDate"
        type="date"
        label={`Prepared Date`}
        placeholder="22/02/2022"
        onChange={(val) => setPreparedDate(val.target.value)}
        value={preparedDate}
      />
      <br />
      <TextField
        name="expiryDate"
        id="expiryDate"
        type="date"
        label={`Expiry Date`}
        placeholder="22/02/2022"
        onChange={(val) => setExpiryDate(val.target.value)}
        value={expiryDate}
      />
      <br />
      {/* <div className="rounded-full p-1 px-3 border gap-2 flex">
              <img src={InfoCirclePurple} />
              <p>50% Discount has been applied to this item.</p>
            </div>
            <br />
            <CheckboxField
              name="applyDiscountToBatch"
              id="applyDiscountToBatch"
              value={applyDisount}
              onChange={() => setApplyDisount(!applyDisount)}
            >
              Apply discount to this batch as well.
            </CheckboxField>
            <RenderIf isTrue={applyDisount}>
              <div className="mt-4">
                <h6>Select Discount Type</h6>
                <RadioButton
                  wrapClass="radio_rows"
                  labels={[
                    {
                      name: 'Flat',
                      id: 'Flat',
                      value: true,
                      label: 'Flat',
                      checked: discountType
                    },
                    {
                      name: 'Progressive',
                      id: 'Progressive',
                      value: false,
                      label: 'Progressive',
                      checked: !discountType
                    }
                  ]}
                  onChange={(val) => setDiscountType(val.target.value)}
                />
                <br />
                {discountType ? (
                  <TextField
                    type="number"
                    value={discountPercentage}
                    onChange={(e) => setDiscountPercentage(e.target.value)}
                    placeholder="Discount Percentage"
                  />
                ) : (
                  <div className="flex align-items-center justify-content-between gap-2">
                    <TextField
                      type="number"
                      value={startDiscountPercentage}
                      onChange={(e) =>
                        setStartDiscountPercentage(e.target.value)
                      }
                      placeholder="Starting Discount Percentage"
                    />
                    <TextField
                      type="number"
                      value={endDiscountPercentage}
                      onChange={(e) =>
                        setEndDiscountPercentage(e.target.value)
                      }
                      placeholder="Ending Discount Percentage"
                    />
                  </div>
                )}
              </div>
            </RenderIf>
            <br /> */}
      <div className="flex align-items-end justify-content-end">
        <Button
          onClick={onCloseNewBatchModal}
          label={'Cancel'}
          color="outline-lite"
        />
        <Button onClick={onApplyNewBatch} label={'Save'} color="bg-button" />
      </div>
    </ModalComponent>
  );
};

export const DiscountModal = ({
  expiringRecipe,
  applyDiscountModal = false,
  onCloseApplyDiscountModal = () => {},
  discountType = true,
  setDiscountType = () => {},
  discountPercentage = '',
  setDiscountPercentage = () => {},
  startDiscountPercentage = '',
  setStartDiscountPercentage = () => {},
  endDiscountPercentage = '',
  setEndDiscountPercentage = () => {},
  onApplyDiscountOnRecipe = () => {},
  onApplyDiscountOthers = () => {},
  foodWasteItem = null
}) => {
  return (
    <>
      <ModalComponent
        modal={applyDiscountModal}
        handleModal={onCloseApplyDiscountModal}
        chooseOption
      >
        <h1>Apply Discount</h1>
        <br />
        <RadioButton
          wrapClass="radio_rows"
          labels={[
            {
              name: 'Flat',
              id: 'Flat',
              value: true,
              label: 'Flat',
              checked: discountType && discountType !== 'false'
            },
            {
              name: 'Progressive',
              id: 'Progressive',
              value: false,
              label: 'Progressive',
              checked: !discountType || discountType === 'false'
            }
          ]}
          onChange={(val) => {
            setDiscountType(val.target.value);
          }}
        />
        <br />
        {discountType && discountType !== 'false' ? (
          <TextField
            type="number"
            value={discountPercentage}
            onChange={(e) => setDiscountPercentage(e.target.value)}
            placeholder="Discount Percentage"
          />
        ) : (
          <div className=" align-items-center justify-content-between gap-2">
            <TextField
              type="number"
              value={startDiscountPercentage}
              onChange={(e) => setStartDiscountPercentage(e.target.value)}
              placeholder="Starting Discount Percentage"
            />
            <TextField
              type="number"
              value={endDiscountPercentage}
              onChange={(e) => setEndDiscountPercentage(e.target.value)}
              placeholder="Ending Discount Percentage"
            />
          </div>
        )}
        <br />
        <div className="flex item_vcenter" style={{ marginLeft: '45%' }}>
          <Button
            onClick={onCloseApplyDiscountModal}
            label={'Cancel'}
            color="outline-lite"
          />
          <Button
            style={{ width: '150px' }}
            onClick={
              expiringRecipe
                ? () => onApplyDiscountOnRecipe()
                : () => onApplyDiscountOthers(foodWasteItem?.ModifierModel?.id)
            }
            type="submit"
            label={'Apply Discount'}
            color="bg-button"
          />
        </div>
      </ModalComponent>
    </>
  );
};
