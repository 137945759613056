import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { TextField } from 'components/input';
import { TextArea } from 'components/textarea';
import { Button } from 'components/button';
import { CheckboxField } from 'components/checkbox';
import { Ikon } from 'assets/icons';
import { useMutation } from 'react-query';
import { client } from 'utilities/db';
import ImageDropzone from 'components/imageDropzone/imageDropzone';
import { CustomSelectDropdown } from 'components/select/multi';
import { days, useFetchCategories } from 'hooks/resourceHooks';
import { getLocationId } from 'utilities/helpers/auth';

export const AddMenuItem = ({
  setMenuStep,
  branchArr,
  branchesSameMenu,
  selectedCategory,
  editableId,
  selectedMenu,
  brands,
  menusRefetch
}) => {
  const { mutate, isLoading, isSuccess, isError, error } = useMutation(
    (userData) => {
      return client.post(
        editableId ? `/menuItem/update/${selectedMenu?.id}` : `/menuItem/add`,
        userData
      );
    }
  );
  const locationId = getLocationId();
  const [linkedResturant, setLinkedResturant] = useState([]);
  const [selectedCategoriesList, setSelectedCategoriesList] = useState([]);
  const [categoryImg, setCategoryImg] = useState('');
  const [imageLoading, setImageLoading] = useState(false);
  const { data: allCategories } = useFetchCategories();
  const [selectedCategoryTypes, setSelectedCategoryTypes] = useState([]);
  const [selectedDays, setSelectedDays] = useState([]);
  const resetForm = () => {
    reset();
    setLinkedResturant([]);
    setSelectedCategoriesList([]);
    setCategoryImg('');
    setImageLoading(false);
    setSelectedCategoryTypes([]);
    setSelectedDays([]);
  };
  const {
    handleSubmit,
    register,
    formState: { errors },
    reset
  } = useForm({
    defaultValues: {
      name: selectedMenu?.item,
      description: selectedMenu?.description,
      price: selectedMenu?.price,
      eatInPrice: selectedMenu?.price,
      takeAwayPrice: selectedMenu?.cost,
      defaultQuantity: selectedMenu?.quantity || '',
      calorie: selectedMenu?.calorie || ''
    }
  });

  const onSubmitMenuItem = (val) => {
    mutate({
      menu_item_name: val?.name,
      cost: val?.price || 0,
      base_price: val?.price || val?.eatInPrice,
      category_id: selectedCategory?.id,
      menu_item_description: val?.description,
      brand_id: locationId,
      image: categoryImg,
      type: val?.type,
      branch_ids: branchesSameMenu ? null : branchArr,
      quantity_threshold: val?.defaultQuantity,
      menu_item_level: branchesSameMenu ? 'BRAND' : 'BRANCH',
      taxable: val?.taxable === 'false' ? false : val?.taxable,
      category_types: selectedCategoryTypes?.map((item) => item?.value),
      quantity_options: {
        force_quantity:
          val?.forceQuantity === 'false' ? false : val?.forceQuantity,
        lock_quantity:
          val?.lockQuantity === 'false' ? false : val?.lockQuantity,
        hide_quantity: val?.hideQuantity === 'false' ? false : val?.hideQuantity
      },
      menu_status: true,
      sale_channels: {
        pos: val?.pos === 'false' ? false : val?.pos,
        online_store: val?.online_store === 'false' ? false : val?.online_store,
        others: val?.online_order === 'false' ? false : val?.online_order
      },
      available_days: selectedDays?.map((item) => ({ [item?.value]: true })),
      available_time: {
        start_time: '',
        end_time: ''
      }
    });
  };

  useEffect(() => {
    if (!isError) return;
  }, [isError]);

  useEffect(() => {
    if (!isSuccess) return;
    menusRefetch();
    resetForm();
  }, [isSuccess, menusRefetch]);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmitMenuItem)}>
        <div className="onheader1">
          <button
            type="submit"
            className="ikonlink"
            color="primary"
            onClick={() => setMenuStep(1)}
          >
            <span>
              <Ikon backArrow />
            </span>
            <span>Back to Categories</span>
          </button>
          <div className="flex item_vcenter">
            <Button
              onClick={() => setMenuStep(1)}
              label={'Cancel'}
              color="outline-lite"
            />
            <Button
              disabled={imageLoading || isLoading}
              isLoading={isLoading}
              type="submit"
              label={'Save'}
              color="bg-button"
            />
          </div>
        </div>
        <div className="pt_10"></div>
        <div>
          <ImageDropzone
            setIsLoading={setImageLoading}
            image={categoryImg}
            setImage={setCategoryImg}
          />
          <div className="mt_15"></div>
          <TextField
            name="name"
            label={'Menu Item Name'}
            type="text"
            error={
              errors?.name?.type === 'required'
                ? 'Menu item name is required'
                : ''
            }
            validation={{
              required: true
            }}
            register={register}
            required
          />
          <div className="mt_15"></div>
          <TextArea
            name="description"
            label={'Description'}
            rows="7"
            register={register}
          />
          <div className="mt_15"></div>
          <CustomSelectDropdown
            name="link"
            placeholder="Choose categories"
            options={allCategories?.map((item) => ({
              label: item?.category_name,
              value: item?.category_name
            }))}
            onChange={setSelectedCategoriesList}
            value={selectedCategoriesList}
            isMulti
          />
          {!branchesSameMenu && (
            <>
              <div className="mt_15"></div>
              <h5 className="mt-4">
                Item Linked with Restaurant Locations (Optional)
              </h5>
              <CustomSelectDropdown
                name="link"
                placeholder="Choose options"
                options={brands?.map((item) => ({
                  label: item?.name,
                  value: item?.id
                }))}
                onChange={setLinkedResturant}
                value={linkedResturant}
                isMulti
              />
            </>
          )}
          {selectedCategoriesList.length > 0 && (
            <>
              <div className="mt_15"></div>
              <CustomSelectDropdown
                name="link"
                placeholder="Choose menu type"
                options={[]
                  .concat(
                    ...allCategories
                      ?.filter((item) =>
                        selectedCategoriesList
                          .map((data) => data.value)
                          .includes(item.category_name)
                      )
                      .map((item) => item.category_types)
                  )
                  .map((item) => ({
                    label: item,
                    value: item
                  }))}
                onChange={setSelectedCategoryTypes}
                value={selectedCategoryTypes}
                isMulti
              />
              {/* <BadgeCheckbox
            labelOne="Choose menu type"
            labels={
              [].concat(...allCategories?.filter(item => selectedCategoriesList.map(data => data.value).includes(item.id)).map(item => item.CategoryTypes)).map(item => ({
                label: item?.category_type_name, name: item?.category_type_name, id: item?.id
              }))}
              onChange={(e) => setSelectedCategoryTypes([...selectedCategoryTypes, e.target?.value])}
              // value={[].concat(...allCategories?.filter(item => selectedCategoriesList.map(data => data.value).includes(item.id)).map(item => item.CategoryTypes)).map(item =>  item?.category_type_name)}
          /> */}
            </>
          )}
          <div className="mt_15"></div>
          <CustomSelectDropdown
            name="link"
            placeholder="Availability Days"
            options={days}
            onChange={setSelectedDays}
            value={selectedDays}
            isMulti
          />
          {/* <BadgeCheckbox
            labelOne="Availability Days"
            labels={days}
          /> */}
          <div className="mt_15"></div>
          <TextField
            name="price"
            label={'Item Price'}
            type="number"
            error={
              errors?.price?.type === 'required' ? 'Price is required' : ''
            }
            validation={{
              required: true
            }}
            register={register}
            required
          />
          {/* <div className="mt_15"></div>
          <BadgeCheckbox
            // {...register('menu_status', {
            //   required: true
            // })}
            labelOne="Choose menu type"
            labels={[{ label: 'Activate', name: 'Activate', id: true },{ label: 'Deactivate', name: 'Deactivate', id: false } ]}
            onChange={(e) => setSelectedCategoryTypes([e.target?.value])}
          /> */}
          <div className="mt_15"></div>
          <CheckboxField
            name="taxable"
            id="taxable"
            value={false}
            register={register}
          >
            This item is taxable.
          </CheckboxField>
          <div className="mt_15"></div>
          <TextField
            name="defaultQuantity"
            label={'Default Quantity'}
            type="number"
            register={register}
          />
          <div className="mt_15"></div>
          <div className="grid grid_gap8">
            <div className="collg6">
              <CheckboxField
                name="forceQuantity"
                id="forceQuantity"
                value={false}
                register={register}
              >
                Force Quantity
              </CheckboxField>
            </div>
            <div className="collg6">
              <CheckboxField
                name="lockQuantity"
                id="lockQuantity"
                value="LOCK"
                register={register}
              >
                Lock Quantity
              </CheckboxField>
            </div>
            <div className="collg6 mt_15">
              <CheckboxField
                name="hideQuantity"
                id="hideQuantity"
                value={false}
                register={register}
              >
                Hide Quantity
              </CheckboxField>
            </div>
          </div>
          <div className="mt_15"></div>
          <TextField
            name="calorie"
            label={'Calorie (optional)'}
            type="number"
            register={register}
          />
          <div className="mt_15"></div>
          <h5>Menu Status</h5>
          <CheckboxField name="pos" id="pos" value={false} register={register}>
            POS
          </CheckboxField>
          <CheckboxField
            name="online_store"
            id="online_store"
            value={false}
            register={register}
          >
            Online Store
          </CheckboxField>
          <CheckboxField
            name="online_order"
            id="online_order"
            value={false}
            register={register}
          >
            Online ordering through partner websites and apps
          </CheckboxField>
        </div>
      </form>
      {error && (
        <div>
          <p style={{ color: 'red', fontWeight: '800' }}>
            {error?.response?.data?.message}
          </p>
        </div>
      )}
    </>
  );
};
