import { useState } from 'react';
import { Toasts } from 'components/toasts';
import { useFetchRequestsAgainstCloudKitchen } from 'hooks/cloud/useFetchRequestsAgainstCloudKitchen';
import { Button } from 'components/button';
import { client } from 'utilities/db';
import { RenderIf } from 'utilities/helpers';
import { useQueryClientHook } from 'hooks/queryClientHook';
import { ThemeTabs } from 'components/tabs';
import styles from './index.module.css';
import { Loading } from 'components/loading';

export const ViewConnections = ({ branchId, setViewRequestModal }) => {
  const [markupData, setMarkupData] = useState(null);

  const { cloudRequestData, cloudRequestLoading, cloudRequestRefetch } =
    useFetchRequestsAgainstCloudKitchen(
      `cloudKitchen/requests/${branchId}`,
      branchId
    );

  const [queryClient] = useQueryClientHook();

  const handleUpdateMarkup = async () => {
    try {
      const { id, markup } = markupData;
      let response = await client.post(
        `/cloudKitchen/updateCloudKitchenMarkUp/${id}`,
        {
          markup: parseInt(markup)
        }
      );
      response = response.data;
      cloudRequestRefetch();
      Toasts.success('Markup updated successfully');
      setMarkupData(null);
    } catch (error) {
      Toasts.error(error?.response?.data?.message);
    }
  };

  const handleUpdateStatus = async (id, status) => {
    try {
      let response = await client.post(
        `/cloudKitchen/updateCloudKitchenStatus/${id}`,
        {
          status,
          user: 'CLOUD'
        }
      );
      response = response.data;
      cloudRequestRefetch();
      Toasts.success('Status updated successfully');
      setMarkupData(null);
    } catch (error) {
      Toasts.error(error?.response?.data?.message);
    }
  };

  return (
    <div className={styles.modal_box}>
      <h3>
        <span className="ff_semi">View Sent Partnership Requests</span>
      </h3>
      <RenderIf isTrue={cloudRequestLoading}>
        <div className={`loading_full`}>
          <Loading />
        </div>
      </RenderIf>
      <RenderIf isTrue={!cloudRequestLoading && cloudRequestData}>
        {/* <h6>
          <strong>Requests Sent</strong>
        </h6> */}
        <div className="mt_20"></div>
        <ThemeTabs
          tabs={[
            { label: 'Waiting' },
            { label: 'Pending' },
            { label: 'Accepted' },
            { label: 'Rejected' }
          ]}
          panels={[
            {
              content: (
                <WaitingKitchenRequest
                  cloudRequestData={cloudRequestData}
                  markupData={markupData}
                  setMarkupData={setMarkupData}
                  handleUpdateStatus={handleUpdateStatus}
                  handleUpdateMarkup={handleUpdateMarkup}
                />
              )
            },
            {
              content: (
                <PendingKitchenRequest
                  cloudRequestData={cloudRequestData}
                  markupData={markupData}
                  setMarkupData={setMarkupData}
                  handleUpdateStatus={handleUpdateStatus}
                  handleUpdateMarkup={handleUpdateMarkup}
                />
              )
            },
            {
              content: (
                <AcceptedKitchenRequest cloudRequestData={cloudRequestData} />
              )
            },
            {
              content: (
                <RejectedKitchenRequest cloudRequestData={cloudRequestData} />
              )
            }
          ]}
          isSearch={<div></div>}
        />
      </RenderIf>
    </div>
  );
};

export const WaitingKitchenRequest = ({
  cloudRequestData,
  markupData,
  setMarkupData,
  handleUpdateStatus,
  handleUpdateMarkup
}) => {
  return (
    <>
      {cloudRequestData
        ?.filter((request) => request?.status === 'WAITING')
        ?.map((request) => {
          return (
            <div className="shadow_box p10 mb_8">
              <div className={styles.item_request}>
                <p className="f12">
                  Request sent for {request?.BrandModel?.brand_name} for branch{' '}
                  {request?.BranchModel?.branch_name} at markup of{' '}
                  <span color="primary">
                    <b>{request?.markup}</b>
                  </span>{' '}
                  with status{' '}
                  <span color="primary">
                    <b>{request?.status}</b>
                  </span>
                </p>
                <div className={styles.buttons}>
                  <Button
                    label="Revise Markup"
                    color="outline-titary"
                    className="button button_radius button_sm"
                    onClick={() =>
                      setMarkupData({
                        id: request?.id,
                        markup: request?.markup
                      })
                    }
                  />
                  <RenderIf isTrue={request?.status_brand === 'Yes'}>
                    <Button
                      label="Accept Request"
                      color="bg-titary"
                      className="button button_radius button_sm"
                      onClick={() =>
                        handleUpdateStatus(request?.id, 'ACCEPTED')
                      }
                    />
                    <Button
                      label="Reject Request"
                      color="outline-danger"
                      className="button button_radius button_sm"
                      onClick={() =>
                        handleUpdateStatus(request?.id, 'REJECTED')
                      }
                    />
                  </RenderIf>
                  <RenderIf isTrue={markupData}>
                    <input
                      type="number"
                      onChange={(e) =>
                        setMarkupData({
                          ...markupData,
                          markup: parseInt(e.target.value)
                        })
                      }
                      defaultValue={request?.markup}
                      className={styles.input_markup}
                    />
                    <Button
                      label="Send Revised Markup"
                      color="bg-titary"
                      className="button button_radius button_sm"
                      onClick={handleUpdateMarkup}
                    />
                  </RenderIf>
                </div>
              </div>
            </div>
          );
        })}
    </>
  );
};

export const PendingKitchenRequest = ({ cloudRequestData }) => {
  return (
    <>
      {cloudRequestData
        ?.filter((request) => request?.status === 'PENDING')
        ?.map((request) => {
          return (
            <div className="shadow_box p10 mb_8">
              <p className="f12">
                Request sent for {request?.BrandModel?.brand_name} for branch{' '}
                {request?.BranchModel?.branch_name} at markup of{' '}
                <span color="primary">
                  <b>{request?.markup}</b>
                </span>{' '}
                with status{' '}
                <span color="primary">
                  <b>{request?.status}</b>
                </span>
              </p>
            </div>
          );
        })}
    </>
  );
};

export const AcceptedKitchenRequest = ({ cloudRequestData }) => {
  return (
    <>
      {cloudRequestData
        ?.filter((request) => request?.status === 'ACCEPTED')
        ?.map((request) => {
          return (
            <div className="shadow_box p10 mb_8">
              <p className="f12">
                Request sent for {request?.BrandModel?.brand_name} for branch{' '}
                {request?.BranchModel?.branch_name} at markup of{' '}
                <span color="primary">
                  <b>{request?.markup}</b>
                </span>{' '}
                with status{' '}
                <span color="primary">
                  <b>{request?.status}</b>
                </span>
              </p>
            </div>
          );
        })}
    </>
  );
};

export const RejectedKitchenRequest = ({ cloudRequestData }) => {
  return (
    <>
      {cloudRequestData
        ?.filter((request) => request?.status === 'REJECTED')
        ?.map((request) => {
          return (
            <div className="shadow_box p10 mb_8">
              <p className="f12">
                Request sent for {request?.BrandModel?.brand_name} for branch{' '}
                {request?.BranchModel?.branch_name} at markup of{' '}
                <span color="primary">
                  <b>{request?.markup}</b>
                </span>{' '}
                with status{' '}
                <span color="primary">
                  <b>{request?.status}</b>
                </span>
              </p>
            </div>
          );
        })}
    </>
  );
};
