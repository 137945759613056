import { ResetPage } from 'pages/forgotpassword/resetpass';
import { useState } from 'react';
import { RenderIf } from 'utilities/helpers';
import { ForgotPage } from 'pages/forgotpassword';

export const ForgotView = () => {
  const [email, setEmail] = useState('');
  const [step, setStep] = useState(1);
  return (
    <>
      <RenderIf isTrue={step === 1}>
        <ForgotPage setStep={setStep} setEmail={setEmail} />
      </RenderIf>
      <RenderIf isTrue={step === 2}>
        <ResetPage email={email} />
      </RenderIf>
    </>
  );
};
