export const getUser = () => {
  const user = localStorage.getItem('user')
    ? JSON.parse(localStorage.getItem('user'))
    : {};
  return user;
};

export const getLocationId = () => {
  const location = localStorage.getItem('onboardLocationId')
    ? JSON.parse(localStorage.getItem('onboardLocationId'))
    : '';
  return location;
};
export const getLocationData = () => {
  const location = localStorage.getItem('defaultBrand')
    ? JSON.parse(localStorage.getItem('defaultBrand'))
    : '';
  return location;
};

export const getBranchId = () => {
  const location = window.localStorage.getItem('defaultBranch')
    ? JSON.parse(window.localStorage.getItem('defaultBranch'))
    : '';
  return location?.id || '';
};

export const allUnits = [
  '',
  'kg',
  'pounds',
  'grams',
  'ounces',
  'liters',
  'teaspoon',
  'number/pieces',
  'tablespoon',
  'dozen'
];
