import { useState } from 'react';
import { useFetchZewstBrands } from 'hooks/brands/useFetchZewstBrands';
import { Button } from 'components/button';
import { RenderIf } from 'utilities/helpers';
import { client } from 'utilities/db';
import { CheckboxField } from 'components/checkbox';
import { Toasts } from 'components/toasts';
import styles from './index.module.css';
import { useNavigate, useParams } from 'react-router-dom';
import { Loading } from 'components/loading';

export const RequestConnections = () => {
  const { branchId } = useParams();

  const { zewstBrandData, zewstBrandLoading } = useFetchZewstBrands();

  const [selectedData, setSelectedData] = useState([]);

  const navigate = useNavigate();

  const handleSelect = (e, data) => {
    const { checked } = e.target;
    if (checked) {
      setSelectedData([...selectedData, { ...data, markup: 0 }]);
    } else {
      const filteredData = selectedData.filter(
        (item) => item.branch_id !== data.branch_id
      );
      setSelectedData(filteredData);
    }
  };

  const handleSubmitRequest = async () => {
    try {
      const brands = [...new Set(selectedData.map((item) => item?.brand_id))];
      const branches = brands.map((item) => {
        return {
          brand_id: item,
          branches: selectedData
            .filter((branch) => branch?.brand_id === item)
            .map((branch) => ({
              branch_id: branch?.branch_id,
              markup: branch?.markup
            }))
        };
      });

      const checkWhereMarkupIsZero = selectedData.filter(
        (item) => item?.markup === 0
      );

      if (checkWhereMarkupIsZero.length > 0) {
        Toasts.error('Please set markup for all selected branches');
        return;
      }

      const body = {
        id: branchId,
        brands: branches
      };

      if (branches?.length === 0) {
        Toasts.error('Please select at least one branch');
        return;
      }

      let response = await client.post('/cloudKitchen/addBrandBranches', body);
      response = response.data;
      console.log(
        '🚀 ~ file: index.jsx ~ line 6 ~ RequestConnections ~ response',
        response
      );
      Toasts.success('Requests sent successfully');
      navigate(-1);
      //setMakeRequestModal(false);
    } catch (error) {
      console.log(error);
      Toasts.error(error?.response?.data?.message);
    }
  };

  return (
    <div className={styles.modal_box}>
      <div
        className="flex item_space_btn"
        style={{ alignItems: 'center', marginTop: '20px' }}
      >
        <div className="mr_20">
          <h3>
            <span className="ff_semi">Partnership Requests</span>
          </h3>
          <RenderIf isTrue={!zewstBrandLoading && zewstBrandData}>
            <p>Please Select Brands and branches you want to connect with</p>
          </RenderIf>
        </div>
        <RenderIf isTrue={!zewstBrandLoading && zewstBrandData}>
          <div>
            <Button
              label="Send Request"
              color="bg-titary"
              onClick={handleSubmitRequest}
            />
          </div>
        </RenderIf>
      </div>
      <div className="mt_15"></div>
      <RenderIf isTrue={zewstBrandLoading}>
        <div className={`loading_full`}>
          <Loading />
        </div>
      </RenderIf>
      <RenderIf isTrue={!zewstBrandLoading && zewstBrandData}>
        <div>
          {zewstBrandData?.map((brand) => {
            return (
              <>
                <RenderIf isTrue={brand?.branches?.length > 0}>
                  <div className="shadow_box p12 mt_8">
                    <h6
                      style={{ color: '#8d23dd', textTransform: 'uppercase' }}
                    >
                      <strong></strong> <b>{brand?.brand_name}</b>
                    </h6>
                    <div className="mt_10"></div>
                    <div>
                      {brand?.branches?.length > 0 &&
                        brand?.branches?.map((branch, index) => {
                          return (
                            <div className="shadow_box p10 mt_8">
                              <div className={styles.branch_item}>
                                <div className={styles.check_item}>
                                  <div className={styles.checkbox}>
                                    <CheckboxField
                                      onChange={(e) => {
                                        handleSelect(e, {
                                          brand_id: brand?.id,
                                          branch_id: branch?.id
                                        });
                                      }}
                                    />
                                  </div>
                                  <div>
                                    <span className="ff_semi">
                                      {branch?.branch_name}
                                    </span>
                                  </div>
                                </div>
                                <div>
                                  <input
                                    type="number"
                                    onChange={(e) => {
                                      const { value } = e.target;
                                      const filteredData = selectedData.map(
                                        (item) => {
                                          if (
                                            item?.brand_id === brand?.id &&
                                            item?.branch_id === branch?.id
                                          ) {
                                            return {
                                              ...item,
                                              markup: parseInt(value)
                                            };
                                          }
                                          return item;
                                        }
                                      );
                                      setSelectedData(filteredData);
                                    }}
                                    placeholder="Markup %"
                                    className={styles.input_markup}
                                  />
                                </div>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </RenderIf>
              </>
            );
          })}
        </div>
      </RenderIf>
    </div>
  );
};
