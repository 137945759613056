import { TextField } from 'components/input';
import React from 'react';
import styles from './index.module.css';
import { Ikon } from 'assets/icons';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};
export default ({ search = '', setSearch }) => {
  const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
    const {
      carouselState: { currentSlide }
    } = rest;
    return (
      <div className={styles.actions}>
        <button
          className={styles.action_btn}
          style={{ marginLeft: '8px' }}
          type={`button`}
          onClick={() => previous()}
        >
          <Ikon leftArrow />
        </button>
        <button
          className={styles.action_btn}
          style={{ marginRight: '8px' }}
          type={`button`}
          onClick={() => next()}
        >
          <Ikon rightArrow />
        </button>
      </div>
    );
  };
  return (
    <>
      <div className="mt_30"></div>
      <div className={styles.block}>
        <div className={styles.head}>
          <h5>Prepared Items In Stock</h5>

          <TextField
            name="search"
            id="search"
            type="text"
            // label={`Search`}
            placeholder="search"
            onChange={(val) => setSearch(val.target.value)}
            value={search}
          />
        </div>
        <div className="mt_30"></div>
        <Carousel
          // containerClass={styles.caroousal_container}
          itemClass={styles.carousal_item}
          responsive={responsive}
          swipeable
          draggable
          arrows={false}
          renderButtonGroupOutside
          partialVisbile={false}
          customButtonGroup={<ButtonGroup />}
        >
          {[1, 1, 2, 2, 1, 1, 1, 1, 1, 1].map((item, index) => {
            return (
              <div className={styles.Item_box}>
                <ItemBox
                  image={
                    'https://cdn.pixabay.com/photo/2022/08/15/03/07/path-7387018_960_720.jpg'
                  }
                  name="Manchurian"
                  serving="10"
                />
              </div>
            );
          })}
        </Carousel>
      </div>
    </>
  );
};

const ItemBox = ({ image, name, serving }) => (
  <div className={styles.imgbox}>
    <img className={styles.img} src={image} />
    <p className={styles.name}>{name}</p>
    <p className={styles.servetxt}>{serving} Serving</p>
  </div>
);
