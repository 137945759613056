import { RenderIf } from 'utilities/helpers';
import { Button } from 'components/button';
import { CheckboxField } from 'components/checkbox';
import { Ikon } from 'assets/icons';
import { TEMPImgs } from 'theme/images';
import styles from './index.module.css';

const TicketsData = [
  {
    orderId: '12',
    tableId: '',
    expectedTime: '32',
    pickFromBrand: 'KFC',
    orderTime: '',
    location: '',
    status: 'green',
    ticketTime: '08:19 PM',
    foods: [
      {
        foodName: 'Drinks',
        foodItems: ['Margarita', 'Gin tonic ( note: adbabdabdja)']
      },
      {
        foodName: 'Appetizers',
        foodItems: [
          'Breadsticks (extra cheese)',
          'Mozarella topsticks with ham',
          'Cheece crackers with hot sauce'
        ]
      },
      {
        foodName: 'Main Courses',
        foodItems: [
          'Château Lafite Rothschild (Bordeaux)',
          'Mozarella topsticks with ham'
        ]
      }
    ]
  },
  {
    orderId: '',
    tableId: '23',
    expectedTime: '',
    pickFromBrand: '',
    orderTime: '21:18:39',
    location: '143,NY',
    status: 'orange',
    ticketTime: '08:49 PM',
    foods: [
      {
        foodName: 'Drinks',
        foodItems: ['Aperol Spritz', 'Coca Cola Zero']
      },
      {
        foodName: 'Appetizers',
        foodItems: ['Seafood soup']
      },
      {
        foodName: 'Main Courses',
        foodItems: ['Spaggheti Carbonara']
      }
    ]
  },
  {
    orderId: '',
    tableId: '40',
    expectedTime: '',
    pickFromBrand: '',
    orderTime: '41:12:09',
    location: '143,NY',
    status: 'red',
    ticketTime: '07:58 PM',
    foods: [
      {
        foodName: 'Drinks',
        foodItems: ['Margarita', 'Gin tonic']
      },
      {
        foodName: 'Appetizers',
        foodItems: [
          'Breadsticks',
          'Mozarella topsticks with ham',
          'Cheece crackers with hot sauce'
        ]
      },
      {
        foodName: 'Main Courses',
        foodItems: [
          'Château Lafite Rothschild (Bordeaux)',
          'Mozarella topsticks with ham'
        ]
      }
    ]
  },
  {
    orderId: '12',
    tableId: '',
    expectedTime: '32',
    pickFromBrand: 'KFC',
    orderTime: '',
    location: '',
    status: 'green',
    ticketTime: '08:19 PM',
    foods: [
      {
        foodName: 'Drinks',
        foodItems: ['Margarita', 'Gin tonic ( note: adbabdabdja)']
      },
      {
        foodName: 'Appetizers',
        foodItems: [
          'Breadsticks (extra cheese)',
          'Mozarella topsticks with ham',
          'Cheece crackers with hot sauce'
        ]
      },
      {
        foodName: 'Main Courses',
        foodItems: [
          'Château Lafite Rothschild (Bordeaux)',
          'Mozarella topsticks with ham'
        ]
      }
    ]
  },
  {
    orderId: '',
    tableId: '23',
    expectedTime: '',
    pickFromBrand: '',
    orderTime: '21:18:39',
    location: '143,NY',
    status: 'orange',
    ticketTime: '08:49 PM',
    foods: [
      {
        foodName: 'Drinks',
        foodItems: ['Aperol Spritz', 'Coca Cola Zero']
      },
      {
        foodName: 'Appetizers',
        foodItems: ['Seafood soup']
      },
      {
        foodName: 'Main Courses',
        foodItems: ['Spaggheti Carbonara']
      }
    ]
  }
];

export const Tickets = () => {
  return (
    <>
      <div className={`grid grid_gap30`}>
        {TicketsData?.map((item, index) => (
          <div className={`collg4`} key={index}>
            <div className={`mt_30`}></div>
            <CardTicket
              orderId={item?.orderId}
              tableId={item?.tableId}
              expectedTime={item?.expectedTime}
              pickFromBrand={item?.pickFromBrand}
              orderTime={item?.orderTime}
              location={item?.location}
              status={item?.status}
              ticketTime={item?.ticketTime}
              foods={item?.foods}
            />
          </div>
        ))}
      </div>
    </>
  );
};

export const CardTicket = (props) => {
  const {
    orderId,
    tableId,
    expectedTime,
    pickFromBrand,
    orderTime,
    location,
    status,
    ticketTime,
    foods = []
  } = props;
  return (
    <>
      <article className={styles.ticket}>
        <div className={styles.ticketHeader}>
          <div className={styles.thLeft}>
            <div className={styles.badge}>
              <RenderIf isTrue={orderId}>
                <span>
                  <Ikon name="upArrowOrder" />
                </span>
                <span>Order id {orderId}</span>
              </RenderIf>
              <RenderIf isTrue={tableId}>
                <span>Table {tableId}</span>
              </RenderIf>
            </div>
            <div className={styles.time}>
              <RenderIf isTrue={expectedTime}>
                <div>{expectedTime} minutes</div>
                <div className={styles.expectText}>Expected time</div>
              </RenderIf>
              <RenderIf isTrue={orderTime}>
                <div>{orderTime}</div>
              </RenderIf>
            </div>
          </div>
          <div>
            <RenderIf isTrue={pickFromBrand}>
              <div className={styles.brand}>
                <div>
                  <picture>
                    <source srcSet={TEMPImgs?.ImgDelivery} />
                    <img src={TEMPImgs?.ImgDelivery} alt="" />
                  </picture>
                </div>
                <div>{pickFromBrand}</div>
              </div>
            </RenderIf>
            <RenderIf isTrue={location}>
              <div>
                <div className={styles.locationPin}>
                  <span>
                    <Ikon name="locationPin" />
                  </span>
                  <span>{location}</span>
                </div>
              </div>
            </RenderIf>
          </div>
        </div>
        <div className={styles.status}>
          <div
            className={
              status === 'green'
                ? styles.green
                : status === 'orange'
                ? styles.orange
                : status === 'red'
                ? styles.red
                : null
            }
            style={{
              width:
                status === 'green'
                  ? '33.333%'
                  : status === 'orange'
                  ? '66.666%'
                  : status === 'red'
                  ? '100%'
                  : null
            }}
          ></div>
        </div>
        <div className={styles.ticketBody}>
          <div className={`mt_30`}></div>
          <div className={styles.ticketTime}>{ticketTime}</div>
          {foods?.length &&
            foods.map((item, index) => (
              <div className={styles.foodItem}>
                <div className={`mt_20`}></div>
                <div className={styles.fitemName}>{item?.foodName}</div>
                {item?.foodItems &&
                  item?.foodItems?.length &&
                  item?.foodItems?.map((it, i) => (
                    <div className={styles.fitem} key={i}>
                      <CheckboxField
                        name={item?.foodName.replace(/\s+/g, '_') + i}
                        id={item?.foodName.replace(/\s+/g, '_') + i}
                        value="YES"
                      >
                        {it}
                      </CheckboxField>
                    </div>
                  ))}
              </div>
            ))}
        </div>
        <div className={styles.ticketFooter}>
          <div>
            <Button color="outline-primary" label={'Done'} />
          </div>
          <div>
            <Button color="bg-primary" type="submit" label={'Serve'} />
          </div>
        </div>
      </article>
    </>
  );
};
